import { Table } from "@wit/mpesa-ui-components";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import moment from "moment";
import React from "react";
import styleStatusCell from "../utils/style-status-cell";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";

/**
 * Renders notifications list table
 * @returns {React.Component}
 */
const NotificationsListTable = ({ filteredNotifications }: any) => {
  const [t] = useTranslation();
  const history = useHistory();

  /** Renders table columns */
  const getTableColumns = (): ColumnProps[] => {
    return [
      {
        formKey: "campaignName",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.notifications.listPage.campaignName"),
      },

      {
        formKey: "createdOn",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.topics.listPage.createdOn"),
        changeFunction: content => <span>{moment(content).format("DD/MM/YYYY")}</span>,
      },
      {
        formKey: "startDate",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.notifications.listPage.startOn"),
        changeFunction: content => <span>{moment(content).format("DD/MM/YYYY")}</span>,
      },
      {
        formKey: "status",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.notifications.listPage.status"),
        changeFunction: content => styleStatusCell(content),
      },
    ];
  };
  return (
    <Table
      values={filteredNotifications}
      columns={getTableColumns()}
      rowClickAction={(index, rowData: any) =>
        history.push(RoutesEnum.SFC_EDIT_NOTIFICATION.replace(":notificationId", rowData.id))
      }
    />
  );
};

export default NotificationsListTable;
