import { Table, ChevronRightIcon, RecurringIcon, useAlert, DownloadIcon } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { DocDashboardUtils } from "../doc-dashboard.utils";
import { DocValidationActions } from "../../doc-validation/doc-validation.store";
import { DocDashboardActions, IDocDashboardOverallRatingDetailsReducerInterface } from "../doc-dashboard.store";
import { PageContainer, PageTitle } from "../../../../shared/shared.styled";
import { LoadingText } from "../../../../shared/shared.styled";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import DocValidationApi from "../../doc-validation/doc-validation.api";
import DocDashboardApi from "../doc-dashboard.api";
import { RoutesEnum } from "../../../../routes/routes.constants";
import {
  IClientDocSubmission,
  IClientDocSubmissionsRequest,
  IClientDocSubmissionsResponse,
} from "../../doc-validation/doc-validation.interface";
import { DocValidationUtils } from "../../doc-validation/doc-validation.utils";
import { useHistory } from "react-router-dom";
import { ILoggedUser } from "../../../authentication/authentication.interfaces";
import EmptyTable from "@wit/mpesa-ui-components/lib/components/table/empty-states/empty-table.component";
import { renderDate } from "../../../../shared/shared.utils";
import { IRatingsCount } from "../doc-dashboard.interface";
import { Buffer } from "buffer";

const DocDashboardPage = () => {
  const DASH_NON_ERROR_STATUS = ["ACCEPTED", "REJECTED", "PENDING_MANUAL_REVIEW"];
  const DASH_ERROR_STATUS = [
    "ERROR_ON_G2",
    "ERROR_ON_FACE_DUPLICATIONS",
    "ERROR_ON_ID_VS_SELFIE",
    "ERROR_ON_ID_DUPLICATIONS",
    "ERROR_ON_CRITICAL_FIELDS",
    "PENDING",
  ];

  const history = useHistory();

  // Hooks init
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  // Local state definitions
  const [isFetchingDocs, setIsFetchingDocs] = React.useState(true);
  const [isFetchingCounters, setIsFetchingCounters] = React.useState(true);
  const [docRequestParams, setDocRequestParams] = React.useState<IClientDocSubmissionsRequest>({
    page: 1,
    pageSize: 10,
    sort: {
      fieldName: "createdAt",
      directionType: "DESC",
    },
  });

  // Redux state
  const { clientSubmissionDocs } = useSelector((store: IStoreInterface) => store.docValidationReducer);
  const { counterEntries, lastUpdatedError } = useSelector((store: IStoreInterface) => store.docDashboardReducer);

  const ratingEntries: IDocDashboardOverallRatingDetailsReducerInterface = useSelector(
    (store: IStoreInterface) => store.docDashboardOverallRatingDetailsReducer,
  );
  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);

  /*
   * Updates the docs table the first time and every time the "activePage" changes
   */
  React.useEffect(() => {
    getCounters();
    getClientDocSubmissions();
    getOverallRatingDetails();
  }, [docRequestParams]);

  const downloadCsv = () => {
    DocDashboardApi.methods.getRegistrationsCsv().then(
      res => {
        const csvData = res.data.content;
        const a = Buffer.from(csvData, "base64").toString();
        const filename = `registrations_export_${moment(new Date()).format("YYYYMMDD_Hmmss")}.csv`;
        const fileDownload = require("js-file-download");
        fileDownload(a, filename);
      },
      () => {
        setAlertProps({
          title: t("pages.docValidation.getDocsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  const getCounters = () => {
    setIsFetchingCounters(true);

    DocDashboardApi.methods
      .getCounters()
      .finally(() => setIsFetchingCounters(false))
      .then(
        res => {
          dispatch(DocDashboardActions.creators.fetchCountersSuccessAction(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.docDashboard.getCountersError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  const getOverallRatingDetails = () => {
    setIsFetchingDocs(true);
    DocDashboardApi.methods
      .getOverallRatingDetails()
      .finally(() => setIsFetchingCounters(false))
      .then(
        response => dispatch(DocDashboardActions.creators.fetchOverallRatingDetails(response.data)),

        () => {
          setAlertProps({
            title: t("pages.docDashboard.getOverallRatingError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  const getClientDocSubmissions = () => {
    setIsFetchingDocs(true);

    DocValidationApi.methods
      .getClientDocSubmissions(docRequestParams)
      .finally(() => setIsFetchingDocs(false))
      .then(
        res => {
          const response: IClientDocSubmissionsResponse = res.data;
          dispatch(
            DocValidationActions.creators.fetchDocsSuccessAction(response.registrations, response.totalElements),
          );
        },
        () => {
          setAlertProps({
            title: t("pages.docDashboard.getDocsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  // Renders
  return (
    <PageContainer>
      <PageTitle>{t("pages.docDashboard.title", { username: user.fullName })}</PageTitle>
      <SubTitle>
        <span>{t("pages.docDashboard.subtitle")}</span> {renderDate({ date: moment(user.lastLoginTs) })}
      </SubTitle>

      {isFetchingCounters ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <DashboardCountersContainer>
          <DashboardStatusContainer>
            <DashboardActionsContainer>
              <SectionTitle>{t("pages.docDashboard.sectionTitleBalance")}</SectionTitle>
              <DashboardActionsWrapper>
                <ExportCsv
                  onClick={() => {
                    downloadCsv();
                  }}
                >
                  <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed} style={{ marginRight: 5 }}>
                    <DownloadIcon />
                  </SmallButtonIconContainer>
                  <span>{t("pages.docDashboard.exportBtnLabel")}</span>
                </ExportCsv>
                <RefreshResults
                  onClick={() => {
                    getCounters();
                    getOverallRatingDetails();
                    getClientDocSubmissions();
                  }}
                >
                  <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue} style={{ marginRight: 5 }}>
                    <RecurringIcon />
                  </SmallButtonIconContainer>
                  <span>{t("pages.docDashboard.refreshResults")}</span>
                </RefreshResults>
              </DashboardActionsWrapper>
            </DashboardActionsContainer>
            <DocCounters>
              {counterEntries instanceof Map ? (
                DASH_NON_ERROR_STATUS.map((status, key) => {
                  let entry = counterEntries.get(status);

                  if (!entry) {
                    entry = {
                      status: status,
                      counter: 0,
                      percentage: 0,
                      lastUpdate: Date.now(),
                    };
                  }

                  const color = DocValidationUtils.getClientDocStatusColor(`${entry.status}`);
                  return (
                    <>
                      <DocCounterCard key={key} color={color} height={260}>
                        <DocCounterStatusWrapper>
                          <DocCounterStatusLabel>
                            {t(`commons.clientDocSubmissionStatus.${entry.status}`)}
                          </DocCounterStatusLabel>
                          <DocStatusCounter>{entry.counter}</DocStatusCounter>
                        </DocCounterStatusWrapper>
                        <DocStatusPercentage>{entry.percentage}%</DocStatusPercentage>
                        <DocLastUpdateLabel>
                          {t("pages.docDashboard.lastUpdate")} {renderDate({ date: moment(entry.lastUpdate) })}
                        </DocLastUpdateLabel>
                      </DocCounterCard>
                    </>
                  );
                })
              ) : (
                <></>
              )}

              <DocCounterCard color={styleTheme.palette.digitalRed} height={260}>
                <DocErrorContainer>
                  {counterEntries instanceof Map ? (
                    DASH_ERROR_STATUS.map((status, key) => {
                      let entry = counterEntries.get(status);
                      if (!entry) {
                        entry = {
                          status: status,
                          counter: 0,
                          percentage: 0,
                          lastUpdate: Date.now(),
                        };
                      }

                      const color = DocValidationUtils.getClientDocStatusColor(`${entry.status}`);

                      return (
                        <DocErrorItem key={key}>
                          <DocErrorLabel color={color}>
                            {t(`commons.clientDocSubmissionStatus.${entry.status}`)}
                          </DocErrorLabel>
                          <DocErrorCounterContainer>
                            <DocErrorCounter color={color}>{entry.counter}</DocErrorCounter>
                            <DocErrorPercentage color={styleTheme.palette.midGrey}>
                              ({entry.percentage})%
                            </DocErrorPercentage>
                          </DocErrorCounterContainer>
                        </DocErrorItem>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </DocErrorContainer>
                <DocLastUpdateLabel>
                  {t("pages.docDashboard.lastUpdate")} {renderDate({ date: moment(lastUpdatedError) })}
                </DocLastUpdateLabel>
              </DocCounterCard>
            </DocCounters>
          </DashboardStatusContainer>
          <DocRatingContainer>
            <SectionTitle marginBottom={14}>{t("pages.docDashboard.rating.customerReview")}</SectionTitle>
            <DocCounterCard>
              <DocReviewWrapper>
                <AverageReviewLabel>
                  {ratingEntries.average ? (
                    <>
                      <CurrentRating>{ratingEntries.average.toFixed(1)}</CurrentRating>
                      <TotalRating>/5</TotalRating>
                    </>
                  ) : (
                    <>{t("pages.docDashboard.rating.noDataLabel")}</>
                  )}
                </AverageReviewLabel>
                <DocStarRatingWrapper>
                  {[...Array(5)].map((_, key) => (
                    <DocStarRatingItem
                      key={key}
                      color={
                        ratingEntries.average
                          ? ~~ratingEntries.average >= key + 1
                            ? styleTheme.palette.lemonYellow
                            : "rgba(0, 124, 146, 0.2)"
                          : "rgba(0, 124, 146, 0.2)"
                      }
                    >
                      <div>
                        <span>&#9733;</span>
                      </div>
                    </DocStarRatingItem>
                  ))}
                </DocStarRatingWrapper>
                {ratingEntries.ratingsCount ? (
                  <DocAveragesRatingWrapper>
                    {ratingEntries.ratingsCount.map((ratingEntry: IRatingsCount, key) => (
                      <DocAveragesRatingItem key={key}>
                        <DocAveragesRatingItemStars>
                          <div>
                            {ratingEntry.rating} {t("pages.docDashboard.rating.star")}
                          </div>
                        </DocAveragesRatingItemStars>
                        <RatingProgressBar progress={ratingEntry.value}>
                          <div />
                        </RatingProgressBar>
                        <DocAveragesRatingItemPercentage>
                          {ratingEntry.value.toFixed(2)}%
                        </DocAveragesRatingItemPercentage>
                      </DocAveragesRatingItem>
                    ))}
                  </DocAveragesRatingWrapper>
                ) : (
                  ""
                )}
              </DocReviewWrapper>
              <DocReviewFooter>
                <DocReviewFooterWrapper>
                  <DocReviewFooterLabel>{t("pages.docDashboard.rating.registrationsSubmitted")}</DocReviewFooterLabel>
                  <DocReviewFooterCounter>{ratingEntries.registrationsSubmitted}</DocReviewFooterCounter>
                </DocReviewFooterWrapper>
                <DocReviewFooterWrapper>
                  <DocReviewFooterLabel>{t("pages.docDashboard.rating.surveyResponses")}</DocReviewFooterLabel>
                  <DocReviewFooterCounter>{ratingEntries.ratingsSubmitted}</DocReviewFooterCounter>
                </DocReviewFooterWrapper>
              </DocReviewFooter>
              <DocLastUpdateLabel>
                {t("pages.docDashboard.rating.lastFetchLabel")} {renderDate({ date: moment(ratingEntries.lastFetch) })}
              </DocLastUpdateLabel>
            </DocCounterCard>
          </DocRatingContainer>
        </DashboardCountersContainer>
      )}

      <SectionTitle style={{ marginTop: 20, marginBottom: "17px" }}>
        {t("pages.docDashboard.sectionTitleRequests")}
      </SectionTitle>
      <DocValidationListContainer style={{ position: "relative", marginBottom: 40 }}>
        {isFetchingDocs ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <ViewAllRequests
              onClick={() => {
                history.push({
                  pathname: RoutesEnum.DOC_VALIDATION,
                  state: {
                    state: {
                      successfulUpadte: false,
                    },
                  },
                });
              }}
            >
              <span>{t("pages.docDashboard.seeAllRequests")}</span>
              <SmallButtonIconContainer style={{ marginLeft: 5 }}>
                <ChevronRightIcon />
              </SmallButtonIconContainer>
            </ViewAllRequests>
            <RaisedPanel style={{ padding: "10px 48px" }}>
              <Table<IClientDocSubmission>
                columns={DocDashboardUtils.getTableColumns()}
                values={clientSubmissionDocs}
                emptyStateComponent={<EmptyTable text={t("commons.emptyTable")} />}
                rowClickAction={(idx, rowData) =>
                  history.push({
                    pathname: RoutesEnum.DOC_VALIDATION_DETAILS,
                    state: {
                      remoteId: rowData.remoteId,
                    },
                  })
                }
              />
            </RaisedPanel>
          </>
        )}
      </DocValidationListContainer>
    </PageContainer>
  );
};

export default DocDashboardPage;

const DashboardCountersContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: fill-available;
`;

const DashboardStatusContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 56vw;
`;

const DashboardActionsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  font-family: "Vodafone Rg";
  align-items: center;
  margin-bottom: 14px;
`;

const DashboardActionsWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const ExportCsv = styled("div")`
  display: flex;
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
`;

const RefreshResults = styled("div")`
  display: flex;
  margin-left: 5px;
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
`;

const DocCounters = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DocCounterCard = styled("div")<{ color?: string; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 27vw;
  min-width: 351px;
  height: ${({ height }) => (height ? `${height}px` : "fill-available")};
  margin: 0 0 25px 0;
  padding: 20px 24px 12px;
  color: ${({ color }) => color || "inherit"};
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
`;

const DocCounterStatusWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
`;

const DocCounterStatusLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  line-height: 1.31;
`;

const DocStatusCounter = styled("div")`
  margin: 34px 0;
  font-family: "Vodafone Rg";
  font-size: 46px;
`;

const DocStatusPercentage = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  line-height: 1.19;
  margin-bottom
`;

const DocLastUpdateLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  line-height: 1.36;
`;

const DocErrorContainer = styled("ul")`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const DocErrorItem = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
  margin-bottom: 9px;
  padding-bottom: 8px;
`;

const DocErrorLabel = styled("div")<{ color: string }>`
  width: fill-available;
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${({ color }) => color};
`;

const DocErrorCounterContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 35%;
  font-family: "Vodafone Rg";
  font-size: 14px;
`;

const DocErrorCounter = styled("div")<{ color: string }>`
  color: ${({ color }) => color};
`;

const DocErrorPercentage = styled("div")<{ color: string }>`
  width: 38px;
  display: flex;
  font-size: 11px;
  margin-left: 7px;
  justify-content: flex-end;
  align-items: center;
  color: ${({ color }) => color};
`;

const DocRatingContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: 23px;
`;

const DocReviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
`;

const AverageReviewLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-weight: bold;
`;

const CurrentRating = styled("span")`
  font-size: 50.4px;
`;

const TotalRating = styled("span")`
  font-size: 30px;
`;

const DocStarRatingWrapper = styled("ul")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  margin: 24px 0 32px 0;
  padding: 0;
  list-style-type: none;
  font-size: 40pt;
  background-color: rgba(0, 124, 146, 0.08);
  border-radius: 6px;
`;

const DocStarRatingItem = styled("li")<{ color?: string }>`
  font-size: 60%;
  margin: 0 6px 0 0;
  color: ${({ color }) => color} !important;
`;

const DocAveragesRatingWrapper = styled("ul")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DocAveragesRatingItem = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const DocAveragesRatingItemStars = styled("div")`
  margin-right: 17px;
  font-family: "Vodafone Rg";
  font-size: 13px;
  color: ${styleTheme.palette.turquoiseBlue};

  > div {
    width: max-content;
  }
`;

const RatingProgressBar = styled("div")<{ progress: number }>`
  width: fill-available;
  height: 10px;
  background-color: #ecf5f7;
  border-radius: 6px;

  > div {
    width: ${({ progress }) => progress}%;
    height: fill-available;
    max-height: 100%;
    max-width: 100%;
    background-color: ${styleTheme.palette.lemonYellow};
    border-radius: 6px;
  }
`;

const DocAveragesRatingItemPercentage = styled("div")`
  margin-left: 17px;
  font-family: "Vodafone Rg";
  font-size: 13px;
  color: ${styleTheme.palette.anthracite};
`;

const DocReviewFooter = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 27px 0 20px 0;
`;

const DocReviewFooterWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 13px;
`;

const DocReviewFooterLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${styleTheme.palette.anthracite};
`;

const DocReviewFooterCounter = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 20px;
  color: ${styleTheme.palette.turquoiseBlue};
`;

const DocValidationListContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 43px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SectionTitle = styled("div")<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  font-family: Vodafone Rg;
  font-size: 18px;
  font-weight: bold;
`;

const RaisedPanel = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
`;

const ViewAllRequests = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  position: absolute;
  right: 0;
  top: -38px;
  cursor: pointer;

  * {
    display: inline-block;
    vertical-align: middle;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color?: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => (props.color ? props.color : props.theme.palette.midGrey)};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  }
`;
