import {
  AutoClosingDropdown,
  ModalTypeEnum,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TextArea,
  TextInput,
  useAlert,
  useModal,
  UpDown,
  Toggle,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik } from "formik";
import i18next from "i18next";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { ConfigContext } from "../../../../app.component";
import { IStoreInterface } from "../../../../configs/store.config";

import { RoutesEnum } from "../../../../routes/routes.constants";
import Dropzone from "../../../../shared/components/file-dropzone-small.component";
import ProgressIndicator from "../../../../shared/components/progress-indicator/progress-indicator.component";
import { IApp, IReleasedVersion } from "../../../../shared/models/app.model";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import AppsApi from "../../../apps/apps.api";
import {
  FormLabel,
  FormLabelSecondary,
  FormSectionContainer,
  FormSectionRow,
  FormSectionTitle,
  SectionColumn,
} from "../../../configurations/discover-cards/add-discover-card/add-discover-card.page";
import { DiscoverCardFlowEnum } from "../../../configurations/discover-cards/discover-cards.model";
import DynamicScreensApi from "../../../configurations/dynamic-screens/dynamic-screens.api";
import { IDynamicScreen } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import { DynamicScreensActions } from "../../../configurations/dynamic-screens/dynamic-screens.store";
import PushNotificationsApi, { ICreatePushNotificationPayload } from "../push-notifications.api";
import {
  PushNotificationAppVersionsEnum,
  PushNotificationLinkTypeEnum,
  PushNotificationUsersEnum,
  IPushNotificationLinkContent,
} from "../push-notifications.model";
import MPESAAppIcon from "./mpesa-app.icon";
import PushNotificationDetailsModal from "./push-notification-details.modal";
import moment from "moment";
import {
  filterDropdownOptions,
  IDropdownOptionsInterface,
} from "../../../configurations/discover-cards/discover-cards.utils";
import { ServiceStatusEnum } from "../../../../shared/models/service-builder.model";
import { BundleTypeStatusEnum } from "../../../configurations/bundles/bundles.model";
import { ServicesActions } from "../../../service-builder/services.store";
import BundlesApi from "../../../configurations/bundles/bundles.api";
import ServicesApi from "../../../service-builder/services.api";
import { BundlesActions } from "../../../configurations/bundles/bundles.store";
import { AntServiceManagerActions } from "../../../ant-service-manager/ant-service-manager.store";
import AntServiceManagerApi from "../../../ant-service-manager/ant-service-manager.api";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import { RegExEpressions } from "../../../../shared/shared.utils";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

/**
 *
 * @param value
 * @param setLanguage
 * @param availableLanguages
 */
const validateLanguages = (
  value: any,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!value) {
    return false;
  }
  for (let l = 0; l < availableLanguages.length; l++) {
    if (!value[availableLanguages[l].code] && availableLanguages[l].mandatory) {
      setLanguage(availableLanguages[l].code.toString());
      return false;
    } else if (!value[availableLanguages[l].code]) {
      setShowWarning(true);
    }
  }
  return true;
};

/**
 * Get validation schema notification settings
 * @param setLanguage
 * @param availableLanguages
 */
const getValidationSchemaNotificationSettings = (
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return object().shape({
    title: object()
      .shape(getTranslationValidators(availableLanguages))
      .test("name-lang", i18next.t("pages.pushNotifications.add.errors.mandatory"), value =>
        validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
      ),
    message: object()
      .shape(getTranslationValidators(availableLanguages))
      .test("desc-lang", i18next.t("pages.pushNotifications.add.errors.mandatory"), value =>
        validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
      ),
  });
};

/**
 * Returns the validators for the service name translations
 * @param availableLanguages - available languages for the apps
 */
const getTranslationValidators = (availableLanguages: ILanguage[]) => {
  let validators: { [x: string]: any } = {};
  availableLanguages.map((lang: ILanguage) => {
    if (lang.mandatory) {
      validators = {
        ...validators,
        [lang.code]: string()
          .trim()
          .required(i18next.t("pages.pushNotifications.add.errors.mandatory"))
          .max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    } else {
      validators = {
        ...validators,
        [lang.code]: string().max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    }
  });
  return validators;
};

/**
 * Get validation schema send settings
 * @param actualType
 * @param actualUsers
 * @param setLanguage
 * @param availableLanguages
 */
const getValidationSchemaSendSettings = (
  actualType: string,
  actualUsers: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  availableLanguages: ILanguage[],
  isImmediately: boolean,
) => {
  let base: { [k: string]: any } = {
    users: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
    appVersion: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
    linkType: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
    csv: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
  };
  if (!isImmediately) {
    base = {
      ...base,
      date: string()
        .required(i18next.t("pages.pushNotifications.add.errors.mandatory"))
        .test("format", i18next.t("pages.pushNotifications.add.errors.wrongFormat"), function(date) {
          if (!date) {
            return true;
          }
          const valid = moment(date, "DD/MM/YYYY").format("DD/MM/YYYY") === date;
          return valid;
        })
        .test("is-greater", i18next.t("pages.pushNotifications.add.errors.timeGreater"), function(date) {
          if (!date) {
            return true;
          }
          return moment(date, "DD/MM/YYYY").isSameOrAfter(Date.now(), "day");
        }),
      hours: string()
        .required(i18next.t("pages.pushNotifications.add.errors.mandatory"))
        .test("is-greater", i18next.t("pages.pushNotifications.add.errors.timeGreater"), function(hour) {
          const { date } = this.parent;
          if (!date) {
            return true;
          }
          return moment(date, "DD/MM/YYYY").isSame(Date.now(), "day") ? moment().hour() <= Number(hour) : true;
        })
        .test("is-greater", i18next.t("pages.pushNotifications.add.errors.hoursGreater"), function(hour) {
          return Number(hour) <= 23 && Number(hour) >= 0;
        }),
      minutes: string()
        .required(i18next.t("pages.pushNotifications.add.errors.mandatory"))
        .test("is-greater", i18next.t("pages.pushNotifications.add.errors.timeGreater"), function(minutes) {
          const { date } = this.parent;
          const { hours } = this.parent;
          if (!date) {
            return true;
          }
          return moment(date, "DD/MM/YYYY").isSame(Date.now(), "day")
            ? (moment().hour() <= Number(hours) && moment().minutes() <= Number(minutes)) ||
                moment().hour() < Number(hours)
            : true;
        })
        .test("is-greater", i18next.t("pages.pushNotifications.add.errors.minutesGreater"), function(minutes) {
          return Number(minutes) <= 59 && Number(minutes) >= 0;
        }),
    };
  }
  if (actualUsers !== PushNotificationUsersEnum.UPLOADED_USERS) {
    delete base.csv;
  }

  if (actualType === PushNotificationLinkTypeEnum.NATIVE) {
    return object().shape({
      sendSettings: object().shape({
        ...base,
        linkFlow: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
      }),
    });
  } else if (actualType === PushNotificationLinkTypeEnum.DYNAMIC_SCREEN) {
    return object().shape({
      sendSettings: object().shape({
        ...base,
        linkEventId: string().required(i18next.t("pages.pushNotifications.add.errors.mandatory")),
        name: object().shape(getTranslationValidators(availableLanguages)),
      }),
    });
  } else {
    return object().shape({
      sendSettings: object().shape({
        ...base,
        linkUri: string()
          .required(i18next.t("pages.pushNotifications.add.errors.mandatory"))
          .matches(RegExEpressions.uri, i18next.t("pages.pushNotifications.add.errors.invalidURI")),
      }),
    });
  }
};

/**
 * Add push notification
 */
const AddPushNotificationPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
  const { config } = useContext(ConfigContext);
  const bundles = useSelector((store: IStoreInterface) => store.bundlesReducer.bundles);
  const miniApps = useSelector((store: IStoreInterface) => store.servicesReducer.services);
  const antMiniApps = useSelector((store: IStoreInterface) => store.antServiceManagerReducer.services);
  const { dynamicScreens } = useSelector((state: IStoreInterface) => state.dynamicScreensReducer);
  const [dynamicScreensOptions, setDynamicScreensOptions] = React.useState<{ key: string; label: string }[]>();

  const MAX_FILE_SIZE = 1000000;

  /*Csv file*/
  const [csvFile, setFile] = React.useState<any>();

  /*File string error*/
  const [fileError, setFileError] = React.useState("");

  //NOTE: form section classes styles are reused from discover cards

  const [initialNotificationSettings, setInitialNotificationSettings] = useState({
    title: undefined,
    message: undefined,
  });

  const [initialSendSettings, setInitialSendSettings] = useState({
    sendSettings: {
      users: undefined,
      csv: undefined,
      appVersion: undefined,
      linkType: undefined,
      linkUri: undefined,
      linkFlow: undefined,
      linkEventId: undefined,
      name: {},
      date: "",
      hours: "",
      minutes: "",
      sendImmediately: false,
      scheduledDate: undefined,
    },
  });

  const [actualUsers, setActualUsers] = useState("");
  const [actualType, setActualType] = useState("");

  const [isImmediately, setIsImmediately] = useState(false);

  const [showAlert, , setAlertProps] = useAlert();

  const [versions, setVersions] = useState<Array<{ label: string; key: string }>>([]);
  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);

  const [step, setStep] = useState(1);

  const [pushNotificationPayload, setPushNotificationPayload] = useState({} as ICreatePushNotificationPayload);

  const [availableMiniApp, setAvailableMiniApp] = useState(true);
  const [availableBundle, setAvailableBundle] = useState(true);

  const linkOptions =
    config && config.pushNotifications && config.pushNotifications.typeOfLinks
      ? config.pushNotifications.typeOfLinks.sort().map(options => ({
          key: options,
          label: t(`pages.pushNotifications.add.linkTypeDropDown.${options}`),
        }))
      : [
          {
            key: PushNotificationLinkTypeEnum.DYNAMIC_SCREEN,
            label: t("pages.pushNotifications.add.linkTypeDropDown.DYNAMIC_SCREEN"),
          },
          {
            key: PushNotificationLinkTypeEnum.EXTERNAL_LINK,
            label: t("pages.pushNotifications.add.linkTypeDropDown.EXTERNAL_LINK"),
          },
          {
            key: PushNotificationLinkTypeEnum.NATIVE,
            label: t("pages.pushNotifications.add.linkTypeDropDown.NATIVE"),
          },
        ];

  /**
   * Filter mini apps accepted options
   */
  const miniAppOptions = filterDropdownOptions([...miniApps, ...antMiniApps], [ServiceStatusEnum.LIVE]);

  /**
   * Get mini app label
   * @param type
   */
  const getMiniAppLabel = (type: string) => {
    let miniAppType: string;
    //Fetch mini app key from url on EDIT
    if (type && id && type.includes("mpesa://mini-app")) {
      const formatedType = type.split("?");
      const bundleKey = formatedType[1].split("id=");
      miniAppType = bundleKey[1];

      if (miniAppOptions.length === 0) {
        setAvailableMiniApp(false);
      } else if (!miniAppOptions.find(app => app.key === miniAppType)) {
        setAvailableMiniApp(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableMiniApp(true);
      }
    } else {
      miniAppType = type;
    }
    return type && miniAppOptions && miniAppOptions.find(opt => opt.key === miniAppType)
      ? (miniAppOptions && (miniAppOptions.find(opt => opt.key === miniAppType) as any)).label
      : t("pages.discoverCards.add.chooseMiniApp");
  };

  /**
   * Filter bundle accepted options
   */
  const bundleOptions =
    availableLanguages && filterDropdownOptions(bundles, [BundleTypeStatusEnum.LIVE], availableLanguages[0]?.code);

  /**
   * Get bundle label
   * @param type
   */
  const getBundleLabel = (type: string) => {
    let bundleType: string;
    //Fetch bundle key from url on EDIT
    if (type && id && type.includes("mpesa://bundle")) {
      const formatedType = type.split("&");
      const bundleKey = formatedType[1].split("bundleTypeName=");
      bundleType = bundleKey[1];

      if (bundleOptions && bundleOptions.length === 0) {
        setAvailableBundle(false);
      } else if (bundleOptions && !bundleOptions.find(bundle => bundle.key === bundleType)) {
        setAvailableBundle(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableBundle(true);
      }
    } else {
      bundleType = type;
    }
    return type && bundleOptions && bundleOptions.find(opt => opt.key === (bundleType as string))
      ? (bundleOptions && (bundleOptions.find(opt => opt.key === bundleType) as any)).label
      : t("pages.discoverCards.add.chooseBundle");
  };

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);

  /**
   * hide push notification modal
   */
  const _hidePushNotificationDetailsModal = () => {
    hidePushNotificationDetailsModal();
  };

  /**
   * Submit push notification
   */
  const _submitPushNotificationPayload = () => {
    return new Promise((resolve, reject) => {
      const req = new FormData();
      req.append("push", JSON.stringify(pushNotificationPayload));
      if (actualUsers === PushNotificationUsersEnum.UPLOADED_USERS) {
        req.append("file", csvFile);
      }
      if (id) {
        PushNotificationsApi.methods.editPushNotification(id, req).then(
          res => {
            PushNotificationsApi.methods.publishPushNotification(res.data.id).then(
              () => {
                _hidePushNotificationDetailsModal();
                setAlertProps({
                  title: t("pages.pushNotifications.edit.success"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
                history.push(RoutesEnum.PUSH_NOTIFICATIONS);
              },
              () => {
                reject();
                setAlertProps({
                  title: t("pages.pushNotifications.edit.publishPushNotificationsError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            );
          },
          err => {
            reject();
            setAlertProps({
              title: t(
                `pages.pushNotifications.${
                  err.status === 400 && err.data.status.businessCode === 40018
                    ? "add.createPushNotificationsFileError"
                    : id
                    ? "edit.editPushNotificationsError"
                    : "add.createPushNotificationsError"
                }`,
              ),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      } else {
        PushNotificationsApi.methods.createPushNotification(req).then(
          res => {
            PushNotificationsApi.methods.publishPushNotification(res.data.id).then(
              () => {
                _hidePushNotificationDetailsModal();
                setAlertProps({
                  title: t("pages.pushNotifications.add.success"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
                history.push(RoutesEnum.PUSH_NOTIFICATIONS);
              },
              () => {
                reject();
                setAlertProps({
                  title: t("pages.pushNotifications.add.publishPushNotificationsError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            );
          },
          err => {
            reject();
            setAlertProps({
              title: t(
                `pages.pushNotifications.${
                  err.status === 400 && err.data.status.businessCode === 40018
                    ? "add.createPushNotificationsFileError"
                    : id
                    ? "edit.editPushNotificationsError"
                    : "add.createPushNotificationsError"
                }`,
              ),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      }
    });
  };
  const [showPushNotificationDetailsModal, hidePushNotificationDetailsModal, pushNotificationDetailsProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PushNotificationDetailsModal
      pushNotification={pushNotificationPayload}
      hideModal={_hidePushNotificationDetailsModal}
      confirmModal={_submitPushNotificationPayload}
      availableLanguages={availableLanguages as ILanguage[]}
      linkUriName={
        pushNotificationPayload &&
        pushNotificationPayload.linkType === PushNotificationLinkTypeEnum.DEEP_LINK &&
        pushNotificationPayload.linkContent &&
        pushNotificationPayload.linkContent.uri
          ? pushNotificationPayload.deepLinkType === PushNotificationLinkTypeEnum.BUNDLE
            ? bundleOptions
              ? (bundleOptions.find(
                  opt => opt.key === (pushNotificationPayload.linkContent as IPushNotificationLinkContent).uri,
                ) as IDropdownOptionsInterface).label
              : ""
            : pushNotificationPayload.deepLinkType === PushNotificationLinkTypeEnum.MINI_APP
            ? miniAppOptions
              ? (miniAppOptions.find(
                  opt => opt.key === (pushNotificationPayload.linkContent as IPushNotificationLinkContent).uri,
                ) as IDropdownOptionsInterface).label
              : ""
            : ""
          : ""
      }
    />,
  );

  React.useEffect(() => {
    pushNotificationDetailsProps({ modalStyles: { width: 825 } });
  }, [pushNotificationDetailsProps]);

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          {
            id: "2",
            code: "sw",
            name: "Swahili (Tanzania)",
            urlIcon: "ic_flag_tanzania.png",
          },
        ]);
      },
    );
  }, []);

  React.useEffect(() => {
    if (id) {
      PushNotificationsApi.methods.getPushNotification(id).then(
        res => {
          const actualPush = res.data;
          initialNotificationSettings.title = actualPush.title;
          initialNotificationSettings.message = actualPush.message;
          //Check if the dataType is deepLink, if it is replace the deepLinkType with the data type value
          actualPush.linkType = actualPush.deepLinkType ? actualPush.deepLinkType : actualPush.linkType;

          const newSendSettings = {
            users: actualPush.users,
            csv: undefined,
            appVersion: actualPush.appVersion,
            linkType: actualPush.linkType,
            linkUri: actualPush.linkContent.uri,
            linkFlow: actualPush.linkContent.flow || undefined,
            linkEventId: actualPush.linkContent.eventId || undefined,
            name: {},
            date: moment(actualPush.scheduledDate, "x").format("DD/MM/YYYY") || "",
            hours: moment(actualPush.scheduledDate, "x").format("HH") || "",
            minutes: moment(actualPush.scheduledDate, "x").format("mm") || "",
            sendImmediately: actualPush.sendImmediately,
            scheduledDate: actualPush.scheduledDate || undefined,
          };
          initialSendSettings.sendSettings = newSendSettings;
          setActualUsers(actualPush.users);
          setActualType(actualPush.linkType);
          if (actualPush.versions) {
            setSelectedVersions(actualPush.versions);
          }
          setIsImmediately(actualPush.sendImmediately);
        },
        () => {
          setAlertProps({
            title: t("pages.pushNotifications.add.errors.get"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    }
  }, []);

  /**
   * Gets the list of dynamic screens
   */
  React.useEffect(() => {
    DynamicScreensApi.methods.getDynamicScreens().then(
      res => {
        dispatch(DynamicScreensActions.creators.getDynamicScreensSuccess(res.data));
        const options = res.data.items.map((dyn: IDynamicScreen) => {
          return {
            key: dyn.eventId,
            label: `${dyn.name.translations[language]} (${dyn.eventId})`,
          };
        });
        setDynamicScreensOptions(options);
      },
      () => {
        setAlertProps({
          title: t("pages.dynamicScreens.getDynamicScreensError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  React.useEffect(() => {
    AppsApi.methods.getApps().then(
      (res: any) => {
        const releases: any = [
          {
            label: t("pages.pushNotifications.add.targetReleasesDropdownPlaceholder"),
            key: PushNotificationAppVersionsEnum.ALL_VERSIONS,
          },
        ];
        const promises: any = [];
        res.data.forEach((app: IApp) => {
          promises.push(
            (() => {
              return new Promise<void>((resolve, reject) => {
                AppsApi.methods.getAppReleases(app.id).then(
                  res => {
                    releases.push(
                      ...[
                        ...res.data.releases.releasedVersions.map((release: IReleasedVersion) => {
                          return { label: release.id, key: release.id };
                        }),
                      ],
                    );
                    resolve();
                  },
                  () => {
                    setAlertProps({
                      title: t("pages.appDetails.releaseVersions.getErrorTitle"),
                      content: t("pages.appDetails.releaseVersions.getErrorContent"),
                      type: AlertTypeEnum.ERROR,
                    });
                    showAlert();
                    reject();
                  },
                );
              });
            })(),
          );
        });
        Promise.all(promises).then(() => {
          const android: { label: string; key: string }[] = [];
          const ios: { label: string; key: string }[] = [];
          const others: { label: string; key: string }[] = [];
          releases.forEach((version: { label: string; key: string }) => {
            if (version.label.toLowerCase().includes("consumer-ios")) {
              ios.push(version);
              return;
            }

            if (version.label.toLowerCase().includes("consumer-android")) {
              android.push(version);
              return;
            }

            others.push(version);
          });

          setVersions([...others, ...android, ...ios]);
          if (id && initialSendSettings.sendSettings.appVersion === PushNotificationAppVersionsEnum.ALL_VERSIONS) {
            const selectedVersionsAux: string[] = [];
            releases.map((v: any) => {
              v.key !== PushNotificationAppVersionsEnum.ALL_VERSIONS && selectedVersionsAux.push(v.key);
            });
            setSelectedVersions(selectedVersionsAux);
          }
          setLoading(false);
        });
      },
      () => {
        setAlertProps({
          title: t("pages.apps.errors.searchApps"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  /**
   * Load store values if they are empty
   */
  React.useEffect(() => {
    if (bundles.length === 0 && linkOptions.find(option => option.key === PushNotificationLinkTypeEnum.BUNDLE)) {
      getBundles();
    }

    if (miniApps.length === 0 && linkOptions.find(option => option.key === PushNotificationLinkTypeEnum.MINI_APP)) {
      getMiniApps();
    }

    if (antMiniApps.length === 0 && linkOptions.find(option => option.key === PushNotificationLinkTypeEnum.MINI_APP)) {
      getAntMiniApps();
    }
  }, []);

  /**
   * Gets the list of bundle types
   */
  const getBundles = () => {
    BundlesApi.methods.getBundles().then(
      res => {
        dispatch(BundlesActions.creators.getBundlesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.bundles.getBundlesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get mini apps list
   */
  const getMiniApps = () => {
    dispatch(ServicesActions.creators.fetchingServicesAction());
    ServicesApi.methods.getServices().then(
      res => {
        dispatch(ServicesActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get ant mini apps list
   */
  const getAntMiniApps = () => {
    dispatch(AntServiceManagerActions.creators.fetchingServicesAction());
    AntServiceManagerApi.methods.getServices().then(
      res => {
        dispatch(AntServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  const userOptions = [
    {
      key: PushNotificationUsersEnum.ALL_USERS,
      label: t("pages.pushNotifications.add.targetUsersDropDown.ALL_USERS"),
    },
    {
      key: PushNotificationUsersEnum.ACTIVE_USERS,
      label: t("pages.pushNotifications.add.targetUsersDropDown.ACTIVE_USERS"),
    },
    {
      key: PushNotificationUsersEnum.NON_ACTIVE_USERS,
      label: t("pages.pushNotifications.add.targetUsersDropDown.NON_ACTIVE_USERS"),
    },
    {
      key: PushNotificationUsersEnum.UPLOADED_USERS,
      label: t("pages.pushNotifications.add.targetUsersDropDown.UPLOADED_USERS"),
    },
  ];

  /**
   * get user label
   * @param type
   */
  const getUsersLabel = (type: string) => {
    return type && userOptions.find(opt => opt.key === type)
      ? (userOptions.find(opt => opt.key === type) as any).label
      : t("pages.pushNotifications.add.chooseUsers");
  };

  /**
   * get release label
   */
  const getReleasesLabel = () => {
    if (selectedVersions.length === versions.length - 1) {
      return t("pages.pushNotifications.add.targetReleasesDropdownPlaceholder");
    } else if (selectedVersions.length > 0) {
      return t("pages.pushNotifications.add.targetReleasesDropdownPlaceholderFilled").replace(
        "{0}",
        String(selectedVersions.length),
      );
    } else {
      return t("pages.pushNotifications.add.chooseReleases");
    }
  };

  function toggleOption(opt: SharedDropdownOption) {
    let newVersions: string[];
    if (opt.key === PushNotificationAppVersionsEnum.ALL_VERSIONS) {
      if (selectedVersions.length < versions.length - 1) {
        newVersions = versions.filter(v => v.key !== PushNotificationAppVersionsEnum.ALL_VERSIONS).map(v => v.key);
      } else {
        newVersions = [];
      }
    } else if (selectedVersions.includes(opt.key)) {
      newVersions = selectedVersions.filter(c => c !== opt.key);
    } else {
      newVersions = [...selectedVersions, opt.key];
    }

    setSelectedVersions(newVersions);
    return newVersions.length > 0;
  }

  function resetDropdown() {
    setSelectedVersions([]);
  }

  function isOptionSelected(opt: SharedDropdownOption) {
    if (opt.key === PushNotificationAppVersionsEnum.ALL_VERSIONS) {
      return selectedVersions.length === versions.length - 1;
    } else {
      return selectedVersions.includes(opt.key);
    }
  }

  /**
   * get link type label
   * @param type
   */
  const getLinkTypeLabel = (type: string) => {
    return type && linkOptions.find(opt => opt.key === type)
      ? (linkOptions.find(opt => opt.key === type) as any).label
      : t("pages.pushNotifications.add.chooseLink");
  };

  /**
   * get dynamic screen label
   * @param dyn
   */
  const getDynamicScreenLabel = (dyn: string) => {
    const dynOptionObj = dynamicScreensOptions ? dynamicScreensOptions.find(opt => opt.key === dyn) : null;
    if (dyn && dynOptionObj) {
      return `${dynOptionObj.label}`;
    }
    return t("pages.pushNotifications.add.chooseDynamicScreen");
  };

  const flowOptions = (config && config.pushNotifications && config.pushNotifications.availableAppFlows
    ? config.pushNotifications.availableAppFlows
    : Object.values(DiscoverCardFlowEnum)
  )
    .sort()
    .map(flow => ({
      label: i18next.t(`commons.internalFlowDropDown.${flow}`),
      key: flow,
    }));

  /**
   * get flow label
   * @param type
   */
  const getFlowLabel = (type: string) => {
    return type && flowOptions.find(opt => opt.key === type)
      ? (flowOptions.find(opt => opt.key === type) as any).label
      : t("pages.discoverCards.add.chooseFlow");
  };

  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const continueToSendWrapper = (values: any) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(continueToSend, values);
    } else {
      setShowWarning(false);
      continueToSend(values);
    }
  };

  /**
   * continue to send
   * @param values
   */
  const continueToSend = (values: any) => {
    setInitialNotificationSettings(values);
    setStep(2);
  };

  /**
   * Submit method
   * @param values
   */
  const submit = (values: any) => {
    if (
      !values.sendSettings.sendImmediately &&
      values.sendSettings.date &&
      values.sendSettings.hours &&
      values.sendSettings.minutes
    ) {
      const dateConverted = `${values.sendSettings.date.split("/")[2]}-${values.sendSettings.date.split("/")[1]}-${
        values.sendSettings.date.split("/")[0]
      }`;
      values.sendSettings.scheduledDate = Date.parse(
        `${dateConverted}T${values.sendSettings.hours}:${values.sendSettings.minutes}:00`,
      );
    }
    const combinedValues = { ...values, ...initialNotificationSettings };

    const versionValue =
      selectedVersions.length === versions.length - 1
        ? PushNotificationAppVersionsEnum.ALL_VERSIONS
        : PushNotificationAppVersionsEnum.SUBSET_VERSIONS;

    let linkContent;
    let deepLinkType;
    if (combinedValues.sendSettings.linkType === PushNotificationLinkTypeEnum.NATIVE) {
      linkContent = {
        type: combinedValues.sendSettings.linkType,
        flow: combinedValues.sendSettings.linkFlow,
      };
    } else if (combinedValues.sendSettings.linkType === PushNotificationLinkTypeEnum.DYNAMIC_SCREEN) {
      linkContent = {
        type: combinedValues.sendSettings.linkType,
        eventId: combinedValues.sendSettings.linkEventId,
        name: combinedValues.sendSettings.name,
      };
    } else if (combinedValues.sendSettings.linkType === PushNotificationLinkTypeEnum.BUNDLE) {
      linkContent = {
        type: PushNotificationLinkTypeEnum.DEEP_LINK,
        uri: combinedValues.sendSettings.linkUri,
      };
      deepLinkType = PushNotificationLinkTypeEnum.BUNDLE;
    } else if (combinedValues.sendSettings.linkType === PushNotificationLinkTypeEnum.MINI_APP) {
      linkContent = {
        type: PushNotificationLinkTypeEnum.DEEP_LINK,
        uri: combinedValues.sendSettings.linkUri,
      };
      deepLinkType = PushNotificationLinkTypeEnum.MINI_APP;
    } else {
      linkContent = {
        type: combinedValues.sendSettings.linkType,
        uri: combinedValues.sendSettings.linkUri,
      };
    }

    const deepLinkList = [PushNotificationLinkTypeEnum.MINI_APP, PushNotificationLinkTypeEnum.BUNDLE];

    const payload: ICreatePushNotificationPayload = {
      title: combinedValues.title,
      message: combinedValues.message,
      users: combinedValues.sendSettings.users,
      appVersion: versionValue,
      versions: versionValue === PushNotificationAppVersionsEnum.SUBSET_VERSIONS ? selectedVersions : undefined,
      linkType: deepLinkList.includes(combinedValues.sendSettings.linkType)
        ? PushNotificationLinkTypeEnum.DEEP_LINK
        : combinedValues.sendSettings.linkType,
      linkContent: linkContent,
      scheduledDate: !combinedValues.sendSettings.sendImmediately
        ? combinedValues.sendSettings.scheduledDate
        : undefined,
      sendImmediately: combinedValues.sendSettings.sendImmediately,
    };

    if (deepLinkType) {
      payload.deepLinkType = deepLinkType;
    }

    setPushNotificationPayload(payload);
    showPushNotificationDetailsModal();
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={id ? t("pages.pushNotifications.edit.title") : t("pages.pushNotifications.add.title")}
        goBackFn={() => history.push(RoutesEnum.PUSH_NOTIFICATIONS)}
      />
      {loading && <LoadingText style={{ marginLeft: 160, marginTop: 106 }}>{t("commons.loading")}</LoadingText>}
      {!loading && availableLanguages && (
        <>
          {step === 1 && (
            <FormContainer>
              <Formik<any>
                onSubmit={continueToSendWrapper}
                initialValues={initialNotificationSettings}
                validateOnChange={false}
                validationSchema={getValidationSchemaNotificationSettings(
                  setLanguage,
                  availableLanguages as ILanguage[],
                  setShowWarning,
                )}
              >
                {props => {
                  const { handleChange, values, handleSubmit, errors } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="form-top-wrapper">
                        <div className="title-wrapper">
                          <div className="step-description">
                            {t("pages.pushNotifications.add.steps.pushNotificationDetails")}
                          </div>
                          <div className="step-indicator">
                            {t("pages.pushNotifications.add.steps.step").replace("{0}", "1")}
                          </div>
                        </div>
                        <div className="progress-wrapper">
                          <div style={{ width: 125, margin: "auto" }}>
                            <ProgressIndicator
                              numberOfDots={2}
                              activeIndex={step}
                              checkPrevious={true}
                              onClick={i => setStep(i + 1)}
                            />
                          </div>
                        </div>
                        <div className="button-wrapper">
                          <PrimaryButton
                            style={{ width: 120 }}
                            id="create-push-notification-button"
                            redTheme={true}
                            type="submit"
                            titleLabel={t("pages.pushNotifications.add.continueButton")}
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="form-section">
                          <FormSectionContainer>
                            <FormSectionRow key={"title"}>
                              <FormSectionTitle>
                                {t("pages.pushNotifications.add.pushNotificationDetails")}
                              </FormSectionTitle>
                              <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                                <GenericLanguageSelector
                                  selectedLanguage={language}
                                  availableLanguages={availableLanguages.map(lang => lang.code)}
                                  changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                                ></GenericLanguageSelector>
                              </div>
                            </FormSectionRow>
                            <FormSectionRow>
                              <SectionColumn>
                                <FormLabel required={availableLanguages.find(l => l.code === language)?.mandatory}>
                                  {t("pages.pushNotifications.add.fields.title")}
                                </FormLabel>
                                <FormLabelSecondary>
                                  ({(values.title && values.title[language] && values.title[language].length) || "0"}
                                  /30)
                                </FormLabelSecondary>
                              </SectionColumn>
                              <SectionColumn>
                                <TextInput
                                  value={(values.title && values.title[language]) || ""}
                                  name={`title.${language}`}
                                  onChange={handleChange}
                                  maxLength={30}
                                  error={
                                    errors.title && ((errors.title as { [x: string]: string })[language] as string)
                                  }
                                  placeholder={`${t(
                                    "pages.pushNotifications.add.fields.title",
                                  )} (${language.toLocaleUpperCase()})`}
                                />
                              </SectionColumn>
                            </FormSectionRow>
                            <FormSectionRow>
                              <SectionColumn>
                                <FormLabel required={availableLanguages.find(l => l.code === language)?.mandatory}>
                                  {t("pages.pushNotifications.add.fields.message")}
                                </FormLabel>
                                <FormLabelSecondary>
                                  (
                                  {(values.message && values.message[language] && values.message[language].length) ||
                                    "0"}
                                  /200)
                                </FormLabelSecondary>
                              </SectionColumn>
                              <SectionColumn>
                                <TextArea
                                  value={(values.message && values.message[language]) || ""}
                                  name={`message.${language}`}
                                  onChange={e => {
                                    handleChange(e);
                                  }}
                                  maxHeight={"200px"}
                                  maxLength={200}
                                  verticalResize={false}
                                  placeholder={`${t(
                                    "pages.pushNotifications.add.fields.message",
                                  )} (${language.toLocaleUpperCase()})`}
                                />
                                <ErrorField>
                                  {errors.message && ((errors.message as { [x: string]: string })[language] as string)}
                                </ErrorField>
                              </SectionColumn>
                            </FormSectionRow>
                          </FormSectionContainer>
                        </div>
                        <div className="preview-section-wrapper">
                          <PreviewSection>
                            <div className="preview-title">{t("pages.pushNotifications.add.preview.previewTitle")}</div>
                            <div className="notification-preview-wrapper">
                              <div className="notification-preview-header">
                                <MPESAAppIcon />
                                <div className="header-title">
                                  {t("pages.pushNotifications.add.preview.headerTitle")}
                                </div>
                                <div className="header-ts">{t("pages.pushNotifications.add.preview.headerTs")}</div>
                              </div>
                              <div className="notification-preview-body">
                                <div className="notification-preview-title">
                                  {(values.title && values.title[language]) ||
                                    `${t(
                                      "pages.pushNotifications.add.fields.title",
                                    )} (${language.toLocaleUpperCase()})`}
                                </div>
                                <div className="notification-preview-message">
                                  {(values.message && values.message[language]) ||
                                    `${t(
                                      "pages.pushNotifications.add.fields.message",
                                    )} (${language.toLocaleUpperCase()})`}
                                </div>
                              </div>
                            </div>
                          </PreviewSection>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </FormContainer>
          )}
          {step === 2 && (
            <FormContainer>
              <Formik<any>
                onSubmit={submit}
                initialValues={initialSendSettings}
                validateOnChange={false}
                validationSchema={getValidationSchemaSendSettings(
                  actualType,
                  actualUsers,
                  setLanguage,
                  availableLanguages,
                  isImmediately,
                )}
              >
                {props => {
                  const { handleChange, values, handleSubmit, setFieldValue, errors } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="form-top-wrapper">
                        <div className="title-wrapper">
                          <div className="step-description">
                            {t("pages.pushNotifications.add.steps.pushNotificationSendSettings")}
                          </div>
                          <div className="step-indicator">
                            {t("pages.pushNotifications.add.steps.step").replace("{0}", "2")}
                          </div>
                        </div>
                        <div className="progress-wrapper">
                          <div style={{ width: 125, margin: "auto" }}>
                            <ProgressIndicator
                              numberOfDots={2}
                              activeIndex={step}
                              checkPrevious={true}
                              onClick={i => {
                                setStep(i + 1);
                                if (!!!id) {
                                  resetDropdown();
                                  setFieldValue("sendSettings.appVersion", "");
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="button-wrapper">
                          <PrimaryButton
                            style={{ width: 120 }}
                            id="create-push-notification-button"
                            redTheme={true}
                            type="submit"
                            titleLabel={
                              id
                                ? t("pages.pushNotifications.edit.saveButton")
                                : t("pages.pushNotifications.add.saveButton")
                            }
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <FormSectionContainer style={{ flex: "1 auto", margin: "auto 100px" }}>
                          <FormSectionRow>
                            <FormSectionTitle>
                              {t("pages.pushNotifications.add.pushNotificationSendSettings")}
                            </FormSectionTitle>
                          </FormSectionRow>
                          <FormSectionRow
                            style={{
                              borderBottomWidth:
                                values.sendSettings.users &&
                                values.sendSettings.users === PushNotificationUsersEnum.UPLOADED_USERS
                                  ? 0
                                  : 1,
                            }}
                          >
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.fields.targetUsers")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn id="users-dropdown">
                              <AutoClosingDropdown
                                error={errors.sendSettings && ((errors.sendSettings as any).users as string)}
                                label={getUsersLabel(values.sendSettings.users)}
                                hasValue={!!values.sendSettings.users}
                                selectOption={opt => {
                                  setFieldValue("sendSettings.users", opt.key);
                                  setActualUsers(opt.key);

                                  setFileError("");
                                  // hacky logic to get the required validation to work
                                  setFieldValue("sendSettings.csv", "");
                                  setFile(undefined);
                                }}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={userOptions}
                              />
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.users &&
                                values.sendSettings.users === PushNotificationUsersEnum.UPLOADED_USERS
                              )
                            }
                          >
                            <SectionColumn>
                              <CustomFormLabel>{t("pages.pushNotifications.add.fields.csvFile_1")}</CustomFormLabel>
                              <CustomFormLabel>{t("pages.pushNotifications.add.fields.csvFile_2")}</CustomFormLabel>
                            </SectionColumn>
                            <SectionColumn id="csv-dropzone">
                              <Dropzone
                                fileName={csvFile && csvFile.name}
                                inputName="csvFile"
                                onDrop={(file: any) => {
                                  if (!file[0]) {
                                    setFileError(t("pages.pushNotifications.add.fileErrors.errorFile"));
                                    setFieldValue("sendSettings.csv", "");
                                    setFile(undefined);
                                  } else {
                                    if (file[0].size > MAX_FILE_SIZE) {
                                      setFileError(t("pages.pushNotifications.add.fileErrors.maxSize"));
                                      setFieldValue("sendSettings.csv", "");
                                      setFile(undefined);
                                    } else if (file[0].name.length > 20) {
                                      setFileError(t("pages.pushNotifications.add.fileErrors.maxFilenameSize"));
                                      setFieldValue("sendSettings.csv", "");
                                      setFile(undefined);
                                    } else {
                                      setFileError("");
                                      // hacky logic to get the required validation to work
                                      setFieldValue("sendSettings.csv", "filled");
                                      setFile(file[0]);
                                    }
                                  }
                                }}
                                onClear={() => {
                                  setFieldValue("sendSettings.csv", "");
                                  setFile(undefined);
                                }}
                                accept={".csv"}
                                multiple={false}
                                dropTitle={t("pages.pushNotifications.add.fileDropzone.title")}
                                dropDescription={t("pages.pushNotifications.add.fileDropzone.description")}
                                releaseText={t("pages.pushNotifications.add.fileDropzone.release")}
                              />
                              <ErrorField>{fileError}</ErrorField>
                              <ErrorField>
                                {errors.sendSettings && ((errors.sendSettings as any).csv as string)}
                              </ErrorField>
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow>
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.fields.targetReleases")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn id="apps-dropdown">
                              <MultipleOptionsDropdown
                                error={errors.sendSettings && ((errors.sendSettings as any).appVersion as string)}
                                label={getReleasesLabel()}
                                toggleOption={opt => {
                                  // hacky logic to get the required validation to work
                                  if (!toggleOption(opt)) {
                                    setFieldValue("sendSettings.appVersion", "");
                                  } else {
                                    setFieldValue("sendSettings.appVersion", "filled");
                                  }
                                }}
                                clearAllFilters={() => {
                                  resetDropdown();
                                  setFieldValue("sendSettings.appVersion", "");
                                }}
                                hasValue={selectedVersions.length > 0}
                                isOptionSelected={opt => isOptionSelected(opt)}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={versions}
                              />
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow>
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.fields.typeOfLink")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn id="linkType-dropdown">
                              <AutoClosingDropdown
                                error={errors.sendSettings && ((errors.sendSettings as any).linkType as string)}
                                label={getLinkTypeLabel(values.sendSettings.linkType)}
                                hasValue={!!values.sendSettings.linkType}
                                selectOption={opt => {
                                  //reset uri value on change
                                  values.sendSettings.linkUri = "";
                                  setFieldValue("sendSettings.linkType", opt.key);
                                  setActualType(opt.key);
                                }}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={linkOptions}
                              />
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.linkType &&
                                values.sendSettings.linkType === PushNotificationLinkTypeEnum.EXTERNAL_LINK
                              )
                            }
                          >
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.linkTypeDropDown.EXTERNAL_LINK")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn>
                              <TextInput
                                error={errors.sendSettings && ((errors.sendSettings as any).linkUri as string)}
                                name="sendSettings.linkUri"
                                placeholder={t("pages.pushNotifications.add.addURI")}
                                onChange={handleChange}
                                value={values.sendSettings.linkUri}
                              />
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.linkType &&
                                values.sendSettings.linkType === PushNotificationLinkTypeEnum.DYNAMIC_SCREEN
                              )
                            }
                          >
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.linkTypeDropDown.DYNAMIC_SCREEN")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn>
                              <DropdownWrapper id={"dynamic-screen-dropdown"}>
                                <AutoClosingDropdown
                                  error={errors.sendSettings && ((errors.sendSettings as any).linkEventId as string)}
                                  label={getDynamicScreenLabel(values.sendSettings.linkEventId)}
                                  hasValue={!!values.sendSettings.linkEventId}
                                  selectOption={opt => {
                                    availableLanguages.forEach(lang => {
                                      const l = lang.code;
                                      const d = dynamicScreens.items.find(d => d.eventId === opt.key);
                                      setFieldValue(
                                        `sendSettings.name.${l}`,
                                        d
                                          ? d.name.translations[l] ||
                                              d.name.translations[Object.keys(d.name.translations)[0]]
                                          : "",
                                      );
                                    });
                                    setFieldValue("sendSettings.linkEventId", opt.key);
                                  }}
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  options={
                                    dynamicScreensOptions as {
                                      key: string;
                                      label: string;
                                    }[]
                                  }
                                />
                              </DropdownWrapper>
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.linkType &&
                                values.sendSettings.linkType === PushNotificationLinkTypeEnum.NATIVE
                              )
                            }
                          >
                            <SectionColumn>
                              <FormLabel>{t("commons.typeOfLinkDropDown.NATIVE")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn>
                              <DropdownWrapper id="flow-dropdown">
                                <AutoClosingDropdown
                                  error={errors.sendSettings && ((errors.sendSettings as any).linkFlow as string)}
                                  label={getFlowLabel(values.sendSettings.linkFlow)}
                                  hasValue={!!values.sendSettings.linkFlow}
                                  selectOption={opt => setFieldValue("sendSettings.linkFlow", opt.key)}
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  options={flowOptions}
                                />
                              </DropdownWrapper>
                            </SectionColumn>
                          </FormSectionRow>
                          {/*Bundles & Mini Apps*/}
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.linkType &&
                                values.sendSettings.linkType === PushNotificationLinkTypeEnum.MINI_APP
                              )
                            }
                          >
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.linkTypeDropDown.MINI_APP")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn>
                              {availableMiniApp ? (
                                <DropdownWrapper id="flow-dropdown">
                                  <AutoClosingDropdown
                                    error={errors.sendSettings && ((errors.sendSettings as any).linkUri as string)}
                                    label={getMiniAppLabel(values.sendSettings.linkUri)}
                                    hasValue={values.sendSettings.linkUri ? true : false}
                                    selectOption={opt => setFieldValue("sendSettings.linkUri", opt.key)}
                                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                    options={miniAppOptions as IDropdownOptionsInterface[]}
                                  ></AutoClosingDropdown>
                                </DropdownWrapper>
                              ) : (
                                <FormLabel>{t("pages.discoverCards.add.unableToFindItem")}</FormLabel>
                              )}
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow
                            hide={
                              !(
                                values.sendSettings.linkType &&
                                values.sendSettings.linkType === PushNotificationLinkTypeEnum.BUNDLE
                              )
                            }
                          >
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.add.linkTypeDropDown.BUNDLE")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn>
                              {availableBundle ? (
                                <DropdownWrapper id="flow-dropdown">
                                  <AutoClosingDropdown
                                    error={errors.sendSettings && ((errors.sendSettings as any).linkUri as string)}
                                    label={getBundleLabel(values.sendSettings.linkUri)}
                                    hasValue={values.sendSettings.linkUri ? true : false}
                                    selectOption={opt => setFieldValue("sendSettings.linkUri", opt.key)}
                                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                    options={bundleOptions as IDropdownOptionsInterface[]}
                                  ></AutoClosingDropdown>
                                </DropdownWrapper>
                              ) : (
                                <FormLabel>{t("pages.discoverCards.add.unableToFindItem")}</FormLabel>
                              )}
                            </SectionColumn>
                          </FormSectionRow>
                          <FormSectionRow>
                            <SectionColumn>
                              <FormLabel>{t("pages.pushNotifications.dateAndTime")}</FormLabel>
                            </SectionColumn>
                            <SectionColumn id="date-time" style={{ display: "flex" }}>
                              <div style={{ maxWidth: 154 }}>
                                <TextInput
                                  value={values.sendSettings.date}
                                  name="date"
                                  onChange={e => {
                                    setFieldValue("sendSettings.date", e.target.value);
                                  }}
                                  error={
                                    errors.sendSettings && ((errors.sendSettings as any).date as string)
                                      ? " "
                                      : undefined
                                  }
                                  placeholder={t("pages.pushNotifications.dateAndTimePlaceholder")}
                                  style={{ width: "148px", marginRight: 6 }}
                                  disabled={values.sendSettings.sendImmediately}
                                />
                                {errors.sendSettings && (errors.sendSettings as any).date ? (
                                  <CustomErrorText>{(errors.sendSettings as any).date as string}</CustomErrorText>
                                ) : errors.sendSettings && (errors.sendSettings as any).hours ? (
                                  <CustomErrorText>{(errors.sendSettings as any).hours as string}</CustomErrorText>
                                ) : errors.sendSettings && (errors.sendSettings as any).minutes ? (
                                  <CustomErrorText>{(errors.sendSettings as any).minutes as string}</CustomErrorText>
                                ) : null}
                              </div>
                              <TimeInputWrapper>
                                <TextInput
                                  value={values.sendSettings.hours}
                                  type="number"
                                  name="hours"
                                  onChange={e => {
                                    if (e.target.value.length <= 2) {
                                      setFieldValue("sendSettings.hours", e.target.value);
                                    }
                                  }}
                                  error={
                                    errors.sendSettings && ((errors.sendSettings as any).hours as string)
                                      ? " "
                                      : undefined
                                  }
                                  placeholder={t("pages.pushNotifications.hoursPlaceholder")}
                                  step="01"
                                  id={"hours-input"}
                                  disabled={values.sendSettings.sendImmediately}
                                />
                                <UpDown
                                  containerStyles={{
                                    marginBottom: "auto",
                                    marginLeft: -26,
                                    marginTop: 6,
                                    zIndex: 0,
                                  }}
                                  upArrowHandler={() => {
                                    if (
                                      document.getElementById("hours-input") &&
                                      !values.sendSettings.sendImmediately &&
                                      Number((document.getElementById("hours-input") as HTMLInputElement).value) < 23
                                    ) {
                                      (document.getElementById("hours-input") as HTMLInputElement).stepUp();
                                      setFieldValue(
                                        "sendSettings.hours",
                                        (document.getElementById("hours-input") as HTMLInputElement).value,
                                      );
                                    }
                                  }}
                                  downArrowHandler={() => {
                                    if (
                                      document.getElementById("hours-input") &&
                                      !values.sendSettings.sendImmediately &&
                                      Number((document.getElementById("hours-input") as HTMLInputElement).value) > 0
                                    ) {
                                      (document.getElementById("hours-input") as HTMLInputElement).stepDown();
                                      setFieldValue(
                                        "sendSettings.hours",
                                        (document.getElementById("hours-input") as HTMLInputElement).value,
                                      );
                                    }
                                  }}
                                />
                                <span style={{ margin: "6px 8px auto 8px" }}>:</span>
                                <TextInput
                                  value={values.sendSettings.minutes}
                                  type="number"
                                  name="minutes"
                                  onChange={e => {
                                    if (e.target.value.length <= 2) {
                                      setFieldValue("sendSettings.minutes", e.target.value);
                                    }
                                  }}
                                  error={
                                    errors.sendSettings && ((errors.sendSettings as any).minutes as string)
                                      ? " "
                                      : undefined
                                  }
                                  placeholder={t("pages.pushNotifications.minutesPlaceholder")}
                                  step="01"
                                  id={"minutes-input"}
                                  disabled={values.sendSettings.sendImmediately}
                                />
                                <UpDown
                                  containerStyles={{
                                    marginBottom: "auto",
                                    marginLeft: -26,
                                    marginTop: 6,
                                    zIndex: 0,
                                  }}
                                  upArrowHandler={() => {
                                    if (
                                      document.getElementById("minutes-input") &&
                                      !values.sendSettings.sendImmediately &&
                                      Number((document.getElementById("minutes-input") as HTMLInputElement).value) < 59
                                    ) {
                                      (document.getElementById("minutes-input") as HTMLInputElement).stepUp();
                                      setFieldValue(
                                        "sendSettings.minutes",
                                        (document.getElementById("minutes-input") as HTMLInputElement).value,
                                      );
                                    }
                                  }}
                                  downArrowHandler={() => {
                                    if (
                                      document.getElementById("minutes-input") &&
                                      !values.sendSettings.sendImmediately &&
                                      Number((document.getElementById("minutes-input") as HTMLInputElement).value) > 0
                                    ) {
                                      (document.getElementById("minutes-input") as HTMLInputElement).stepDown();
                                      setFieldValue(
                                        "sendSettings.minutes",
                                        (document.getElementById("minutes-input") as HTMLInputElement).value,
                                      );
                                    }
                                  }}
                                />
                              </TimeInputWrapper>
                              <ToogleDiv id="immediately-toggle">
                                <Toggle
                                  key={`toogleImmediately`}
                                  cb={(newValue: boolean) => {
                                    setIsImmediately(newValue);
                                    setFieldValue("sendSettings.sendImmediately", newValue);
                                  }}
                                  initialValue={values.sendSettings.sendImmediately}
                                />
                                <SpanForm>{t("pages.pushNotifications.immediately")}</SpanForm>
                              </ToogleDiv>
                            </SectionColumn>
                          </FormSectionRow>
                        </FormSectionContainer>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </FormContainer>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default AddPushNotificationPage;

const FormContainer = styled.div`
  margin-left: 160px;
  margin-top: calc(128px + 28px - 50px);

  .form-top-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 30px;

    .title-wrapper {
      flex: 0 auto;

      width: 185px;
      padding-right: 20px;

      .step-description {
        font-family: Vodafone Rg;
        font-weight: bold;
        size: 16px;
        line-height: 21px;
        color: ${props => props.theme.palette.darkGrey};
      }

      .step-indicator {
        font-family: Vodafone Rg;
        size: 16px;
        line-height: 21px;
        color: ${props => props.theme.palette.platinum};
      }
    }

    .progress-wrapper {
      flex: 1 auto;
    }

    .button-wrapper {
      flex: 0 auto;

      padding-left: 20px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: row;
  }

  .form-section {
    flex: 0.6;
  }

  .preview-section-wrapper {
    flex: 0.4;
    margin-left: 46px;
    margin-top: 40px;
  }

  textarea {
    font-size: 16px;
    height: 100px;
  }
`;

export const PreviewSection = styled.div`
  .preview-title {
    font-family: Vodafone Rg;
    font-weight: bold;
    color: ${props => props.theme.palette.midGrey};
    size: 16px;
    line-height: 21px;
    letter-spacing: 0;
  }

  .notification-preview-wrapper {
    margin-top: 16px;

    border-radius: 6px;
    background-color: rgba(227, 227, 227, 0.65);

    .notification-preview-header {
      font-family: Vodafone Rg;
      size: 14px;
      line-height: 20px;
      padding: 16px 8px;

      letter-spacing: 0;
      color: ${props => props.theme.palette.midGrey};

      border-bottom: solid 1px #dcd9d9;

      display: flex;
      flex-direction: row;

      .header-title {
        flex: 1 auto;

        padding-left: 8px;
      }

      .header-ts {
        flex: 0 auto;

        padding-right: 8px;
      }
    }

    .notification-preview-body {
      font-family: Vodafone Rg;
      padding: 16px 8px;

      .notification-preview-title {
        size: 16px;
        line-height: 21px;
        font-weight: bold;
        color: ${props => props.theme.palette.black};
        display: block;
      }

      .notification-preview-message {
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
        display: block;
        size: 14px;
        line-height: 19px;
        color: ${props => props.theme.palette.midGrey};
      }
    }
  }
`;

const CustomFormLabel = styled.span`
  display: block;
  font-family: Vodafone Rg;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.palette.midGrey};
`;

const ErrorField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;

const DropdownWrapper = styled.div`
  > button {
    > div {
      min-width: -webkit-fill-available !important;
    }
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    padding-right: 26px;
    width: 65px;
  }
  margin-left: 6px;
`;

const ToogleDiv = styled("div")`
  label {
    background-color: white;
  }
  margin: auto;
  margin-right: 0px;
  margin-top: 6px;
`;

export const SpanForm = styled.span`
  margin-left: 12px;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

export const CustomErrorText = styled.p`
  white-space: nowrap;
  overflow: visible;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.digitalRed};
  margin-top: 4px;
  margin-bottom: 0px;
`;
