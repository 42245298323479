import { AutoClosingDropdown, TextInput, Toggle } from "@wit/mpesa-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CallToActionSelection, SpanForm } from "../../announcements/announcements.styled";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import {
  AudienceType,
  CallToActionType,
  IActionInterface,
  ICallToActionOption,
  ICallToActionOptions,
  IValueOption,
} from "../../announcements/announcements.model";
import styled from "styled-components";
import AnnouncementsApi from "../../announcements/announcements.api";
import { INotification, SetFieldValueType } from "../push-notifications.modal";
import { FormikErrors } from "formik";

interface IProps {
  values: INotification;
  errors: FormikErrors<INotification>;
  isEditing: boolean;
  notificationValues: INotification;
  setFieldValue: SetFieldValueType;
  onChangeValues(values: INotification): void;
}

/**
 * React component to render call to action toggle button and its corresponding fields.
 */
const CallToActionComponent = ({
  values,
  errors,
  isEditing,
  notificationValues,
  setFieldValue,
  onChangeValues,
}: IProps) => {
  const [t] = useTranslation();

  // const [showCallAction, toggleShowCallAction] = useState(false);
  const [callToActionOptions, setCallToActionOptions] = useState<ICallToActionOptions>();

  /**
   * function to return the list of option to the dropdown of actions availables
   */
  const renderCallToActionOptions = () => {
    const options = [] as SharedDropdownOption[];

    if (callToActionOptions) {
      Object.entries(callToActionOptions).forEach(([key, option]: [string, ICallToActionOption]) => {
        options.push({
          label: "",
          key: key,
          labelElement: (
            <DivCallActionOption>
              <div>{option.description ? option.description : t(`pages.announcements.actionEnum.${key}`)}</div>
            </DivCallActionOption>
          ),
        });
      });
    } else {
      options.push({
        label: "",
        key: "not-available",
        disabled: true,
        labelElement: (
          <DivCallActionOption>
            <span style={{ color: "red" }}>{t("pages.announcements.errors.callToActionOptionsError")}</span>
          </DivCallActionOption>
        ),
      });
    }
    return options;
  };

  /**
   * Get call to action options list from the BE
   */
  const getCallToActionOptions = () => {
    AnnouncementsApi.methods.getCallToActionOptions(notificationValues.audience as AudienceType).then(res => {
      const { LINK, ...resOfValues } = res.data as ICallToActionOptions;

      setCallToActionOptions(resOfValues);
    });
  };

  useEffect(() => {
    getCallToActionOptions();
  }, []);

  /**
   * This return the feature description
   * @param featureName
   * @returns {string}
   */
  const getFeatureDescription = (featureName: string | undefined): string => {
    if (featureName && callToActionOptions && callToActionOptions.FEATURE && callToActionOptions.FEATURE.values) {
      const result = callToActionOptions.FEATURE.values?.find(element => element.name === featureName);

      return result ? result.description : "";
    }

    return "";
  };

  /**
   * function to return the list of option to the dropdown of features availables
   */
  const getFeature = () => {
    if (callToActionOptions && callToActionOptions.FEATURE && callToActionOptions.FEATURE.values) {
      return callToActionOptions.FEATURE.values.map(feature => ({
        label: feature.description,
        key: feature.name,
      }));
    }
    return [
      {
        label: "",
        key: "not-available",
        disabled: true,
        labelElement: (
          <DivCallActionOption>
            <span style={{ color: "red" }}>{t("pages.announcements.errors.callToActionOptionsFeatureError")}</span>
          </DivCallActionOption>
        ),
      },
    ];
  };

  return (
    <Container>
      <CallToActionSelection>{t("pages.announcements.detailPage.rows.callToActionHeader")}</CallToActionSelection>
      <CallToActionToggle isEditing={isEditing}>
        <Toggle
          key="notification-toggle-call-to-action"
          cb={(newValue: boolean) => {
            setFieldValue("hasCallToAction", newValue);
            onChangeValues({
              ...notificationValues,
              hasCallToAction: newValue,
              callToAction: { title: "", action: {} as IActionInterface },
            });
            setFieldValue("callToAction", { title: "", action: {} as IActionInterface });
          }}
          isDisabled={!isEditing}
          initialValue={values.hasCallToAction}
        />
        <SpanForm>{t("pages.announcements.detailPage.rows.callToActionCheck")}</SpanForm>
      </CallToActionToggle>
      {notificationValues.hasCallToAction && (
        <SectionFormRow>
          <CallTitleSection>
            {!isEditing ? (
              <>
                <DefineTitle required={isEditing}>
                  {t("pages.announcements.detailPage.rows.callToActionTitle")}
                </DefineTitle>
                <NotEditingText>{notificationValues.callToAction?.title}</NotEditingText>
              </>
            ) : (
              <TextInput
                required={true}
                maxLength={25}
                title={t("pages.announcements.detailPage.rows.callToActionTitle")}
                placeholder={t("pages.announcements.detailPage.rows.callToActionCheck")}
                value={values.callToAction?.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("callToAction.title", e.target.value);
                  onChangeValues({
                    ...notificationValues,
                    callToAction: { ...notificationValues.callToAction!, title: e.target.value },
                  });
                }}
                error={errors.callToAction?.title ? t("commons.mandatoryField") : ""}
              />
            )}
          </CallTitleSection>
          <DefineActionSection>
            <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.defineCallAction")}</DefineTitle>
            {!isEditing ? (
              <NotEditingText>
                {notificationValues.callToAction?.action.type
                  ? t(`pages.announcements.actionEnum.${notificationValues.callToAction.action?.type}`)
                  : ""}
              </NotEditingText>
            ) : (
              <AutoClosingDropdown
                options={renderCallToActionOptions()}
                selectOption={(opt: SharedDropdownOption) => {
                  setFieldValue("callToAction.action.type", opt.key);
                  setFieldValue("callToAction.action.resource", "");
                  console.log("opt", opt);
                  onChangeValues({
                    ...notificationValues,
                    callToAction: {
                      ...values.callToAction!,
                      action: { ...values.callToAction?.action, type: opt.key, resource: "" },
                    },
                  });
                }}
                label={
                  notificationValues && values.callToAction?.action.type
                    ? t(`pages.announcements.actionEnum.${values.callToAction.action.type}`)
                    : t("pages.announcements.detailPage.rows.selectCallToAction")
                }
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                hasValue={false}
                error={errors.callToAction?.action?.type}
              />
            )}
          </DefineActionSection>
        </SectionFormRow>
      )}

      {(values.callToAction.action.type === CallToActionType.INTERNAL_LINK ||
        values.callToAction.action.type === CallToActionType.EXTERNAL_LINK) &&
      notificationValues.hasCallToAction ? (
        <TextInputSection>
          {!isEditing ? (
            <>
              <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.linkUrl")}</DefineTitle>
              <NotEditingText>
                {values.callToAction.action.resource ? values.callToAction.action.resource : ""}
              </NotEditingText>
            </>
          ) : (
            <TextInput
              required={true}
              title={t("pages.announcements.detailPage.rows.linkUrl")}
              placeholder={t("pages.announcements.detailPage.rows.placeholderLinkUrl")}
              value={
                notificationValues.callToAction.action.resource ? notificationValues.callToAction.action.resource : ""
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("callToAction.action.resource", e.target.value);
                onChangeValues({
                  ...notificationValues,
                  callToAction: {
                    ...notificationValues.callToAction!,
                    action: { ...notificationValues.callToAction.action, resource: e.target.value },
                  },
                });
              }}
              error={errors.callToAction?.action?.resource ? t("commons.mandatoryField") : undefined}
            />
          )}
        </TextInputSection>
      ) : values.callToAction.action.type === CallToActionType.FEATURE && notificationValues.hasCallToAction ? (
        <SectionFormRow>
          <CallTitleSection>
            <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.feature")}</DefineTitle>
            {!isEditing ? (
              <NotEditingText>
                {values.callToAction.action.resource ? getFeatureDescription(values.callToAction.action.resource) : ""}
              </NotEditingText>
            ) : (
              <AutoClosingDropdown
                options={getFeature()}
                selectOption={(opt: SharedDropdownOption) => {
                  setFieldValue("callToAction.action.resource", opt.key);
                  onChangeValues({
                    ...notificationValues,
                    callToAction: {
                      ...notificationValues.callToAction!,
                      action: { ...notificationValues.callToAction.action, resource: opt.key },
                    },
                  });
                }}
                label={
                  notificationValues.callToAction.action.resource &&
                  callToActionOptions &&
                  callToActionOptions.FEATURE &&
                  callToActionOptions.FEATURE.values
                    ? (callToActionOptions.FEATURE.values.find(
                        value => value.name === notificationValues.callToAction.action.resource,
                      ) as IValueOption)?.description
                    : t("pages.announcements.detailPage.rows.selectCallToAction")
                }
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                hasValue={false}
                // error={!values.callToAction.action.resource ? t("commons.mandatoryField") : undefined}
              />
            )}
          </CallTitleSection>
        </SectionFormRow>
      ) : values.callToAction.action.type === CallToActionType.MINIAPP && notificationValues.hasCallToAction ? (
        <SectionFormRow>
          <CallTitleSection>
            {!isEditing ? (
              <>
                <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.miniApps")}</DefineTitle>
                <NotEditingText>
                  {values.callToAction.action.resource ? values.callToAction.action.resource : ""}
                </NotEditingText>
              </>
            ) : (
              <TextInput
                required={true}
                title={t("pages.announcements.detailPage.rows.miniApps")}
                placeholder={t("pages.announcements.detailPage.rows.placeholderMiniApp")}
                value={
                  notificationValues.callToAction.action.resource ? notificationValues.callToAction.action.resource : ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("callToAction.action.resource", e.target.value);
                  onChangeValues({
                    ...notificationValues,
                    callToAction: {
                      ...notificationValues.callToAction!,
                      action: {
                        ...notificationValues.callToAction.action,
                        resource: e.target.value,
                      },
                    },
                  });
                }}
                // error={!values.callToAction.action.resource ? t("commons.mandatoryField") : undefined}
              />
            )}
          </CallTitleSection>
        </SectionFormRow>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default CallToActionComponent;

const Container = styled.section`
  margin-top: 1.5rem;
`;

const SectionFormRow = styled("div")`
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
`;

const CallToActionToggle = styled("div")<{ isEditing: boolean }>`
  margin-top: 3vh;
  ${props => (!props.isEditing ? "label{background-color: white;opacity: 0.5;}" : null)};
`;

const CallTitleSection = styled("div")`
  flex: 0.6;
`;
const DefineActionSection = styled("div")`
  flex: 0.35;
`;

const DefineTitle = styled("div")<{ required: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  ${props => (props.required ? `::after {content: " *";color: #ff0000;font-weight: 400;padding-left: 2px;}` : null)};
`;

const NotEditingText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;
const DivCallActionOption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const TextInputSection = styled("div")`
  position: relative;
  margin-top: 3vh;
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #999;

  input {
    ::placeholder {
      color: #999999;
    }
  }
`;
