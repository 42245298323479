import styled from "styled-components";
import { ReferralCampaignFilterEnum } from "./components/referral-campaigns-filters.component";
import {
  IReferralCampaign,
  IScreen,
  ReceiverTypeRewardEnum,
  ReferralCampaignStatus,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
} from "./referral-campaigns.model";
import { FormikErrors, yupToFormErrors } from "formik";
import { array, number, object, Ref, string, StringSchema } from "yup";
import moment from "moment";
import i18next from "i18next";
import { ILanguage } from "../../../shared/models/language.model";

/**
 * filter referral campaigns by status
 */
export const filterReferralCampaignsByStatus = (
  referralCampaigns: IReferralCampaign[],
  status?: ReferralCampaignStatus,
): IReferralCampaign[] => {
  if (status) {
    return referralCampaigns.filter(dc => dc.status === status);
  }
  return referralCampaigns;
};

/**
 * filter referral campaigns by search
 */
export const filterReferralCampaignsBySearch = (referralCampaigns: IReferralCampaign[], searchString: string) => {
  if (searchString) {
    return referralCampaigns.filter(referralCampaign =>
      referralCampaign.name.translations.en.toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return referralCampaigns;
};

/**
 * Filter an array of Discover cards using a search string
 */
export const filterReferralCampaigns = (
  referralCampaigns: IReferralCampaign[],
  filters: Map<string, string[]>,
): IReferralCampaign[] => {
  let newReferralCampaigns: IReferralCampaign[];
  const status = filters.get(ReferralCampaignFilterEnum.STATUS) as any;
  const search = filters.get(ReferralCampaignFilterEnum.SEARCH);

  newReferralCampaigns = filterReferralCampaignsByStatus(referralCampaigns, status ? status[0] : undefined);

  if (search) {
    newReferralCampaigns = filterReferralCampaignsBySearch(newReferralCampaigns, search[0]);
  }

  return newReferralCampaigns;
};

/**
 * Validator helper for name and description
 * @returns {{[p: string]: any}}
 */
const getTranslationValidators = (languages: ILanguage[]) => {
  let validators: { [x: string]: Ref | StringSchema<string> } = {};
  languages.map((lang: ILanguage) => {
    if (lang.mandatory) {
      validators = {
        ...validators,
        [lang.code]: string()
          .trim()
          .required(i18next.t("pages.referralCampaigns.translationRequired"))
          .max(255, i18next.t("pages.referralCampaigns.maxLenDescription")),
      };
    } else {
      validators = {
        ...validators,
        [lang.code]: string().max(255, i18next.t("pages.referralCampaigns.maxLenDescription")),
      };
    }
  });
  return validators;
};

/**
 * Validator helper for invitation message
 * @returns {{[p: string]: any}}
 */
const getTranslationWithTagsValidators = (languages: ILanguage[]) => {
  let validators: { [x: string]: Ref | StringSchema<string> } = {};
  languages.map((lang: ILanguage) => {
    if (lang.mandatory) {
      validators = {
        ...validators,
        [lang.code]: string()
          .trim()
          .required(i18next.t("pages.referralCampaigns.translationRequired"))
          .max(225, i18next.t("pages.referralCampaigns.maxLenDescription225"))
          .test(
            "hasDuplicates",
            i18next.t("pages.referralCampaigns.createCampaign.campaignDetails.tagDuplicated"),
            function(val) {
              const tagsLabels = ["URL Link"];
              const parts = val.split(/\[(.*?)\]/g);
              const tagsFound = [] as string[];
              for (let i = 1; i < parts.length; i = i + 2) {
                if (tagsLabels.includes(parts[i])) {
                  tagsFound.push(parts[i]);
                }
              }
              if (tagsFound.filter((item, index) => tagsFound.indexOf(item) !== index).length > 0) {
                return false;
              }
              return true;
            },
          )
          .test("hasTag", i18next.t("pages.referralCampaigns.createCampaign.campaignDetails.tagMandatory"), function(
            val,
          ) {
            const tagsLabels = ["URL Link"];
            const parts = val.split(/\[(.*?)\]/g);
            const tagsFound = [] as string[];
            for (let i = 1; i < parts.length; i = i + 2) {
              if (tagsLabels.includes(parts[i])) {
                tagsFound.push(parts[i]);
              }
            }
            if (!!val && tagsFound.length === 0) {
              return false;
            }
            return true;
          }),
      };
    } else {
      validators = {
        ...validators,
        [lang.code]: string()
          .max(225, i18next.t("pages.referralCampaigns.maxLenDescription225"))
          .test(
            "hasDuplicates",
            i18next.t("pages.referralCampaigns.createCampaign.campaignDetails.tagDuplicated"),
            function(val) {
              const tagsLabels = ["URL Link"];
              const parts = val.split(/\[(.*?)\]/g);
              const tagsFound = [] as string[];
              for (let i = 1; i < parts.length; i = i + 2) {
                if (tagsLabels.includes(parts[i])) {
                  tagsFound.push(parts[i]);
                }
              }
              if (tagsFound.filter((item, index) => tagsFound.indexOf(item) !== index).length > 0) {
                return false;
              }
              return true;
            },
          )
          .test("hasTag", i18next.t("pages.referralCampaigns.createCampaign.campaignDetails.tagMandatory"), function(
            val,
          ) {
            const tagsLabels = ["URL Link"];
            const parts = val.split(/\[(.*?)\]/g);
            const tagsFound = [] as string[];
            for (let i = 1; i < parts.length; i = i + 2) {
              if (tagsLabels.includes(parts[i])) {
                tagsFound.push(parts[i]);
              }
            }
            if (!!val && tagsFound.length === 0) {
              return false;
            }
            return true;
          }),
      };
    }
  });
  return validators;
};

/**
 * Form validation schema
 * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
 */
const schemaCampaignDetails = (languages: ILanguage[]) =>
  object().shape({
    name: object().shape({
      translations: object().shape(getTranslationValidators(languages)),
    }),
    startDate: string().when("defaultCampaign", {
      is: false,
      then: string()
        .when("defaultCampaign", {
          is: true,
          then: string().nullable(true),
        })
        .when("status", {
          is: val => val !== ReferralCampaignStatus.LIVE,
          then: string()
            .required(i18next.t("pages.referralCampaigns.requiredField"))
            .test("format", i18next.t("pages.referralCampaigns.wrongFormat"), function(startDate) {
              if (!startDate) {
                return true;
              }
              return moment(startDate, "DD/MM/YYYY").format("DD/MM/YYYY") === startDate;
            })
            .test("is-greater", i18next.t("pages.referralCampaigns.timeGreaterStart"), function(startDate) {
              if (!startDate) {
                return true;
              }
              return moment(startDate, "DD/MM/YYYY").isSameOrAfter(new Date(), "day");
            }),
        }),
    }),
    endDate: string()
      .when("defaultCampaign", {
        is: true,
        then: string().nullable(true),
      })
      .when("defaultCampaign", {
        is: false,
        then: string()
          .required(i18next.t("pages.referralCampaigns.requiredField"))
          .test("format", i18next.t("pages.referralCampaigns.wrongFormat"), function(endDate) {
            if (!endDate) {
              return true;
            }
            return moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          })
          .test("is-greater", i18next.t("pages.referralCampaigns.timeGreaterEnd"), function(endDate) {
            if (!endDate) {
              return true;
            }
            return moment(endDate, "DD/MM/YYYY").isAfter(moment(this.parent.startDate, "DD/MM/YYYY"), "day");
          }),
      }),
    conversionEvent: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    inviteMessage: object().shape({
      translations: object().shape(getTranslationWithTagsValidators(languages)),
    }),
  });

/**
 * Fn to validate the form every stroke
 * @param values
 */
export const validateSchemaCampaignDetails = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<FormikErrors<IReferralCampaign>> => {
  return new Promise<FormikErrors<IReferralCampaign>>(resolve => {
    schemaCampaignDetails(languages)
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        setShowWarning && setShowWarning(false);
        //Validate if any optional language is empty
        for (let l = 0; l < languages.length; l++) {
          if (!values.name.translations[languages[l].code] || !values.inviteMessage.translations[languages[l].code]) {
            setShowWarning && setShowWarning(true);
          }
        }
        resolve({});
      })
      .catch(y => {
        resolve(yupToFormErrors(y));
      });
  }).then(r => {
    return r;
  });
};

/**
 * Validate campaign name and invite message translations
 */
export const validateTranslationsCampaignDetails = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>,
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  validateSchemaCampaignDetails(values, languages, setShowWarning).then(errors => {
    if (errors && (errors.inviteMessage || errors.name)) {
      const langErrors = Object.keys({
        ...(errors.inviteMessage && errors.inviteMessage.translations
          ? (errors.inviteMessage.translations as Object)
          : {}),
        ...(errors.name && errors.name.translations ? (errors.name.translations as Object) : {}),
      });
      setSelectedLanguage(langErrors[0]);
    }
  });
};

/**
 * Form validation schema for a reward
 */
const rewardSchema = () => {
  return {
    target: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    type: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    maxInvites: number().when("target", {
      is: TargetEnum.SENDER,
      then: number()
        .when("type", {
          is: val => val === SenderTypeRewardEnum.SINGLE,
          then: number()
            .notOneOf([0], i18next.t("pages.referralCampaigns.zeroIsInvalid"))
            .required(i18next.t("pages.referralCampaigns.requiredField"))
            .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))
            .min(-1, i18next.t("pages.referralCampaigns.minValue")),
        })
        .when("type", {
          is: val => val === SenderTypeRewardEnum.AGGREGATED,
          then: number()
            .required(i18next.t("pages.referralCampaigns.requiredField"))
            .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))
            .min(2, i18next.t("pages.referralCampaigns.minValueAggregated")),
        }),
    }),
    prizeCategory: string().when("type", {
      is: val =>
        val === SenderTypeRewardEnum.SINGLE ||
        val === SenderTypeRewardEnum.AGGREGATED ||
        val === ReceiverTypeRewardEnum.SINGLE,
      then: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    }),
    amount: string().when("type", {
      is: val =>
        val === SenderTypeRewardEnum.SINGLE ||
        val === SenderTypeRewardEnum.AGGREGATED ||
        val === ReceiverTypeRewardEnum.SINGLE,
      then: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    }),
  };
};

/**
 * Form validation schema
 * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
 */
const schemaRewardDetails = () =>
  object().shape({
    rewards: array()
      .required()
      .min(2)
      .max(2)
      .of(object().shape(rewardSchema())),
  });

/**
 * Fn to validate the form every stroke
 * @param values
 */
export const validateSchemaRewardDetails = (values: IReferralCampaign): Promise<FormikErrors<IReferralCampaign>> => {
  return new Promise<FormikErrors<IReferralCampaign>>(resolve => {
    schemaRewardDetails()
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        resolve({});
      })
      .catch(y => {
        resolve(yupToFormErrors(y));
      });
  }).then(r => {
    return r;
  });
};

/**
 * Validate campaign name and invite message translations
 */
export const validateRewardDetails = (values: IReferralCampaign) => {
  validateSchemaRewardDetails(values);
};

/**
 * Form validation schema for a screen
 */
const senderScreenSchema = (languages: ILanguage[]) => {
  return {
    type: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    title: object().when("type", {
      is: val => val === TypeScreenEnum.SENDER_INITIAL || val === TypeScreenEnum.SENDER_FINAL,
      then: object().shape({
        translations: object().shape(getTranslationValidators(languages)),
      }),
    }),
    description: object().when("type", {
      is: val => val === TypeScreenEnum.SENDER_INITIAL || val === TypeScreenEnum.SENDER_FINAL,
      then: object().shape({
        translations: object().shape(getTranslationValidators(languages)),
      }),
    }),
    urlImage: string().when("type", {
      is: val => val === TypeScreenEnum.SENDER_INITIAL || val === TypeScreenEnum.SENDER_FINAL,
      then: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    }),
  };
};

/**
 * Form validation schema
 * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
 */
const schemaSenderScreen = (languages: ILanguage[]) =>
  object().shape({
    screens: array()
      .required()
      .min(1)
      .max(3)
      .of(object().shape(senderScreenSchema(languages))),
  });

/**
 * Fn to validate the form every stroke
 * @param values
 */
export const validateSchemaSenderScreen = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<FormikErrors<IReferralCampaign>> => {
  return new Promise<FormikErrors<IReferralCampaign>>(resolve => {
    schemaSenderScreen(languages)
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        setShowWarning && setShowWarning(false);
        //Validate if any optional language is empty
        const senderValues = values.screens.find(
          s => s.type === TypeScreenEnum.SENDER_INITIAL || s.type === TypeScreenEnum.SENDER_FINAL,
        );
        for (let l = 0; l < languages.length; l++) {
          if (
            !senderValues?.title?.translations[languages[l].code] ||
            !senderValues?.description?.translations[languages[l].code]
          ) {
            setShowWarning && setShowWarning(true);
          }
        }
        resolve({});
      })
      .catch(y => {
        resolve(yupToFormErrors(y));
      });
  }).then(r => {
    return r;
  });
};

/**
 * Validate campaign name and invite message translations
 */
export const validateTranslationsSenderScreen = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>,
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  validateSchemaSenderScreen(values, languages, setShowWarning).then(errors => {
    if (errors && errors.screens) {
      let langErrors = [] as string[];
      (errors.screens as IScreen[]).forEach(errorScreen => {
        if (errorScreen && (errorScreen.title || errorScreen.description)) {
          langErrors = Object.keys({
            ...(errorScreen.title && errorScreen.title.translations ? (errorScreen.title.translations as Object) : {}),
            ...(errorScreen.description && errorScreen.description.translations
              ? (errorScreen.description.translations as Object)
              : {}),
          });
        }
      });
      if (langErrors.length > 0) {
        setSelectedLanguage(langErrors[0]);
      }
    }
  });
};

/**
 * Form validation schema for a screen
 */
const receiverScreenSchema = (languages: ILanguage[]) => {
  return {
    type: string().required(i18next.t("pages.referralCampaigns.requiredField")),
    headerTitle: object().when("type", {
      is: TypeScreenEnum.RECEIVER,
      then: object().shape({
        translations: object().shape(getTranslationValidators(languages)),
      }),
    }),
    title: object().shape({
      translations: object().shape(getTranslationValidators(languages)),
    }),
    description: object().shape({
      translations: object().shape(getTranslationValidators(languages)),
    }),
    urlImage: string().required(i18next.t("pages.referralCampaigns.requiredField")),
  };
};

/**
 * Form validation schema
 * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
 */
const schemaReceiverScreen = (languages: ILanguage[]) =>
  object().shape({
    screens: array()
      .required()
      .min(2)
      .max(3)
      .of(object().shape(receiverScreenSchema(languages))),
  });

/**
 * Fn to validate the form every stroke
 * @param values
 */
export const validateSchemaReceiverScreen = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<FormikErrors<IReferralCampaign>> => {
  return new Promise<FormikErrors<IReferralCampaign>>(resolve => {
    schemaReceiverScreen(languages)
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        setShowWarning && setShowWarning(false);
        //Validate if any optional language is empty
        const receiverValues = values.screens.find(s => s.type === TypeScreenEnum.RECEIVER);

        for (let l = 0; l < languages.length; l++) {
          if (
            !receiverValues?.title?.translations[languages[l].code] ||
            !receiverValues?.description?.translations[languages[l].code] ||
            !receiverValues?.headerTitle?.translations[languages[l].code]
          ) {
            setShowWarning && setShowWarning(true);
          }
        }
        resolve({});
      })
      .catch(y => {
        resolve(yupToFormErrors(y));
      });
  }).then(r => {
    return r;
  });
};

/**
 * Validate campaign name and invite message translations
 */
export const validateTranslationsReceiverScreen = (
  values: IReferralCampaign,
  languages: ILanguage[],
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>,
  setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  validateSchemaReceiverScreen(values, languages, setShowWarning).then(errors => {
    if (errors && errors.screens) {
      let langErrors = [] as string[];
      (errors.screens as IScreen[]).forEach(errorScreen => {
        if (errorScreen && (errorScreen.title || errorScreen.description || errorScreen.headerTitle)) {
          langErrors = Object.keys({
            ...(errorScreen.headerTitle && errorScreen.headerTitle.translations
              ? (errorScreen.headerTitle.translations as Object)
              : {}),
            ...(errorScreen.title && errorScreen.title.translations ? (errorScreen.title.translations as Object) : {}),
            ...(errorScreen.description && errorScreen.description.translations
              ? (errorScreen.description.translations as Object)
              : {}),
          });
        }
      });
      if (langErrors.length > 0) {
        setSelectedLanguage(langErrors[0]);
      }
    }
  });
};

export const TooltipText = styled("div")`
  font-size: 12px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 140px;
  text-align: center;
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 5px;
  cursor: pointer;
`;

export const FormLabelText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;

export const MutedText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;
