import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import { TFunction } from "i18next";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { RoutesEnum } from "../../../routes/routes.constants";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import SentForApprovalIcon from "../../../shared/icons/sent-for-approval.icon";
import SentForLdapApprovalIcon from "../../../shared/icons/sent-for-ldap-approval.icon";
import AuthenticationApi from "../authentication.api";
import { IForgotPasswordRequest } from "../authentication.interfaces";
import { RECAPTCHA_KEY } from "../authentication.utils";

function getValidationSchema(t: TFunction) {
  return object().shape({
    email: string()
      .required(t("components.changePasswordModal.required"))
      .email(t("commons.emailRequired")),
  });
}

/**
 * Forgot password
 */
const ForgotPasswordPage = () => {
  const [t] = useTranslation(["public"]);
  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const [isLdapUser, setIsLdapUser] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  function handleResetPassword(values: IForgotPasswordRequest, actions: FormikHelpers<IForgotPasswordRequest>) {
    actions.setSubmitting(true);
    const data = {
      email: values.email,
      language: "en-GB",
    };
    AuthenticationApi.methods
      .forgotPassword(data)
      .then(
        () => {
          setCanSubmit(false);
          setSuccessfulRequest(true);
        },
        error => {
          if (error.data.status.httpCode === 403 && error.data.status.businessCode === 4033) {
            setIsLdapUser(true);
          } else {
            setAlertProps({
              title: t("pages.forgotPassword.sendEmailError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          }
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  /**
   * On recaptcha change
   */
  const onValidRecaptcha = () => {
    setCanSubmit(true);
  };

  return (
    <ForgotPasswordContainer>
      {successfulRequest ? (
        <ForgotPasswordFormContainer>
          <ForgotPasswordTitle>{t("pages.forgotPassword.title")}</ForgotPasswordTitle>
          <ForgotPasswordDescription>{t("pages.forgotPassword.successfulDescription")}</ForgotPasswordDescription>
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <SentForApprovalIcon />
          </div>
          <ResendLink onClick={() => setSuccessfulRequest(false)}>
            {t("pages.forgotPassword.resendEmailLink")}
          </ResendLink>
          <div>
            <PrimaryButton
              redTheme={true}
              titleLabel={t("pages.forgotPassword.backToLoginBtn")}
              onClick={() => history.push(RoutesEnum.LOGIN)}
            />
          </div>
        </ForgotPasswordFormContainer>
      ) : isLdapUser ? (
        <ForgotPasswordFormContainer>
          <ForgotPasswordTitle>{t("pages.forgotPassword.title")}</ForgotPasswordTitle>
          <ForgotPasswordLdapDescription>{t("pages.forgotPassword.ldapUserDescription")}</ForgotPasswordLdapDescription>
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <SentForLdapApprovalIcon />
          </div>
          <PrimaryButtonContainer>
            <PrimaryButton
              redTheme={false}
              titleLabel={t("pages.forgotPassword.backToLoginBtn")}
              onClick={() => history.push(RoutesEnum.LOGIN)}
              style={{ border: `1px solid ${styleTheme.palette.aluminium}` }}
            />
          </PrimaryButtonContainer>
        </ForgotPasswordFormContainer>
      ) : (
        <Formik
          initialValues={{ email: "", language: "" }}
          onSubmit={handleResetPassword}
          validationSchema={getValidationSchema(t)}
          render={({ setFieldValue, values, errors, handleSubmit, isSubmitting }) => (
            <ForgotPasswordFormContainer onSubmit={handleSubmit}>
              <ForgotPasswordTitle>{t("pages.forgotPassword.title")}</ForgotPasswordTitle>
              <ForgotPasswordDescription>{t("pages.forgotPassword.description")}</ForgotPasswordDescription>
              <TextInput
                name={"email"}
                autoFocus
                required={true}
                style={{ marginBottom: !!values.email && !errors.email ? 20 : 40 }}
                type="email"
                title={t("pages.forgotPassword.inputTitle")}
                placeholder={t("pages.forgotPassword.inputPlaceholder")}
                value={values.email}
                error={errors.email}
                onChange={evt => setFieldValue("email", evt.target.value)}
              />
              {!!values.email && !errors.email ? (
                <RecaptchaContainer>
                  <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onValidRecaptcha} />
                </RecaptchaContainer>
              ) : null}
              <ButtonContainer>
                <PrimaryButton
                  disabled={isSubmitting || !canSubmit}
                  redTheme={true}
                  titleLabel={t("pages.forgotPassword.btnLabel")}
                  type="submit"
                />
              </ButtonContainer>
              <ResendLink onClick={() => history.push(RoutesEnum.LOGIN)} style={{ marginTop: 12 }}>
                {t("pages.forgotPassword.backToLoginBtn")}
              </ResendLink>
            </ForgotPasswordFormContainer>
          )}
        />
      )}

      <AnimatedBackgroundImage />
    </ForgotPasswordContainer>
  );
};

export default ForgotPasswordPage;

const ButtonContainer = styled("div")``;

const ForgotPasswordContainer = styled("div")`
  height: 100%;
`;

const ForgotPasswordFormContainer = styled("form")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const RecaptchaContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
`;

const ForgotPasswordTitle = styled("span")`
  font-family: Vodafone Lt;
  font-size: 33px;
  color: #333;
  margin-bottom: 53px;
`;

const ForgotPasswordDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333;
  margin-bottom: 18px;
`;

const ResendLink = styled("span")`
  margin-left: auto;
  margin-right: auto;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #999;
  margin-bottom: 12px;
  cursor: pointer;
`;
const PrimaryButtonContainer = styled("div")`
  margin-top: 37px;
`;

const ForgotPasswordLdapDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: #333;
  margin-bottom: 18px;
`;
