import { ClientDocSubmissionNextStatusEnumKeys, ClientDocSubmissionStatusEnumKeys } from "../ekyc.shared.enums";
import { IDisableOptionProps } from "./doc-validation-details.interface";

type ReasonsMap = { [key: string]: string[] };
const DocStatusReasons: ReasonsMap = {
  REJECT_CRITICAL_FIELDS: [
    "NAME_DONT_MATCH",
    "BIRTH_DATE_DONT_MATCH",
    "ID_NUMBER_DONT_MATCH",
    "ADDRESS_DONT_MATCH",
    "SIGNATURE_DONT_MATCH",
    "VISA_NOT_VALID",
    "CUSTOM",
  ],
  ERROR: ["ACCOUNT_EXISTS_FOR_MSISDN", "INCORRECT_MSISDN", "INVALID_KYC_FIELDS", "OTHER"],
  REJECT_ID_VS_SELFIE: ["PICTURE_DONT_MATCH", "CUSTOM"],
  REJECT_FACE_DUPLICATIONS: ["ANOTHER_FACE_EXISTS", "CUSTOM"],
  REJECT_ID_DUPLICATIONS: ["ANOTHER_ID_EXISTS", "CUSTOM"],
  REJECT_ON_G2: ["SOMETHING_WENT_WRONG"],
  REJECT_ON_MANUAL_REVIEW: ["DATA_DOESNT_MATCH", "CUSTOM"],
};

export const DocValidationDetailsUtils = {
  getNextDocStatus: (currentStatus: ClientDocSubmissionStatusEnumKeys): ClientDocSubmissionNextStatusEnumKeys[] => {
    switch (currentStatus) {
      case "PENDING":
      case "ACCEPTED":
      case "REJECTED":
        // this are states that cannot be edit
        return [];
      case "ERROR_ON_CRITICAL_FIELDS":
        return ["MOVE_ON", "REJECT_CRITICAL_FIELDS"];
      case "ERROR_ON_ID_VS_SELFIE":
        return ["MOVE_ON", "REJECT_ID_VS_SELFIE"];
      case "ERROR_ON_FACE_DUPLICATIONS":
        return ["MOVE_ON", "REJECT_FACE_DUPLICATIONS"];
      case "ERROR_ON_ID_DUPLICATIONS":
        return ["MOVE_ON", "REJECT_ID_DUPLICATIONS"];
      case "ERROR_ON_G2":
        return ["MOVE_ON", "REJECT_ON_G2"];
      case "PENDING_MANUAL_REVIEW":
        return ["MOVE_ON", "REJECT_ON_MANUAL_REVIEW"];
      default:
        // For other unsupported states, let user choose a generic rejection message
        return ["REJECT_ON_G2"];
    }
  },

  nextDocStatusHasReason: (nextStatus: ClientDocSubmissionNextStatusEnumKeys): boolean =>
    nextStatus !== "MOVE_ON" && nextStatus !== undefined && nextStatus !== null,

  getDocStatusReasons: (status?: ClientDocSubmissionStatusEnumKeys): string[] => {
    let reasonLabels: string[] = [];

    if (status && DocStatusReasons.hasOwnProperty(status)) {
      reasonLabels = Object.values(DocStatusReasons[`${status}`]);
    }

    return reasonLabels;
  },

  // Disables the option to reject or move_on with a registration based on the optionToBeDisabled and forbiddenStatuses parameters
  disableOption: (disableOptionProps: IDisableOptionProps) => {
    const { currentOption, currentStatus, forbiddenStatuses, optionToBeDisabled } = disableOptionProps;
    return currentOption === optionToBeDisabled && forbiddenStatuses.includes(currentStatus);
  },
};
