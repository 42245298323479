import React, { useState, useCallback, useContext } from "react";
import { IStoreInterface } from "../../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import {
  useAlert,
  useModal,
  ModalTypeEnum,
  SecondaryPageTitle,
  FormSection,
  TextInput,
  MultipleOptionsDropdown,
  EditIcon,
  CheckIcon,
  TrashIcon,
  UndoIcon,
  TextArea,
} from "@wit/mpesa-ui-components";
import { IUpdateExternalServiceRequest } from "../external-services-add/external-service-add.interface";
import { FormikHelpers, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { IExternalServices, ExternalServicePermitionsEnum } from "../../../../shared/models/service-manager.model";
import ExternalServicesApi from "../external-services.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { ExternalServicesActions } from "../external-services.store";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { PageContainer } from "../../../../shared/shared.styled";
import styled from "styled-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { getExternalServicePermissions } from "../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { useHistory, useParams } from "react-router-dom";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { BackOfficeMarketsEnums } from "../../../../shared/shared.enums";
import { ConfigContext } from "../../../../app.component";

/** External Service Profie page */
const ExternalServicesProfile = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { externalServiceID } = useParams<any>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [permissionsSelected, setPermissionsSelected] = useState<ExternalServicePermitionsEnum[]>([]);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideDeleteModal()}
      primaryAction={() => deleteRequest()}
      title={t("pages.externalServices.deleteModal.title")}
      description={t("pages.externalServices.deleteModal.description")}
      primaryBtnLabel={t("pages.externalServices.deleteModal.btnLabel")}
    />,
  );

  const externalService = useSelector(
    (store: IStoreInterface) =>
      store.externalServicesReducer.externalServices.find(
        service => service.serviceId === externalServiceID,
      ) as IExternalServices,
  );

  const refreshPage = useCallback(() => {
    ExternalServicesApi.methods.getExternalServices().then(
      res => {
        dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.SERVICE_MANAGER);
      },
    );
  }, [dispatch, setAlertProps, showAlert, t]);

  const [isStarting, setIsStarting] = React.useState(true);
  const { config } = useContext(ConfigContext);

  function isOptionSelected(opt?: SharedDropdownOption) {
    let gotPin = false;
    let gotIdentity = false;
    if (isStarting) {
      if (externalService.permissions) {
        externalService.permissions.forEach(element => {
          if (element && element.includes("PIN")) {
            gotPin = true;
          } else if (element && element.includes("IDENTITY")) {
            gotIdentity = true;
          }
        });

        if (gotPin && gotIdentity) {
          setPermissionsSelected([
            ...permissionsSelected,
            ExternalServicePermitionsEnum.PIN,
            ExternalServicePermitionsEnum.IDENTITY,
          ]);
        } else if (gotPin && !gotIdentity) {
          setPermissionsSelected([...permissionsSelected, ExternalServicePermitionsEnum.PIN]);
        } else if (!gotPin && gotIdentity) {
          setPermissionsSelected([...permissionsSelected, ExternalServicePermitionsEnum.IDENTITY]);
        }
        setIsStarting(false);
        return permissionsSelected;
      }
    }

    if (opt) {
      return permissionsSelected.includes(opt.key);
    }
  }

  function getDropdownLabel() {
    isOptionSelected();
    // If permissionsSelected is duplicated
    if (permissionsSelected.length && permissionsSelected[0] === permissionsSelected[1]) {
      permissionsSelected.shift();
    }
    if (
      permissionsSelected.includes(ExternalServicePermitionsEnum.PIN) &&
      permissionsSelected.includes(ExternalServicePermitionsEnum.IDENTITY)
    ) {
      return <div>PIN, IDENTITY</div>;
    } else if (permissionsSelected.length === 1) {
      if (permissionsSelected.includes(ExternalServicePermitionsEnum.PIN)) {
        return <div>PIN</div>;
      } else {
        return <div>IDENTITY</div>;
      }
    } else {
      return <div>-</div>;
    }
  }

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setPermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      if (opt.key === ExternalServicePermitionsEnum.PIN) {
        setPermissionsSelected([...permissionsSelected, ExternalServicePermitionsEnum.PIN]);
      } else {
        setPermissionsSelected([...permissionsSelected, ExternalServicePermitionsEnum.IDENTITY]);
      }
    }
  }

  function resetDropdown() {
    setPermissionsSelected([]);
  }

  function displayPermissionsDisplay() {
    if (externalService.permissions) {
      if (externalService.permissions.length === 2) {
        return (
          <>
            <Value style={{ color: "#00c3ff" }}>
              {t("pages.externalServices.configurations.columns.permissionPIN")},
            </Value>
            <Value style={{ color: "#6338eb", marginLeft: "5px" }}>
              {t("pages.externalServices.configurations.columns.permissionIDENTITY")}
            </Value>
          </>
        );
      } else if (externalService.permissions.length === 1) {
        if (externalService.permissions.includes(ExternalServicePermitionsEnum.IDENTITY)) {
          return (
            <Value style={{ color: "#6338eb" }}>
              {t("pages.externalServices.configurations.columns.permissionIDENTITY")}
            </Value>
          );
        } else {
          return (
            <Value style={{ color: "#00c3ff" }}>
              {t("pages.externalServices.configurations.columns.permissionPIN")}
            </Value>
          );
        }
      } else {
        return <Value>-</Value>;
      }
    } else {
      return <Value>-</Value>;
    }
  }

  /**
   *editExernalService request
   * @param values
   * @param actions
   */
  const editExternalService = (values: IUpdateExternalServiceRequest, actions: FormikHelpers<any>) => {
    values.permissions = [];
    values.permissions = [...permissionsSelected];
    if (
      permissionsSelected.includes(ExternalServicePermitionsEnum.PIN) &&
      config?.market === BackOfficeMarketsEnums.KE &&
      (!values.rsaPublicKey || values.rsaPublicKey?.trim() === "")
    ) {
      setAlertProps({
        title: t("pages.serviceManager.externalServices.alerts.rsaRequired"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    } else {
      setConfirmationProps({
        title: t("pages.serviceManager.externalServices.links.saveChanges"),
        description: t("pages.serviceManager.externalServices.modals.confirmEdit", {
          externalService: externalService.serviceId,
        }),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => {
          actions.setSubmitting(true);
          ExternalServicesApi.methods
            .updateExternalService(values)
            .finally(() => actions.setSubmitting(false))
            .then(
              () => {
                setAlertProps({
                  title: t("pages.serviceManager.externalServices.alerts.editSuccess"),
                  type: AlertTypeEnum.SUCCESS,
                });

                showAlert();
                setIsEditing(false);
                dispatch(ExternalServicesActions.creators.editExternalServiceAction(values));
                ExternalServicesActions.creators.fetchingExternalServicesAction();
                ExternalServicesApi.methods.getExternalServices().then(res => {
                  dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
                });
              },
              () => {
                setAlertProps({
                  title: t("pages.serviceManager.externalServices.alerts.editError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            )
            .finally(() => {
              actions.setSubmitting(false);
              hideConfirmationModal();
              refreshPage();
            });
        },
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /**
   * Remove External Service Request
   */
  const deleteRequest = () => {
    ExternalServicesApi.methods
      .deleteExternalService(externalService.serviceId)
      .then(
        () => {
          setAlertProps({
            title: t("pages.externalServices.deleteRequest.titleDone"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        () => {
          setAlertProps({
            title: t("pages.externalServices.deleteRequest.titleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideDeleteModal);
  };

  /** show deleteModal */
  const deleteExternalService = () => {
    showDeleteModal();
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        goBackFn={() =>
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`)
        }
        title={t("pages.externalServices.profilePage.title")}
        displayInitialsCircle={false}
      />
      <PageContent>
        <Formik
          initialValues={{
            externalServiceId: externalService.serviceId,
            url: externalService.url,
            permissions: [...permissionsSelected],
            rsaPublicKey: externalService.rsaPublicKey,
          }}
          onSubmit={editExternalService}
          render={({ values, handleChange, handleSubmit, resetForm }) => (
            <>
              <MainContent>
                <form onSubmit={handleSubmit}>
                  <FormSection
                    isEditing={isEditing}
                    title={t("pages.externalServices.addPage.description")}
                    rows={[
                      {
                        label: (
                          <TitleLabel mandatory={true}>
                            {t("pages.externalServices.addPage.getRows.serviceIdentifier")}
                          </TitleLabel>
                        ),
                        displayComponent: externalService.serviceId,
                        editingComponent: (
                          <TextInput
                            name="externalServiceId"
                            value={values.externalServiceId}
                            onChange={handleChange}
                            placeholder={t("pages.externalServices.configurations.serviceId")}
                            disabled={true}
                          />
                        ),
                      },
                      {
                        label: (
                          <TitleLabel mandatory={true}>
                            {t("pages.externalServices.configurations.columns.url")}
                          </TitleLabel>
                        ),
                        displayComponent: (
                          <DescriptionContainer>
                            <Value>{externalService.url}</Value>
                          </DescriptionContainer>
                        ),
                        editingComponent: (
                          <TextInput
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                            placeholder={t("pages.externalServices.configurations.columns.url")}
                          />
                        ),
                      },
                      {
                        label: t("pages.externalServices.configurations.columns.permissions"),
                        displayComponent: <DescriptionContainer>{displayPermissionsDisplay()}</DescriptionContainer>,
                        editingComponent: (
                          <MultipleOptionsDropdown
                            label={getDropdownLabel()}
                            toggleOption={opt => toggleOption(opt)}
                            clearAllFilters={() => resetDropdown()}
                            hasValue={false}
                            isOptionSelected={opt => isOptionSelected(opt)}
                            options={getExternalServicePermissions()}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          />
                        ),
                      },
                      {
                        label: (
                          <TitleLabel
                            mandatory={
                              permissionsSelected.includes(ExternalServicePermitionsEnum.PIN) &&
                              config?.market === BackOfficeMarketsEnums.KE
                            }
                          >
                            {t("pages.externalServices.addPage.getRows.RSApublicKey")}
                          </TitleLabel>
                        ),
                        displayComponent: <RsaKeyLabelText>{values.rsaPublicKey}</RsaKeyLabelText>,
                        editingComponent: (
                          <TextArea
                            name="rsaPublicKey"
                            value={values.rsaPublicKey}
                            onChange={handleChange}
                            placeholder={t("pages.externalServices.configurations.columns.publicKey")}
                          />
                        ),
                      },
                    ]}
                  />
                </form>
              </MainContent>
              <SideMenu className={"side-menu"}>
                {!isEditing ? (
                  <LinkContainer
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  >
                    <IconContainer color={styleTheme.palette.turquoiseBlue}>
                      <EditIcon />
                    </IconContainer>
                    <span>{t("pages.externalServices.profilePage.editDetails")}</span>
                  </LinkContainer>
                ) : (
                  <>
                    <LinkContainer onClick={() => handleSubmit()}>
                      <IconContainer color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </IconContainer>
                      <span>{t("pages.userProfile.links.saveChanges")}</span>
                    </LinkContainer>

                    <LinkContainer
                      onClick={() => {
                        resetForm();
                        resetDropdown();
                        setIsEditing(false);
                        setIsStarting(true);
                      }}
                    >
                      <IconContainer color={styleTheme.palette.vodafoneRed}>
                        <UndoIcon />
                      </IconContainer>
                      <span>{t("pages.externalServices.profilePage.cancelText")}</span>
                    </LinkContainer>
                  </>
                )}
                <LinkContainer onClick={() => deleteExternalService()} disabled={isEditing}>
                  <IconContainer color={styleTheme.palette.vodafoneRed}>
                    <TrashIcon />
                  </IconContainer>
                  <span>{t("pages.externalServices.profilePage.removeService")}</span>
                </LinkContainer>
              </SideMenu>
            </>
          )}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ExternalServicesProfile;

const DescriptionContainer = styled("span")`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 40%;
  margin-left: 10px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

export const FileIconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

const TitleLabel = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;
const RsaKeyLabelText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333333;
  line-height: 21px;
  padding: 12px 0 11px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
