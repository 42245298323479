import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import { INotification } from "./push-notifications.modal";

const PushNotificationsApi = {
  routes: {
    addPushNotification: "/on-demand-notifications",
    deletePushNotification: "/on-demand-notifications/:notificationId",
    getNotificationsAll: "/on-demand-notifications/all",
  },
  methods: {
    addPushNotification: (pushNotification: INotification): AxiosPromise => {
      return ApiClient.post(PushNotificationsApi.routes.addPushNotification, pushNotification, { timeout: 90000 });
    },
    deletePushNotification: (notifId: string): AxiosPromise => {
      return ApiClient.delete(PushNotificationsApi.routes.deletePushNotification.replace(":notificationId", notifId));
    },
    getNotificationsAll: (): AxiosPromise => {
      return ApiClient.get(PushNotificationsApi.routes.getNotificationsAll);
    },
  },
};

export default PushNotificationsApi;
