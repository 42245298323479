import React from "react";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AutoClosingDropdown } from "@wit/mpesa-ui-components";
import { EFormDataStatus, IStatusDropDownComponentProps } from "../feedback-and-problems.model";
import { feedbacksStringPath, problemsStringPath, isFeedbackTabActive } from "../feedback-and-problems.utils";

const ResultStatusDropDownComponent = ({
  sectionId,
  itemId,
  currentStatus,
  onSelectedRowDataStatusChange,
  translations,
}: IStatusDropDownComponentProps) => {
  const baseFeedbackString = `${feedbacksStringPath}.resultArea.statusEnums`;
  const baseProblemReportString = `${problemsStringPath}.resultArea.statusEnums`;

  return (
    <StatusDropDownWrapper
      id="status-dropdown"
      onClick={eve => {
        eve.stopPropagation();
      }}
    >
      <AutoClosingDropdown
        hasValue={true}
        label={
          <span
            style={
              currentStatus === EFormDataStatus.RESOLVED
                ? { color: styleTheme.palette.springGreen }
                : currentStatus === EFormDataStatus.IN_PROGRESS
                ? { color: styleTheme.palette.freshOrange }
                : { color: styleTheme.palette.red.normal }
            }
          >
            {sectionId === 0
              ? translations(`${baseFeedbackString}.${currentStatus}`)
              : translations(`${baseProblemReportString}.${currentStatus}`)}
          </span>
        }
        options={
          currentStatus === EFormDataStatus.RESOLVED
            ? [
                {
                  key: EFormDataStatus.NOT_REVIEWED,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.NOT_REVIEWED}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.NOT_REVIEWED}`),
                },
                {
                  key: EFormDataStatus.IN_PROGRESS,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.IN_PROGRESS}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.IN_PROGRESS}`),
                },
              ]
            : currentStatus === EFormDataStatus.IN_PROGRESS
            ? [
                {
                  key: EFormDataStatus.NOT_REVIEWED,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.NOT_REVIEWED}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.NOT_REVIEWED}`),
                },
                {
                  key: EFormDataStatus.RESOLVED,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.RESOLVED}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.RESOLVED}`),
                },
              ]
            : [
                {
                  key: EFormDataStatus.IN_PROGRESS,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.IN_PROGRESS}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.IN_PROGRESS}`),
                },
                {
                  key: EFormDataStatus.RESOLVED,
                  label: isFeedbackTabActive(sectionId)
                    ? translations(`${baseFeedbackString}.${EFormDataStatus.RESOLVED}`)
                    : translations(`${baseProblemReportString}.${EFormDataStatus.RESOLVED}`),
                },
              ]
        }
        selectOption={option =>
          onSelectedRowDataStatusChange({
            itemId: itemId,
            statusCode: option.key,
          })
        }
      />
    </StatusDropDownWrapper>
  );
};

export default ResultStatusDropDownComponent;

const StatusDropDownWrapper = styled("div")<{ disabled?: Boolean }>`
  min-width: 150px;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-weight: inherit;
  margin: 4px 0;
  position: absolute;

  button {
    border: 0;
    font-weight: inherit;
    padding-left: 0;
    &:hover {
      border: 0;
    }
  }

  div {
    width: 100%;
    font-weight: 400 !important;
  }
`;
