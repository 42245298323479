import produce from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IExternalServices } from "../../../shared/models/service-manager.model";
import { IUpdateExternalServiceRequest } from "./external-services-add/external-service-add.interface";

export interface IExternalServicesReducerInterface {
  externalServices: IExternalServices[];
  isLoadingExternalServices: boolean;
}

const externalInitialState: IExternalServicesReducerInterface = {
  externalServices: [],
  isLoadingExternalServices: false,
};

export const ExternalServicesActions = {
  types: {
    FETCHING_EXTERNAL_SERVICES: "FETCHING_EXTERNAL_SERVICES",
    FETCH_EXTERNAL_SERVICES_SUCCESS: "FETCH_EXTERNAL_SERVICES_SUCCESS",
    FETCH_EXTERNAL_SERVICES_ERROR: "FETCH_EXTERNAL_SERVICES_ERROR",
    FETCH_EXTERNAL_SERVICES_LEAVE: "FETCH_EXTERNAL_SERVICES_LEAVE",
    ADD_EXTERNAL_SERVICE: "ADD_EXTERNAL_SERVICE",
    REMOVE_EXTERNAL_SERVICE: "REMOVE_EXTERNAL_SERVICE",
    EDIT_EXTERNAL_SERVICE: "EDIT_EXTERNAL_SERVICE",
  },
  creators: {
    fetchingExternalServicesAction: () => ({
      type: ExternalServicesActions.types.FETCHING_EXTERNAL_SERVICES,
    }),
    fetchExternalServicesSuccessAction: (externalServices: IExternalServices[]) => ({
      type: ExternalServicesActions.types.FETCH_EXTERNAL_SERVICES_SUCCESS,
      payload: {
        externalServices,
      },
    }),
    fetchExternalServicesLeaveAction: () => ({
      type: ExternalServicesActions.types.FETCH_EXTERNAL_SERVICES_ERROR,
    }),
    editExternalServiceAction: (values: IUpdateExternalServiceRequest) => ({
      type: ExternalServicesActions.types.EDIT_EXTERNAL_SERVICE,
      payload: {
        values,
      },
    }),
  },
};

export const externalServicesReducer = produce(
  (draft: IExternalServicesReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case ExternalServicesActions.types.FETCHING_EXTERNAL_SERVICES:
        draft.isLoadingExternalServices = true;
        return;
      case ExternalServicesActions.types.FETCH_EXTERNAL_SERVICES_SUCCESS:
        draft.isLoadingExternalServices = false;
        draft.externalServices = action.payload.externalServices.externalServices;
        return;
      case ExternalServicesActions.types.FETCH_EXTERNAL_SERVICES_ERROR:
        draft.isLoadingExternalServices = false;
        return;
      case AuthenticationActions.types.LOGOUT:
        return externalInitialState;
      default:
        return draft;
    }
  },
  externalInitialState,
);
