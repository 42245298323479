import React from "react";
import styled from "styled-components";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { Table } from "@wit/mpesa-ui-components";
import { IInviteList } from "../../referral-campaigns.model";
import EmptyStateIllustration from "../../../../../shared/icons/empty-state-illustration.icon";
import { formatUnixTimestampDate } from "../../../../../shared/shared.utils";

interface IAnalyticsInvitesProps {
  columnsConfig: ColumnProps[];
  data: IInviteList[];
  noResultsText: string;
}

const AnalyticsInvitesTable = ({ columnsConfig, data, noResultsText }: IAnalyticsInvitesProps) => {
  const invitesList = formatUnixTimestampDate(data, "firstLogin", "DD/MMM/YYYY HH:MM:SS") || [];

  /**
   * Retrieves the columns for Invites List
   * @returns {ColumnProps[]}
   */
  const getTableColumns = (): ColumnProps[] => {
    const columns = columnsConfig.map(column => {
      return {
        ratio: 3 / 12,
        label: column.label,
        formKey: column.formKey,
        isEditable: column.isEditable,
        sortable: true,
      };
    });

    return [...columns];
  };

  return (
    <Table<IInviteList>
      columns={getTableColumns()}
      values={invitesList}
      sortable={false}
      isStripedTable={true}
      showActionColumn={false}
      emptyStateComponent={
        <EmptyState>
          <EmptyStateIllustration />
          <NoResultsFoundText>{noResultsText}</NoResultsFoundText>
        </EmptyState>
      }
    />
  );
};

export default AnalyticsInvitesTable;

const EmptyState = styled.div`
  margin-top: 192px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const NoResultsFoundText = styled.span`
  margin: 33.67px 0 144.67px 0;
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;
