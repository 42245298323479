import React from "react";
import { useTranslation } from "react-i18next";
import { AutoClosingDropdown, FormSection, TextInput } from "@wit/mpesa-ui-components";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import HelpIcon from "../../../../shared/icons/help.icon";
import {
  IReferralCampaign,
  IReward,
  PrizeCategoryEnum,
  ReceiverTypeRewardEnum,
  SenderTypeRewardEnum,
  TargetEnum,
} from "../referral-campaigns.model";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { TooltipContainer, TooltipText, FormLabelText } from "../referral-campaigns.utils";

interface IRewardDetailsFormProps {
  firstTitle: React.ReactNode;
  secondTitle: React.ReactNode;
  isEditing: boolean;
  language: string;
  values: IReferralCampaign;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
}

/**
 * RewardDetailsForm component
 * Can be used to create or edit the reward details
 */
const RewardDetailsForm = ({
  firstTitle,
  secondTitle,
  isEditing,
  language: lang,
  values,
  setFieldValue,
  errors,
}: IRewardDetailsFormProps) => {
  const [t] = useTranslation();

  /**
   * method to find a reward
   */
  const findReward = (target: TargetEnum): IReward => {
    return values.rewards.find(reward => reward.target === target) as IReward;
  };

  /**
   * Returns the sender rewards types options
   */
  const getSenderRewardTypeOptions = () => {
    const rewards: {
      label: string;
      key: SenderTypeRewardEnum | undefined;
    }[] = Object.values(SenderTypeRewardEnum).map(type => ({
      label: t(`pages.referralCampaigns.createCampaign.rewardDetails.senderRewardType.${type.toLowerCase()}`),
      labelElement: (
        <span id={`senderRewards-${type}`}>
          {t(`pages.referralCampaigns.createCampaign.rewardDetails.senderRewardType.${type.toLowerCase()}`)}
        </span>
      ),
      key: type,
    }));
    return rewards;
  };

  /**
   * Returns the receiver rewards types options
   */
  const getReceiverRewardTypeOptions = () => {
    const rewards: {
      label: string;
      key: ReceiverTypeRewardEnum | undefined;
    }[] = Object.values(ReceiverTypeRewardEnum).map(type => ({
      label: t(`pages.referralCampaigns.createCampaign.rewardDetails.receiverRewardType.${type.toLowerCase()}`),
      labelElement: (
        <span id={`receiverRewards-${type}`}>
          {t(`pages.referralCampaigns.createCampaign.rewardDetails.receiverRewardType.${type.toLowerCase()}`)}
        </span>
      ),
      key: type,
    }));
    return rewards;
  };

  /**
   * Returns the receiver rewards types options
   */
  const getPrizeCategotyOptions = () => {
    const categories: {
      label: string;
      key: PrizeCategoryEnum | undefined;
    }[] = Object.values(PrizeCategoryEnum).map(category => ({
      label: t(`pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${category.toLowerCase()}`),
      labelElement: (
        <span id={category}>
          {t(`pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${category.toLowerCase()}`)}
        </span>
      ),
      key: category,
    }));
    return categories;
  };

  /**
   * method to set a field at rewards array
   */
  const setRewardField = (values: IReferralCampaign, target: TargetEnum, field: string, value: any) => {
    if (values.rewards) {
      const rewards = [...values.rewards];
      const reward = rewards.find(reward => reward.target === target);
      if (reward) {
        rewards[rewards.indexOf(reward)] = { ...reward, [field]: value };
      } else {
        rewards.push({ target, [field]: value } as IReward);
      }
      return rewards;
    } else {
      return [{ target, [field]: value } as IReward];
    }
  };

  /**
   * method to get the extra rows for sender
   */
  const getExtraRowsForSender = (values: IReferralCampaign): FormSectionRow[] => {
    if (values.rewards) {
      const rewardSender = findReward(TargetEnum.SENDER);
      if (rewardSender && rewardSender.type === SenderTypeRewardEnum.SINGLE) {
        return getSenderSingleRewardRows();
      } else if (rewardSender && rewardSender.type === SenderTypeRewardEnum.AGGREGATED) {
        return getSenderAggregatedRewardRows();
      }
    }
    return [];
  };

  /**
   * method to get the extra rows for single type of reward
   */
  const getSenderSingleRewardRows = (): FormSectionRow[] => {
    const rows = [
      {
        label: (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabelText>{t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvites")}</FormLabelText>
              <TooltipContainer data-tip data-for="tooltip-max-invites" id={"max-invites-tooltip"}>
                <HelpIcon />
                <ReactTooltip
                  event="mouseover"
                  eventOff="mouseleave mousedown"
                  place="top"
                  type="dark"
                  effect="solid"
                  id={"tooltip-max-invites"}
                  className={"tooltip-container"}
                >
                  <TooltipText>
                    {t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesTooltip")}
                  </TooltipText>
                </ReactTooltip>
              </TooltipContainer>
            </div>
          </>
        ),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).maxInvites
            ? (findReward(TargetEnum.SENDER).maxInvites as number) > 0
              ? `${findReward(TargetEnum.SENDER).maxInvites} invites`
              : t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.noLimits")
            : null,
        editingComponent: (
          <SectionColumn>
            <InviteNumbersContainer>
              <NumberButton
                onClick={() => setFieldValue(`rewards`, setRewardField(values, TargetEnum.SENDER, "maxInvites", -1))}
                type="button"
                value={
                  values.rewards && findReward(TargetEnum.SENDER)
                    ? findReward(TargetEnum.SENDER).maxInvites === -1
                    : false
                }
              >
                {t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.noLimits")}
              </NumberButton>
              <NumberButton
                onClick={() => setFieldValue(`rewards`, setRewardField(values, TargetEnum.SENDER, "maxInvites", 3))}
                type="button"
                value={
                  values.rewards && findReward(TargetEnum.SENDER)
                    ? findReward(TargetEnum.SENDER).maxInvites === 3
                    : false
                }
              >
                {t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.threeInvites")}
              </NumberButton>
              <NumberButton
                onClick={() => setFieldValue(`rewards`, setRewardField(values, TargetEnum.SENDER, "maxInvites", 5))}
                type="button"
                value={
                  values.rewards && findReward(TargetEnum.SENDER)
                    ? findReward(TargetEnum.SENDER).maxInvites === 5
                    : false
                }
              >
                {t("pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.fiveInvites")}
              </NumberButton>
              <TextInput
                name="sender-rewards"
                type={"number"}
                step="any"
                min={1}
                style={
                  findReward(TargetEnum.SENDER) &&
                  findReward(TargetEnum.SENDER).maxInvites !== undefined &&
                  findReward(TargetEnum.SENDER).maxInvites !== -1 &&
                  findReward(TargetEnum.SENDER).maxInvites !== 3 &&
                  findReward(TargetEnum.SENDER).maxInvites !== 5
                    ? { borderColor: "#e60000" }
                    : undefined
                }
                value={
                  findReward(TargetEnum.SENDER) &&
                  findReward(TargetEnum.SENDER).maxInvites !== undefined &&
                  findReward(TargetEnum.SENDER).maxInvites !== -1 &&
                  findReward(TargetEnum.SENDER).maxInvites !== 3 &&
                  findReward(TargetEnum.SENDER).maxInvites !== 5
                    ? findReward(TargetEnum.SENDER).maxInvites
                    : undefined
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.enterOtherNumber",
                )}`}
                required={true}
                onChange={e =>
                  setFieldValue(
                    `rewards`,
                    setRewardField(values, TargetEnum.SENDER, "maxInvites", Number(e.target.value)),
                  )
                }
                error={
                  errors.rewards && errors.rewards.find((error: IReward) => error && error.maxInvites)
                    ? errors.rewards.find((error: IReward) => error && error.maxInvites).maxInvites
                    : undefined
                }
              />
            </InviteNumbersContainer>
          </SectionColumn>
        ),
        required: true,
      },
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeCategory"),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).prizeCategory
            ? t(
                `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                  TargetEnum.SENDER,
                ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
              )
            : null,
        editingComponent: (
          <SectionColumn>
            <AutoClosingDropdown
              hasValue={!!values.rewards}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              label={
                values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).prizeCategory ? (
                  t(
                    `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                      TargetEnum.SENDER,
                    ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
                  )
                ) : (
                  <SpanPlaceholder>
                    {t(`pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryPlaceholder`)}
                  </SpanPlaceholder>
                )
              }
              options={getPrizeCategotyOptions()}
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.prizeCategory) &&
                !findReward(TargetEnum.SENDER).prizeCategory
                  ? errors.rewards.find((error: IReward) => error && error.prizeCategory).prizeCategory
                  : undefined
              }
              selectOption={opt =>
                setFieldValue("rewards", setRewardField(values, TargetEnum.SENDER, "prizeCategory", opt.key))
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmount"),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).amount
            ? findReward(TargetEnum.SENDER).amount
            : null,
        editingComponent: (
          <SectionColumn>
            <TextInput
              name="sender-rewards"
              value={
                findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).amount
                  ? findReward(TargetEnum.SENDER).amount
                  : undefined
              }
              placeholder={`${t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmountPlaceholder")}`}
              required={true}
              onChange={e =>
                setFieldValue(`rewards`, setRewardField(values, TargetEnum.SENDER, "amount", e.target.value))
              }
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.amount) &&
                !findReward(TargetEnum.SENDER).amount
                  ? errors.rewards.find((error: IReward) => error && error.amount).amount
                  : undefined
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
    ] as FormSectionRow[];
    return rows;
  };

  /**
   * method to get the extra rows for aggregated type of reward
   */
  const getSenderAggregatedRewardRows = (): FormSectionRow[] => {
    const rows = [
      {
        label: (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabelText>
                {t("pages.referralCampaigns.createCampaign.rewardDetails.numberAcceptedInvites")}
              </FormLabelText>
              <TooltipContainer
                data-tip
                data-for="tooltip-number-accepted-invites"
                id={"number-accepted-invites-tooltip"}
              >
                <HelpIcon />
                <ReactTooltip
                  event="mouseover"
                  eventOff="mouseleave mousedown"
                  place="top"
                  type="dark"
                  effect="solid"
                  id={"tooltip-number-accepted-invites"}
                  className={"tooltip-container"}
                >
                  <TooltipText>
                    {t("pages.referralCampaigns.createCampaign.rewardDetails.numberAcceptedInvitesTooltip")}
                  </TooltipText>
                </ReactTooltip>
              </TooltipContainer>
            </div>
          </>
        ),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).maxInvites
            ? `${findReward(TargetEnum.SENDER).maxInvites} invites`
            : null,
        editingComponent: (
          <SectionColumn>
            <InviteNumbersContainer>
              <TextInput
                name="sender-aggregated-rewards"
                type={"number"}
                min={2}
                step="any"
                value={
                  findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).maxInvites !== undefined
                    ? findReward(TargetEnum.SENDER).maxInvites
                    : undefined
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.rewardDetails.maxInvitesOptions.numberOfAcceptedInvites",
                )}`}
                required={true}
                onChange={e =>
                  setFieldValue(
                    `rewards`,
                    setRewardField(values, TargetEnum.SENDER, "maxInvites", Number(e.target.value)),
                  )
                }
                error={
                  errors.rewards && errors.rewards.find((error: IReward) => error && error.maxInvites)
                    ? errors.rewards.find((error: IReward) => error && error.maxInvites).maxInvites
                    : undefined
                }
              />
            </InviteNumbersContainer>
          </SectionColumn>
        ),
        required: true,
      },
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeCategory"),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).prizeCategory
            ? t(
                `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                  TargetEnum.SENDER,
                ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
              )
            : null,
        editingComponent: (
          <SectionColumn>
            <AutoClosingDropdown
              hasValue={!!values.rewards}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              label={
                values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).prizeCategory ? (
                  t(
                    `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                      TargetEnum.SENDER,
                    ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
                  )
                ) : (
                  <SpanPlaceholder>
                    {t(`pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryPlaceholder`)}
                  </SpanPlaceholder>
                )
              }
              options={getPrizeCategotyOptions()}
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.prizeCategory) &&
                !findReward(TargetEnum.SENDER).prizeCategory
                  ? errors.rewards.find((error: IReward) => error && error.prizeCategory).prizeCategory
                  : undefined
              }
              selectOption={opt =>
                setFieldValue("rewards", setRewardField(values, TargetEnum.SENDER, "prizeCategory", opt.key))
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmount"),
        displayComponent:
          values.rewards && findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).amount
            ? findReward(TargetEnum.SENDER).amount
            : null,
        editingComponent: (
          <SectionColumn>
            <TextInput
              name="sender-rewards"
              value={
                findReward(TargetEnum.SENDER) && findReward(TargetEnum.SENDER).amount
                  ? findReward(TargetEnum.SENDER).amount
                  : undefined
              }
              placeholder={`${t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmountPlaceholder")}`}
              required={true}
              onChange={e =>
                setFieldValue(`rewards`, setRewardField(values, TargetEnum.SENDER, "amount", e.target.value))
              }
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.amount) &&
                !findReward(TargetEnum.SENDER).amount
                  ? errors.rewards.find((error: IReward) => error && error.amount).amount
                  : undefined
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
    ] as FormSectionRow[];
    return rows;
  };

  /**
   * method to get the extra rows for receiver
   */
  const getExtraRowsForReceiver = (values: IReferralCampaign): FormSectionRow[] => {
    if (values.rewards) {
      const rewardSender = findReward(TargetEnum.RECEIVER);
      if (rewardSender && rewardSender.type === SenderTypeRewardEnum.SINGLE) {
        return getReceiverSingleRewardRows();
      }
    }
    return [];
  };

  /**
   * method to get the extra rows for sigle type of reward
   */
  const getReceiverSingleRewardRows = (): FormSectionRow[] => {
    const rows = [
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeCategory"),
        displayComponent:
          values.rewards && findReward(TargetEnum.RECEIVER) && findReward(TargetEnum.RECEIVER).prizeCategory
            ? t(
                `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                  TargetEnum.RECEIVER,
                ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
              )
            : null,
        editingComponent: (
          <SectionColumn>
            <AutoClosingDropdown
              hasValue={!!values.rewards}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              label={
                values.rewards && findReward(TargetEnum.RECEIVER) && findReward(TargetEnum.RECEIVER).prizeCategory ? (
                  t(
                    `pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryOptions.${(findReward(
                      TargetEnum.RECEIVER,
                    ).prizeCategory as PrizeCategoryEnum).toLowerCase()}`,
                  )
                ) : (
                  <SpanPlaceholder>
                    {t(`pages.referralCampaigns.createCampaign.rewardDetails.prizeCategoryPlaceholder`)}
                  </SpanPlaceholder>
                )
              }
              options={getPrizeCategotyOptions()}
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.prizeCategory) &&
                !findReward(TargetEnum.RECEIVER).prizeCategory
                  ? errors.rewards.find((error: IReward) => error && error.prizeCategory).prizeCategory
                  : undefined
              }
              selectOption={opt =>
                setFieldValue("rewards", setRewardField(values, TargetEnum.RECEIVER, "prizeCategory", opt.key))
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
      {
        label: t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmount"),
        displayComponent:
          values.rewards && findReward(TargetEnum.RECEIVER) && findReward(TargetEnum.RECEIVER).amount
            ? findReward(TargetEnum.RECEIVER).amount
            : null,
        editingComponent: (
          <SectionColumn>
            <TextInput
              name="receiver-rewards"
              value={
                findReward(TargetEnum.RECEIVER) && findReward(TargetEnum.RECEIVER).amount
                  ? findReward(TargetEnum.RECEIVER).amount
                  : undefined
              }
              placeholder={`${t("pages.referralCampaigns.createCampaign.rewardDetails.prizeAmountPlaceholder")}`}
              required={true}
              onChange={e =>
                setFieldValue(`rewards`, setRewardField(values, TargetEnum.RECEIVER, "amount", e.target.value))
              }
              error={
                errors.rewards &&
                errors.rewards.find((error: IReward) => error && error.amount) &&
                !findReward(TargetEnum.RECEIVER).amount
                  ? errors.rewards.find((error: IReward) => error && error.amount).amount
                  : undefined
              }
            />
          </SectionColumn>
        ),
        required: true,
      },
    ] as FormSectionRow[];
    return rows;
  };

  return (
    <>
      <FormSection
        isEditing={isEditing}
        title={firstTitle}
        rows={([
          {
            label: t("pages.referralCampaigns.createCampaign.rewardDetails.rewardType"),
            displayComponent:
              values.rewards && findReward(TargetEnum.SENDER).type ? (
                <NonEditableText>
                  {t(
                    `pages.referralCampaigns.createCampaign.rewardDetails.senderRewardType.${findReward(
                      TargetEnum.SENDER,
                    ).type.toLowerCase()}`,
                  )}
                </NonEditableText>
              ) : null,
            editingComponent: (
              <SectionColumn id={"dropdown-sender-rewards"}>
                <AutoClosingDropdown
                  hasValue={!!values.rewards}
                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                  label={
                    values.rewards && findReward(TargetEnum.SENDER).type ? (
                      t(
                        `pages.referralCampaigns.createCampaign.rewardDetails.senderRewardType.${findReward(
                          TargetEnum.SENDER,
                        ).type.toLowerCase()}`,
                      )
                    ) : (
                      <SpanPlaceholder>
                        {t(`pages.referralCampaigns.createCampaign.rewardDetails.rewardTypePlaceholder`)}
                      </SpanPlaceholder>
                    )
                  }
                  options={getSenderRewardTypeOptions()}
                  error={
                    errors.rewards &&
                    errors.rewards.find((error: IReward) => error && error.type) &&
                    !findReward(TargetEnum.SENDER).type
                      ? errors.rewards.find((error: IReward) => error && error.type).type
                      : undefined
                  }
                  selectOption={opt => {
                    setFieldValue("rewards", setRewardField(values, TargetEnum.SENDER, "type", opt.key));
                  }}
                />
              </SectionColumn>
            ),
            required: true,
          },
        ] as FormSectionRow[]).concat(getExtraRowsForSender(values))}
      />
      <div style={{ marginTop: "40px" }}>
        <FormSection
          isEditing={isEditing}
          title={secondTitle}
          rows={([
            {
              label: t("pages.referralCampaigns.createCampaign.rewardDetails.rewardType"),
              displayComponent:
                values.rewards && findReward(TargetEnum.RECEIVER).type ? (
                  <NonEditableText>
                    {t(
                      `pages.referralCampaigns.createCampaign.rewardDetails.receiverRewardType.${findReward(
                        TargetEnum.RECEIVER,
                      ).type.toLowerCase()}`,
                    )}
                  </NonEditableText>
                ) : null,
              editingComponent: (
                <SectionColumn id={"dropdown-receiver-rewards"}>
                  <AutoClosingDropdown
                    hasValue={!!values.rewards}
                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                    label={
                      values.rewards && findReward(TargetEnum.RECEIVER).type ? (
                        t(
                          `pages.referralCampaigns.createCampaign.rewardDetails.receiverRewardType.${findReward(
                            TargetEnum.RECEIVER,
                          ).type.toLowerCase()}`,
                        )
                      ) : (
                        <SpanPlaceholder>
                          {t(`pages.referralCampaigns.createCampaign.rewardDetails.rewardTypePlaceholder`)}
                        </SpanPlaceholder>
                      )
                    }
                    options={getReceiverRewardTypeOptions()}
                    error={
                      errors.rewards &&
                      errors.rewards.find((error: IReward) => error && error.type) &&
                      !findReward(TargetEnum.RECEIVER).type
                        ? errors.rewards.find((error: IReward) => error && error.type).type
                        : undefined
                    }
                    selectOption={opt =>
                      setFieldValue("rewards", setRewardField(values, TargetEnum.RECEIVER, "type", opt.key))
                    }
                  />
                </SectionColumn>
              ),
              required: true,
            },
          ] as FormSectionRow[]).concat(getExtraRowsForReceiver(values))}
        />
      </div>
    </>
  );
};

export default RewardDetailsForm;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
`;

const SectionColumn = styled("div")`
  flex: 1;
  max-width: 100%;
  > button > div {
    width: 100%;
  }
`;

const SpanPlaceholder = styled("span")`
  color: ${props => props.theme.palette.midGrey};
  font-family: Vodafone Rg;
  font-size: 16px;
`;

const InviteNumbersContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
  }
`;

const NumberButton = styled("button")<{ value: any }>`
  border-radius: 4px;
  width: 76px;
  height: 36px;
  margin-right: 16px;
  border: solid 1px ${props => props.theme.palette.silver};
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.palette.anthracite};
  background-color: transparent;
  min-width: 76px;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.palette.vodafoneRed};
  }

  ${props => {
    let borderColor = "";
    if (props.value) {
      borderColor = props.theme.palette.vodafoneRed;
    }
    return `border: 1px solid ${borderColor}`;
  }}
`;
