import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import {
  IClientDocDashCounter,
  IDashboardCounterEntry,
  IDocDashboardOverallRatingDetailsResponse,
} from "./doc-dashboard.interface";
import { getDocSubmissionStatus } from "../ekyc.shared.utils";
import { ClientDocSubmissionStatusEnum } from "../ekyc.shared.enums";
import moment from "moment";
import { sortArrayBy } from "../../../shared/shared.utils";

export const DocDashboardActions = {
  types: {
    FETCH_COUNTERS_SUCCESS: "FETCH_COUNTERS_SUCCESS",
    FETCH_OVERALL_RATING_DETAILS: "FETCH_OVERALL_RATING_DETAILS",
  },
  creators: {
    fetchCountersSuccessAction: (counterEntries: IDashboardCounterEntry[]) => ({
      type: DocDashboardActions.types.FETCH_COUNTERS_SUCCESS,
      payload: {
        counterEntries,
      },
    }),
    fetchOverallRatingDetails: (ratingEntries: IDocDashboardOverallRatingDetailsResponse) => ({
      type: DocDashboardActions.types.FETCH_OVERALL_RATING_DETAILS,
      payload: {
        ratingEntries,
      },
    }),
  },
};

export interface IDocDashboardCounterReducerInterface {
  counterEntries: Map<string, IClientDocDashCounter>;
  lastUpdatedError: string;
}

const countersInitialState: IDocDashboardCounterReducerInterface = {
  counterEntries: new Map(),
  lastUpdatedError: "",
};

const overallRatingDetailsState: IDocDashboardOverallRatingDetailsReducerInterface = {
  average: null,
  ratingsCount: null,
  registrationsSubmitted: 0,
  ratingsSubmitted: 0,
  lastFetch: 0,
};

export interface IDocDashboardOverallRatingDetailsReducerInterface {
  average: number | null;
  ratingsCount: any[] | null;
  registrationsSubmitted: number;
  ratingsSubmitted: number;
  lastFetch: string | number;
}

export const docDashboardReducer = produce(
  (draft: IDocDashboardCounterReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocDashboardActions.types.FETCH_COUNTERS_SUCCESS:
        let total = 0;
        const entries = action.payload.counterEntries.statusDetails || [];
        draft.counterEntries = new Map();
        const errorStatus: string[] = [
          ClientDocSubmissionStatusEnum.ERROR_ON_CRITICAL_FIELDS,
          ClientDocSubmissionStatusEnum.ERROR_ON_FACE_DUPLICATIONS,
          ClientDocSubmissionStatusEnum.ERROR_ON_G2,
          ClientDocSubmissionStatusEnum.ERROR_ON_ID_DUPLICATIONS,
          ClientDocSubmissionStatusEnum.ERROR_ON_ID_VS_SELFIE,
        ];

        const dates = entries
          .filter((entry: IClientDocDashCounter) => errorStatus.includes(entry.status))
          .map((entry: IClientDocDashCounter) => entry.lastUpdate);
        draft.lastUpdatedError = moment.max(dates.map((date: string) => moment(date))).toString();

        for (let i = 0; i < entries.length; i++) {
          total += entries[i].total;
        }

        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          const status = getDocSubmissionStatus(entry.status);

          const newEntry = {
            status: status,
            counter: entry.total,
            percentage: Math.round(((entry.total * 100) / total) * 100) / 100, // Rounding to 2 decimals
            lastUpdate: entry.lastUpdate,
          };

          draft.counterEntries.set(newEntry.status, newEntry);
        }
        return draft;
      default:
        return draft;
    }
  },
  countersInitialState,
);

export const docDashboardOverallRatingDetailsReducer = produce(
  (draft: IDocDashboardOverallRatingDetailsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocDashboardActions.types.FETCH_OVERALL_RATING_DETAILS:
        const ratingResponse: IDocDashboardOverallRatingDetailsResponse = action.payload.ratingEntries;

        if (!ratingResponse.average || !ratingResponse.ratingsCount) {
          draft = ratingResponse;
        } else {
          draft = {
            ...ratingResponse,
            ratingsCount: sortArrayBy(ratingResponse.ratingsCount, "rating", 2),
            average: Number(ratingResponse.average.toFixed(1)),
          };
        }

        return draft;
      default:
        return draft;
    }
  },
  overallRatingDetailsState,
);
