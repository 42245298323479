import { ModalTypeEnum, PlusIcon, SearchBar, SmallButton, Table, useAlert, useModal } from "@wit/mpesa-ui-components";
import { TableCell } from "@wit/mpesa-ui-components/lib/components/table/table-row/table-row.styled";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { IBusiness } from "../../../shared/models/business.model";
import { FEATURES } from "../../../shared/renderer.utils";
import { SearchBarFilterContainer } from "../../../shared/responsive-ui.styled";
import { IconContainer, Row } from "../../../shared/shared.styled";
import { getBusinessColumns, renderTableBusinessButtons } from "./business-utils";
import { BusinessesAPI } from "./business.api";
import EditBusinessModal from "./edit-business.modal";

interface IBusinessListProps {
  getNewData: () => void;
}

/**
 * BusinessList component
 */
const BusinessList = ({ getNewData }: IBusinessListProps) => {
  const [t] = useTranslation();
  const [selectedBusiness, setSelectedBusiness] = React.useState<IBusiness | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState<any>(undefined);
  const [showAlert, , setAlertProps] = useAlert();
  const themeContext = useContext(ThemeContext);
  const showCategories = useCheckFeatureAvailable(FEATURES.BUSINESS_CATEGORIES);
  const {
    businesses: { data, lastVersion },
    categories,
  } = useSelector((state: IStoreInterface) => state.businessesReducer);

  const [showEditModal, hideEditModal, setEditProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditBusinessModal
      onCancelClick={() => hideEditModal()}
      setSelectedBusiness={setSelectedBusiness}
      selectedBusiness={selectedBusiness as IBusiness}
    />,
  );

  const [showAddModal, hidAddModal, setAddProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditBusinessModal
      onCancelClick={() => hidAddModal()}
      selectedBusiness={selectedBusiness as IBusiness}
      setSelectedBusiness={setSelectedBusiness}
    />,
  );

  const businessList = useMemo(() => {
    return (
      data &&
      categories && (
        <TableWrapper className="tableWrapper">
          <Table<IBusiness>
            columns={getBusinessColumns(t, categories.data, lastVersion, showCategories)}
            values={results || data}
            displayActions={business =>
              renderTableBusinessButtons<IBusiness>(business, {
                edit: { onClick: editBusiness },
                delete: { onClick: deleteBusiness },
                revert: findLastVersion(business.id) ? { onClick: restoreBusiness } : undefined,
              })
            }
          />
        </TableWrapper>
      )
    );
  }, [results, data]);

  React.useEffect(() => {
    setEditProps({ modalStyles: { width: 615, overflow: "initial" } });
  }, [setEditProps]);

  React.useEffect(() => {
    setAddProps({ modalStyles: { width: 615, overflow: "initial" } });
  }, [setAddProps]);

  React.useEffect(() => {
    if (searchTerm && searchTerm !== "") {
      setResults(
        data.filter(
          e =>
            e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            e.shortCode.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setResults(data);
    }
  }, [data, searchTerm]);

  const editBusiness = (business: IBusiness) => {
    setSelectedBusiness(business);
    showEditModal();
  };

  const deleteBusiness = (business: IBusiness) => {
    BusinessesAPI.methods
      .deleteBusiness(business)
      .then(
        _res => {
          setAlertProps({
            title: t("pages.configurations.businessTab.success.deleteBusinessesSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.configurations.businessTab.errors.deleteBusinessesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getNewData());
  };

  const restoreBusiness = (business: IBusiness) => {
    BusinessesAPI.methods
      .restoreBusiness(business)
      .then(
        _res => {
          getNewData();
          setAlertProps({
            title: t("pages.configurations.businessTab.success.restoreBusinessesSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.configurations.businessTab.errors.restoreBusinessesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getNewData());
  };

  const createNewBusiness = () => {
    setSelectedBusiness(undefined);
    showAddModal();
  };

  const findLastVersion = (id: number | undefined) => {
    if (!id) {
      return undefined;
    }
    return lastVersion.find(b => b.id === id) || null;
  };

  return (
    <BusinessListContainer>
      <Row style={{ justifyContent: "space-between", gap: "24px", flexWrap: "wrap", marginBottom: "24px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            placeholderLabel={t("pages.configurations.businessTab.businessListPage.search")}
            value={searchTerm || ""}
            onChange={e => setSearchTerm(e.target.value)}
            clearValue={() => setSearchTerm("")}
          />
        </SearchBarFilterContainer>
        <div id={"add-new-business"}>
          <SmallButton
            iconComponent={
              <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
                <PlusIcon />
              </IconContainer>
            }
            onClick={() => createNewBusiness()}
            titleLabel={t("pages.configurations.businessTab.addNewBusiness")}
          />
        </div>
      </Row>
      {businessList}
    </BusinessListContainer>
  );
};

export default BusinessList;

const TableWrapper = styled.div`
  > div:first-child {
    > div:last-child {
      > div {
        border-bottom: 1px solid;
        border-bottom-color: ${props => props.theme.palette.lightGrey};
      }
    }
  }
`;

const BusinessListContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 50px;
  ${TableCell}:last-of-type {
    margin-right: 0 !important;
  }
`;

export const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;
