import { AxiosPromise } from "axios";
import { IExternalServices } from "../../../shared/models/service-manager.model";
import ApiClient from "../../../configs/axios.config";
import {
  ISingleExternalServiceRequest,
  IUpdateExternalServiceRequest,
  IUpdateExternalServiceResponse,
} from "./external-services-add/external-service-add.interface";

const ExternalServicesApi = {
  routes: {
    services: "/external-services",
    allServices: "/external-services/all",
    servicesMock: "https://demo2293923.mockable.io/external-services/all",
    serviceNewMock: "https://demo2293923.mockable.io/external-services/all-services",
  },
  methods: {
    getExternalServices: (): AxiosPromise<IExternalServices[]> => {
      return ApiClient.get(ExternalServicesApi.routes.allServices);
    },
    createExternalService: (externalService: ISingleExternalServiceRequest[]): AxiosPromise<IExternalServices> => {
      return ApiClient.put(
        ExternalServicesApi.routes.services,
        externalService.length === 1 ? externalService[0] : externalService,
      );
    },
    updateExternalService: (values: IUpdateExternalServiceRequest): AxiosPromise<IUpdateExternalServiceResponse> => {
      return ApiClient.put(ExternalServicesApi.routes.services, values);
    },
    deleteExternalService: (serviceIdentifier: string): AxiosPromise<void> => {
      return ApiClient.delete(`${ExternalServicesApi.routes.services}/${serviceIdentifier}`);
    },
  },
};

export default ExternalServicesApi;
