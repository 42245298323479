import {
  AutoClosingDropdown,
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  StatusChip,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";

import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import SunEditorCore from "suneditor/src/lib/core";
import { RoutesEnum } from "../../../../routes/routes.constants";
//TODO: Remove local lib with typescript update
import SunEditor from "../../../../shared/components/document-editor";
import { useAsyncState } from "../../../../shared/hooks/use-async-state.hook";
import { IDocument } from "../../../../shared/models/app.model";
import { VersionControlButtonsContainer } from "../../../../shared/responsive-ui.styled";
import { Column } from "../../../../shared/shared.styled";
import { areObjSimilar } from "../../../../shared/shared.utils";
import { processEmptyDocument } from "../documents.utils";
import "../editor.css";
import DocumentsApi from "../documents.api";
import DocumentsLastVersionModal from "./document-last-version.modal";
import PublishDocumentsModal from "./publish-document.modal";
import { IStoreInterface } from "../../../../configs/store.config";
import { DocumentsActions } from "../documents.store";

/**
 *
 * @param t
 * get breadcrumbs
 */
const getBreadcrumbs = (t: TFunction, document: string, tabId: number): Breadcrumb[] => {
  return [
    {
      label: t("pages.documents.configuration"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.documents.title"),
      url: `${RoutesEnum.DOCUMENTS}?tabIdx=${tabId}`,
    },
    {
      label: document,
      url: "",
    },
  ];
};

/**
 * Document page
 */
const DocumentPage = () => {
  const [t] = useTranslation();
  const { id, document, tabId } = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [app, setApp] = useState<IDocument>();
  const [documentContent, setDocumentContent] = useAsyncState();
  const [showAlert, , setAlertProps] = useAlert();
  const [editorVal, setEditorVal] = useState<string | null>("");
  const [availableLanguages, setAvailableLanguages] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [canSave, setCanSave] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<any>();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const { doc } = useSelector((state: IStoreInterface) => {
    return {
      doc: state.documentsReducer.document,
    };
  });
  const [documentName, setDocumentName] = useState(doc);
  const editor = React.useRef<SunEditorCore>();

  /*
   * Get app data incl doc
   * */
  React.useEffect(() => {
    getDocument();
  }, [dispatch]);

  /**
   * Checks the available languages based on the document
   * */
  const checkAvailableLanguages = (application: IDocument, cb: () => void) => {
    if (application) {
      let languages: string[] = [];
      for (const prop in application.content) {
        languages.push(prop);
      }
      languages = languages.sort();
      setAvailableLanguages(languages);
      if (!selectedLanguage) {
        setSelectedLanguage(languages[0]);
      }
      cb();
    }
  };

  /**
   * GET Document request
   * */
  const getDocument = () => {
    DocumentsApi.methods.getDocument(document, id).then(
      res => {
        checkAvailableLanguages(res.data, () => {
          setApp(res.data);
        });
      },
      err => {
        setAlertProps({
          title: t("pages.document.getErrorTitle"),
          content: t("pages.document.getErrorContent"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    // Request for a document name if none found in the store
    if (!documentName) {
      DocumentsApi.methods.getDocumentList(document).then(res => {
        setDocumentName(res.data.documents[0].title);
      });
    }
  };

  /**
   * Publish changes handler
   * */
  const publishChanges = () => {
    setIsPublishing(true);
    DocumentsApi.methods
      .publish(document, id)
      .then(
        res => {
          setAlertProps({
            title: t("pages.privacyPolicy.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getDocument();
        },
        err => {
          setAlertProps({
            title: t("pages.privacyPolicy.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsPublishing(false);
      });
  };

  /**
   * Save changes handler
   * */
  const saveChanges = () => {
    setIsLoading(true);
    const doc = processEmptyDocument(documentContent);
    DocumentsApi.methods
      .save(document, id, doc)
      .then(
        res => {
          setAlertProps({
            title: t("pages.privacyPolicy.saveSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getDocument();
        },
        err => {
          setAlertProps({
            title: t("pages.privacyPolicy.saveError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Get previous version
   * */
  const getPreviousVersion = (cb: Function) => {
    DocumentsApi.methods.getDocument(document, id, true).then(
      res => {
        setPreviousVersion(res.data);
        cb();
      },
      err => {
        setAlertProps({
          title: t("pages.privacyPolicy.getPreviousVersionError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Revert to last version handler
   * */
  const revertToLastVersion = () => {
    setConfirmationProps({
      title: t("pages.privacyPolicy.confirmRevert"),
      description: t("pages.privacyPolicy.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        DocumentsApi.methods.revert(document, id).then(
          res => {
            setAlertProps({
              title: t("pages.privacyPolicy.revertSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            hideConfirmationModal();
            getDocument();
          },
          err => {
            setAlertProps({
              title: t("pages.privacyPolicy.revertError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
            hideConfirmationModal();
          },
        );
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Async fn to set the document content
   * */
  async function setDocumentContentFn() {
    if (app) {
      await setDocumentContent(app.content);
    }
  }

  /**
   * On app obj change the editor value will be updated
   * */
  React.useEffect(() => {
    if (app) {
      setDocumentContentFn().then(() => {
        setEditorVal(app.content[selectedLanguage] || "");
      });
      setCanSave(false);
      if (app.hasUnpublishedChanges) {
        setCanPublish(true);
      } else {
        setCanPublish(false);
      }
    }
  }, [app]);

  /**
   * Checks canSave and canPublish actions
   * */
  React.useEffect(() => {
    if (app) {
      if (areObjSimilar(documentContent, app.content)) {
        setCanSave(false);
        if (app.hasUnpublishedChanges) {
          setCanPublish(true);
        }
      } else {
        setCanSave(true);
        setCanPublish(false);
      }
    }
  }, [documentContent]);

  /**
   * Selected language useEffect
   * */
  React.useEffect(() => {
    if (documentContent) {
      setEditorVal(documentContent[selectedLanguage] || "");
    }
  }, [selectedLanguage]);

  /**
   * Sets the selected language
   * */
  const setLanguage = (opt: SharedDropdownOption) => {
    if (opt.key !== selectedLanguage) {
      setSelectedLanguage(opt.key);
      setEditorVal(null);
    }
  };

  /**
   * Returns a list of languages to populate the dropdown
   * */
  const getAvailableLanguages = () => {
    return Object.values(availableLanguages).map(lang => ({
      label: t(`pages.privacyPolicy.${lang}`),
      key: lang,
    }));
  };

  /**
   * Handler for editor changes
   * */
  const onEditorChange = (val: string) => {
    if (documentContent) {
      const auxDoc = { ...documentContent };
      auxDoc[selectedLanguage] = val;
      setEditorVal(val);
      setDocumentContent(auxDoc);
    }
  };

  /**
   * Check last version modal
   * */
  const _hideCheckLastVersionModal = () => {
    hideTcsLastVersionModal();
  };
  const [showTcsLastVersionModal, hideTcsLastVersionModal, documentLastVersionModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <DocumentsLastVersionModal
      document={previousVersion}
      selectedLanguage={selectedLanguage}
      hideModal={_hideCheckLastVersionModal}
    />,
  );
  React.useEffect(() => {
    documentLastVersionModalProps({ modalStyles: { width: 600 } });
  }, [documentLastVersionModalProps]);

  /**
   * Hide publish modal
   * */
  const _hidePublishModal = () => {
    hidePublishModal();
  };

  /**
   * Publish modal
   */
  const _publishChanges = () => {
    hidePublishModal();
    publishChanges();
  };
  const [showPublishModal, hidePublishModal, publishModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishDocumentsModal
      document={documentContent}
      lastDocument={previousVersion}
      selectedLanguage={selectedLanguage}
      hideModal={_hidePublishModal}
      publishChanges={_publishChanges}
    />,
  );
  React.useEffect(() => {
    publishModalProps({ modalStyles: { width: 825, padding: 0 } });
  }, [publishModalProps]);

  /**
   * Check last version handler
   * */
  const checkLastVersion = () => {
    getPreviousVersion(() => {
      showTcsLastVersionModal();
    });
  };

  /**
   * Show publish modal
   * */
  const showPublishConfirmationModal = () => {
    getPreviousVersion(() => {
      showPublishModal();
    });
  };

  /**
   *
   * @param sunEditor
   * get sun editor instance
   */
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <>
      {app && documentContent && selectedLanguage ? (
        <>
          <HeaderContainer>
            <PageTitle
              breadcrumbs={getBreadcrumbs(t, documentName, tabId)}
              title={documentName}
              navigateFunction={history.push}
              goBackFn={() => history.push(`${RoutesEnum.DOCUMENTS}?tabIdx=${tabId}`)}
              titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
              rightComponentContainerStyle={{
                display: "flex",
                alignContent: "center",
                height: "100%",
                marginRight: "0px",
              }}
              rightComponent={
                <>
                  <ButtonContainer
                    style={{
                      marginRight: "13px",
                      position: "relative",
                      top: "10px",
                    }}
                  >
                    <PrimaryButton
                      id="save-changes-button"
                      disabled={!canSave || isLoading}
                      titleLabel={t("pages.privacyPolicy.save")}
                      type="submit"
                      onClick={saveChanges}
                      style={{ width: "fit-content" }}
                    />
                  </ButtonContainer>
                  <ButtonContainer style={{ position: "relative", top: "10px" }}>
                    <PrimaryButton
                      id="publish-changes"
                      disabled={!canPublish}
                      titleLabel={t("pages.privacyPolicy.publish")}
                      loading={isPublishing}
                      type="submit"
                      redTheme={true}
                      onClick={showPublishConfirmationModal}
                      style={{ width: "fit-content" }}
                    />
                  </ButtonContainer>
                </>
              }
            />
          </HeaderContainer>

          <EditorContainer>
            <DocumentDataRow>
              <Column style={{ width: "20%", justifyContent: "center" }}>
                <Label>{t("pages.privacyPolicy.status")}</Label>
                {app ? (
                  <ChipContainer>
                    <StatusChip type={app.status as any}>{t(`commons.apps.enums.status.${app.status}`)}</StatusChip>
                  </ChipContainer>
                ) : null}
              </Column>
              <Column
                style={{ flexBasis: "20%", justifyContent: "center", marginLeft: "auto", zIndex: 12 }}
                id={"languages"}
              >
                <AutoClosingDropdown
                  options={getAvailableLanguages()}
                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                  selectOption={opt => {
                    setLanguage(opt);
                  }}
                  label={t(`pages.privacyPolicy.${selectedLanguage}`)}
                  hasValue={true}
                />
              </Column>
            </DocumentDataRow>
            {app && app.hasPreviousVersion ? (
              <DocumentDataRow>
                <VersionControlButtonsContainer>
                  <div id="check-last-version">
                    <FastActionButton
                      label={t("pages.privacyPolicy.checkLastVersion")}
                      onClick={checkLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.black}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    ></FastActionButton>
                  </div>
                  <div id="revert-to-last-version">
                    <FastActionButton
                      label={t("pages.privacyPolicy.revertChanges")}
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.black}>
                          <UndoIcon />
                        </FastIconWrapper>
                      }
                    ></FastActionButton>
                  </div>
                </VersionControlButtonsContainer>
              </DocumentDataRow>
            ) : null}
            {editorVal !== null && (
              <SunEditor
                onChange={val => {
                  onEditorChange(val);
                }}
                setDefaultStyle="height: auto; min-height: 400px;"
                setOptions={{
                  lang: {
                    code: "en",
                    toolbar: {
                      default: "Default",
                      save: "Save",
                      font: "Font",
                      formats: "Formats",
                      fontSize: "Size",
                      bold: "Bold",
                      underline: "Underline",
                      italic: "Italic",
                      strike: "Strike",
                      subscript: "Subscript",
                      superscript: "Superscript",
                      removeFormat: "Remove Format",
                      fontColor: "Font Color",
                      hiliteColor: "Highlight Color",
                      indent: "Indent",
                      outdent: "Outdent",
                      align: "Align",
                      alignLeft: "Align left",
                      alignRight: "Align right",
                      alignCenter: "Align center",
                      alignJustify: "Align justify",
                      list: "List",
                      orderList: "Ordered list",
                      unorderList: "Unordered list",
                      horizontalRule: "Horizontal line",
                      hr_solid: "Solid",
                      hr_dotted: "Dotted",
                      hr_dashed: "Dashed",
                      table: "Table",
                      link: "Link",
                      math: "Math",
                      image: "Image",
                      video: "Video",
                      audio: "Audio",
                      fullScreen: "Full screen",
                      showBlocks: "Show blocks",
                      codeView: "Code view",
                      undo: "Undo",
                      redo: "Redo",
                      preview: "Preview",
                      print: "print",
                      tag_p: "Paragraph",
                      tag_div: "Normal (DIV)",
                      tag_h: "Header",
                      tag_blockquote: "Quote",
                      tag_pre: "Code",
                      template: "Template",
                      lineHeight: "Line height",
                      paragraphStyle: "Paragraph style",
                      textStyle: "Text style",
                      imageGallery: "Image gallery",
                      mention: "Mention",
                    },
                    dialogBox: {
                      linkBox: {
                        title: "Insert Link",
                        url: "URL to link",
                        text: "Text to display",
                        newWindowCheck: "Open in new window",
                        downloadLinkCheck: "Download link",
                        bookmark: "Bookmark",
                      },
                      mathBox: {
                        title: "Math",
                        inputLabel: "Mathematical Notation",
                        fontSizeLabel: "Font Size",
                        previewLabel: "Preview",
                      },
                      imageBox: {
                        title: "Insert image",
                        file: "Select from files",
                        url: "Image URL",
                        altText: "Alternative text",
                      },
                      videoBox: {
                        title: "Insert Video",
                        file: "Select from files",
                        url: "Media embed URL, YouTube/Vimeo",
                      },
                      audioBox: {
                        title: "Insert Audio",
                        file: "Select from files",
                        url: "Audio URL",
                      },
                      browser: {
                        tags: "Tags",
                        search: "Search",
                      },
                      caption: "Insert description",
                      close: "Close",
                      submitButton: "Insert",
                      revertButton: "Revert",
                      proportion: "Constrain proportions",
                      basic: "Basic",
                      left: "Left",
                      right: "Right",
                      center: "Center",
                      width: "Width",
                      height: "Height",
                      size: "Size",
                      ratio: "Ratio",
                    },
                    controller: {
                      edit: "Edit",
                      unlink: "Unlink",
                      remove: "Remove",
                      insertRowAbove: "Insert row above",
                      insertRowBelow: "Insert row below",
                      deleteRow: "Delete row",
                      insertColumnBefore: "Insert column before",
                      insertColumnAfter: "Insert column after",
                      deleteColumn: "Delete column",
                      fixedColumnWidth: "Fixed column width",
                      resize100: "Resize 100%",
                      resize75: "Resize 75%",
                      resize50: "Resize 50%",
                      resize25: "Resize 25%",
                      autoSize: "Auto size",
                      mirrorHorizontal: "Mirror, Horizontal",
                      mirrorVertical: "Mirror, Vertical",
                      rotateLeft: "Rotate left",
                      rotateRight: "Rotate right",
                      maxSize: "Max size",
                      minSize: "Min size",
                      tableHeader: "Table header",
                      mergeCells: "Merge cells",
                      splitCells: "Split Cells",
                      HorizontalSplit: "Horizontal split",
                      VerticalSplit: "Vertical split",
                    },
                    menu: {
                      spaced: "Spaced",
                      bordered: "Bordered",
                      neon: "Neon",
                      translucent: "Translucent",
                      shadow: "Shadow",
                      code: "Code",
                    },
                  },
                  imageResizing: false,
                  imageHeightShow: false,
                  imageUrlInput: false,
                  buttonList: [
                    [
                      "undo",
                      "redo",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "fontColor",
                      "hiliteColor",
                      "link",
                      "table",
                      "image",
                    ],
                  ],
                }}
                getSunEditorInstance={getSunEditorInstance}
                defaultValue={editorVal}
                setContents={documentContent[selectedLanguage]}
              />
            )}
          </EditorContainer>
        </>
      ) : null}
    </>
  );
};

export default DocumentPage;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

export const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px 48px 0 118px;
  overflow: auto;
`;

export const DocumentDataRow = styled("div")`
  display: flex;
  padding-bottom: 33px;
`;

export const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;

export const EditorContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px 48px 0 118px;
  min-height: 100%;
  overflow: auto;
  background-color: #fafafa;
  .sun-editor .se-dialog-tabs {
    display: none;
  }
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button {
    display: none;
  }
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer {
    display: none;
  }
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div {
    display: none;
  }
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
    color: white;
    border-radius: 20px;
    background-color: #e60000;
    box-shadow: none;
    border: none;
    padding: 8px 30px;
  }
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files > input {
    padding: 5px;
  }
  .sun-editor-editable[contenteditable="true"] .se-component {
    outline: none;
  }
  .se-dialog-form {
    font-family: Vodafone Rg;
  }
  .se-modal-title {
    font-family: Vodafone Rg;
  }
  .se-input-form _se_image_alt {
    font-family: Vodafone Rg;
  }
  .se-input-form _se_image_file {
    font-family: Vodafone Rg;
  }
  .se-input-form se-input-url {
    font-family: Vodafone Rg;
  }
  .se-link-preview {
    font-family: Vodafone Rg;
  }
  .se-input-form _se_anchor_text {
    font-family: Vodafone Rg;
  }
  .sun-editor-editable table {
    border-collapse: collapse;
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 10px;
    width: 100%;
  }
  .sun-editor-editable table thead {
    background-color: #f4f4f4;
    color: #000000;
    border-bottom: none;
  }
  .sun-editor-editable table td,
  .sun-editor-editable table th {
    border: 1px solid #ddd;
  }
`;

const ChipContainer = styled("div")`
  margin-top: 14px;
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
