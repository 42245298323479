import React, { useContext, useEffect, useState } from "react";
import { ButtonContainer, PageContainer } from "../../../shared/shared.styled";
import {
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  TabChild,
  Tabs,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import NotificationsCampaignDetails from "../components/audience-details.component";
import { INotification } from "../push-notifications.modal";
import NotificationsDetails from "../components/notification-details.component";
import styleStatusCell from "../utils/style-status-cell";
import SafaricomDeleteModal from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useHistory, useParams } from "react-router-dom";
import { RoutesEnum } from "../../../routes/routes.constants";
import PushNotificationsApi from "../push-notifications.api";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { useSelector } from "react-redux";
import moment from "moment";

const NOTIFICATION_INITIAL_VALUES: INotification = {
  id: "",
  campaignName: "",
  title: "",
  body: "",
  backgroundImage: "",
  sendImmediately: false,
  status: "" as any,
  hasCallToAction: false,
  callToAction: { title: "", action: { type: "" as any, resource: "" } },
  targetVersions: [],
  startDate: 1685604185836 as any,
  audience: "" as any,
};

/**
 * Renders edit notification page
 * @returns {React.Component}
 */
const EditNotificationPage = () => {
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const notificationsList = useSelector((state: any) => state?.sfcPushNotificationsReducer?.notificationsList) || [];
  const params: { notificationId: string } = useParams();

  const [tab, setTab] = useState<number>(0);
  const [notificationValues, setNotificationValues] = useState({
    ...NOTIFICATION_INITIAL_VALUES,
    ...notificationsList,
  });
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

  /**
   * function go back
   */
  const goBack = () => {
    window.history.back();
  };

  /** */
  const onChangeValues = (values: INotification) => {
    // setNotificationValues(values);
  };

  /**
   * Function to execute delete request announcement
   */
  const deleteRequest = () => {
    setIsRequesting(true);
    PushNotificationsApi.methods
      .deletePushNotification(notificationValues.id || "")
      .then(
        res => {
          setAlertProps({
            title: t("pages.pushNotifications.delete.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push(RoutesEnum.SFC_PUSH_NOTIFICATIONS);
        },
        () => {
          setAlertProps({
            title: t("pages.pushNotifications.delete.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsRequesting(false);
        hideDeleteModal();
      });
  };

  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      isLoading={isRequesting}
      title={t("pages.notifications.modal.deleteTitle")}
      description={t("pages.notifications.modal.deleteDescription")}
      primaryBtnLabel={t("pages.notifications.buttons.modalDelete")}
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.cancelBtn")}
      primaryAction={() => {
        deleteRequest();
      }}
      secondaryAction={() => {
        hideDeleteModal();
      }}
    />,
  );

  /**
   * Return the breadcrumbs
   */
  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      {
        label: t("pages.notifications.listPage.title"),
        url: RoutesEnum.SFC_PUSH_NOTIFICATIONS,
      },
      {
        label: notificationValues.campaignName,
        url: "",
      },
    ];
  };

  useEffect(() => {
    const notificationDetails = notificationsList?.find((item: any) => item.id === params.notificationId);
    setNotificationValues({ ...NOTIFICATION_INITIAL_VALUES, ...notificationDetails });
  }, []);

  return (
    <PageContainer style={{ paddingBottom: "200px" }}>
      <PageTitle
        breadcrumbs={getBreadcrumbs()}
        title={notificationValues.campaignName}
        navigateFunction={history.push}
        separatorStyle={{ display: "none" }}
        goBackFn={goBack}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          // TODO: show buttons when edit mode is ready
          true ? (
            <></>
          ) : (
            <>
              <ButtonContainer style={{ marginRight: 20 }}>
                <PrimaryButton
                  id={"save"}
                  disabled={true}
                  titleLabel={t("pages.announcements.detailPage.buttons.saveCreate")}
                  redTheme={false}
                  style={{ minWidth: "fit-content" }}
                  onClick={() => {}}
                />
              </ButtonContainer>
              <ButtonContainer>
                <PrimaryButton
                  id={"publish"}
                  disabled={true}
                  titleLabel={t("pages.announcements.detailPage.buttons.publish")}
                  redTheme={true}
                  style={{ minWidth: "fit-content" }}
                  onClick={() => {}}
                />
              </ButtonContainer>
            </>
          )
        }
      />
      <div className="pageContent display-column">
        <TopContentRow>
          <TopContentCol>
            <Label>Status</Label>
            <span>{styleStatusCell(notificationValues.status)}</span>
          </TopContentCol>
          <TopContentCol>
            <Label>Created On</Label>
            <span>{moment(notificationValues.createdOn).format("DD MMM YYYY")}</span>
          </TopContentCol>
          <DeleteButtonContainer>
            <FastActionWrapper isDisabled={false}>
              <FastActionButton
                label={t("pages.notifications.createPage.deleteTitle")}
                onClick={showDeleteModal}
                iconComponent={
                  <div
                    style={{
                      stroke: "#e60000",
                      width: 16,
                      height: 16,
                    }}
                  >
                    <TrashIcon />
                  </div>
                }
              />
            </FastActionWrapper>
          </DeleteButtonContainer>
        </TopContentRow>
        <DivTabsDetails key={"formKey"}>
          <Tabs isSecondaryTabs={true} isSfcTabs={true} controlledSelectedTab={tab} controlledSetSelectedTab={setTab}>
            <TabChild
              label={<TabTitle editing={false}>{t("pages.notifications.editPage.firstTabTitle")}</TabTitle>}
              key="first-tab"
            >
              <hr />
              <DivMainContent>
                <DivContent>
                  <NotificationsCampaignDetails
                    notificationValues={notificationValues}
                    onChangeValues={onChangeValues}
                    isEditing={false}
                    isDetailsPage={true}
                  />
                </DivContent>
                <DivSidemenuContent>{/* TODO: Add edit button here */}</DivSidemenuContent>
              </DivMainContent>
            </TabChild>
            <TabChild
              label={<TabTitle editing={false}>{t("pages.notifications.editPage.secondTabTitle")}</TabTitle>}
              key="second-tab"
            >
              <hr />
              <DivMainContent>
                <DivContent>
                  <NotificationsDetails
                    notificationValues={notificationValues}
                    onChangeValues={onChangeValues}
                    isEditing={false}
                    isDetailsPage={true}
                  />
                </DivContent>
                <DivSidemenuContent>{/* TODO: Add edit button here */}</DivSidemenuContent>
              </DivMainContent>
            </TabChild>
          </Tabs>
        </DivTabsDetails>
      </div>
    </PageContainer>
  );
};

const DivTabsDetails = styled("div")`
  > div > div > div > div {
    z-index: 1;
  }
  > div > div:last-child {
    margin-top: -10px;
    hr {
      border: 1px solid ${props => props.theme.palette.aluminium};
    }
  }
`;

const TopContentRow = styled.div`
  display: flex;
  column-gap: 3rem;
  margin-bottom: 2rem;
  font-family: Vodafone Rg;
`;

const TopContentCol = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;
const Label = styled.div`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #999;
  margin-bottom: 0.5rem;
`;

const DeleteButtonContainer = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: end;
  align-items: flex-start;
`;

const DivMainContent = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DivContent = styled("div")`
  flex: 0.75;
`;

const DivSidemenuContent = styled("div")`
  flex: 0.125;
  z-index: 0 !important;
`;

const TabTitle = styled("span")<{ editing: boolean }>`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  letter-spacing: 0.4px;
  ${props =>
    props.editing
      ? `::after {
    content: " *";
    color: #ff0000;
    padding-left: 2px;
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
      }`
      : null};
`;

const FastActionWrapper = styled("div")<{ isDisabled: boolean }>`
  button {
    width: inherit;
    min-height: 27px;
    height: inherit;
    ${props =>
      props.isDisabled
        ? "pointer-events: none; opacity:0.5;background-color: #ffffff; :hover{cursor:not-allowed;}"
        : null}
  }
`;

export default EditNotificationPage;
