import React from "react";
import { TFunction } from "react-i18next";
import { ILanguage } from "../../../shared/models/language.model";

// Enums
export enum EFormType {
  FEEDBACK = "FEEDBACK",
  REPORT_PROBLEM = "REPORT_PROBLEM",
  CUSTOMER_SURVEY = "CUSTOMER_SURVEY",
}

export enum EFormDataStatus {
  NOT_REVIEWED = "NOT_REVIEWED",
  IN_PROGRESS = "IN_PROGRESS",
  RESOLVED = "RESOLVED",
}

export enum EDateFilterKeys {
  TIME_RANGE = "timeRange",
  START_DATE = "startDate",
  END_DATE = "endDate",
}

// Types
type TCategoriesData = {
  key: number | string;
  translations: {
    [key: string]: string;
  };
};

type TFormDataCategories = {
  id: string | number;
  order: number;
  name: TCategoriesData;
};

type TFormData = {
  feedbackId: string | number;
  msisdn: string | number;
  category: {
    id: string | number;
    name: TCategoriesData;
  };
  date: string | number;
  feedback: string;
  status: EFormDataStatus | IStatusNodeObject;
};

type TFormDataCustomer = {
  feedbackId: string | number;
  msisdn: string | number;
  completedQuestions: number;
  date: string | number;
  ratedExperience: string;
  finishedTransactions: string;
  customerSatisfaction: string;
  opinion: string;
};

type TDropDownData = {
  key: number | string;
  label: string;
};

// Interfaces
export interface ITableResultList extends TFormData {}
export interface ITableResultListCustomer extends TFormDataCustomer {}
export interface ITableResultRow extends Omit<TFormData, "category"> {
  category: string;
}

export interface ITableCustomerResultRow extends TFormDataCustomer {}

export interface IStatusDropDownData extends TDropDownData {}
export interface ICategoriesDropDownData extends TDropDownData {}

export interface IStatusNodeObject {
  props: {
    sectionId: number;
    itemId: number | string;
    currentStatus: ITableResultRow;
  };
}

export interface ISearchRequest {
  searchQuery?: string;
  startDate?: number | string;
  endDate?: number | string;
  categoryId?: number | string;
  status?: EFormDataStatus;
}
export interface IFormDataRequest extends ISearchRequest {
  pageNumber?: number;
  pageSize?: number;
}
export interface IFormDataResponse {
  count: number;
  list: ITableResultList[] | ITableResultListCustomer[];
}

export interface IUpdateFormDataRowResponse {
  status: EFormDataStatus;
}

export interface IFormDetailsResponse extends TFormDataCategories {}
export interface IFormDetailsRequest {
  categories: {
    id: string | number;
    order: number;
  }[];
}

export interface ICreateFormCategoryRequest {
  name: {
    [key: string]: string;
  };
}
export interface ICreateFormCategoryResponse {
  status: {
    httpCode: number;
    businessCode: number | string;
    message: string;
  };
}
export interface IFormCategoriesResponse extends Omit<TFormDataCategories, "order"> {}

export interface IExportFormDataRequest {
  csv: boolean;
  excel: boolean;
}

export interface ISectionDetailsComponentProps {
  sectionId: number;
  toggleSideDrawer: () => void;
}

export interface ISectionFiltersComponentProps {
  sectionId: number;
  searchInputMaxLength: number;
  selectableStatuses: IStatusDropDownData[];
  onClickRefresh: (filtersParams?: ISearchRequest) => void;
  staticParams: ISearchRequest;
}

export interface IExportAndRefreshComponentProps {
  resultSetLength: number;
  sectionId: number;
  onClickRefresh: () => void;
  onClickExport: () => void;
}

export interface ICardsComponentProps {
  title: string;
  titleColor?: string;
  dataColor?: string;
  data: React.ReactNode;
  footer: string;
}

export interface IFiltersDropDownComponentProps {
  componentId: string;
  placeholder: string;
  options: IStatusDropDownData[];
  onChangeSelectedOption: (option: IStatusDropDownData) => void;
  selectedOption: IStatusDropDownData;
}

export interface IExportModalComponentProps {
  closeExportModal: () => void;
  exportFormData: (payload: IExportFormDataRequest) => void;
  sectionId: number;
}

export interface ISectionResultsTableComponentProps {
  columnsConfig: {
    [key: string]: {
      formKey: string;
      label: string;
      isEditable: boolean;
      sortable: boolean;
      ratio: number;
    }[];
  };
  isResultOfSearch: boolean;
  toggleSideDrawer: () => void;
  selectedRowData: React.Dispatch<React.SetStateAction<ITableResultRow | undefined>>;
  onSelectedRowDataStatusChange: (data: { itemId: number | string; statusCode: EFormDataStatus }) => void;
  sectionId: number;
  translations: TFunction<"translation">;
}

export interface ISectionResultsCustomerTableComponentProps {
  columnsConfig: {
    [key: string]: {
      formKey: string;
      label: string;
      isEditable: boolean;
      sortable: boolean;
      ratio: number;
    }[];
  };
  isResultOfSearch: boolean;
  toggleSideDrawer: () => void;
  selectedRowData: React.Dispatch<React.SetStateAction<ITableCustomerResultRow | undefined>>;
  onSelectedRowDataStatusChange: (data: { itemId: number | string; statusCode: EFormDataStatus }) => void;
  sectionId: number;
  translations: TFunction<"translation">;
}

export interface IStatusDropDownComponentProps {
  sectionId: number;
  itemId: number | string;
  currentStatus: EFormDataStatus;
  onSelectedRowDataStatusChange: (data: { itemId: number | string; statusCode: EFormDataStatus }) => void;
  translations: TFunction<"translation">;
}

export interface IResultsEmptyComponentProps {
  sectionId: number;
  translations: TFunction<"translation">;
  isSearchResult: boolean;
}

export interface IResultsTableDrawerComponentProps {
  sectionId: number;
  drawerBodyData?: ITableResultRow;
  closeDrawer: () => void;
}

export interface IResultsTableCustomerDrawerComponentProps {
  drawerBodyData?: ITableCustomerResultRow;
  closeDrawer: () => void;
}

export interface IResultsPaginationComponentProps {
  sectionId: number;
  currentActivePage: number;
  numberOfRowsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  feedbacks: IFormDataResponse;
  problemReports: IFormDataResponse;
  customerSurvey: IFormDataResponse;
}

export interface IDetailsDrawerComponentProps {
  sectionId: number;
  categoryList: IFormCategoriesResponse[];
  activeCategories: IFormDetailsResponse[];
  selectableMarketLanguages: ILanguage[];
  selectedMarketLanguage: string;
  onClickSubmitChanges: (payload: IFormDetailsRequest) => void;
  onClickLanguageSelector: React.Dispatch<React.SetStateAction<string>>;
  closeDrawer: () => void;
}

export interface ICategoryCreationModalComponentProps {
  sectionId: number;
  marketLanguages: ILanguage[];
  categoryList: IFormCategoriesResponse[];
  createFormCategory: (payload: ICreateFormCategoryRequest) => void;
  closeCategoryCreationModal: () => void;
}
