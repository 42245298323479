import React, { useContext, useState } from "react";
import {
  PrimaryButton,
  TextInput,
  AutoClosingDropdown,
  ArrowDownIcon,
  HelpIcon,
  useAlert,
} from "@wit/mpesa-ui-components";
import styled, { css, keyframes, ThemeContext } from "styled-components";
import { ICategory } from "../../../../shared/models/category.model";
import { Formik } from "formik";
import { Row, RatioContainer, IconContainer } from "../../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import IconsDropDown from "./iconsDropDown";
import { BusinessesAPI } from "../business.api";
import { getNewData } from "../business-utils";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BusinessStatusEnum } from "../business-utils";
import { ILanguage } from "../../../../shared/models/language.model";
import { object, string } from "yup";
import i18next from "i18next";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface ICategoryDrawerProps {
  setIsVisible: (visible: Boolean) => void;
  isVisible: Boolean;
  selectedCategory: ICategory;
  setSelectedCategory: (cat: ICategory | undefined) => void;
  languages: ILanguage[];
  groups: {
    groupCategory: ICategory;
    children: ICategory[];
  }[];
}

/**
 * Category Drawer component
 * @returns  {JSX.Element}
 */
const CategoryDrawer = ({
  isVisible,
  setIsVisible,
  selectedCategory,
  setSelectedCategory,
  languages,
  groups,
}: ICategoryDrawerProps) => {
  const [t] = useTranslation();
  const categories = useSelector((state: IStoreInterface) => state.businessesReducer.categories);
  const [showAlert, , setAlertProps] = useAlert();
  const themeContext = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const getCategoryDropdownOptions = (): SharedDropdownOption[] => {
    const options: any[] = [];

    groups.forEach(category => {
      options.push({
        key: category.groupCategory.id,
        label: category.groupCategory.name.en,
      });
    });

    return options;
  };

  const getDropdownLabel = (categoryId?: string) => {
    if (!categoryId) {
      return "";
    }
    const categorySelected = categories.data.find(c => c.id === categoryId);

    return categorySelected
      ? categorySelected.name.en
      : t("pages.configurations.businessTab.modal.edit.category.placeholder");
  };

  const renderLanguages = (values: ICategory | undefined, setFieldValue: any, errors: any) => {
    return languages.map(language => {
      return (
        <Row key={language.code} style={{ marginBottom: 20 }}>
          <RatioContainer ratio={1}>
            <InputWrapper>
              <div style={{ flex: 0.9 }}>
                <TextInput
                  id={`category-name-${language.code}`}
                  aligment="left"
                  title={t("pages.categories.edit.categoryName")}
                  placeholder={t("pages.categories.edit.enterCategoryName")}
                  name="name"
                  onChange={e => setFieldValue(`name[${language.code}]`, e.target.value)}
                  value={values && values.name && values.name[language.code] ? values.name[language.code] : ""}
                  error={errors.name ? errors.name[language.code] : ""}
                  required={language.mandatory}
                />
              </div>
              <Language>{language.name}</Language>
            </InputWrapper>
          </RatioContainer>
        </Row>
      );
    });
  };

  const cancel = () => {
    setIsVisible(false);
    setSelectedCategory(undefined);
  };

  /**
   *
   * @param value
   * @param setLanguage
   * @param availableLanguages
   */
  const validateLanguages = (value: any, availableLanguages: ILanguage[]) => {
    if (!value) {
      return false;
    }
    for (let l = 0; l < availableLanguages.length; l++) {
      if (!value[availableLanguages[l].code] && availableLanguages[l].mandatory) {
        return false;
      } else if (!value[availableLanguages[l].code]) {
        setShowWarning(true);
      }
    }
    return true;
  };

  /**
   * Returns the validators for the service name translations
   * @param availableLanguages - available languages for the apps
   */
  const getTranslationValidators = (availableLanguages: ILanguage[]) => {
    let validators: { [x: string]: any } = {};
    availableLanguages.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.categories.edit.validations.name")),
        };
      }
    });
    return validators;
  };

  /**
   * Get validation schema notification settings
   * @param setLanguage
   * @param availableLanguages
   */
  const getCategoriesValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("category-name-lang", i18next.t("pages.categories.edit.validations.name"), value =>
          validateLanguages(value, availableLanguages),
        ),
      urlIcon: string().required(i18next.t("pages.categories.edit.validations.name")),
    });
  };

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);

  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: ICategory | undefined) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(_handleSubmit, values);
    } else {
      setShowWarning(false);
      _handleSubmit(values);
    }
  };

  const _handleSubmit = (value: ICategory | undefined) => {
    if (!value) {
      return;
    }
    setIsLoading(true);
    if (selectedCategory) {
      BusinessesAPI.methods
        .editCategories(value)
        .then(
          () => {
            setAlertProps({
              title: t("pages.categories.edit.success.edit"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            cancel();
            setIsLoading(false);
          },
          _err => {
            setAlertProps({
              title: t("pages.categories.edit.error.edit"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
            setIsLoading(false);
          },
        )
        .then(() => getNewData(dispatch, showAlert, setAlertProps, t));
    } else {
      BusinessesAPI.methods
        .createCategories(value)
        .then(
          () => {
            setAlertProps({
              title: t("pages.categories.edit.success.create"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            cancel();
            setIsLoading(false);
          },
          _err => {
            setAlertProps({
              title: t("pages.categories.edit.error.create"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
            setIsLoading(false);
          },
        )
        .then(() => getNewData(dispatch, showAlert, setAlertProps, t));
    }
  };

  const changeStatus = (currentStatus: any, setFieldValue: any) => {
    if (currentStatus === BusinessStatusEnum.LIVE || currentStatus === BusinessStatusEnum.ENABLE) {
      setFieldValue("status", BusinessStatusEnum.DISABLE);
    } else {
      setFieldValue("status", BusinessStatusEnum.ENABLE);
    }
  };

  const getInitialValues = (): ICategory => {
    return (
      selectedCategory || {
        id: "",
        name: "",
        status: "LIVE",
        urlIcon: "",
        deleted: false,
      }
    );
  };

  return (
    <div>
      {isVisible && (
        <>
          <DrawerContainer isVisible={isVisible}>
            <Header>
              <div onClick={() => cancel()}>
                <IconBack size={20} color={themeContext.palette.vodafoneRed}>
                  <ArrowDownIcon />
                </IconBack>
              </div>
              <ModalTitle>
                {selectedCategory ? t("pages.categories.edit.edit") : t("pages.categories.edit.add")}
              </ModalTitle>
            </Header>

            <Formik<ICategory>
              onSubmit={submitWrapper}
              validationSchema={getCategoriesValidationSchema(languages)}
              validateOnChange={false}
              initialValues={getInitialValues()}
              render={({ isSubmitting, handleSubmit, values, setFieldValue, errors, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row
                      style={{
                        flexDirection: "row",
                        alignItems: "end",
                        marginBottom: "65px",
                      }}
                    >
                      <RatioContainer ratio={1 / 4}>
                        <LogoContainer size={72} color="black">
                          {values.urlIcon ? (
                            <img
                              alt={values.urlIcon}
                              src={`./images/categoryIcons/${values.urlIcon}`}
                              onError={e => setFieldValue("urlIcon", "")}
                            />
                          ) : (
                            <HelpIcon />
                          )}
                        </LogoContainer>
                      </RatioContainer>
                      <RatioContainer ratio={3 / 4}>
                        <IconsDropDown
                          onIconSelect={icon => setFieldValue("urlIcon", icon)}
                          initialIcon={values.urlIcon}
                          errors={errors.urlIcon}
                        />
                      </RatioContainer>
                    </Row>
                    {renderLanguages(values, setFieldValue, errors)}
                    {((selectedCategory && !selectedCategory.isGroup) || !selectedCategory) && (
                      <Row style={{ marginTop: 60 }}>
                        <DropDownWrapper>
                          <Label> {t("pages.categories.edit.parentCategory")}</Label>
                          <AutoClosingDropdown
                            hasValue={values && values.parentCategory ? true : false}
                            label={
                              values && values.parentCategory
                                ? getDropdownLabel(values.parentCategory)
                                : t("pages.categories.edit.selectParent")
                            }
                            options={getCategoryDropdownOptions()}
                            selectOption={opt => {
                              return setFieldValue("parentCategory", opt.key);
                            }}
                          />
                        </DropDownWrapper>
                      </Row>
                    )}
                    {selectedCategory && (
                      <StatusWrapper>
                        <Label>{t("pages.configurations.businessTab.modal.status.label")}</Label>
                        <div onClick={() => changeStatus(values.status, setFieldValue)}>
                          {values.status === BusinessStatusEnum.LIVE ||
                          values.status === BusinessStatusEnum.ENABLE ||
                          values.status === BusinessStatusEnum.EDIT ? (
                            <LiveStatus>
                              <span>{t("pages.configurations.businessTab.modal.status.live")}</span>
                            </LiveStatus>
                          ) : (
                            <DisabledStatus>
                              <span>{t("pages.configurations.businessTab.modal.status.disabled")}</span>
                            </DisabledStatus>
                          )}
                        </div>
                      </StatusWrapper>
                    )}
                    <ButtonsContainer>
                      <div>
                        <PrimaryButton
                          redTheme={true}
                          type="submit"
                          titleLabel={t("pages.categories.edit.saveChangesBtnLabel")}
                          id="save-changes-button"
                          disabled={isLoading}
                        />
                      </div>
                      <div>
                        <PrimaryButton
                          loading={false}
                          disabled={isLoading}
                          type="button"
                          onClick={() => cancel()}
                          titleLabel={t("pages.categories.edit.cancelBtnLabel")}
                          id="cancel-button"
                        />
                      </div>
                    </ButtonsContainer>
                  </form>
                );
              }}
            />
          </DrawerContainer>
          <DrawerOverlay className="overlay" isVisible={isVisible} />
        </>
      )}
    </div>
  );
};

export default CategoryDrawer;

const slideIn = keyframes`
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }

  `;

const slideOut = keyframes`
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(100%);
    }
  `;

const slideInAnimation = css`
  animation: ${slideIn} 0.25s ease-out forwards;
`;

const slideOutAnimation = css`
  animation: ${slideOut} 0.25s ease-out forwards;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
  text-align: left;
`;

const DropDownWrapper = styled.div`
  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
    flex-direction: row;
  }
  width: 100%;
`;

const DrawerContainer = styled.div<{ isVisible: Boolean }>`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 718px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  padding: 67px 75px;
  left: calc(100% - 711px);
  overflow: scroll;
  ${props => (props.isVisible ? slideInAnimation : slideOutAnimation)};
`;
const DrawerOverlay = styled.div<{ isVisible: Boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: black;
  opacity: 0.4;
  display: ${props => (props.isVisible ? "flex" : "none")};
`;

const Language = styled.span`
  display: flex;
  flex: 0.1;
  align-items: center;
  padding-left: 4px;
  padding-top: 30px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;

  > div {
    width: "fit-content";
    margin-right: 16px;

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const IconBack = styled(IconContainer)`
  display: flex;
  width: 16px;
  justify-content: flex-end;
  width: 100%;
  svg {
    transform: rotate(90deg);
  }
  cursor: pointer;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
  margin-left: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const LogoContainer = styled(IconContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 100px;
  border-radius: 6px;
  background-color: #ebf7fa;
`;

const Status = styled.div`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.palette.white};
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: Vodafone Rg;

  > span {
    position: relative;
    bottom: 1px;
  }
`;

const LiveStatus = styled(Status)`
  color: ${props => props.theme.palette.successGreen};
  border: 1px solid;
  border-color: ${props => props.theme.palette.successGreen};
`;
const DisabledStatus = styled(Status)`
  color: black;
  border: 1px solid black;
  opacity: 0.5;
`;

const StatusWrapper = styled(Row)`
  margin-top: 24px;
`;
