import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { useFilters, TabChild, Tabs, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { IStoreInterface } from "../../../../configs/store.config";
import {
  RelativeTableRow,
  RelativeRow,
  AbsoluteRow,
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
  AnalyticsContainer,
} from "./referral-campaigns-analytics.styles";
import RefreshAndExportAction from "./components/analytics-refresh-and-export.component";
import SearchByMsisdn from "./components/analytics-number-search.component";
import DateFilter from "./components/analytics-date-filter.component";
import AnalyticsInvitesTable from "./components/analytics-invites-table.component";
import AnalyticsRewardsTable from "./components/analytics-rewards-table.components";
import { ReferralAnalyticsPageConfigs } from "./referral-campaigns-analytics.utils";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import {
  IReferralAnalyticsProps,
  IReferralAnalyticsRequest,
  ReferralAnalyticsDateFilterKey,
} from "../referral-campaigns.model";
import ReferralCampaignsApi from "../referral-campaigns.api";
import { ReferralAnalyticsStoreActions } from "./referral-campaigns-analytics.store";

/**
 * ReferralAnalytics component
 */
const ReferralAnalytics = ({ sectionTabs, referralCampaignID, referralCampaignStartDate }: IReferralAnalyticsProps) => {
  const pageConfig = ReferralAnalyticsPageConfigs();
  const {
    filters,
    resetFilters,
    updateFilter,
    isFilterActive,
    clearFilter,
    getFilterValue,
    updateMultipleFilters,
  } = useFilters();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const staticTempRequestParams = React.useRef({
    id: referralCampaignID,
    page: pageConfig.INITIAL_PAGE,
    pageSize: pageConfig.PAGE_LIST_ROWS,
  } as IReferralAnalyticsRequest);
  const firstRenderRef = React.useRef({
    dateFilter: 0,
    pageRequest: 0,
    sectionRequest: 0,
  });
  const [sectionId, setSectionId] = React.useState<number>(pageConfig.INITIAL_SECTION);
  const [activePage, setActivePage] = React.useState<number>(pageConfig.INITIAL_PAGE);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [isFetchingWithParams, setIsFetchingWithParams] = React.useState<boolean>(false);
  const [mainRequestParams, setMainRequestParams] = React.useState<IReferralAnalyticsRequest>({
    id: referralCampaignID,
    page: pageConfig.INITIAL_PAGE,
    pageSize: pageConfig.PAGE_LIST_ROWS,
  });

  // Analytics Store
  const { analyticsInvites, analyticsRewards } = useSelector(
    (state: IStoreInterface) => state.referralAnalyticsReducer,
  );

  /**
   * Handles the pagination change
   * When the user clicks on page numbers it updates the page number on
   * requestParams and calls the fetchData function
   * @param pageNumber: number
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    setMainRequestParams({ ...mainRequestParams, page: pageNumber });
  };

  /**
   * Handles the section change
   * @param id: number
   */
  const handleRequestParamsChange = () => {
    if (sectionId === 0) {
      referralAnalyticsRequestActions.clearRewardsData();
      referralAnalyticsRequestActions.getInvitesData();
      return;
    }

    referralAnalyticsRequestActions.clearInvitesData();
    referralAnalyticsRequestActions.getRewardsData();
  };

  /**
   * Refresh results on the table
   */
  const handleRefresh = () => {
    setActivePage(pageConfig.INITIAL_PAGE);
    setMainRequestParams({ ...staticTempRequestParams.current, page: pageConfig.INITIAL_PAGE });
  };

  /**
   * Download file handler
   * @param res
   */
  const handleFileDownload = (response: AxiosResponse<any, any>) => {
    const fileDownload = require("js-file-download");
    const date = {
      start: !!mainRequestParams.startDate
        ? moment(Number(mainRequestParams.startDate) * 1000)
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
      end: !!mainRequestParams.endDate
        ? moment(Number(mainRequestParams.endDate) * 1000)
            .subtract(1, "days")
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
    };

    if (date.start && date.end) {
      fileDownload(response, `analytics_export_${date.start}_${date.end}.xlsx`);
      return;
    }

    fileDownload(response, `analytics_export.xlsx`);
  };

  /**
   * Returns the time filter object
   * This function works alongside with getDateFilter()
   * @returns {object}
   */
  const getTimeFilter = () => {
    if (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE)) {
      const startTime = (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE) as string[])[0].split(":");
      const endTime = (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE) as string[])[1].split(":");
      return { startTime: startTime, endTime: endTime };
    }
    return null;
  };

  /**
   * Returns the date filter object
   * This function needs to be called after getTimeFilter()
   * @returns {object}
   */
  const getDateFilter = () => {
    if (filters.get(ReferralAnalyticsDateFilterKey.START_DATE)) {
      let startDate,
        endDate = null;
      const timeFilter = getTimeFilter();
      if (timeFilter) {
        startDate = moment((filters.get(ReferralAnalyticsDateFilterKey.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0);
        endDate = moment((filters.get(ReferralAnalyticsDateFilterKey.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(59);
      } else {
        startDate = moment((filters.get(ReferralAnalyticsDateFilterKey.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0);
        endDate = moment((filters.get(ReferralAnalyticsDateFilterKey.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * A set of referral analytics action functions to invites and rewards, and file export
   */
  const referralAnalyticsRequestActions = {
    getInvitesData: async () => {
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsInvites());
      setIsFetchingData(true);
      try {
        const response = await ReferralCampaignsApi.methods.getInvitesList(mainRequestParams);
        dispatch(ReferralAnalyticsStoreActions.creators.setReferralAnalyticsInvites(response.data));
        setIsFetchingData(false);
      } catch (error) {
        setIsFetchingData(false);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.getInvitesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    },
    getRewardsData: async () => {
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsRewards());
      setIsFetchingData(true);
      try {
        const response = await ReferralCampaignsApi.methods.getRewardsList(mainRequestParams);
        dispatch(ReferralAnalyticsStoreActions.creators.setReferralAnalyticsRewards(response.data));
        setIsFetchingData(false);
      } catch (error) {
        setIsFetchingData(false);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.getRewardsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    },
    clearInvitesData: () => {
      dispatch(ReferralAnalyticsStoreActions.creators.clearReferralAnalyticsInvites());
    },
    clearRewardsData: () => {
      dispatch(ReferralAnalyticsStoreActions.creators.clearReferralAnalyticsRewards());
    },
    getExcelFile: async () => {
      setIsFetchingData(true);
      const userDefaultTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsExcelFile());

      try {
        const response = await ReferralCampaignsApi.methods.getExcelFile({
          ...mainRequestParams,
          timeZone: userDefaultTimezone,
        });
        handleFileDownload(response.data);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.exportSuccessful"),
          type: AlertTypeEnum.SUCCESS,
        });
      } catch (error) {
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.exportError"),
          type: AlertTypeEnum.ERROR,
        });
      } finally {
        showAlert();
        setIsFetchingData(false);
      }
    },
  };

  /**
   * Updates the request state each time the filters are changed
   */
  React.useEffect(() => {
    if (firstRenderRef.current.dateFilter <= 0) {
      firstRenderRef.current.dateFilter = ++firstRenderRef.current.dateFilter;
      return;
    }

    const dateFilter = getDateFilter();
    if (dateFilter) {
      staticTempRequestParams.current = { ...staticTempRequestParams.current, ...dateFilter };
    } else {
      const { startDate, endDate, ...rest } = staticTempRequestParams.current;
      staticTempRequestParams.current = { ...rest };
    }
    setActivePage(pageConfig.INITIAL_PAGE);
    setMainRequestParams(staticTempRequestParams.current);
  }, [filters]);

  /**
   * Updates staticRequestParams when searchQuery is updated
   */
  React.useEffect(() => {
    staticTempRequestParams.current = { ...staticTempRequestParams.current, msisdn: searchQuery };
  }, [searchQuery]);

  /**
   * Resets all filters (search, date picker)
   * Reset activePage to default Initial Page
   * Reset tempRequestParams
   * Updates the request state that will trigger a fetch action
   */
  React.useEffect(() => {
    resetFilters();
    setSearchQuery("");
    setIsFetchingWithParams(false);
    setActivePage(pageConfig.INITIAL_PAGE);
    firstRenderRef.current.dateFilter = 0;
    staticTempRequestParams.current = {
      id: referralCampaignID,
      page: pageConfig.INITIAL_PAGE,
      pageSize: pageConfig.PAGE_LIST_ROWS,
    };

    setMainRequestParams({
      id: referralCampaignID,
      page: pageConfig.INITIAL_PAGE,
      pageSize: pageConfig.PAGE_LIST_ROWS,
    });
  }, [sectionId]);

  /**
   * Fetches Analytics on page first load and also when param changes
   */
  React.useEffect(() => {
    if (firstRenderRef.current.pageRequest <= 0) {
      firstRenderRef.current.pageRequest = ++firstRenderRef.current.pageRequest;
      return;
    }

    if ((mainRequestParams.startDate && mainRequestParams.endDate) || mainRequestParams.msisdn) {
      setIsFetchingWithParams(true);
    } else {
      setIsFetchingWithParams(false);
    }
    handleRequestParamsChange();
  }, [mainRequestParams]);

  return (
    <>
      <AnalyticsContainer>
        <ContainerHeader>
          <div className="container_header_left">{/* Future enhancement: Filter by Date */}</div>
          <div className="container_header_right">{/* Future enhancement: Refresh and Export */}</div>
        </ContainerHeader>

        <ContainerBody>
          <div className="container_body_cards">{/* Future enhancement: 4 Cards */}</div>
          <div className="container_body_tabs">
            <Tabs controlledSelectedTab={sectionId} controlledSetSelectedTab={setSectionId}>
              {sectionTabs.map((label, idx) => (
                <TabChild key={idx} label={label}>
                  <AbsoluteRow>
                    <RefreshAndExportAction
                      refreshLabel={t("pages.referralCampaigns.analytics.refresh")}
                      refreshCurrentTab={handleRefresh}
                      exportLabel={t("pages.referralCampaigns.analytics.export")}
                      exportFile={referralAnalyticsRequestActions.getExcelFile}
                      totals={{ totalRewards: analyticsRewards.count, totalInvites: analyticsInvites.count }}
                    />
                  </AbsoluteRow>

                  <RelativeRow>
                    <SearchByMsisdn
                      searchLabel={t("pages.referralCampaigns.analytics.searchPlaceholder")}
                      searchString={searchQuery}
                      onChangeFn={setSearchQuery}
                      onKeyDownFn={handleRefresh}
                    />

                    <DateFilter
                      filters={filters}
                      updateFilter={updateFilter}
                      isFilterActive={isFilterActive}
                      clearFilter={clearFilter}
                      getFilterValue={getFilterValue}
                      updateMultipleFilters={updateMultipleFilters}
                      minSelectableDate={referralCampaignStartDate}
                      translations={{
                        dropdownPlaceholder: t("pages.referralCampaigns.analytics.dateFilterPlaceholder"),
                        clearFilters: t("components.dateFilter.clearFilters"),
                        CUSTOM: t("components.dateFilter.customRange"),
                        LAST_3_MONTH: t("components.dateFilter.last3Months"),
                        LAST_6_MONTH: t("components.dateFilter.last6Months"),
                        LAST_MONTH: t("components.dateFilter.lastMonth"),
                        LAST_WEEK: t("components.dateFilter.lastWeek"),
                        LAST_YEAR: t("components.dateFilter.lastYear"),
                      }}
                    />
                  </RelativeRow>

                  <RelativeTableRow>
                    {!!!sectionId ? (
                      <AnalyticsInvitesTable
                        columnsConfig={pageConfig.SECTION_COLUMN.invites}
                        noResultsText={
                          isFetchingWithParams
                            ? t("pages.referralCampaigns.analytics.noInvitesOnSearch")
                            : t("pages.referralCampaigns.analytics.noInvites")
                        }
                        data={analyticsInvites?.list || []}
                      />
                    ) : (
                      <AnalyticsRewardsTable
                        columnsConfig={pageConfig.SECTION_COLUMN.rewards}
                        noResultsText={
                          isFetchingWithParams
                            ? t("pages.referralCampaigns.analytics.noRewardsOnSearch")
                            : t("pages.referralCampaigns.analytics.noRewards")
                        }
                        data={analyticsRewards?.list || []}
                      />
                    )}
                  </RelativeTableRow>
                </TabChild>
              ))}
            </Tabs>
          </div>
        </ContainerBody>

        {!!analyticsRewards.count || !!analyticsInvites.count ? (
          <ContainerFooter>
            <TablePagination
              handlePageChange={handlePageChange}
              totalItems={sectionId ? analyticsRewards?.count || 0 : analyticsInvites?.count || 0}
              activePage={activePage}
              pageSize={pageConfig.PAGE_LIST_ROWS}
            />
          </ContainerFooter>
        ) : null}
      </AnalyticsContainer>
    </>
  );
};

export default ReferralAnalytics;
