import React, { useState } from "react";
import useVersionsDiff, { VersionItem } from "../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { IBusiness } from "../../../shared/models/business.model";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@wit/mpesa-ui-components";
import { Column, Row } from "../../../shared/shared.styled";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { BusinessStatusEnum, getStatusColor } from "./business-utils";
import { ICategory } from "../../../shared/models/category.model";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";

interface IPublishBusinessModalProps {
  onCancelClick: () => void;
  submit: () => void;
}

/**
 * Publish Business Modal Component
 * @param onCancelClick
 * @param submit
 * @returns
 */
const PublishBusinessModal = ({ onCancelClick, submit }: IPublishBusinessModalProps) => {
  const [t] = useTranslation();

  const { businesses, categories } = useSelector((state: IStoreInterface) => state.businessesReducer);
  const { prev, current } = useVersionsDiff(businesses.lastVersion, businesses.data);
  const { prev: prevCat, current: currentCat } = useVersionsDiff(categories?.lastVersion, categories?.data);
  const [tabIndex, setTabIndex] = useState(0);

  const getCategory = (id: string) => {
    return categories.data.find(category => category.id === id) || { name: { en: "" } };
  };

  const showCategories = useCheckFeatureAvailable(FEATURES.BUSINESS_CATEGORIES);

  /**
   * Category Item component
   * @param item
   * @returns  {JSX.Element}
   */
  const renderCategoryItem = (item: VersionItem<ICategory>) => (
    <ItemColumn disabled={item.status === BusinessStatusEnum.DELETE}>
      <Row>
        <ImageContainerCategory>
          <img
            style={{ height: 20 }}
            alt={item.urlIcon}
            src={`./images/categoryIcons/${item.urlIcon}`}
            onError={e => ((e.target as HTMLImageElement).src = "./images/organization.png")}
          />
        </ImageContainerCategory>
        <ItemInfo>
          <div style={{ display: "flex", alignItems: "center" }}>
            {item.name.en && (
              <Row>
                {t("pages.configurations.businessTab.lastVersion.categoryName")} - Eng: {item.name.en}
              </Row>
            )}
            {item.name.sw && (
              <Row>
                {t("pages.configurations.businessTab.lastVersion.categoryName")} - Swa: {item.name.sw}
              </Row>
            )}
            <VersionItemStatusChip status={item.versionItemStatus} />
          </div>
          {item.parentCategory && (
            <Row>
              {" "}
              {t("pages.configurations.businessTab.lastVersion.parentCategory")}:
              {getCategory(item.parentCategory).name.en}
            </Row>
          )}
        </ItemInfo>
        <StatusContainer>
          {item.status === BusinessStatusEnum.LIVE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.LIVE)}>
              {t("pages.configurations.businessTab.status.live")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DELETE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DELETE)}>
              {t("pages.configurations.businessTab.status.delete")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DISABLE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLE)}>
              {t("pages.configurations.businessTab.status.deactivate")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DISABLED && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLED)}>
              {t("pages.configurations.businessTab.status.deactivated")}
            </StatusRow>
          )}
        </StatusContainer>
      </Row>
    </ItemColumn>
  );

  /**
   * Business Item component
   * @param item
   * @returns  {JSX.Element}
   */
  const renderIBusinessItem = (item: VersionItem<IBusiness>) => {
    return (
      <ItemColumn disabled={item.status === BusinessStatusEnum.DELETE}>
        <Row>
          <ImageContainer>
            <img
              style={{ height: 24 }}
              src={`./imagesuploaded/${item.urlIcon}`}
              alt={item.name}
              onError={e => ((e.target as HTMLImageElement).src = "./images/organization.png")}
            />
          </ImageContainer>
          <ItemInfo>
            <div style={{ display: "flex", alignItems: "center" }}>
              {item.shortCode && (
                <Row>
                  {t("pages.configurations.businessTab.publish.shortCode")}: {item.shortCode}
                </Row>
              )}
              <VersionItemStatusChip status={item.versionItemStatus} />
            </div>
            {item.name && (
              <Row>
                {t("pages.configurations.businessTab.publish.businessName")}: {item.name}
              </Row>
            )}
            {item.amount && (
              <Row>
                {t("pages.configurations.businessTab.publish.amount")}: {item.amount}
              </Row>
            )}
            {showCategories && getCategory(item.categoryId).name.en && (
              <Row>
                {t("pages.configurations.businessTab.publish.category")}: {getCategory(item.categoryId).name.en}
              </Row>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status === BusinessStatusEnum.LIVE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.LIVE)}>
                {t("pages.configurations.businessTab.status.live")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DELETE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DELETE)}>
                {t("pages.configurations.businessTab.status.delete")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DISABLE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLE)}>
                {t("pages.configurations.businessTab.status.deactivate")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DISABLED && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLED)}>
                {t("pages.configurations.businessTab.status.deactivated")}
              </StatusRow>
            )}
            {item.popular && <Row style={{ color: "#00b0ca" }}>{t("pages.configurations.businessTab.popular")}</Row>}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Row style={{ padding: 24 }}>
        <Column>
          <ModalTitle>{t("pages.configurations.businessTab.publish.publish")}</ModalTitle>
          <ModalSubTitle>{t("pages.configurations.businessTab.publish.wantPublish")}</ModalSubTitle>
        </Column>
        <Column style={{ flex: 2 }}>
          <RightContainer>
            {showCategories && (
              <>
                <SelectList selected={tabIndex === 0} onClick={() => setTabIndex(0)}>
                  {t("pages.configurations.businessTab.publish.business")}
                </SelectList>
                <SelectList selected={tabIndex === 1} onClick={() => setTabIndex(1)}>
                  {t("pages.configurations.businessTab.publish.categories")}
                </SelectList>
              </>
            )}
          </RightContainer>
        </Column>
      </Row>
      <Content>
        <Row>
          <ItemColumn>{t("pages.configurations.businessTab.publish.previousVersion")}</ItemColumn>
          <ItemColumn>{t("pages.configurations.businessTab.publish.editedVersion")}</ItemColumn>
        </Row>
        {tabIndex === 0 && (
          <ItemsContainer>
            {prev &&
              current &&
              current.map((edited, index) => {
                return (
                  <Item key={edited.id}>
                    {prev[index] ? renderIBusinessItem(prev[index]) : <ItemColumn />}
                    {edited ? renderIBusinessItem(edited) : <ItemColumn />}
                  </Item>
                );
              })}
          </ItemsContainer>
        )}
        {showCategories && tabIndex === 1 && (
          <ItemsContainer>
            {currentCat.map((category, index) => {
              return (
                <Item key={category.id}>
                  {prevCat[index] ? renderCategoryItem(prevCat[index]) : <ItemColumn />}
                  {category ? renderCategoryItem(category) : <ItemColumn />}
                </Item>
              );
            })}
          </ItemsContainer>
        )}
      </Content>
      <ButtonsContainer style={{ padding: 24 }}>
        <div>
          <PrimaryButton
            type="button"
            id="cancel-button"
            onClick={onCancelClick}
            titleLabel={t("pages.configurations.businessTab.publish.cancelBtnLabel")}
          />
        </div>
        <div>
          <PrimaryButton
            id="confirm-button"
            redTheme={true}
            type="submit"
            onClick={() => submit()}
            titleLabel={t("pages.configurations.businessTab.publish.saveChangesBtnLabel")}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default PublishBusinessModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ModalSubTitle = styled(ModalTitle)`
  font-size: 18px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ItemsContainer = styled.div`
  max-height: 220px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const ImageContainerCategory = styled(ImageContainer)`
  background-color: #ebf7fa;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding-left: 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SelectList = styled.div<{ selected: Boolean }>`
  height: fit-content;
  padding-bottom: 9px;
  color: ${({ selected, theme }) => (selected ? theme.palette.digitalRed : theme.palette.greyDarker)};
  margin-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid;
  border-bottom-color: ${({ selected, theme }) => (selected ? theme.palette.digitalRed : "transparent")};
`;

const StatusRow = styled(Row)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};

  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;
