import React from "react";
import moment from "moment";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import i18next from "i18next";
import { DocValidationUtils } from "../doc-validation/doc-validation.utils";
import { IClientDocSubmission } from "../doc-validation/doc-validation.interface";

export const DocDashboardUtils = {
  getTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 2 / 10,
        label: i18next.t("pages.docDashboard.table.creationDate"),
        formKey: "requestTime",
        isEditable: false,
        changeFunction: content => {
          if (!content) {
            return "-";
          }

          const creationDate = new Date(content);
          const creationDateStr = moment(creationDate).format("DD/MM/YYYY HH:mm:ss");
          return <>{creationDateStr}</>;
        },
      },
      {
        ratio: 2 / 10,
        label: i18next.t("pages.docDashboard.table.fullName"),
        formKey: "fullName",
        isEditable: false,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 2 / 10,
        label: i18next.t("pages.docDashboard.table.phoneNumber"),
        formKey: "phoneNumber",
        isEditable: false,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 2 / 10,
        label: i18next.t("pages.docDashboard.table.idNumber"),
        formKey: "idCardNumber",
        isEditable: false,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 2 / 10,
        label: i18next.t("pages.docDashboard.table.status"),
        formKey: "status",
        isEditable: false,
        changeFunction: (content, row: IClientDocSubmission) => (
          <span style={{ color: DocValidationUtils.getClientDocStatusColor(row.status) }}>
            {i18next.t(`commons.clientDocSubmissionStatus.${row.status}`)}
          </span>
        ),
      },
    ];
  },
};
