import {
  AdminIcon,
  AntMobileIcon,
  ElementWithTooltip,
  AppsIcon,
  ListViewIcon,
  MegaphoneIcon,
  MobileIcon,
  MpesaLogoIcon,
  ServicesIcon,
  SettingsIcon,
  SupportIcon,
  SurveyIcon,
} from "@wit/mpesa-ui-components";
import React from "react";
import styled from "styled-components";
import { IConfig } from "../app.component";
import { UserRolesDefault, UserRolesSFC, UserRolesVDF } from "../features/admin/users/users.interfaces";
import { ILoggedUser } from "../features/authentication/authentication.interfaces";
import { RoutesEnum, RoutesPrivileges } from "../routes/routes.constants";
import { NavigationLinkContainer } from "./components/navigation-bar.component";
import AuditingIcon from "./icons/auditing.icon";
import { isSFCMarket } from "./shared.utils";
import TopicsIcon from "./icons/Topics.icon";
import PushNotificationIcon from "./icons/notification.icon";

export enum CLIENTS {
  SAFARICOM = "SAFARICOM",
  SAFARICOMET = "SAFARICOMET",
  VFGROUP = "VFGROUP",
}

export enum THEMES {
  CLASSIC = "CLASSIC",
  SAFARICOM = "SAFARICOM",
}

export enum AREAS {
  ADMIN = "ADMIN",
  ANT_SERVICE_MANAGER = "ANT_SERVICE_MANAGER",
  APPS = "APPS",
  AUDITING = "AUDITING",
  SERVICE_BUILDER = "SERVICE_BUILDER",
  SERVICE_MANAGER = "SERVICE_MANAGER",
  SETTINGS = "SETTINGS",
  SUPPORT = "SUPPORT",
  TOOLS = "TOOLS",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  TOPICS = "TOPICS",
  NOTIFICATIONS = "NOTIFICATIONS",
  DOC_VALIDATION = "DOC_VALIDATION",
  DOC_DASHBOARD = "DOC_DASHBOARD",
  DOC_BULKS = "DOC_BULKS",
  ON_DEMAND_NOTIFICATIONS = "ON_DEMAND_NOTIFICATIONS",
}

export enum FEATURES {
  APP_PROPERTIES = "APP_PROPERTIES",
  BANKS = "BANKS",
  BUNDLES = "BUNDLES",
  BUSINESS = "BUSINESS",
  BUSINESS_CATEGORIES = "BUSINESS_CATEGORIES",
  CATEGORIES = "CATEGORIES",
  DISCOVER_CARDS = "DISCOVER_CARDS",
  FEEDBACK_AND_PROBLEMS_REPORT = "FEEDBACK_AND_PROBLEMS_REPORT",
  DISABLE_MOBILE_APPS = "DISABLE_MOBILE_APPS",
  DOCUMENTS = "DOCUMENTS",
  DYNAMIC_SCREENS = "DYNAMIC_SCREENS",
  NETWORKS = "NETWORKS",
  PUSH_NOTIFICATIONS = "PUSH_NOTIFICATIONS",
  RATE_CARDS = "RATE_CARDS",
  REFERRAL_CAMPAIGNS = "REFERRAL_CAMPAIGNS",
  REQUEST_MONEY = "REQUEST_MONEY",
  REQUEST_MONEY_TAB_BLACKLIST = "REQUEST_MONEY_TAB_BLACKLIST",
  REQUEST_MONEY_TAB_REPORTED_USERS = "REQUEST_MONEY_TAB_REPORTED_USERS",
  REQUEST_MONEY_TAB_WHITELIST = "REQUEST_MONEY_TAB_WHITELIST",
  SURVEYS = "SURVEYS",
  LANGUAGE_TRANSLATIONS = "LANGUAGE_TRANSLATIONS",
  IDENTITY_CONVERTER = "IDENTITY_CONVERTER",
  SERVICES_AREA = "SERVICES_AREA",
  CONSUMER_GROW = "CONSUMER_GROW",
  BUSINESS_AUDIENCE_AGENT = "BUSINESS_AUDIENCE_AGENT",
  SERVICE_USER_TYPE = "SERVICE_USER_TYPE",
  AGENT_HUB = "AGENT_HUB",
  AUTOMATIC_SUGGESTED_ANNOUNCEMENTS = "AUTOMATIC_SUGGESTED_ANNOUNCEMENTS",
  REQUEST_PAYMENT = "REQUEST_PAYMENT",
  REQUEST_PAYMENT_TAB_BLACKLIST = "REQUEST_PAYMENT_TAB_BLACKLIST",
  MINI_APPS_CONFIGURATION_ROLE = "MINI_APPS_CONFIGURATION_ROLE",
  CONSUMER_AUDIENCE_CHILD = "CONSUMER_AUDIENCE_CHILD",
  WALLETS = "WALLETS",
  ADD_AND_REMOVE_SERVICES = "ADD_AND_REMOVE_SERVICES",
  CATEGORY_MINI_APPS_TABS = "CATEGORY_MINI_APPS_TABS",
  MY_SAFARICOM_PRE_PAID = "MY_SAFARICOM_PRE_PAID",
  MY_SAFARICOM_POST_PAID = "MY_SAFARICOM_POST_PAID",
  NEW_IMAGE_FOR_ANNOUNCEMENT_CAROUSEL = "NEW_IMAGE_FOR_ANNOUNCEMENT_CAROUSEL",
  GSM_PROFILE = "GSM_PROFILE",
  MINI_APPS_CATEGORY_EDIT = "MINI_APPS_CATEGORY_EDIT",
  SHORTCUTS = "SHORTCUTS",
  RECAPTCHA_TOKEN = "RECAPTCHA_TOKEN",
}

const RendererUtils = {
  checkRolePermission: (
    roles: Array<UserRolesDefault | UserRolesVDF | UserRolesSFC>,
    loggedUser?: ILoggedUser,
  ): boolean => {
    if (loggedUser) {
      if (roles.includes(loggedUser.role)) {
        return true;
      }
    }
    return false;
  },
  checkFeatureAvailable: (config: IConfig, feature: string) => {
    return (
      config.disabledFeatures === undefined ||
      (config.disabledFeatures !== undefined && !config.disabledFeatures.includes(feature))
    );
  },
  getNavigationMenuLogoUsingConfig: (config: IConfig) => {
    if (config) {
      if (config.client === CLIENTS.VFGROUP) {
        return <MpesaLogoIcon id={"navigation-logo"} />;
      } else if (isSFCMarket()) {
        return <SafaricomLogoIcon id={"navigation-logo"} src={"./images/safaricom_logo.svg"} alt="logo" />;
      } else {
        return <SupportIcon />;
      }
    }
  },
  getNavigationIconsUsingConfig: (
    tooltip: {
      labels: { [key: string]: string };
    },
    history: any,
    config?: IConfig,
    loggedUser?: ILoggedUser,
  ) => {
    const NavigationIcons: React.ReactNodeArray = [];
    if (config) {
      config.availableAreas.forEach(area => {
        switch (area) {
          case AREAS.APPS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.apps, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"apps"}
                  containerKeyId={"apps"}
                  tooltipLabel={tooltip.labels.apps}
                >
                  <NavigationLinkContainer
                    id={"apps-icon"}
                    active={window.location.pathname.includes(RoutesEnum.APPS)}
                    onClick={() => history.push(RoutesEnum.APPS)}
                  >
                    <AppsIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.SERVICE_BUILDER:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.sbList, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"service-builder"}
                  containerKeyId={"service-builder"}
                  tooltipLabel={tooltip.labels.serviceBuilder}
                >
                  <NavigationLinkContainer
                    id={"service-builder-icon"}
                    active={window.location.pathname.includes(RoutesEnum.SERVICE_BUILDER)}
                    onClick={() => history.push(RoutesEnum.SERVICE_BUILDER)}
                  >
                    <MobileIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.ANT_SERVICE_MANAGER:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.sbCrud, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"ant-service-manager"}
                  containerKeyId={"ant-service-manager"}
                  tooltipLabel={tooltip.labels.antServiceBuilder}
                >
                  <NavigationLinkContainer
                    id={"ant-service-manager-icon"}
                    active={window.location.pathname.includes(RoutesEnum.ANT_SERVICE_MANAGER)}
                    onClick={() => history.push(RoutesEnum.ANT_SERVICE_MANAGER)}
                  >
                    <AntMobileIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.SETTINGS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.configs, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"configurations"}
                  containerKeyId={"configurations"}
                  tooltipLabel={tooltip.labels.config}
                >
                  <NavigationLinkContainer
                    id={"configurations-icon"}
                    active={window.location.pathname.includes(RoutesEnum.CONFIGS)}
                    onClick={() => history.push(RoutesEnum.CONFIGS)}
                  >
                    <SettingsIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.ADMIN:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.admin, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"users"}
                  containerKeyId={"users"}
                  tooltipLabel={tooltip.labels.admin}
                >
                  <NavigationLinkContainer
                    id={"users-icon"}
                    active={window.location.pathname.includes(RoutesEnum.ADMIN)}
                    onClick={() => history.push(RoutesEnum.ADMIN)}
                  >
                    <AdminIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.SUPPORT:
            NavigationIcons.push(
              <ElementWithTooltip
                marginBottom={24}
                tooltipPosition={1}
                tooltipArrow={true}
                tooltipHeight={35}
                tooltipMargin={0}
                key={"support"}
                containerKeyId={"support"}
                tooltipLabel={tooltip.labels.support}
              >
                <NavigationLinkContainer
                  id={"support-icon"}
                  active={window.location.pathname.includes(RoutesEnum.SUPPORT)}
                  onClick={() => history.push(RoutesEnum.SUPPORT)}
                >
                  <SupportIcon />
                </NavigationLinkContainer>
              </ElementWithTooltip>,
            );
            break;
          case AREAS.TOOLS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.tools, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"tools"}
                  containerKeyId={"tools"}
                  tooltipLabel={tooltip.labels.tools}
                >
                  <NavigationLinkContainer
                    id={"tools-icon"}
                    active={window.location.pathname.includes(RoutesEnum.TOOLS)}
                    onClick={() => history.push(RoutesEnum.TOOLS)}
                  >
                    <ServicesIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.SERVICE_MANAGER:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.serviceManager, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"service-manager"}
                  containerKeyId={"service-manager"}
                  tooltipLabel={tooltip.labels.serviceManager}
                >
                  <NavigationLinkContainer
                    id={"service-manager-icon"}
                    active={window.location.pathname.includes(RoutesEnum.SERVICE_MANAGER)}
                    onClick={() => history.push(RoutesEnum.SERVICE_MANAGER)}
                  >
                    <MobileIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.ANNOUNCEMENTS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.announcements, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"announcements"}
                  containerKeyId={"announcements"}
                  tooltipLabel={tooltip.labels.announcements}
                >
                  <NavigationLinkContainer
                    id={"announcements-icon"}
                    active={window.location.pathname.includes(RoutesEnum.ANNOUNCEMENTS)}
                    onClick={() => history.push(RoutesEnum.ANNOUNCEMENTS)}
                  >
                    <MegaphoneIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.TOPICS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.topics, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key="topics"
                  containerKeyId="topics"
                  tooltipLabel={tooltip.labels.topics}
                >
                  <NavigationLinkContainer
                    id={"topics-icon"}
                    active={window.location.pathname.includes(RoutesEnum.TOPICS)}
                    onClick={() => history.push(RoutesEnum.TOPICS)}
                  >
                    <TopicsIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.ON_DEMAND_NOTIFICATIONS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.announcements, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"notifications"}
                  containerKeyId={"notifications"}
                  tooltipLabel={tooltip.labels.pushNotifications}
                >
                  <NavigationLinkContainer
                    id={"notifications-icon"}
                    active={window.location.pathname.includes(RoutesEnum.SFC_PUSH_NOTIFICATIONS)}
                    onClick={() => history.push(RoutesEnum.SFC_PUSH_NOTIFICATIONS)}
                  >
                    <PushNotificationIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.AUDITING:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.admin, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"auditing"}
                  containerKeyId={"auditing"}
                  tooltipLabel={tooltip.labels.auditing}
                >
                  <NavigationLinkContainer
                    id={"auditing-icon"}
                    active={window.location.pathname.includes(RoutesEnum.AUDITING)}
                    onClick={() => history.push(RoutesEnum.AUDITING)}
                  >
                    <AuditingIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.DOC_DASHBOARD:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.ekyc, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"doc-dashboard"}
                  containerKeyId={"doc-dashboard"}
                  tooltipLabel={tooltip.labels.docDashboard}
                >
                  <NavigationLinkContainer
                    id={"doc-dashboard-icon"}
                    active={window.location.pathname.includes(RoutesEnum.DOC_DASHBOARD)}
                    onClick={() => history.push(RoutesEnum.DOC_DASHBOARD)}
                  >
                    <AppsIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.DOC_VALIDATION:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.ekyc, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"doc-validation"}
                  containerKeyId={"doc-validation"}
                  tooltipLabel={tooltip.labels.docValidation}
                >
                  <NavigationLinkContainer
                    id={"doc-validation-icon"}
                    active={window.location.pathname.includes(RoutesEnum.DOC_VALIDATION)}
                    onClick={() => history.push(RoutesEnum.DOC_VALIDATION)}
                  >
                    <SurveyIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
          case AREAS.DOC_BULKS:
            if (RendererUtils.checkRolePermission(RoutesPrivileges.admin, loggedUser)) {
              NavigationIcons.push(
                <ElementWithTooltip
                  marginBottom={24}
                  tooltipPosition={1}
                  tooltipArrow={true}
                  tooltipHeight={35}
                  tooltipMargin={0}
                  key={"doc-bulk-list"}
                  containerKeyId={"doc-bulk-list"}
                  tooltipLabel={tooltip.labels.docBulkList}
                >
                  <NavigationLinkContainer
                    id={"doc-bulk-list-icon"}
                    active={window.location.pathname.includes(RoutesEnum.DOC_BULK_LIST)}
                    onClick={() => history.push(RoutesEnum.DOC_BULK_LIST)}
                  >
                    <ListViewIcon />
                  </NavigationLinkContainer>
                </ElementWithTooltip>,
              );
            }
            break;
        }
      });
    }
    return NavigationIcons;
  },
  getLoginLogoUsingConfig: (config?: IConfig): string => {
    if (config) {
      if (config.client === CLIENTS.VFGROUP) {
        return "./images/m-pesa-vodafone.png";
      } else {
        return "./images/safaricom_logo_login.png";
      }
    }
    return "";
  },
};

const SafaricomLogoIcon = styled("img")`
  width: 100%;
`;

export default RendererUtils;
