import React, { useState } from "react";
import { ButtonInfo, ButtonSection, DetailSteps, DetailTitle } from "../../announcements/announcements.styled";
import {
  ModalTypeEnum,
  PrimaryButton,
  ProgressBar,
  SecondaryPageTitle,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { PageContent } from "../../../shared/responsive-ui.styled";
import styled from "styled-components";
import { PageContainer } from "../../../shared/shared.styled";
import { INotification, NOTIFICATION_STATUSES, SelectAudienceByTypes } from "../push-notifications.modal";
import NotificationsDetails from "../components/notification-details.component";
import { ConsumerAudience, IActionInterface } from "../../announcements/announcements.model";
import { AnnouncementsUtils } from "../../announcements/announcements.utils";
import PushNotificationsApi from "../push-notifications.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";
import ConfirmWithLoadingModal from "../../safaricom-service-manager/section-manager/confirm-with-loading-modal.component";
import NotificationsCampaignDetails from "../components/audience-details.component";

const NOTIFICATION_INITIAL_VALUES: INotification = {
  campaignName: "",
  createdOn: "",
  startDate: "",
  startTime: "",
  selectAudienceBy: "",
  backgroundImage: "",
  title: "",
  targetVersions: [],
  sendImmediately: false,
  audience: ConsumerAudience.ALL_CONSUMERAPPS,
  hasCallToAction: false,
  callToAction: {
    action: {} as IActionInterface,
    title: "",
  },
  status: NOTIFICATION_STATUSES.DRAFT,
};

/**
 * Render create notification page
 * @returns {JSX.Element}
 */
const CreateNotificationPage = () => {
  const [t] = useTranslation();

  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
  const stepsTitles = ["firstStepTitle", "secondStepTitle", "thirdStepTitle"];
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const history = useHistory();
  const [notificationValues, setNotificationValues] = useState(NOTIFICATION_INITIAL_VALUES);
  const [step, setStep] = useState(1);
  const totalOfSteps = 2;

  /**
   * function go back
   */
  const goBack = () => {
    if (step === 1) {
      window.history.back();
    } else {
      goBackStep();
    }
  };

  /**
   * function go back step
   */
  const goBackStep = () => {
    setStep(currStep => currStep - 1);
  };

  /** */
  const onChangeValues = (values: INotification) => {
    setNotificationValues(values);
  };

  /** */
  const advanceToNextStep = async () => {
    setStep(step => step + 1);
  };

  /**
   * Validate the form for the next step button validity
   */
  const isReadyToGoToNextStep = () => {
    const {
      campaignName,
      startDate,
      startTime,
      selectAudienceBy,
      targetVersions,
      csvFile,
      sendImmediately,
      isNotValidStartDate,
    } = notificationValues;

    if (step !== 1) {
      return false;
    }

    if (selectAudienceBy === SelectAudienceByTypes.APP_AND_APP_VERSION) {
      return !!(
        campaignName.trim() &&
        ((startDate && startTime && !isNotValidStartDate) || sendImmediately) &&
        targetVersions?.length
      );
    }

    if (selectAudienceBy === SelectAudienceByTypes.CSV_FILE_UPLOAD) {
      return !!(campaignName.trim() && startDate && startTime && csvFile);
    }

    return false;
  };

  /**
   * Is ready to create (publish or save as draft) a new notification
   */
  const isReadyToCreateNotification = (): boolean => {
    const { hasCallToAction, callToAction, title, body } = notificationValues;

    if (step !== 2) {
      return false;
    }

    if (hasCallToAction) {
      return (
        !!callToAction?.action?.type &&
        !!callToAction.action.resource?.trim() &&
        !!callToAction.title.trim() &&
        !!title.trim() &&
        !!body?.trim()
      );
    }

    return !!title.trim() && !!body?.trim();
  };

  /**
   * Handle create notification
   * @param isDraft Flag indicating whether create notification as a draft or publish it.
   */
  const handleCreateNotification = (status: NOTIFICATION_STATUSES) => {
    notificationValues.status = status;
    showConfirmModal();
  };

  /**
   * Handle publish notification
   */
  const requestCreatePushNotification = (srcPushNotification: INotification) => {
    if (!srcPushNotification.sendImmediately) {
      const [startDateTime, isValid] = AnnouncementsUtils.addTimeToDate(
        srcPushNotification.startDate,
        srcPushNotification.startTime || "",
      );
      srcPushNotification.startDate = new Date(startDateTime).getTime().toString();
    } else {
      const nextDateTimeJob = new Date();
      nextDateTimeJob.setMinutes(nextDateTimeJob.getMinutes() + 1);
      srcPushNotification.startDate = nextDateTimeJob.getTime().toString();
    }

    const pushNotification: INotification = {
      ...srcPushNotification,
      createdOn: new Date().getTime().toString(),
    };
    setIsRequesting(true);

    if (pushNotification.consumerAudience) {
      pushNotification.consumerAudience = AnnouncementsUtils.cleanMsisdn(pushNotification.consumerAudience);
    }

    PushNotificationsApi.methods.addPushNotification(pushNotification).then(
      res => {
        setAlertProps({
          title:
            notificationValues.status === NOTIFICATION_STATUSES.DRAFT
              ? t("pages.notifications.modal.notificationDraftSuccess")
              : t("pages.notifications.modal.notificationPublishedSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        history.push(RoutesEnum.SFC_PUSH_NOTIFICATIONS);
        setIsRequesting(false);
      },
      err => {
        setAlertProps({
          title: t("pages.notifications.modal.errorAddNotification"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsRequesting(false);
        hideConfirmModal();
      },
    );
  };

  const [showConfirmModal, hideConfirmModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <ConfirmWithLoadingModal
      isLoading={isRequesting}
      title={
        notificationValues.status === NOTIFICATION_STATUSES.DRAFT
          ? t("pages.announcements.detailPage.modals.confirmSaveDraft")
          : t("pages.announcements.detailPage.modals.confirmPublish")
      }
      description={
        notificationValues.status === NOTIFICATION_STATUSES.DRAFT
          ? t("pages.announcements.detailPage.modals.confirmDescriptionasDraft", {
              campaignName: notificationValues.campaignName,
            })
          : t("pages.announcements.detailPage.modals.confirmDescriptionPublish", {
              campaignName: notificationValues.campaignName,
            })
      }
      primaryBtnLabel={
        notificationValues.status === NOTIFICATION_STATUSES.DRAFT
          ? t("pages.notifications.buttons.save")
          : t("pages.notifications.buttons.publish")
      }
      secondaryBtnLabel={t("pages.notifications.buttons.modalCancel")}
      primaryAction={() => {
        requestCreatePushNotification(notificationValues);
      }}
      secondaryAction={() => {
        hideConfirmModal();
      }}
    />,
  );

  return (
    <PageContainer style={{ paddingBottom: "200px" }}>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.notifications.createPage.title")}
        goBackFn={goBack}
      />
      <PageContent className="pageContent display-column">
        <StepSection>
          <div>
            <DetailTitle>{t(`pages.notifications.createPage.${stepsTitles[step - 1]}`)}</DetailTitle>
            <DetailSteps>{t("pages.announcements.detailPage.step") + step}</DetailSteps>
          </div>
          <ProgressBarInfo step={step}>
            <div>
              <ProgressBar step={step} totalOfSteps={totalOfSteps} canClickPreviousSteps={true} onClickStep={setStep} />
            </div>
          </ProgressBarInfo>
          <ButtonSection>
            {step < totalOfSteps ? (
              <ButtonInfo>
                <PrimaryButton
                  titleLabel={t("pages.announcements.detailPage.buttons.continue")}
                  redTheme={true}
                  type="submit"
                  onClick={advanceToNextStep}
                  disabled={!isReadyToGoToNextStep()}
                />
              </ButtonInfo>
            ) : (
              <>
                {/* TODO: Enable when the edit page is ready */}
                {false && (
                  <ButtonInfo style={{ marginRight: "16px" }}>
                    <PrimaryButton
                      titleLabel={t("pages.announcements.detailPage.buttons.saveCreate")}
                      redTheme={false}
                      type="submit"
                      onClick={() => handleCreateNotification(NOTIFICATION_STATUSES.DRAFT)}
                      disabled={!isReadyToCreateNotification()}
                    />
                  </ButtonInfo>
                )}
                <ButtonInfo>
                  <PrimaryButton
                    titleLabel={t("pages.announcements.detailPage.buttons.publish")}
                    redTheme={true}
                    type="submit"
                    onClick={() => handleCreateNotification(NOTIFICATION_STATUSES.SCHEDULED)}
                    disabled={!isReadyToCreateNotification()}
                  />
                </ButtonInfo>
              </>
            )}
          </ButtonSection>
        </StepSection>
        {step === 1 ? (
          <StepContainer>
            <NotificationsCampaignDetails
              notificationValues={notificationValues}
              onChangeValues={onChangeValues}
              isEditing
            />
          </StepContainer>
        ) : step === 2 ? (
          <StepContainer>
            <NotificationsDetails notificationValues={notificationValues} onChangeValues={onChangeValues} isEditing />
          </StepContainer>
        ) : null}
      </PageContent>
    </PageContainer>
  );
};

export default CreateNotificationPage;

export const StepSection = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 28px;
  flex-wrap: wrap;
  align-items: center;
  > div:first-child {
    min-width: 26%;
  }
  > div:last-child {
    min-width: 28%;
  }
`;

const ProgressBarInfo = styled("div")<{ step: number }>`
  display: flex;
  justify-content: center;
  > div {
    width: 150px;

    > div > div {
      &:nth-child(-n + ${props => props.step - 1}) {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
    }

    > div:nth-child(2) {
      background: ${props => props.theme.palette.greyDarkest};
      opacity: 0.5;
    }
    > div:last-child {
      > div > div > div {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
    }
  }
`;

const StepContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
