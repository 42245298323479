import React from "react";
import moment from "moment";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import { IClientDocBulk } from "../doc-bulk/doc-bulk.model";
import { MemberIcon } from "@wit/mpesa-ui-components";
import { Row } from "../../../shared/shared.styled";
import styled from "styled-components";
import { renderDate } from "../../../shared/shared.utils";
import { IClientDocSubmission } from "./doc-validation.interface";

export const DocValidationUtils = {
  getClientDocStatusColor: (status: string): string => {
    switch (status) {
      case "PENDING":
        return styleTheme.palette.freshOrange;
      case "ACCEPTED":
        return styleTheme.palette.digitalGreen;
      case "REJECTED":
      case "ERROR_ON_G2":
      case "ERROR_ON_ID_DUPLICATIONS":
      case "ERROR_ON_FACE_DUPLICATIONS":
      case "ERROR_ON_CRITICAL_FIELDS":
      case "ERROR_ON_ID_VS_SELFIE":
        return styleTheme.palette.digitalRed;
      case "PENDING_MANUAL_REVIEW":
        return styleTheme.palette.turquoiseBlue;
      default:
        return styleTheme.palette.grey;
    }
  },

  getTableColumns: (bulkDetails?: IClientDocBulk | null): ColumnProps[] => {
    return [
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.fullName"),
        formKey: "fullName",
        isEditable: false,
        sortable: true,
        changeFunction: (content, row: IClientDocSubmission) => {
          return (
            <Row>
              <MemberIcon fullName={row.fullName} />
              <span>{row.fullName}</span>
            </Row>
          );
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.phoneNumber"),
        formKey: "phoneNumber",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.idNumber"),
        formKey: "idCardNumber",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.requestTime"),
        formKey: "requestTime",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          if (!content) {
            return "-";
          }

          const requestDate = new Date(content);
          const requestDateStr = renderDate({ date: moment(requestDate) });
          return <>{requestDateStr}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.lastStatusTime"),
        formKey: "statusTime",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          if (!content) {
            return "-";
          }

          const lastStatusDate = new Date(content);
          const lastStatusDateStr = renderDate({ date: moment(lastStatusDate) });
          return <>{lastStatusDateStr}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docValidation.table.status"),
        formKey: "status",
        isEditable: false,
        sortable: true,
        changeFunction: (content, row: IClientDocSubmission) => {
          const conflicted =
            bulkDetails &&
            bulkDetails.conflictedRequestIds &&
            bulkDetails.conflictedRequestIds.indexOf(row.remoteId) !== -1;
          return (
            <>
              {conflicted ? <ConflictMarker>!</ConflictMarker> : <></>}
              <span
                style={{
                  color: DocValidationUtils.getClientDocStatusColor(row.status),
                  paddingLeft: conflicted ? 22 : 0,
                }}
              >
                {i18next.t(`commons.clientDocSubmissionStatus.${row.status}`)}
              </span>
            </>
          );
        },
      },
    ];
  },
};

export function onEnterPressed(event: React.KeyboardEvent<HTMLInputElement>, fn: { (): void; (): void }) {
  if (event.keyCode === 13) {
    fn();
  }
}

const ConflictMarker = styled("div")`
  position: absolute;
  top: 14px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  background-color: ${styleTheme.palette.red.lighter};
  color: ${styleTheme.palette.red.normal};
  line-height: 16px;
  text-align: center;
`;
