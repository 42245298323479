import {
  FormSection,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import styleTheme, { safaricomPalette } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { PageContent } from "../../../shared/responsive-ui.styled";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AnnouncementsUtils } from "../../announcements/announcements.utils";
import { AudienceType, BusinessAudience, ConsumerAudience } from "../../announcements/announcements.model";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import i18next from "i18next";
import UploadCSVFileIcon from "../../../shared/icons/upload-csv-file.icon";
import AnnouncementsApi from "../../announcements/announcements.api";
import DownloadFileIcon from "../../../shared/icons/download.icon";
import TrashIcon from "../../../shared/icons/trash.icon";
import DropzoneCSVBackground from "../components/dropzone-csv-background.component";

/**
 * This returns a validation schema for create topics from
 */
const getValidationSchema = () => {
  return object().shape({
    name: string().required("Topic name is required!"),
    description: string().required("Topic description is required!"),
    csvFile: string().required(i18next.t("commons.mandatoryField")),
  });
};

interface ITopicForm {
  name: string;
  description: string;
  audienceApp: AudienceType;
  csvFile: string;
}

type SetFieldValueType = (key: string, value: any) => void;

/**
 * Create topic page
 */
const CreateTopicPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, __, setAlertProps] = useAlert();

  const [csvFile, setCSVFile] = useState<File | null>();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isFormReady, setIsFormReady] = React.useState(true);

  /**
   * onDrop handler method for uploading CSV file
   */
  const onDrop = (
    acceptedCSVFile: File[],
    audience: AudienceType,
    setFieldValue: SetFieldValueType,
    setTouched: (touched: FormikTouched<ITopicForm>) => void,
    touched: FormikTouched<ITopicForm>,
  ) => {
    if (!acceptedCSVFile[0]) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.topics.createPage.errorUpload"),
      });
      showAlert();
    } else if (acceptedCSVFile[0] && acceptedCSVFile[0].size > 1000000) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.topics.createPage.maxSizeError"),
      });
      showAlert();
    } else {
      const file: File = acceptedCSVFile[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = function() {
        if (reader.result) {
          setUploadingFile(true);
          if (audience === BusinessAudience.ALL_BUSINESSAPPS) {
            AnnouncementsApi.methods.validateShortcode(reader.result.toString()).then(
              res => {
                if (!res.data.validInputs.length) {
                  setAlertProps({
                    type: AlertTypeEnum.ERROR,
                    title: t("pages.announcements.detailPage.rows.validateFile"),
                    content: t("pages.topics.createPage.shortCodesErrMsg"),
                  });
                  showAlert();
                  setUploadingFile(false);
                  return;
                }
                setFieldValue("csvFile", file.name);
                setFieldValue("businessAudience", res.data.validInputs);
                setCSVFile(file);
                setUploadingFile(false);
                setTouched({ ...touched, csvFile: true });
              },
              () => {
                setAlertProps({
                  type: AlertTypeEnum.ERROR,
                  title: t("pages.announcements.detailPage.rows.validateFile"),
                  content: t("pages.announcements.detailPage.rows.failToValidateShortcodes"),
                });
                showAlert();
                setUploadingFile(false);
              },
            );
          } else if (audience === ConsumerAudience.ALL_CONSUMERAPPS) {
            AnnouncementsApi.methods.validateMsisdn(reader.result.toString()).then(
              res => {
                if (!res.data.validInputs.length) {
                  setAlertProps({
                    type: AlertTypeEnum.ERROR,
                    title: t("pages.announcements.detailPage.rows.validateFile"),
                    content: t("pages.topics.createPage.msisdnsErrMsg"),
                  });
                  showAlert();
                  setUploadingFile(false);
                  return;
                }
                setFieldValue("csvFile", file.name);
                setCSVFile(file);
                setFieldValue("consumerAudience", res.data.validInputs);
                setUploadingFile(false);
              },
              () => {
                setAlertProps({
                  type: AlertTypeEnum.ERROR,
                  title: t("pages.announcements.detailPage.rows.validateFile"),
                  content: t("pages.announcements.detailPage.rows.failToValidateShortcodes"),
                });
                showAlert();
                setUploadingFile(false);
              },
            );
          }
        }
      };
    }
  };

  /**
   * handle download the uploaded CSV file
   */
  const handleDownload = () => {
    if (csvFile) {
      const blob = new Blob([csvFile], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = csvFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /**
   * Update service function
   * @param values
   * @param actions
   */
  const createTopic = (values: ITopicForm, actions: FormikHelpers<any>) => {
    console.log(values);
  };

  /** Go back to topic list page */
  const goBack = () => {
    history.goBack();
  };

  /**
   * Toggle option for audience method
   */
  const toggleAudienceOption = (opt: SharedNestedOptionDropdown, setFieldValue: SetFieldValueType) => {
    setFieldValue("audienceApp", opt.key);
    clearAudienceFields(setFieldValue);
  };

  /**
   * Clear audience fields
   * @param {SetFieldValueType} setFieldValue
   */
  const clearAudienceFields = (setFieldValue: SetFieldValueType) => {
    setCSVFile(null);
    setFieldValue("csvFile", "");
  };

  /** render preview of CSV file
   * @param {SetFieldValueType} setFieldValue
   * @returns {JSX.Element}
   */
  const renderCSVFilePreview = (setFieldValue: SetFieldValueType) => (
    <FilePreview>
      <DropzoneInsideDiv>
        <IconDiv isDrag={true}>
          <UploadCSVFileIcon />
        </IconDiv>
        <FileNameText>{csvFile?.name}</FileNameText>
        <Row>
          <IconActionContainer onClick={handleDownload}>
            <DownloadFileIcon />
          </IconActionContainer>
          <IconActionContainer onClick={() => clearAudienceFields(setFieldValue)}>
            <TrashIcon />
          </IconActionContainer>
        </Row>
      </DropzoneInsideDiv>
    </FilePreview>
  );

  /**
   * Handle upload and preview of CSV file
   * @param {ITopicForm} values
   * @param {FormikErrors<ITopicForm>} errors
   * @param {FormikTouched<ITopicForm>} touched
   * @param {SetFieldValueType} setFieldValue
   * @param {(FormikTouched<ITopicForm>) => void} setTouched
   * @returns {JSX.Element}
   */
  const handleRenderUploadAndPreviewCSVFile = (
    values: ITopicForm,
    errors: FormikErrors<ITopicForm>,
    touched: FormikTouched<ITopicForm>,
    setFieldValue: SetFieldValueType,
    setTouched: (touched: FormikTouched<ITopicForm>) => void,
  ) => {
    if (uploadingFile) {
      return (
        <div style={{ margin: 50 }}>
          <LoadingComponent />
        </div>
      );
    }
    if (csvFile) {
      return renderCSVFilePreview(setFieldValue);
    }

    return (
      <DropzoneCSVBackground
        inputName="upload-csv-file"
        onDrop={(file: File[]) => onDrop(file, values.audienceApp, setFieldValue, setTouched, touched)}
        accept={".csv"}
        multiple={false}
        dropText={t("pages.topics.createPage.dropTxt")}
        releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
        error={!!errors.csvFile && touched.csvFile}
        maxSizeText="Max 1MB"
      />
    );
  };

  const initialValues = {
    name: "",
    description: "",
    audienceApp: BusinessAudience.ALL_BUSINESSAPPS,
    csvFile: "",
  };

  return (
    <>
      {!isFormReady ? (
        <div>
          <LoadingComponent />
        </div>
      ) : (
        <PageContainer>
          <SecondaryPageTitle
            title={t("pages.topics.createPage.title")}
            goBackFn={() => history.goBack()}
            displayInitialsCircle={false}
          />
          <PageContent>
            <Formik
              onSubmit={createTopic}
              initialValues={initialValues}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={getValidationSchema()}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue, handleSubmit, errors, touched, setTouched }) => (
                <MainContent className="main-content">
                  <FormContainer>
                    <FormSection
                      title={t("pages.topics.createPage.subtitle")}
                      isEditing={true}
                      rows={[
                        {
                          label: (
                            <DropzoneTitle mandatory={true}>{t("pages.topics.createPage.nameLabel")}</DropzoneTitle>
                          ),
                          displayComponent: <NonEditableText>{values.name}</NonEditableText>,
                          editingComponent: (
                            <TextInput
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={() => setTouched({ ...touched, name: true })}
                              error={touched.name ? errors.name : ""}
                              placeholder={t("pages.topics.createPage.namePlaceholder")}
                              required
                            />
                          ),
                        },
                        {
                          label: (
                            <DropzoneTitle mandatory={true}>
                              {t("pages.topics.createPage.descriptionLabel")}
                            </DropzoneTitle>
                          ),
                          displayComponent: <NonEditableText>{values.description}</NonEditableText>,
                          editingComponent: (
                            <TextInput
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={() => setTouched({ ...touched, description: true })}
                              error={touched.description ? errors.description : ""}
                              placeholder={t("pages.topics.createPage.descriptionPlaceholder")}
                              required
                            />
                          ),
                        },
                        {
                          label: <DropzoneTitle mandatory={true}>Apps</DropzoneTitle>,
                          displayComponent: t(`pages.announcements.audienceEnum.${values.audienceApp}`),
                          editingComponent: (
                            <MultipleOptionsDropdown
                              dropdownType={DropdownType.RECTANGULAR_NORMAL}
                              label={
                                <span style={{ color: safaricomPalette.black }}>
                                  {t(`pages.announcements.audienceEnum.${values.audienceApp}`)}
                                </span>
                              }
                              hasValue={false}
                              options={AnnouncementsUtils.getAudienceOptions([] as AudienceType[], false, true)}
                              toggleOption={(opt: SharedNestedOptionDropdown) =>
                                toggleAudienceOption(opt, setFieldValue)
                              }
                              isOptionSelected={(opt: SharedDropdownOption) =>
                                AnnouncementsUtils.isOptionSelected(
                                  [values.audienceApp] as AudienceType[],
                                  opt.key,
                                  false,
                                  true,
                                )
                              }
                              clearAllFilters={() => null}
                              showClearAllFilters={false}
                              error={errors.audienceApp ? t("commons.mandatoryField") : undefined}
                            />
                          ),
                        },
                        {
                          label: (
                            <DropzoneTitle mandatory={true}>{t("pages.topics.createPage.fileLabel")}</DropzoneTitle>
                          ),
                          displayComponent: renderCSVFilePreview(setFieldValue),
                          editingComponent: handleRenderUploadAndPreviewCSVFile(
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setTouched,
                          ),
                        },
                      ]}
                    />
                    <FormActions>
                      <PrimaryButton
                        titleLabel={t("pages.topics.createPage.cancelBtn")}
                        type="button"
                        redTheme={false}
                        style={{ marginRight: "1rem" }}
                        onClick={() => goBack()}
                      />
                      <PrimaryButton
                        id="create-new-topic-button"
                        type="submit"
                        titleLabel={t("pages.topics.createPage.createBtn")}
                        onClick={() => handleSubmit()}
                        redTheme
                        safaricom
                      />
                    </FormActions>
                  </FormContainer>
                </MainContent>
              )}
            </Formik>
          </PageContent>
        </PageContainer>
      )}
    </>
  );
};

export default CreateTopicPage;

const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 70px 48px 0 118px;
  height: 100%;
  overflow: auto;
  width: 100%;
  > div {
    flex-shrink: 0;
  }
  @media (max-width: 1024px) {
    padding: 70px 1rem 0 40px;
  }
`;
const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 70%;
  flex: 1;
  > div {
    :first-child {
      > div > div {
        :first-child {
          max-width: 30%;
        }
        :last-child {
          max-width: 70%;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const FormActions = styled("div")`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  width: auto;
  button {
    width: auto;
  }
  > div {
    width: 0;
  }
`;

const FilePreview = styled("div")`
  height: 116px;
  border: 2px dashed ${styleTheme.palette.aluminium};
  border-radius: 6px;
  outline: none;
  display: flex;
  margin-bottom: 10px;
`;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Vodafone Rg;
`;

const IconDiv = styled("div")<{ isDrag: boolean }>`
  margin: 10px;
  display: flex;
  align-items: center;
  > svg {
    width: 55px;
    height: 55px;
    > path {
      stroke: ${props => (props.isDrag ? props.theme.palette.darkGrey : props.theme.palette.midGrey)};
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    margin: 0.1rem;
    > svg {
      width: 40px;
      height: 40px;
    }
  }
`;

const FileNameText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: #333333;
  text-overflow: ellipsis;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0.3rem;
  @media (max-width: 1024px) {
    margin-right: 0.1rem;
    font-size: 18px;
  }
`;

const Row = styled("div")`
  display: flex;
`;

const IconActionContainer = styled("div")`
  cursor: pointer;
`;
