import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  TabChild,
  Tabs,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { ConfigContext } from "../../../app.component";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { IConfig } from "../../../app.component";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { VersionControlButtonsContainer } from "../../../shared/responsive-ui.styled";
import { Column, LoadingText, PageContainer, Row } from "../../../shared/shared.styled";
import BuyAirtimePage from "./components/buy-airtime.component";
import LastVersionModalPage from "./components/last-version-modal.component";
import PublishChangesModalPage from "./components/publish-changes-modal.component";
import SendMoneyPage from "./components/send-money.component";
import NetworksApi from "./networks.api";
import { INetworkItem, INetworkUpdateItem, NetworksSectionEnum, NetworksStatusEnum } from "./networks.model";
import { NetworksActions } from "./networks.store";
import { BackOfficeMarketsEnums } from "../../../shared/shared.enums";
import { isEnabledOnMarket } from "../../../shared/shared.utils";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";

export const getStatus = (currentStatus: NetworksStatusEnum) => {
  switch (currentStatus) {
    case NetworksStatusEnum.LIVE:
    case NetworksStatusEnum.ENABLE:
    case NetworksStatusEnum.EDIT:
      return true;
    case NetworksStatusEnum.DISABLE:
    case NetworksStatusEnum.DISABLED:
      return false;
  }
};

const getBreadcrumbs = (t: TFunction, market?: BackOfficeMarketsEnums): Breadcrumb[] => {
  return [
    {
      label: t("pages.documents.configuration"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label:
        market === BackOfficeMarketsEnums.MZ ? t("pages.configuration.wallets") : t("pages.configuration.networks"),
      url: "",
    },
  ];
};

const NetworksPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const query = new URLSearchParams(location.search);
  const [tab, setTab] = React.useState(Number(query.get("tabIdx")) || 0);
  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [approvalLoading, setApprovalLoading] = React.useState(false);
  const [revertLoading, setRevertLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, , setAlertProps] = useAlert();

  const saveChanges = (network: INetworkItem, value: boolean) => {
    const refreshValue = {
      networkType: network.networkType,
      sectionType: network.sectionType,
      status: value ? NetworksStatusEnum.LIVE : NetworksStatusEnum.DISABLED,
      id: network.id,
    } as INetworkUpdateItem;

    NetworksApi.methods.editNetworks(refreshValue).then(
      () => {
        setAlertProps({
          title: t("pages.networks.editSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        hideConfirmationModal();
        if (network.sectionType === NetworksSectionEnum.PAY_PERSON) {
          getSendMoneyNetworks();
        } else {
          getBuyAirtimeNetworks();
        }
      },
      () => {
        setAlertProps({
          title: t("pages.networks.editError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        if (network.sectionType === NetworksSectionEnum.PAY_PERSON) {
          getSendMoneyNetworks();
        } else {
          getBuyAirtimeNetworks();
        }
      },
    );
  };

  const publishChanges = () => {
    setApprovalLoading(true);
    NetworksApi.methods
      .publishNetworks(tab === 0 ? "sendMoney" : "buyAirtime")
      .then(
        () => {
          setAlertProps({
            title: t("pages.networks.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          hidePublishModal();
          if (tab === 0) {
            getSendMoneyNetworks();
            getLastSendMoneyNetworks();
          } else {
            getBuyAirtimeNetworks();
            getLastBuyAirtimeNetworks();
          }
        },
        () => {
          setAlertProps({
            title: t("pages.networks.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          hidePublishModal();
          if (tab === 0) {
            getSendMoneyNetworks();
          } else {
            getBuyAirtimeNetworks();
          }
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setApprovalLoading(false);
      });
  };

  //TODO: change with BE integration

  const { networksSM, networksBA, lastVersionSM, lastVersionBA } = useSelector(
    (state: IStoreInterface) => state.networksReducer,
    shallowEqual,
  );

  const [showLastVersionModal, hideLastVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 480, padding: 0 } } as any,
    <LastVersionModalPage
      onCancelClick={() => hideLastVersionModal()}
      networkList={Number(tab) === 0 ? lastVersionSM.networks : lastVersionBA.networks}
    />,
  );

  const [showPublishModal, hidePublishModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 824, padding: 0 } } as any,
    <PublishChangesModalPage
      onCancelClick={() => hidePublishModal()}
      onPublishClick={publishChanges}
      isLoading={approvalLoading}
      networkList={Number(tab) === 0 ? networksSM.networks : networksBA.networks}
      lastNetworkList={Number(tab) === 0 ? lastVersionSM.networks : lastVersionBA.networks}
    />,
  );

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  const revertChangesHandler = () => {
    setConfirmationProps({
      title: t("pages.networks.confirmRevert"),
      description: t("pages.networks.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => revertChanges(),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  //TODO: remove this "workarround"
  const revertChanges = () => {
    setRevertLoading(true);
    if (Number(tab) === 0) {
      NetworksApi.methods
        .revertNetworks("sendMoney")
        .then(
          () => {
            setAlertProps({
              title: t("pages.networks.revertSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            getSendMoneyNetworks();
          },
          () => {
            setAlertProps({
              title: t("pages.networks.revertError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .finally(() => setRevertLoading(false));
    } else {
      NetworksApi.methods
        .revertNetworks("buyAirtime")
        .then(
          () => {
            setAlertProps({
              title: t("pages.networks.revertSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            getBuyAirtimeNetworks();
          },
          () => {
            setAlertProps({
              title: t("pages.networks.revertError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .finally(() => setRevertLoading(false));
    }
    hideConfirmationModal();
  };

  const getSendMoneyNetworks = () => {
    NetworksApi.methods.getNetworks("sendMoney").then(
      res => {
        setIsLoading(true);
        dispatch(NetworksActions.creators.getNetworksSMSuccess(res.data));
        setIsLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.networks.errorLoadingNetworks"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      },
    );
  };

  const getBuyAirtimeNetworks = () => {
    NetworksApi.methods.getNetworks("buyAirtime").then(
      res => {
        setIsLoading(true);
        dispatch(NetworksActions.creators.getNetworksBASuccess(res.data));
        setIsLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.networks.errorLoadingNetworks"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      },
    );
  };

  const getLastSendMoneyNetworks = () => {
    NetworksApi.methods.getLastVersion("sendMoney").then(
      res => {
        setIsLoading(true);
        dispatch(NetworksActions.creators.getNetworksSMLastVersion(res.data));
        setIsLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.networks.errorLoadingNetworks"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      },
    );
  };

  const getLastBuyAirtimeNetworks = () => {
    NetworksApi.methods.getLastVersion("buyAirtime").then(
      res => {
        setIsLoading(true);
        dispatch(NetworksActions.creators.getNetworksBALastVersion(res.data));
        setIsLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.networks.errorLoadingNetworks"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      },
    );
  };

  React.useEffect(() => {
    getSendMoneyNetworks();
    getBuyAirtimeNetworks();
    getLastSendMoneyNetworks();
    getLastBuyAirtimeNetworks();
    return () => {};
  }, []);

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t, (config as IConfig).market)}
        title={
          isEnabledOnMarket(config, BackOfficeMarketsEnums.MZ)
            ? t("pages.configuration.wallets")
            : t("pages.networks.title")
        }
        navigateFunction={history.push}
        separatorStyle={{ display: "none" }}
        goBackFn={() => window.history.back()}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          <>
            <ButtonContainer style={{ position: "relative", top: "-17px" }}>
              <PrimaryButton
                id={"publish-changes"}
                disabled={
                  Number(tab) === 0
                    ? !networksSM.unpublishedChanges || lastVersionLoading
                    : !networksBA.unpublishedChanges || lastVersionLoading
                }
                titleLabel={t("pages.networks.publishChanges")}
                type="submit"
                redTheme={true}
                style={{ width: "fit-content" }}
                onClick={() => {
                  setLastVersionLoading(true);
                  showPublishModal();
                }}
              />
            </ButtonContainer>
          </>
        }
      />
      {!networksSM.networks || !networksBA.networks ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : null}
      {networksSM.networks && networksBA.networks ? (
        <>
          <VersionControlOperationsContainer>
            <Column style={{ flexDirection: "row" }}>
              {(Number(tab) === 0 && networksSM.previousVersion && networksSM.unpublishedChanges) ||
              (Number(tab) === 1 && networksBA.previousVersion && networksBA.unpublishedChanges) ? (
                <VersionControlButtonsContainer>
                  <div id={"check-last-version"}>
                    <FastActionButton
                      label={t("pages.networks.checkLastVersion")}
                      onClick={() => showLastVersionModal()}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    />
                  </div>

                  <div id={"revert-to-last-version"}>
                    <FastActionButton
                      label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                      onClick={() => {
                        revertChangesHandler();
                      }}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </VersionControlButtonsContainer>
              ) : null}
            </Column>
          </VersionControlOperationsContainer>
          <Row style={{ marginTop: "-27px" }}>
            {isEnabledOnMarket(config, BackOfficeMarketsEnums.MZ) ? (
              <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab} isResponsive={true}>
                <TabChild label={t("pages.networks.sendMoney")}>
                  {!isLoading && <SendMoneyPage networkList={networksSM.networks} saveChanges={saveChanges} />}
                </TabChild>
              </Tabs>
            ) : (
              <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab} isResponsive={true}>
                <TabChild label={t("pages.networks.sendMoney")}>
                  {!isLoading && <SendMoneyPage networkList={networksSM.networks} saveChanges={saveChanges} />}
                </TabChild>
                <TabChild label={t("pages.networks.buyAirtime")}>
                  {!isLoading && <BuyAirtimePage networkList={networksBA.networks} saveChanges={saveChanges} />}
                </TabChild>
              </Tabs>
            )}
          </Row>
        </>
      ) : null}
    </PageContainer>
  );
};

export default NetworksPage;

const VersionControlOperationsContainer = styled("div")`
  margin-left: auto;
  align-self: flex-end;
  z-index: 1;
  height: 27px;
  @media (max-width: 768px) {
    position: relative;
    top: 48px;
    margin-left: 0;
    align-self: flex-start;
  }
`;
const Container = styled(PageContainer)`
  padding: 50px 82px 20px 119px;
`;
const ButtonContainer = styled("div")`
  width: fit-content;
`;
export const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
