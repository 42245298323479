import { useTranslation } from "react-i18next";

/**
 * Referral Analytics Page Configuration Data
 * @returns {}
 */
export const ReferralAnalyticsPageConfigs = () => {
  const [t] = useTranslation();
  const PAGE_SECTIONS = [
    t("pages.referralCampaigns.analytics.invites"),
    t("pages.referralCampaigns.analytics.rewards"),
  ];
  const PAGE_LIST_ROWS = 20;
  const INITIAL_PAGE = 1;
  const INITIAL_SECTION = 0;
  const SECTION_COLUMN = {
    invites: [
      {
        formKey: "receiverMsisdn",
        label: t("pages.referralCampaigns.analytics.invitesSection.receiver"),
        isEditable: false,
      },
      {
        formKey: "firstLogin",
        label: t("pages.referralCampaigns.analytics.invitesSection.firstLogin"),
        isEditable: false,
      },
      {
        formKey: "senderMsisdn",
        label: t("pages.referralCampaigns.analytics.invitesSection.invitedBy"),
        isEditable: false,
      },
    ],
    rewards: [
      {
        formKey: "msisdn",
        label: t("pages.referralCampaigns.analytics.rewardsSection.phoneNumber"),
        isEditable: false,
      },
      {
        formKey: "rewardType",
        label: t("pages.referralCampaigns.analytics.rewardsSection.rewardType"),
        isEditable: false,
      },
      {
        formKey: "rewardDate",
        label: t("pages.referralCampaigns.analytics.rewardsSection.date2Reward"),
        isEditable: false,
      },
    ],
  };

  return {
    PAGE_SECTIONS,
    PAGE_LIST_ROWS,
    INITIAL_PAGE,
    INITIAL_SECTION,
    SECTION_COLUMN,
  };
};
