import { produce } from "immer";
import i18next from "i18next";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import {
  EFieldStatus,
  IClientDocSubmissionDetailsResponse,
  IDocValidationDetailsImagesReducerInterface,
  IDocValidationDetailsReducerInterface,
} from "./doc-validation-details.interface";
import { getDocSubmissionStatus } from "../ekyc.shared.utils";
import { ClientDocSubmissionStatusEnum, ClientDocSubmissionStatusEnumKeys } from "../ekyc.shared.enums";
import { IClientDocStatusHistoryEntry } from "./doc-status-history.model";

export const DocValidationDetailsActions = {
  types: {
    FETCH_DOC_DETAILS_SUCCESS: "FETCH_DOC_DETAILS_SUCCESS",
    FETCH_DOC_DETAILS_IMAGES_SUCCESS: "FETCH_DOC_DETAILS_IMAGES_SUCCESS",
  },
  creators: {
    fetchDocDetailsSuccessAction: (detailsResponse: IClientDocSubmissionDetailsResponse) => ({
      type: DocValidationDetailsActions.types.FETCH_DOC_DETAILS_SUCCESS,
      payload: {
        detailsResponse,
      },
    }),
    fetchDocDetailsImagesSuccessAction: (imagesResponse: IDocValidationDetailsImagesReducerInterface[]) => ({
      type: DocValidationDetailsActions.types.FETCH_DOC_DETAILS_IMAGES_SUCCESS,
      payload: {
        imagesResponse,
      },
    }),
  },
};

const validationDetailsState: IDocValidationDetailsReducerInterface = {
  remoteId: "",
  requestId: "",
  fullName: "",
  dateOfBirth: {
    status: EFieldStatus.REVIEW,
    value: 0,
  },
  documentType: "",
  idNumber: {
    status: EFieldStatus.REVIEW,
    value: 0,
  },
  nationality: {
    status: EFieldStatus.REVIEW,
    value: 0,
  },
  phoneNumber: "",
  requestTime: 0,
  status: getDocSubmissionStatus(ClientDocSubmissionStatusEnum.UNKNOWN) as ClientDocSubmissionStatusEnumKeys,
  reason: undefined,
  expirationDate: undefined,
  userLanguage: "",
  numberOfIdNumberDuplications: 0,
  numberOfPhoneNumberDuplications: 0,
  statusHistory: [],
  duplicateFaces: null,
  duplicateDocuments: null,
  g2Error: "",
};

const validationDetailsImagesState: IDocValidationDetailsImagesReducerInterface[] = [];

export const docValidationDetailsReducer = produce(
  (draft: IDocValidationDetailsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocValidationDetailsActions.types.FETCH_DOC_DETAILS_SUCCESS:
        const detailsResponse: IClientDocSubmissionDetailsResponse = action.payload.detailsResponse;
        draft.remoteId = detailsResponse.id;
        draft.requestId = detailsResponse.requestId;
        draft.fullName = `${detailsResponse.firstName.value} ${detailsResponse.lastName.value}`;
        draft.firstName = detailsResponse.firstName;
        draft.lastName = detailsResponse.lastName;
        draft.dateOfBirth = detailsResponse.dateOfBirth;
        draft.placeOfBirth = detailsResponse.placeOfBirth;
        draft.gender = detailsResponse.gender || { status: EFieldStatus.REVIEW, value: "unknownValue" };
        draft.documentType = detailsResponse.documentType || "unknownValue";
        draft.idNumber = detailsResponse.documentNumber;
        draft.nationality = detailsResponse.nationality
          ? {
              ...detailsResponse.nationality,
              value: i18next.exists(`market_values:nationalities.${detailsResponse.nationality.value}`)
                ? String(i18next.t(`market_values:nationalities.${detailsResponse.nationality.value}`))
                : String(i18next.t("commons.unknownValue")),
            }
          : {
              status: EFieldStatus.REVIEW,
              value: String(i18next.t("commons.unknownValue")),
            };
        draft.addressCommune = detailsResponse.commune;
        draft.addressStreet = detailsResponse.street;
        draft.addressProvince = detailsResponse.province;
        draft.addressTown = detailsResponse.town;
        draft.phoneNumber = detailsResponse.msisdn;
        draft.requestTime = detailsResponse.creationDate;
        draft.status = getDocSubmissionStatus(detailsResponse.status) as ClientDocSubmissionStatusEnumKeys;
        console.log(draft.status);
        draft.lastStatusTime = detailsResponse.updateStatusDate;
        draft.reason = detailsResponse.comment || "-";
        draft.expirationDate = detailsResponse.expirationDate;
        draft.userLanguage = detailsResponse.language;
        draft.numberOfIdNumberDuplications =
          detailsResponse.numberOfIdNumberDuplications > 0 ? detailsResponse.numberOfIdNumberDuplications : 0;
        draft.numberOfPhoneNumberDuplications =
          detailsResponse.numberOfPhoneNumberDuplications > 0 ? detailsResponse.numberOfPhoneNumberDuplications : 0;
        draft.lastSeenBy = detailsResponse.lastGetUser;
        draft.lastSeenTime = detailsResponse.lastGetTime;
        draft.penultSeenBy = detailsResponse.penultGetUser;
        draft.penultSeenTime = detailsResponse.penultGetTime;
        draft.g2Error = detailsResponse.g2ErrorComment || "";

        if (detailsResponse.registrationHistories) {
          draft.statusHistory = [];
          for (let i = 0; i < detailsResponse.registrationHistories.length; i++) {
            const entry = detailsResponse.registrationHistories[i];
            const newEntry: IClientDocStatusHistoryEntry = {
              username: entry.operator || "-",
              status: getDocSubmissionStatus(entry.status || "") as ClientDocSubmissionStatusEnumKeys,
              statusDate: entry.statusDate || 0,
            };

            draft.statusHistory.push(newEntry);
          }

          draft.statusHistory.sort((a, b) => {
            let result;
            if (a.statusDate === b.statusDate) {
              result = 0;
            } else {
              result = a.statusDate < b.statusDate ? 1 : -1;
            }
            return result;
          });
        }

        draft.duplicateFaces = detailsResponse.duplicateFaces || null;

        draft.duplicateDocuments = detailsResponse.duplicateDocuments || null;

        return draft;
      default:
        return draft;
    }
  },
  validationDetailsState,
);

export const docValidationDetailsImagesReducer = produce(
  (draft: IDocValidationDetailsImagesReducerInterface[], action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocValidationDetailsActions.types.FETCH_DOC_DETAILS_IMAGES_SUCCESS:
        draft = action.payload.imagesResponse;
        return draft;
      default:
        return draft;
    }
  },
  validationDetailsImagesState,
);
