import { ClientDocBulkStatusEnum, ClientDocSubmissionStatusEnum } from "./ekyc.shared.enums";

export const getDocSubmissionStatus = (rawDocSubmissionStatus: string): string => {
  switch (rawDocSubmissionStatus) {
    case ClientDocSubmissionStatusEnum.PENDING:
      return "PENDING";
    case ClientDocSubmissionStatusEnum.ACCEPTED:
      return "ACCEPTED";
    case ClientDocSubmissionStatusEnum.REJECTED:
      return "REJECTED";
    case ClientDocSubmissionStatusEnum.ERROR_ON_CRITICAL_FIELDS:
      return "ERROR_ON_CRITICAL_FIELDS";
    case ClientDocSubmissionStatusEnum.ERROR_ON_ID_VS_SELFIE:
      return "ERROR_ON_ID_VS_SELFIE";
    case ClientDocSubmissionStatusEnum.ERROR_ON_FACE_DUPLICATIONS:
      return "ERROR_ON_FACE_DUPLICATIONS";
    case ClientDocSubmissionStatusEnum.ERROR_ON_ID_DUPLICATIONS:
      return "ERROR_ON_ID_DUPLICATIONS";
    case ClientDocSubmissionStatusEnum.ERROR_ON_G2:
      return "ERROR_ON_G2";
    case ClientDocSubmissionStatusEnum.PENDING_MANUAL_REVIEW:
      return "PENDING_MANUAL_REVIEW";
    default:
      return "UNKNOWN";
  }
};

export const getDocBulkStatus = (rawDocBulkStatus: string): string => {
  switch (rawDocBulkStatus) {
    case ClientDocBulkStatusEnum.PENDING_SUBMISSION:
      return "PENDING_SUBMISSION";
    case ClientDocBulkStatusEnum.SUBMITTED_ON_G2:
      return "SUBMITTED_ON_G2";
    case ClientDocBulkStatusEnum.COMPLETE:
      return "COMPLETE";
    default:
      return "UNKNOWN";
  }
};

export const getFileExtension = (filename: string) => {
  return filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2);
};

export const reduceImageSize = (img: any, fileType: string, maxBytes: number): Promise<any> => {
  let reducePercentage = 0;
  let data = _reducePercentage(img, 1, fileType);
  const imgSizeBytes = dataURLToBytes(data);

  if (imgSizeBytes > maxBytes) {
    reducePercentage = 0.9;
  } else {
    return Promise.resolve(dataURItoBlob(data));
  }

  const promise = new Promise((resolve, reject) => {
    data = _reducePercentage(img, reducePercentage, fileType);
    let byteSize = dataURLToBytes(data);

    // Check size and reduce by percentage if necessary, until size is small enough
    if (byteSize > maxBytes) {
      const tmpImgBuffer = new Image();
      tmpImgBuffer.src = data;
      tmpImgBuffer.onload = function() {
        data = _reducePercentage(tmpImgBuffer, reducePercentage, fileType);
        byteSize = dataURLToBytes(data);

        if (byteSize > maxBytes) {
          tmpImgBuffer.src = data;
          return;
        }

        resolve(dataURItoBlob(data));
      };
    } else {
      resolve(dataURItoBlob(data));
    }
  });

  return promise;
};

/**
 * Reduce the size (by a percentage) of an image by redrawing it to a blank canvas.
 * @param {Image} img
 * @param reducePercentage
 * @param {String} fileType
 * @returns {string} The data URL of the downsized image.
 * @private
 */
function _reducePercentage(img: any, reducePercentage: number, fileType: string) {
  const newWidth = img.width * reducePercentage;
  const newHeight = img.height * reducePercentage;

  const canvas = _renderToCanvas(newWidth, newHeight, function(ctx: any) {
    ctx.clearRect(0, 0, newWidth, newHeight);
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
  });
  return canvas.toDataURL(fileType, 0.9);
}

/**
 * Creates a canvas element and applies the render function to its 2d context.
 * @param {Number} width
 * @param {Number} height
 * @param {Function} renderFn
 * @returns {Element} The canvas element
 * @private
 */
function _renderToCanvas(width: number, height: number, renderFn: Function) {
  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  renderFn(canvas.getContext("2d"));
  return canvas;
}

function dataURLToBytes(data: any) {
  return data.length - 24;
}

function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
