import { AutoComplete, Calendar, CalendarIcon, Dropdown, FormSection, TextInput } from "@wit/mpesa-ui-components";
import React, { useEffect } from "react";
import { INotification, SelectAudienceByTypes, SetFieldValueType } from "../push-notifications.modal";
import { FormikErrors } from "formik";
import { DropzoneTitle } from "../../safaricom-service-manager/service-details/pages/service-manager-service-details.styled";
import moment from "moment";
import { AnnouncementsUtils } from "../../announcements/announcements.utils";
import { CalendarSection, DropdownLabelContainer, IconLabelContainer } from "../../announcements/announcements.styled";
import styled from "styled-components";
import { CalendarHeader } from "../../../shared/shared.styled";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { useTranslation } from "react-i18next";

interface IProps {
  values: INotification;
  notificationValues: INotification;
  errors: FormikErrors<INotification>;
  isEditing: boolean;
  setFieldValue: SetFieldValueType;
  onChangeValues: (value: INotification) => void;
  setIsNotValidStartDate: (isNotValidStartDate: boolean) => void;
  isNotValidStartDate: boolean;
}

/**
 * Renders notification campaign fields
 * @returns {React.Component}
 */
const CampaignFormSection = ({
  values,
  notificationValues,
  errors,
  isEditing,
  setFieldValue,
  onChangeValues,
  setIsNotValidStartDate,
  isNotValidStartDate,
}: IProps) => {
  const [t] = useTranslation();

  /**
   *  This function check if the dates and times are valid taking in consideration the minimum time difference
   * @param setFieldValue
   * @param values
   * @param startDate
   * @param endDate
   */
  const validateDatesAndTimes = (values: INotification, startDate?: string) => {
    startDate = startDate ? startDate : values.startDate;

    if (moment(startDate).isSameOrBefore(new Date(), "seconds") && values.startTime) {
      setIsNotValidStartDate(true);
    } else {
      setIsNotValidStartDate(false);
    }
  };

  useEffect(() => {
    onChangeValues({ ...notificationValues, isNotValidStartDate });
  }, [isNotValidStartDate]);

  /**
   * Start date change handler method
   * @param date start date
   */
  const startDateChangeHandler = (date: moment.Moment) => {
    let dateString: string = date.format("YYYY-MM-DD[T]HH:mm:ssZ").toString();
    let isValid: boolean = false;
    const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
    if (values.startTime && regex.test(values.startTime)) {
      [dateString, isValid] = AnnouncementsUtils.addTimeToDate(dateString, values.startTime);
    }

    if (!isValid) {
      onChangeValues({ ...values, startDate: dateString });
    } else {
      setFieldValue("startDate", dateString);
      onChangeValues({ ...values, startDate: dateString });
    }

    setIsNotValidStartDate(!!(values.startTime && !isValid));
    validateDatesAndTimes(values, dateString);
  };

  /**
   * Start time change handler method
   * @param startTime time value to validate
   */
  const startTimeChangeHandler = (startTime: string) => {
    setFieldValue("startTime", startTime);
    onChangeValues({ ...values, startTime });

    if (startTime.slice(-2) === "am" || startTime.slice(-2) === "pm") {
      const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
      if (regex.test(startTime)) {
        const [date, isValid] = AnnouncementsUtils.addTimeToDate(
          values.startDate ? values.startDate : moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
          startTime,
        );
        if (isValid) {
          setFieldValue("startTime", startTime);
          onChangeValues({ ...values, startTime });
          validateDatesAndTimes(values, date);
        } else {
          setIsNotValidStartDate(true);
        }
      }
    }
  };

  /**
   * Renders campaign details fields
   * @param {INotification} values Notification form values
   * @param {SetFieldValueType} setFieldValue
   * @param {FormikErrors<INotification>} errors
   */
  const campaignDetailsRows = (
    values: INotification,
    setFieldValue: SetFieldValueType,
    errors: FormikErrors<INotification>,
  ) => {
    return [
      {
        label: <DropzoneTitle mandatory>{t("pages.announcements.detailPage.rows.name")}</DropzoneTitle>,
        displayComponent: notificationValues.campaignName,
        editingComponent: (
          <TextInput
            name="name"
            value={values.campaignName}
            placeholder={t("pages.announcements.detailPage.rows.placeholderName")}
            required={true}
            onChange={e => {
              const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
              setFieldValue("campaignName", sanitizedValue);
              onChangeValues({ ...values, campaignName: sanitizedValue });
            }}
            maxLength={56}
            error={errors.campaignName}
          />
        ),
      },
      {
        label: <DropzoneTitle mandatory={true}>{t("pages.announcements.detailPage.rows.startDate")}</DropzoneTitle>,
        displayComponent: (
          <DivDisplayStartDate>
            <DivContent>
              {t("pages.announcements.detailPage.rows.displayStartDate", {
                date: notificationValues.startDate ? moment(notificationValues.startDate).format("DD MMM YYYY") : null,
                time: notificationValues.startTime
                  ? notificationValues.startTime
                  : moment(notificationValues.startDate).format("hh:mm a"),
              })}
            </DivContent>
          </DivDisplayStartDate>
        ),
        editingComponent: (
          <DivDateComponents>
            <DivCalendar style={{ position: "relative" }}>
              {values.sendImmediately && values.selectAudienceBy !== SelectAudienceByTypes.CSV_FILE_UPLOAD && (
                <OverlayCalendar />
              )}
              <Dropdown
                hasValue={false}
                label={
                  <DropdownLabelContainer>
                    <IconLabelContainer>
                      <CalendarIcon />
                    </IconLabelContainer>{" "}
                    {values.startDate && !values.sendImmediately ? (
                      <CalendarHeader>{moment(values.startDate).format("DD/MM/YYYY")}</CalendarHeader>
                    ) : (
                      <span>{"DD/MM/YYYY"}</span>
                    )}
                  </DropdownLabelContainer>
                }
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                error={errors.startDate ? t("commons.mandatoryField") : undefined}
              >
                {
                  <CalendarSection>
                    <Calendar selectDate={startDateChangeHandler} />
                  </CalendarSection>
                }
              </Dropdown>
            </DivCalendar>
            {
              <DivTime style={{ position: "relative" }}>
                {values.sendImmediately && values.selectAudienceBy !== SelectAudienceByTypes.CSV_FILE_UPLOAD && (
                  <OverlayCalendar />
                )}
                <AutoComplete
                  value={values.startTime && !values.sendImmediately ? values.startTime : ""}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderTime")}
                  options={AnnouncementsUtils.timeOptions()}
                  maxLength={8}
                  selectOption={startTimeChangeHandler}
                  disabled={values.sendImmediately && values.selectAudienceBy !== SelectAudienceByTypes.CSV_FILE_UPLOAD}
                  error={isNotValidStartDate ? t("pages.announcements.detailPage.rows.errorSelectTime") : undefined}
                />
              </DivTime>
            }
          </DivDateComponents>
        ),
      },
    ];
  };
  return (
    <FormSection
      title="Campaign Details"
      rows={campaignDetailsRows(values, setFieldValue, errors)}
      isEditing={isEditing}
    />
  );
};

export default CampaignFormSection;

const DivDateComponents = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
`;

const DivCalendar = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const DivTime = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const DivDisplayStartDate = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DivContent = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #333;
`;

const OverlayCalendar = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  z-index: 1;
  opacity: 0.2;
  border: 1px solid #000;
  border-radius: 5px;
`;
