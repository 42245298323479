import { ExternalServicePermitionsEnum } from "../../../../shared/models/service-manager.model";

export enum PermissionsRoleEnum {
  PIN = "PIN",
  IDENTITY = "IDENTITY",
}

export interface ISingleExternalServiceRequest {
  externalServiceId: string;
  url: string;
  permissions: PermissionsRoleEnum[];
  rsaPublicKey?: string;
}

export interface ICreateExternalServiceRequest {
  externalService: Array<ISingleExternalServiceRequest>;
}

export interface IUpdateExternalServiceRequest {
  externalServiceId: string;
  url: string;
  permissions: ExternalServicePermitionsEnum[];
  rsaPublicKey?: string;
}

export interface IUpdateExternalServiceResponse {
  externalServiceId: string;
  url: string;
  permissions: ExternalServicePermitionsEnum[];
}
