import {
  AutoComplete,
  Calendar,
  CalendarIcon,
  CancelIcon,
  Dropdown,
  Exclamationicon,
  FormSection,
  MultipleOptionsDropdown,
  PlusIcon,
  StatusChip,
  TextArea,
  TextInput,
  Toggle,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { safaricomPalette } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { StatusEnum } from "@wit/mpesa-ui-components/lib/enums/status.enum";
import { Formik, FormikErrors, getIn } from "formik";
import i18next from "i18next";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { boolean, object, string } from "yup";
import { IStoreInterface } from "../../../configs/store.config";
import { SafaricomTheme } from "../../../safaricom.theme";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import TargetFileIcon from "../../../shared/icons/target-file.icon";
import { FEATURES } from "../../../shared/renderer.utils";
import { CalendarHeader, IconContainer } from "../../../shared/shared.styled";
import { formatBytes } from "../../../shared/shared.utils";
import AnnouncementsApi from "../announcements.api";
import {
  AnnouncementFormSteps,
  AppPlacement,
  AudienceType,
  BusinessAudience,
  BusinessType,
  CallToActionType,
  ConsumerAudience,
  GsmProfile,
  IAnnouncement,
  ICallToActionInterface,
  IStoryInterface,
} from "../announcements.model";
import {
  AudienceSelection,
  CalendarSection,
  DescriptionText,
  DescriptionTextContainer,
  DropdownLabelContainer,
  IconLabelContainer,
  InformativeText,
  LabelForm,
  SpanForm,
  TextButton,
} from "../announcements.styled";
import { AnnouncementsUtils } from "../announcements.utils";
import TargetAudienceDrawer from "./target-audience-drawer.component";

interface IAnnouncementsStepCampaignDetailsProps {
  announcement: IAnnouncement;
  setAnnouncement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
  isEditing: boolean;
  onSubmitForm: (values?: IAnnouncement) => void;
  onReady: React.Dispatch<React.SetStateAction<boolean>>;
  detailPage?: boolean;
  showUpdateDate?: boolean;
  showUpdateDateForEndDate?: boolean;
  setEditingAppVersion?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingHomescreenCard?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAnnouncementSavedData?: IAnnouncement;
}

/**
 * Component to manage the first section of create/editing announcement
 * @param announcement is the information of the announcement
 * @param setAnnouncement is to set the changes made in the form
 * @param fileBusinessInfoCSV save the information about the target uploaded
 * @param fileConsumerInfoCSV save the information about the target uploaded
 * @param fileBusinessCSV save the information about the target uploaded
 * @param fileConsumerCSV save the information about the target uploaded
 * @param handleFileChange save the information about the target uploaded
 * @param onSubmitForm function to execute after the submitting the form
 * @param onReady function that check if is possible to continue to the next step
 * @param detailPage is to hide the title of the announcement
 */
const AnnouncementsStepCampaignDetails = ({
  announcement,
  setAnnouncement,
  isEditing,
  onSubmitForm,
  onReady,
  detailPage = false,
  showUpdateDate,
  showUpdateDateForEndDate,
  setEditingAppVersion,
  setEditingHomescreenCard,
  selectedAnnouncementSavedData,
}: IAnnouncementsStepCampaignDetailsProps) => {
  const [t] = useTranslation();

  const [showThumbnail, setShowThumbnail] = React.useState<boolean>(false);
  const [showThumbnailForEndDate, setShowThumbnailForEndDate] = React.useState<boolean>(false);
  const [isNotValidStartDate, setIsNotValidStartDate] = React.useState<boolean>(false);
  const [isNotValidEndDate, setIsNotValidEndDate] = React.useState<boolean>(false);
  const [showTargetAudienceDrawer, setShowTargetAudienceDrawer] = React.useState<boolean>(false);
  const [uploadBusinessAgent, setUploadBusinessAgent] = React.useState<boolean>(
    (announcement.targetBusinessAgent && announcement.targetBusinessAgent.length > 0) ||
      (announcement.fileInfoBusinessAgent &&
        announcement.fileInfoBusinessAgent.invalidTargets &&
        announcement.fileInfoBusinessAgent.invalidTargets.length > 0)
      ? true
      : false,
  );
  const [uploadBusinessMerchant, setUploadBusinessMerchant] = React.useState<boolean>(
    (announcement.targetBusinessMerchant && announcement.targetBusinessMerchant.length > 0) ||
      (announcement.fileInfoBusinessMerchant &&
        announcement.fileInfoBusinessMerchant.invalidTargets &&
        announcement.fileInfoBusinessMerchant.invalidTargets.length > 0)
      ? true
      : false,
  );
  const [uploadConsumer, setUploadConsumer] = React.useState<boolean>(
    (announcement.targetConsumer && announcement.targetConsumer.length > 0) ||
      (announcement.fileInfoConsumer?.invalidTargets && announcement.fileInfoConsumer.invalidTargets.length > 0)
      ? true
      : false,
  );
  const [validTargets, setValidTargets] = React.useState<string[]>([]);
  const [invalidTargets, setInvalidTargets] = React.useState<string[]>([]);
  const [allTargets, setAllTargets] = React.useState<string[]>([]);

  const MAX_FILE_SIZE = 100000000;
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [loadingFileBusinessAgent, setLoadingFileBusinessAgent] = React.useState<boolean>(false);
  const [loadingFileBusinessMerchant, setLoadingFileBusinessMerchant] = React.useState<boolean>(false);
  const [loadingFileConsumer, setLoadingFileConsumer] = React.useState<boolean>(false);
  const refConsumer = useRef(null);
  const refBusinessAgent = useRef(null);
  const refBusinessMerchant = useRef(null);
  const audienceAvailable: AudienceType = useSelector(
    (state: IStoreInterface) => state.announcementsReducer.audienceAvailable,
  );

  const businessAgentEnabled = useCheckFeatureAvailable(FEATURES.BUSINESS_AUDIENCE_AGENT);
  const automaticSuggestedEnabled = useCheckFeatureAvailable(FEATURES.AUTOMATIC_SUGGESTED_ANNOUNCEMENTS);
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);
  const profileTypesEnabled = useCheckFeatureAvailable(FEATURES.GSM_PROFILE);
  const [audienceClicked, setAudienceClicked] = React.useState<AudienceType>(
    consumerChildEnabled ? ConsumerAudience.ALL_CONSUMERAPPS : ConsumerAudience.CONSUMERAPP,
  );
  const [audienceSelected, setAudienceSelected] = React.useState<AudienceType | undefined>(
    consumerChildEnabled ? ConsumerAudience.CONSUMERAPP_ADULT : ConsumerAudience.CONSUMERAPP,
  );

  /**
   * Validation function to form fields
   */
  const validateStepOne = () => {
    if (profileTypesEnabled) {
      return object().shape({
        campaignName: string()
          .required(i18next.t("commons.mandatoryField"))
          .max(56, i18next.t("pages.announcements.errors.maxCampaignName")),
        startDate: string().required(i18next.t("commons.mandatoryField")),
        time: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/, i18next.t("commons.invalidTime")),
        endDate: string().required(i18next.t("commons.mandatoryField")),
        endTime: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/, i18next.t("commons.invalidTime")),

        audience: string().required(i18next.t("commons.mandatoryField")),
        appPlacement: string().when("audience", {
          is: audience => AnnouncementsUtils.isConsumerAudience(audience),
          then: string().required(i18next.t("commons.mandatoryField")),
          otherwise: string().nullable(),
        }),
        profileTypes: string().when("audience", {
          is: audience => AnnouncementsUtils.isConsumerAudience(audience),
          then: string().required(i18next.t("commons.mandatoryField")),
          otherwise: string().nullable(),
        }),
        sendPushNotifications: boolean().notRequired(),
        pushNotificationDetails: object().when("sendPushNotifications", {
          is: sendPushNotifications => sendPushNotifications === true,
          then: object().shape({
            title: string()
              .typeError(i18next.t("commons.fieldMustBeOfType", { fieldName: "title", fieldType: "string" }))
              .max(52, i18next.t("pages.announcements.errors.maxCharactersError", { limitOfCharacters: 52 }))
              .required(i18next.t("commons.mandatoryField")),
            description: string()
              .typeError(i18next.t("commons.fieldMustBeOfType", { fieldName: "description", fieldType: "string" }))
              .max(491, i18next.t("pages.announcements.errors.maxCharactersError", { limitOfCharacters: 491 }))
              .required(i18next.t("commons.mandatoryField")),
          }),
          otherwise: object().nullable(true),
        }),
      });
    } else {
      return object().shape({
        campaignName: string()
          .required(i18next.t("commons.mandatoryField"))
          .max(56, i18next.t("pages.announcements.errors.maxCampaignName")),
        startDate: string().required(i18next.t("commons.mandatoryField")),
        time: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/, i18next.t("commons.invalidTime")),
        endDate: string().required(i18next.t("commons.mandatoryField")),
        endTime: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/, i18next.t("commons.invalidTime")),

        audience: string().required(i18next.t("commons.mandatoryField")),
        sendPushNotifications: boolean().notRequired(),
        pushNotificationDetails: object().when("sendPushNotifications", {
          is: sendPushNotifications => sendPushNotifications === true,
          then: object().shape({
            title: string()
              .typeError(i18next.t("commons.fieldMustBeOfType", { fieldName: "title", fieldType: "string" }))
              .max(52, i18next.t("pages.announcements.errors.maxCharactersError", { limitOfCharacters: 52 }))
              .required(i18next.t("commons.mandatoryField")),
            description: string()
              .typeError(i18next.t("commons.fieldMustBeOfType", { fieldName: "description", fieldType: "string" }))
              .max(491, i18next.t("pages.announcements.errors.maxCharactersError", { limitOfCharacters: 491 }))
              .required(i18next.t("commons.mandatoryField")),
          }),
          otherwise: object().nullable(true),
        }),
      });
    }
  };

  /**
   * Function to upload the files csv for targets in audience
   * @param e event handler
   * @param aud audience selected
   * @param setFieldValue function to change the information in the model data
   */
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    aud: AudienceType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    businessAudience?: BusinessType,
  ) => {
    if (!e.target.files) {
      return;
    }
    const file: File = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.csvError"),
        content: t("pages.announcements.detailPage.rows.csvMaxSizeError"),
      });
      showAlert();
    } else {
      if (file.name.match(/.(csv)$/i)) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = function() {
          if (reader.result) {
            if (aud === BusinessAudience.BUSINESSAPP_AGENT) {
              setLoadingFileBusinessAgent(true);
              AnnouncementsApi.methods
                .validateShortcode(reader.result.toString())
                .then(
                  res => {
                    if ((res.data.validInputs as string[]).length > 0) {
                      setFieldValue("targetBusinessAgent", res.data.validInputs);
                    }
                    setFieldValue("fileInfoBusinessAgent", {
                      name: file.name,
                      size: file.size,
                      invalidTargets: res.data.invalidInputs,
                      allTargets: reader.result
                        ? reader.result
                            .toString()
                            .replace(/[\r\n]+/g, ",")
                            .replace(/[\s]+/g, "")
                            .split(",")
                            .filter(input => input.length > 0)
                        : "",
                    });
                    setUploadBusinessAgent(true);
                  },
                  () => {
                    setUploadBusinessAgent(false);
                    setAlertProps({
                      type: AlertTypeEnum.ERROR,
                      title: t("pages.announcements.detailPage.rows.validateFile"),
                      content: t("pages.announcements.detailPage.rows.failToValidateShortcodes"),
                    });
                    showAlert();
                  },
                )
                .finally(() => {
                  setLoadingFileBusinessAgent(false);
                });
            } else if (aud === BusinessAudience.BUSINESSAPP_MERCHANT) {
              setLoadingFileBusinessMerchant(true);
              AnnouncementsApi.methods
                .validateShortcode(reader.result.toString())
                .then(
                  res => {
                    if ((res.data.validInputs as string[]).length > 0) {
                      setFieldValue("targetBusinessMerchant", res.data.validInputs);
                    }
                    setFieldValue("fileInfoBusinessMerchant", {
                      name: file.name,
                      size: file.size,
                      invalidTargets: res.data.invalidInputs,
                      allTargets: reader.result
                        ? reader.result
                            .toString()
                            .replace(/[\r\n]+/g, ",")
                            .replace(/[\s]+/g, "")
                            .split(",")
                            .filter(input => input.length > 0)
                        : "",
                    });
                    setUploadBusinessMerchant(true);
                  },
                  () => {
                    setUploadBusinessMerchant(false);
                    setAlertProps({
                      type: AlertTypeEnum.ERROR,
                      title: t("pages.announcements.detailPage.rows.validateFile"),
                      content: t("pages.announcements.detailPage.rows.failToValidateShortcodes"),
                    });
                    showAlert();
                  },
                )
                .finally(() => {
                  setLoadingFileBusinessMerchant(false);
                });
            } else {
              setLoadingFileConsumer(true);

              AnnouncementsApi.methods
                .validateMsisdn(reader.result.toString())
                .then(
                  res => {
                    if ((res.data.validInputs as string[]).length > 0) {
                      setFieldValue("targetConsumer", res.data.validInputs);
                    }
                    setFieldValue("fileInfoConsumer", {
                      name: file.name,
                      size: file.size,
                      invalidTargets: res.data.invalidInputs,
                      allTargets: reader.result
                        ? reader.result
                            .toString()
                            .replace(/[\r\n]+/g, ",")
                            .replace(/[\s]+/g, "")
                            .split(",")
                            .filter(input => input.length > 0)
                        : "",
                    });
                    setUploadConsumer(true);
                  },
                  () => {
                    setUploadConsumer(false);
                    setAlertProps({
                      type: AlertTypeEnum.ERROR,
                      title: t("pages.announcements.detailPage.rows.validateFile"),
                      content: t("pages.announcements.detailPage.rows.failToValidateMsisdns"),
                    });
                    showAlert();
                  },
                )
                .finally(() => {
                  setLoadingFileConsumer(false);
                });
            }
          }
        };
      } else {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.csvError"),
        });
        showAlert();
      }
    }
  };

  /**
   * function to set edit mode on App version screen
   * @returns
   */
  const setEditModeOnAppVersions = () => {
    if (setEditingAppVersion) {
      setEditingAppVersion(
        selectedAnnouncementSavedData && selectedAnnouncementSavedData.targetVersions?.length === 0 ? true : false,
      );
    }
  };

  /**
   * function to set edit mode on Homescreen Card
   * @returns
   */
  const setEditModeOnHomeScreen = () => {
    if (setEditingHomescreenCard) {
      setEditingHomescreenCard(
        selectedAnnouncementSavedData && !selectedAnnouncementSavedData.icon && !selectedAnnouncementSavedData.color
          ? true
          : false,
      );
    }
  };

  /**
   * function that will open the drawer for select target
   * @param announcement info about announcement
   * @param typeDisplay if displaycontent is 0 else 1 in editing mode
   */
  const openDrawerTargets = (announcement: IAnnouncement, typeDisplay: number, audience: AudienceType) => {
    if (
      (announcement.targetBusinessMerchant !== undefined ||
        (announcement.fileInfoBusinessMerchant &&
          announcement.fileInfoBusinessMerchant.invalidTargets &&
          announcement.fileInfoBusinessMerchant.invalidTargets.length > 0)) &&
      audience === BusinessAudience.BUSINESSAPP_MERCHANT
    ) {
      setValidTargets(announcement.targetBusinessMerchant ? announcement.targetBusinessMerchant : []);
      setInvalidTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoBusinessMerchant && announcement.fileInfoBusinessMerchant.invalidTargets
          ? announcement.fileInfoBusinessMerchant.invalidTargets
          : [],
      );
      setAllTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoBusinessMerchant && announcement.fileInfoBusinessMerchant.allTargets
          ? announcement.fileInfoBusinessMerchant.allTargets
          : [],
      );
      setAudienceClicked(BusinessAudience.BUSINESSAPP_MERCHANT);
    }
    if (
      (announcement.targetBusinessAgent !== undefined ||
        (announcement.fileInfoBusinessAgent &&
          announcement.fileInfoBusinessAgent.invalidTargets &&
          announcement.fileInfoBusinessAgent.invalidTargets.length > 0)) &&
      audience === BusinessAudience.BUSINESSAPP_AGENT
    ) {
      setValidTargets(announcement.targetBusinessAgent ? announcement.targetBusinessAgent : []);
      setInvalidTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoBusinessAgent && announcement.fileInfoBusinessAgent.invalidTargets
          ? announcement.fileInfoBusinessAgent.invalidTargets
          : [],
      );
      setAllTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoBusinessAgent && announcement.fileInfoBusinessAgent.allTargets
          ? announcement.fileInfoBusinessAgent.allTargets
          : [],
      );
      setAudienceClicked(BusinessAudience.BUSINESSAPP_AGENT);
    }
    if (
      (announcement.targetConsumer !== undefined ||
        (announcement.fileInfoConsumer?.invalidTargets && announcement.fileInfoConsumer.invalidTargets.length > 0)) &&
      audience === ConsumerAudience.CONSUMERAPP
    ) {
      setValidTargets(announcement.targetConsumer ? announcement.targetConsumer : []);
      setInvalidTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoConsumer && announcement.fileInfoConsumer.invalidTargets
          ? announcement.fileInfoConsumer.invalidTargets
          : [],
      );
      setAllTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoConsumer && announcement.fileInfoConsumer.allTargets
          ? announcement.fileInfoConsumer.allTargets
          : [],
      );
      setAudienceClicked(ConsumerAudience.CONSUMERAPP);
    }
    if (
      consumerChildEnabled &&
      (announcement.targetConsumer !== undefined ||
        (announcement.fileInfoConsumer?.invalidTargets && announcement.fileInfoConsumer.invalidTargets.length > 0)) &&
      audience === ConsumerAudience.ALL_CONSUMERAPPS
    ) {
      setValidTargets(announcement.targetConsumer ? announcement.targetConsumer : []);
      setInvalidTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoConsumer && announcement.fileInfoConsumer.invalidTargets
          ? announcement.fileInfoConsumer.invalidTargets
          : [],
      );
      setAllTargets(
        typeDisplay === 0
          ? []
          : announcement.fileInfoConsumer && announcement.fileInfoConsumer.allTargets
          ? announcement.fileInfoConsumer.allTargets
          : [],
      );
      setAudienceClicked(ConsumerAudience.ALL_CONSUMERAPPS);
    }

    setAudienceSelected(announcement.audience ? announcement.audience : undefined);
    setShowTargetAudienceDrawer(true);
  };

  /** onchange audience check target audience */
  const onChangeAudienceStoriesHandling = (
    values: IAnnouncement,
    audience: AudienceType,
    setFieldValue: (field: string, value: IStoryInterface[] | null, shouldValidate?: boolean | undefined) => void,
  ) => {
    const stories: IStoryInterface[] = values.stories.map(s => ({ ...s }));

    stories.forEach((story, index) => {
      if (
        story.callAction &&
        story.callToAction.action.type === CallToActionType.FEATURE &&
        story.callToAction.action.resource
      ) {
        stories[index].callAction = false;
        stories[index].callToAction = { action: {} } as ICallToActionInterface;
      }
    });

    setFieldValue("stories", stories);
  };

  /**
   *  This function check if the dates and times are valid taking in consideration the minimum time difference
   * @param setFieldValue
   * @param values
   * @param startDate
   * @param endDate
   */
  const validateDatesAndTimes = (values: IAnnouncement, startDate: string | undefined, endDate: string | undefined) => {
    startDate = startDate ? startDate : values.startDate;
    endDate = endDate ? endDate : values.endDate;
    const dateDifferenceInMinutes = moment.duration(moment(endDate).diff(moment(startDate))).asMinutes();
    if (dateDifferenceInMinutes < 1 && values.endDate) {
      setIsNotValidEndDate(true);
      onReady(false);
    } else {
      setIsNotValidEndDate(false);
    }
    if (moment(startDate).isSameOrBefore(new Date(), "seconds") && values.time) {
      setIsNotValidStartDate(true);
      onReady(false);
    } else {
      setIsNotValidStartDate(false);
    }
  };

  /**
   * method to set start date and set a default end date if is not defined yet
   */
  const setStartDate = (
    setFieldValue: (key: string, value: any, shouldValidate?: boolean) => void,
    startDate: string,
    values: IAnnouncement,
  ) => {
    setFieldValue("startDate", startDate);
    if (!values.endDate) {
      //set default endDate
      const endDateInitialValue = moment(startDate)
        .add(1, "days")
        .format("YYYY-MM-DD[T]HH:mm:ssZ");

      setFieldValue("endDate", endDateInitialValue);
    }
  };

  /**
   * to return the fields to the first section of the form
   * @param values are the values of the announcement to change
   * @param errors  are to use when in validation the fields are with errors
   * @param setFieldValue function used to update values
   */
  const getCampaignDetails = (
    values: IAnnouncement,
    errors: FormikErrors<IAnnouncement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ): FormSectionRow[] => {
    return [
      {
        label: t("pages.announcements.detailPage.rows.name"),
        displayComponent: announcement.campaignName,
        required: true,
        editingComponent: (
          <TextInput
            name="name"
            value={values.campaignName}
            placeholder={t("pages.announcements.detailPage.rows.placeholderName")}
            required={true}
            onChange={e => {
              setFieldValue("campaignName", e.target.value);
              onChangeValues({ ...values, campaignName: e.target.value });
            }}
            maxLength={56}
            error={errors.campaignName}
          />
        ),
      },
      {
        label: t("pages.announcements.detailPage.rows.startDate"),
        displayComponent: (
          <DivDisplayStartDate>
            <DivContent>
              {t("pages.announcements.detailPage.rows.displayStartDate", {
                date: announcement.startDate ? moment(announcement.startDate).format("DD MMM YYYY") : null,
                time: announcement.time ? announcement.time : moment(announcement.startDate).format("hh:mm a"),
              })}
            </DivContent>
            {!showUpdateDate ? null : (
              <DivBadgeExclamation>
                {showThumbnail ? (
                  <>
                    <Arrow />
                    <DivThumbnail>{t("pages.announcements.detailPage.rows.thumbnailStartDate")}</DivThumbnail>
                  </>
                ) : null}
                <Exclamationicon
                  onMouseEnter={() => {
                    setShowThumbnail(true);
                  }}
                  onMouseOut={() => {
                    setShowThumbnail(false);
                  }}
                />
              </DivBadgeExclamation>
            )}
          </DivDisplayStartDate>
        ),
        required: true,
        editingComponent: (
          <DivDateComponents>
            <DivCalendar>
              <Dropdown
                hasValue={false}
                label={
                  <DropdownLabelContainer>
                    <IconLabelContainer>
                      <CalendarIcon />
                    </IconLabelContainer>{" "}
                    {values.startDate ? (
                      <CalendarHeader>{moment(values.startDate).format("DD/MM/YYYY")}</CalendarHeader>
                    ) : (
                      <span>{"DD/MM/YYYY"}</span>
                    )}
                  </DropdownLabelContainer>
                }
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                error={errors.startDate ? t("commons.mandatoryField") : undefined}
              >
                {
                  <CalendarSection>
                    <Calendar
                      selectDate={(date: moment.Moment) => {
                        let dateString: string = date.format("YYYY-MM-DD[T]HH:mm:ssZ").toString();
                        let isValid: boolean = false;
                        const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                        if (values.time && regex.test(values.time)) {
                          [dateString, isValid] = AnnouncementsUtils.addTimeToDate(dateString, values.time);
                        }
                        setStartDate(setFieldValue, dateString, values);
                        onChangeValues({ ...values, startDate: dateString });
                        setIsNotValidStartDate(!!(values.time && !isValid));
                        validateDatesAndTimes(values, dateString, undefined);
                      }}
                    />
                  </CalendarSection>
                }
              </Dropdown>
            </DivCalendar>
            {
              <DivTime>
                <AutoComplete
                  value={values.time ? values.time : ""}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderTime")}
                  options={AnnouncementsUtils.timeOptions()}
                  maxLength={8}
                  selectOption={time => {
                    setFieldValue("time", time);
                    if (time.slice(-2) === "am" || time.slice(-2) === "pm") {
                      const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                      if (regex.test(time)) {
                        const [date, isValid] = AnnouncementsUtils.addTimeToDate(
                          values.startDate ? values.startDate : moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
                          time,
                        );
                        if (isValid) {
                          setStartDate(setFieldValue, date, values);
                          setFieldValue("time", time);
                          onChangeValues({ ...values, time });
                          validateDatesAndTimes(values, date, undefined);
                        } else {
                          setIsNotValidStartDate(true);
                          onReady(false);
                        }
                      }
                    }
                  }}
                  error={isNotValidStartDate ? t("pages.announcements.detailPage.rows.errorSelectTime") : undefined}
                />
              </DivTime>
            }
          </DivDateComponents>
        ),
      },
      {
        label: t("pages.announcements.detailPage.rows.endDate"),
        displayComponent: (
          <DivDisplayStartDate>
            <DivContent>
              {t("pages.announcements.detailPage.rows.displayStartDate", {
                date: announcement.endDate ? moment(announcement.endDate).format("DD MMM YYYY") : null,
                time: announcement.endTime ? announcement.endTime : moment(announcement.endDate).format("hh:mm a"),
              })}
            </DivContent>
            {!showUpdateDateForEndDate ? null : (
              <DivBadgeExclamation>
                {showThumbnailForEndDate ? (
                  <>
                    <Arrow />
                    <DivThumbnail>{t("pages.announcements.detailPage.rows.thumbnailEndDate")}</DivThumbnail>
                  </>
                ) : null}
                <Exclamationicon
                  onMouseEnter={() => {
                    setShowThumbnailForEndDate(true);
                  }}
                  onMouseOut={() => {
                    setShowThumbnailForEndDate(false);
                  }}
                />
              </DivBadgeExclamation>
            )}
          </DivDisplayStartDate>
        ),
        required: true,
        editingComponent: (
          <DivDateComponents>
            <DivCalendar>
              <Dropdown
                hasValue={false}
                label={
                  <DropdownLabelContainer>
                    <IconLabelContainer>
                      <CalendarIcon />
                    </IconLabelContainer>{" "}
                    {values.endDate ? (
                      <CalendarHeader>{moment(values.endDate).format("DD/MM/YYYY")}</CalendarHeader>
                    ) : (
                      <span>{"DD/MM/YYYY"}</span>
                    )}
                  </DropdownLabelContainer>
                }
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
              >
                {
                  <CalendarSection>
                    <Calendar
                      selectDate={(date: moment.Moment) => {
                        let dateString: string = date.format("YYYY-MM-DD[T]HH:mm:ssZ").toString();
                        const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                        if (values.endTime && regex.test(values.endTime)) {
                          [dateString] = AnnouncementsUtils.addTimeToDate(dateString, values.endTime);
                        }
                        setFieldValue("endDate", dateString);
                        onChangeValues({ ...values, endDate: dateString });
                        validateDatesAndTimes(values, values.startDate, dateString);
                      }}
                    />
                  </CalendarSection>
                }
              </Dropdown>
            </DivCalendar>
            {
              <DivTime>
                <AutoComplete
                  value={values.endTime ? values.endTime : ""}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderTime")}
                  options={AnnouncementsUtils.timeOptions()}
                  maxLength={8}
                  selectOption={time => {
                    setFieldValue("endTime", time);
                    if (time.slice(-2) === "am" || time.slice(-2) === "pm") {
                      const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                      if (regex.test(time)) {
                        const [date, isValid] = AnnouncementsUtils.addTimeToDate(
                          values.endDate ? values.endDate : moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
                          time,
                        );

                        if (isValid) {
                          setFieldValue("endDate", date);
                          setFieldValue("endTime", time);
                          onChangeValues({ ...values, endTime: time });
                          validateDatesAndTimes(values, values.startDate, date);
                        } else {
                          setIsNotValidEndDate(true);
                        }
                      }
                    }
                  }}
                  error={
                    errors.endTime
                      ? errors.endTime
                      : isNotValidEndDate
                      ? t("pages.announcements.detailPage.rows.errorSelectEndTime")
                      : undefined
                  }
                />
              </DivTime>
            }
          </DivDateComponents>
        ),
      },
    ];
  };

  /**
   * method to reset values when audience is changed
   */
  const resetValuesOnChangingAudience = (setFieldValue: (key: string, value: any) => void) => {
    setFieldValue("targetVersions", []);
    setFieldValue("icon", "");
    setFieldValue("title", "");
    setFieldValue("thumbnailImage", "");
    setFieldValue("smallDescription", "");
    setFieldValue("color", "");
    setFieldValue("sendPushNotifications", false);
    setFieldValue("suggested", false);
    setFieldValue("targetConsumer", []);
    setFieldValue("fileInfoConsumer", null);
    setFieldValue("targetBusinessAgent", []);
    setFieldValue("fileInfoBusinessAgent", null);
    setFieldValue("targetBusinessMerchant", []);
    setFieldValue("fileInfoBusinessMerchant", null);

    setUploadConsumer(false);
    setUploadBusinessAgent(false);
    setUploadBusinessMerchant(false);

    if (setEditingAppVersion) {
      setEditingAppVersion(true);
    }
    if (setEditingHomescreenCard) {
      setEditingHomescreenCard(true);
    }
  };

  /**
   * Toggle option for audience method
   */
  const toggleAudienceOption = (
    opt: SharedNestedOptionDropdown,
    values: IAnnouncement,
    setFieldValue: (key: string, value: any) => void,
  ) => {
    let chosenOpt = opt.key;
    if (
      opt.key === BusinessAudience.ALL_BUSINESSAPPS ||
      opt.key === ConsumerAudience.ALL_CONSUMERAPPS ||
      (!businessAgentEnabled && AnnouncementsUtils.isBusinessAudience(opt.key)) ||
      (!consumerChildEnabled && AnnouncementsUtils.isConsumerAudience(opt.key)) ||
      opt.key === ConsumerAudience.CONSUMERAPP
    ) {
      if (AnnouncementsUtils.isBusinessAudience(opt.key) && !businessAgentEnabled) {
        chosenOpt = BusinessAudience.BUSINESSAPP_MERCHANT;
      } else if (AnnouncementsUtils.isConsumerAudience(opt.key) && !consumerChildEnabled) {
        chosenOpt = ConsumerAudience.CONSUMERAPP;
      }

      setFieldValue("audience", chosenOpt);
      onChangeValues({ ...values, audience: chosenOpt });

      if (AnnouncementsUtils.isConsumerAudience(opt.key)) {
        setFieldValue("dismissible", false);
        if (opt.key === ConsumerAudience.CONSUMERAPP_CHILD) {
          setFieldValue("suggested", false);
        }
        if (profileTypesEnabled) {
          setFieldValue("profileTypes", Object.values(GsmProfile));
          setFieldValue("appPlacement", undefined);
          onChangeValues({
            ...values,
            profileTypes: Object.values(GsmProfile),
            appPlacement: undefined,
            audience: chosenOpt,
          });
        }
      } else if (automaticSuggestedEnabled) {
        setFieldValue("suggested", false);
      }
    } else if (consumerChildEnabled && AnnouncementsUtils.isConsumerAudience(opt.key)) {
      if (values.audience === ConsumerAudience.ALL_CONSUMERAPPS || values.audience === opt.key) {
        chosenOpt =
          opt.key === ConsumerAudience.CONSUMERAPP_CHILD
            ? ConsumerAudience.CONSUMERAPP_ADULT
            : ConsumerAudience.CONSUMERAPP_CHILD;
      } else if (values.audience !== opt.key) {
        chosenOpt = ConsumerAudience.ALL_CONSUMERAPPS;
      }

      setFieldValue("audience", chosenOpt);
      onChangeValues({ ...values, audience: chosenOpt });
    } else {
      setFieldValue("profileTypes", []);
      setFieldValue("appPlacement", undefined);
      if (values.audience === BusinessAudience.ALL_BUSINESSAPPS || values.audience === opt.key) {
        chosenOpt =
          opt.key === BusinessAudience.BUSINESSAPP_AGENT
            ? BusinessAudience.BUSINESSAPP_MERCHANT
            : BusinessAudience.BUSINESSAPP_AGENT;
      } else if (values.audience !== opt.key) {
        chosenOpt = BusinessAudience.ALL_BUSINESSAPPS;
      }
      setFieldValue("audience", chosenOpt);
      onChangeValues({ ...values, audience: chosenOpt });
    }

    resetValuesOnChangingAudience(setFieldValue);
    onChangeAudienceStoriesHandling(values, chosenOpt, setFieldValue);
  };

  /**
   * Toggles a user profile
   * @param opt
   * @param values
   * @param setFieldValue
   */
  const toggleGsmProfile = (
    opt: SharedDropdownOption,
    values: IAnnouncement,
    setFieldValue: (key: string, value: any) => void,
  ) => {
    const option = opt.key;
    let profiles = values.profileTypes || [];
    if (profiles?.includes(option)) {
      profiles = profiles.filter(p => p !== option);
    } else {
      profiles.push(option);
    }

    setFieldValue("profileTypes", profiles);
    onChangeValues({ ...values, profileTypes: profiles });
  };

  /**
   * Toggles the app placement
   * @param opt
   * @param values
   * @param setFieldValue
   */
  const toggleAppPlacement = (
    opt: SharedDropdownOption,
    values: IAnnouncement,
    setFieldValue: (key: string, value: any) => void,
  ) => {
    const option = opt.key;
    setFieldValue("appPlacement", option);
    onChangeValues({ ...values, appPlacement: option });
  };

  /**
   * Display values of the gsm profile types selected
   * @param profileTypes
   */
  const getProfileTypesValues = (profileTypes: GsmProfile[] | undefined) => {
    let displayStr: string;
    if (profileTypes && profileTypes.length > 0) {
      displayStr = profileTypes.map(p => t(`pages.announcements.gsmProfileEnum.${p}`)).join(", ");
    } else {
      displayStr = t(`pages.announcements.detailPage.rows.profilesSelected`, {
        nProfiles: 0,
      });
    }
    return displayStr;
  };

  /**
   * to return the fields to the second section of the form
   * @param values are the values of the announcement to change
   * @param errors  are to use when in validation the fields are with errors
   * @param setFieldValue function used to update values
   */
  const getAudienceSelection = (
    values: IAnnouncement,
    errors: FormikErrors<IAnnouncement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ): FormSectionRow[] => {
    const audience: FormSectionRow[] = [];
    audience.push({
      label: t("pages.announcements.detailPage.rows.audienceApps"),
      required: true,
      displayComponent: t(`pages.announcements.audienceEnum.${announcement.audience}`),
      editingComponent: !detailPage ? (
        <MultipleOptionNestedDropdown
          dropdownType={DropdownType.RECTANGULAR_NORMAL}
          label={
            values.audience ? (
              <span style={{ color: safaricomPalette.black }}>
                {t(`pages.announcements.audienceEnum.${values.audience}`)}
              </span>
            ) : values.audience === undefined ? (
              t("pages.announcements.detailPage.rows.selectAudience")
            ) : (
              t(`pages.announcements.audienceEnum.${audienceAvailable}`)
            )
          }
          hasValue={false}
          options={AnnouncementsUtils.getAudienceOptions(
            [values.audience] as AudienceType[],
            businessAgentEnabled,
            consumerChildEnabled,
          )}
          toggleOption={(opt: SharedNestedOptionDropdown) => toggleAudienceOption(opt, values, setFieldValue)}
          isOptionSelected={(opt: SharedDropdownOption) =>
            AnnouncementsUtils.isOptionSelected(
              [values.audience] as AudienceType[],
              opt.key,
              businessAgentEnabled,
              consumerChildEnabled,
            )
          }
          clearAllFilters={() => null}
          showClearAllFilters={false}
          error={errors.audience ? t("commons.mandatoryField") : undefined}
        />
      ) : (
        <SpanForm>{t(`pages.announcements.audienceEnum.${announcement.audience}`)}</SpanForm>
      ),
    });

    // GSM profiles are enabled
    if (profileTypesEnabled && values.audience === ConsumerAudience.CONSUMERAPP) {
      audience.push({
        label: t("pages.announcements.detailPage.rows.gsmProfile"),
        required: true,
        displayComponent: <ProfileTypesList>{getProfileTypesValues(values.profileTypes)}</ProfileTypesList>,
        editingComponent: !detailPage ? (
          <MultipleOptionsDropdown
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            label={t(`pages.announcements.detailPage.rows.profilesSelected`, {
              nProfiles: values.profileTypes?.length ?? 0,
            })}
            hasValue={false}
            options={AnnouncementsUtils.getGsmProfileOptions()}
            toggleOption={(opt: SharedDropdownOption) => toggleGsmProfile(opt, values, setFieldValue)}
            isOptionSelected={(opt: SharedDropdownOption) =>
              AnnouncementsUtils.isGsmProfileSelected((values.profileTypes as unknown) as GsmProfile[], opt.key)
            }
            clearAllFilters={() => null}
            showClearAllFilters={false}
            error={errors.profileTypes ? t("commons.mandatoryField") : undefined}
          />
        ) : (
          <SpanForm>{getProfileTypesValues(values.profileTypes)}</SpanForm>
        ),
      });
      audience.push({
        label: t("pages.announcements.detailPage.rows.appPlacement"),
        required: true,
        displayComponent: announcement.appPlacement
          ? t(`pages.announcements.appPlacementEnum.${announcement.appPlacement}`)
          : "n.d.",
        editingComponent: !detailPage ? (
          <MultipleOptionsDropdown
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            label={
              values.appPlacement
                ? t(`pages.announcements.appPlacementEnum.${announcement.appPlacement}`)
                : t(`pages.announcements.detailPage.rows.selectAppPlacement`)
            }
            hasValue={false}
            options={AnnouncementsUtils.getAppPlacementOptions()}
            toggleOption={(opt: SharedDropdownOption) => toggleAppPlacement(opt, values, setFieldValue)}
            isOptionSelected={(opt: SharedDropdownOption) =>
              AnnouncementsUtils.isAppPlacementSelected((values.appPlacement as unknown) as AppPlacement, opt.key)
            }
            clearAllFilters={() => null}
            showClearAllFilters={false}
            error={errors.appPlacement ? t("commons.mandatoryField") : undefined}
          />
        ) : (
          <SpanForm>
            {announcement.appPlacement
              ? t(`pages.announcements.appPlacementEnum.${announcement.appPlacement}`)
              : "n.d."}
          </SpanForm>
        ),
      });
    }

    if (businessAgentEnabled) {
      if (
        values.audience === BusinessAudience.BUSINESSAPP_AGENT ||
        values.audience === BusinessAudience.ALL_BUSINESSAPPS
      ) {
        audience.push({
          label: t("pages.announcements.detailPage.rows.audienceBusinessAgentApps"),
          displayComponent: (
            <UploadFileContainer ref={refBusinessAgent}>
              <>
                {!uploadBusinessAgent
                  ? t("pages.announcements.detailPage.rows.placeholderNoCSV")
                  : `${
                      announcement.fileInfoBusinessAgent
                        ? announcement.fileInfoBusinessAgent.name
                        : t("pages.announcements.detailPage.rows.fileNameToBusiness")
                    }`}
              </>
              {uploadBusinessAgent ? (
                <StatusChipContainer>
                  <StatusChip
                    type={StatusEnum.LIVE}
                    style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                    onClick={() => {
                      openDrawerTargets(announcement, 0, BusinessAudience.BUSINESSAPP_AGENT);
                    }}
                  >
                    {t("pages.announcements.detailPage.rows.validTargets", {
                      numberOfTargets: announcement.targetBusinessAgent ? announcement.targetBusinessAgent.length : 0,
                    })}
                  </StatusChip>
                </StatusChipContainer>
              ) : null}
            </UploadFileContainer>
          ),
          editingComponent: loadingFileBusinessAgent ? (
            <LoadingComponent />
          ) : (
            <UploadFileContainer ref={refBusinessAgent}>
              <TextButton style={{ marginRight: "auto" }}>
                <IconContainer style={{ marginRight: "8px" }} size={20} color={SafaricomTheme.palette.vodafoneRed}>
                  {!uploadBusinessAgent ? <PlusIcon /> : <TargetFileIcon />}
                </IconContainer>
                {!uploadBusinessAgent ? (
                  <div>{t("pages.announcements.detailPage.rows.placeholderCSV")}</div>
                ) : (
                  <>
                    <div>
                      {values.fileInfoBusinessAgent
                        ? values.fileInfoBusinessAgent.name
                        : t("pages.announcements.detailPage.rows.fileNameToBusiness")}
                    </div>
                    <div style={{ color: "#aaa", marginLeft: "5px" }}>
                      {formatBytes(
                        values.fileInfoBusinessAgent
                          ? values.fileInfoBusinessAgent.size
                          : AnnouncementsUtils.getSizeAboutTargets(values.targetBusinessAgent),
                      )}
                    </div>
                  </>
                )}
                {!uploadBusinessAgent ? (
                  <input
                    type="file"
                    accept=".csv"
                    name="targetBusinessAgent"
                    onChange={e =>
                      handleFileChange(e, BusinessAudience.BUSINESSAPP_AGENT, setFieldValue, BusinessType.AGENT)
                    }
                  />
                ) : (
                  <IconContainer
                    size={16}
                    color={SafaricomTheme.palette.white}
                    style={{
                      marginLeft: "8px",
                      backgroundColor: SafaricomTheme.palette.greyLighter,
                      borderRadius: "50%",
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setFieldValue("targetBusinessAgent", []);
                      setFieldValue("fileInfoBusinessAgent", null);
                      setUploadBusinessAgent(false);
                      setShowTargetAudienceDrawer(false);
                    }}
                  >
                    <CancelIcon />
                  </IconContainer>
                )}
              </TextButton>
              {uploadBusinessAgent ? (
                <StatusChipContainer>
                  <StatusChip
                    type={StatusEnum.LIVE}
                    style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                    onClick={() => {
                      openDrawerTargets(values, 1, BusinessAudience.BUSINESSAPP_AGENT);
                    }}
                  >
                    {t("pages.announcements.detailPage.rows.validTargets", {
                      numberOfTargets: values.targetBusinessAgent ? values.targetBusinessAgent.length : 0,
                    })}
                  </StatusChip>
                  <StatusChip
                    type={StatusEnum.DELETE}
                    style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.errorRed }}
                    onClick={() => {
                      openDrawerTargets(values, 1, BusinessAudience.BUSINESSAPP_AGENT);
                    }}
                  >
                    {t("pages.announcements.detailPage.rows.invalidTargets", {
                      numberOfTargets:
                        values.fileInfoBusinessAgent && values.fileInfoBusinessAgent.invalidTargets
                          ? values.fileInfoBusinessAgent.invalidTargets.length
                          : 0,
                    })}
                  </StatusChip>
                </StatusChipContainer>
              ) : null}
            </UploadFileContainer>
          ),
        });
      }
    }
    if (
      values.audience === BusinessAudience.BUSINESSAPP_MERCHANT ||
      values.audience === BusinessAudience.ALL_BUSINESSAPPS
    ) {
      audience.push({
        label: t("pages.announcements.detailPage.rows.audienceBusinessMerchantApps"),
        displayComponent: (
          <UploadFileContainer ref={refBusinessMerchant}>
            <>
              {!uploadBusinessMerchant
                ? t("pages.announcements.detailPage.rows.placeholderNoCSV")
                : `${
                    announcement.fileInfoBusinessMerchant
                      ? announcement.fileInfoBusinessMerchant.name
                      : t("pages.announcements.detailPage.rows.fileNameToBusiness")
                  }`}
            </>
            {uploadBusinessMerchant ? (
              <StatusChipContainer>
                <StatusChip
                  type={StatusEnum.LIVE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                  onClick={() => {
                    openDrawerTargets(announcement, 0, BusinessAudience.BUSINESSAPP_MERCHANT);
                  }}
                >
                  {t("pages.announcements.detailPage.rows.validTargets", {
                    numberOfTargets: announcement.targetBusinessMerchant
                      ? announcement.targetBusinessMerchant.length
                      : 0,
                  })}
                </StatusChip>
              </StatusChipContainer>
            ) : null}
          </UploadFileContainer>
        ),
        editingComponent: loadingFileBusinessMerchant ? (
          <LoadingComponent />
        ) : (
          <UploadFileContainer ref={refBusinessMerchant}>
            <TextButton style={{ marginRight: "auto" }}>
              <IconContainer style={{ marginRight: "8px" }} size={20} color={SafaricomTheme.palette.vodafoneRed}>
                {!uploadBusinessMerchant ? <PlusIcon /> : <TargetFileIcon />}
              </IconContainer>
              {!uploadBusinessMerchant ? (
                <div>{t("pages.announcements.detailPage.rows.placeholderCSV")}</div>
              ) : (
                <>
                  <div>
                    {values.fileInfoBusinessMerchant
                      ? values.fileInfoBusinessMerchant.name
                      : t("pages.announcements.detailPage.rows.fileNameToBusiness")}
                  </div>
                  <div style={{ color: "#aaa", marginLeft: "5px" }}>
                    {formatBytes(
                      values.fileInfoBusinessMerchant
                        ? values.fileInfoBusinessMerchant.size
                        : AnnouncementsUtils.getSizeAboutTargets(values.targetBusinessMerchant),
                    )}
                  </div>
                </>
              )}
              {!uploadBusinessMerchant ? (
                <input
                  type="file"
                  accept=".csv"
                  name="targetBusinessMerchant"
                  onChange={e =>
                    handleFileChange(e, BusinessAudience.BUSINESSAPP_MERCHANT, setFieldValue, BusinessType.MERCHANT)
                  }
                />
              ) : (
                <IconContainer
                  size={16}
                  color={SafaricomTheme.palette.white}
                  style={{
                    marginLeft: "8px",
                    backgroundColor: SafaricomTheme.palette.greyLighter,
                    borderRadius: "50%",
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setFieldValue("targetBusinessMerchant", []);
                    setFieldValue("fileInfoBusinessMerchant", null);
                    setUploadBusinessMerchant(false);
                    setShowTargetAudienceDrawer(false);
                  }}
                >
                  <CancelIcon />
                </IconContainer>
              )}
            </TextButton>
            {uploadBusinessMerchant ? (
              <StatusChipContainer>
                <StatusChip
                  type={StatusEnum.LIVE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                  onClick={() => {
                    openDrawerTargets(values, 1, BusinessAudience.BUSINESSAPP_MERCHANT);
                  }}
                >
                  {t("pages.announcements.detailPage.rows.validTargets", {
                    numberOfTargets: values.targetBusinessMerchant ? values.targetBusinessMerchant.length : 0,
                  })}
                </StatusChip>
                <StatusChip
                  type={StatusEnum.DELETE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.errorRed }}
                  onClick={() => {
                    openDrawerTargets(values, 1, BusinessAudience.BUSINESSAPP_MERCHANT);
                  }}
                >
                  {t("pages.announcements.detailPage.rows.invalidTargets", {
                    numberOfTargets:
                      values.fileInfoBusinessMerchant && values.fileInfoBusinessMerchant.invalidTargets
                        ? values.fileInfoBusinessMerchant.invalidTargets.length
                        : 0,
                  })}
                </StatusChip>
              </StatusChipContainer>
            ) : null}
          </UploadFileContainer>
        ),
      });
    }
    if (
      automaticSuggestedEnabled &&
      (values.audience === ConsumerAudience.CONSUMERAPP_ADULT || values.audience === ConsumerAudience.CONSUMERAPP) &&
      (!detailPage || (announcement && announcement.suggested))
    ) {
      audience.push({
        label: (
          <>
            <ToogleDiv isEditing={!detailPage && !values.sendPushNotifications}>
              <Toggle
                key={`tooglePush${isEditing}`}
                cb={(newValue: boolean) => {
                  setFieldValue("suggested", newValue);
                  onChangeValues(values);
                }}
                isDisabled={detailPage || values.sendPushNotifications}
                initialValue={announcement.suggested}
              />
              <SpanForm>{t("pages.announcements.detailPage.rows.autoSugestedForYou")}</SpanForm>
            </ToogleDiv>
            <InformativeTextContainer>
              <InformativeText>{t("pages.announcements.detailPage.rows.autoSugestedForYouWarning")}</InformativeText>
            </InformativeTextContainer>
          </>
        ),
        displayComponent: <></>,
        editingComponent: <></>,
      });
    }
    if (AnnouncementsUtils.isConsumerAudience(values.audience as AudienceType)) {
      audience.push({
        label: (
          <LabelForm disabled={automaticSuggestedEnabled && values.suggested}>
            {t("pages.announcements.detailPage.rows.audienceConsumerApps")}
          </LabelForm>
        ),
        displayComponent: (
          <UploadFileContainer ref={refConsumer}>
            <>
              {!uploadConsumer
                ? t("pages.announcements.detailPage.rows.placeholderNoCSV")
                : `${
                    announcement.fileInfoConsumer
                      ? announcement.fileInfoConsumer.name
                      : t("pages.announcements.detailPage.rows.fileNameToConsumer")
                  }`}
            </>
            {uploadConsumer ? (
              <StatusChipContainer>
                <StatusChip
                  type={StatusEnum.LIVE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                  onClick={() => {
                    openDrawerTargets(
                      announcement,
                      0,
                      consumerChildEnabled ? ConsumerAudience.ALL_CONSUMERAPPS : ConsumerAudience.CONSUMERAPP,
                    );
                  }}
                >
                  {t("pages.announcements.detailPage.rows.validTargets", {
                    numberOfTargets: announcement.targetConsumer ? announcement.targetConsumer.length : 0,
                  })}
                </StatusChip>
              </StatusChipContainer>
            ) : null}
          </UploadFileContainer>
        ),
        editingComponent: loadingFileConsumer ? (
          <LoadingComponent />
        ) : (
          <UploadFileContainer ref={refConsumer}>
            <TextButton style={{ marginRight: "auto" }}>
              <IconContainer style={{ marginRight: "8px" }} size={20} color={SafaricomTheme.palette.vodafoneRed}>
                {!uploadConsumer ? <PlusIcon /> : <TargetFileIcon />}
              </IconContainer>
              {!uploadConsumer ? (
                <div>
                  <SpanForm disabled={automaticSuggestedEnabled && values.suggested}>
                    {t("pages.announcements.detailPage.rows.placeholderCSV")}
                  </SpanForm>
                </div>
              ) : (
                <>
                  <div>
                    {values.fileInfoConsumer
                      ? values.fileInfoConsumer.name
                      : t("pages.announcements.detailPage.rows.fileNameToConsumer")}
                  </div>
                  <div style={{ color: "#aaa", marginLeft: "5px" }}>
                    {formatBytes(
                      values.fileInfoConsumer
                        ? values.fileInfoConsumer.size
                        : AnnouncementsUtils.getSizeAboutTargets(values.targetConsumer),
                    )}
                  </div>
                </>
              )}
              {!uploadConsumer ? (
                <input
                  disabled={automaticSuggestedEnabled && values.suggested}
                  type="file"
                  accept=".csv"
                  onChange={e =>
                    handleFileChange(
                      e,
                      consumerChildEnabled ? ConsumerAudience.ALL_CONSUMERAPPS : ConsumerAudience.CONSUMERAPP,
                      setFieldValue,
                    )
                  }
                />
              ) : (
                <IconContainer
                  size={16}
                  color={SafaricomTheme.palette.white}
                  style={{
                    marginLeft: "8px",
                    backgroundColor: SafaricomTheme.palette.greyLighter,
                    borderRadius: "50%",
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setFieldValue("targetConsumer", []);
                    setFieldValue("fileInfoConsumer", null);
                    setUploadConsumer(false);
                    setShowTargetAudienceDrawer(false);
                  }}
                >
                  <CancelIcon />
                </IconContainer>
              )}
            </TextButton>
            {uploadConsumer ? (
              <StatusChipContainer>
                <StatusChip
                  type={StatusEnum.LIVE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.vodafoneRed }}
                  onClick={() => {
                    openDrawerTargets(
                      values,
                      1,
                      consumerChildEnabled ? ConsumerAudience.ALL_CONSUMERAPPS : ConsumerAudience.CONSUMERAPP,
                    );
                  }}
                >
                  {t("pages.announcements.detailPage.rows.validTargets", {
                    numberOfTargets: values.targetConsumer ? values.targetConsumer.length : 0,
                  })}
                </StatusChip>
                <StatusChip
                  type={StatusEnum.DELETE}
                  style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.errorRed }}
                  onClick={() => {
                    openDrawerTargets(
                      values,
                      1,
                      consumerChildEnabled ? ConsumerAudience.ALL_CONSUMERAPPS : ConsumerAudience.CONSUMERAPP,
                    );
                  }}
                >
                  {t("pages.announcements.detailPage.rows.invalidTargets", {
                    numberOfTargets:
                      values.fileInfoConsumer && values.fileInfoConsumer.invalidTargets
                        ? values.fileInfoConsumer.invalidTargets.length
                        : 0,
                  })}
                </StatusChip>
              </StatusChipContainer>
            ) : null}
          </UploadFileContainer>
        ),
      });
    }
    audience.push({
      label: (
        <ToogleDiv isEditing={isEditing && (!automaticSuggestedEnabled || !values.suggested)}>
          <Toggle
            key={`tooglePush${isEditing}${values.audience}`}
            cb={(newValue: boolean) => {
              setFieldValue("sendPushNotifications", newValue);
              onChangeValues(values);
              if (newValue) {
                validatePushNotificationTitleAndDescription(values);
              }
            }}
            isDisabled={(automaticSuggestedEnabled && values.suggested) || !isEditing}
            initialValue={values.sendPushNotifications}
          />
          <SpanForm>{t("pages.announcements.detailPage.rows.sendNotification")}</SpanForm>
          {values.sendPushNotifications && (uploadBusinessAgent || uploadBusinessMerchant || uploadConsumer) && (
            <WarningTextContainer>
              <InformativeText>{t("pages.announcements.detailPage.rows.pushNotificationWarning")}</InformativeText>
            </WarningTextContainer>
          )}
        </ToogleDiv>
      ),
      displayComponent: <></>,
      editingComponent: <></>,
    });
    if (values.sendPushNotifications) {
      audience.push(
        {
          label: t("pages.announcements.detailPage.rows.pushNotificationTitle"),
          displayComponent: announcement.pushNotificationDetails?.title,
          required: values.sendPushNotifications,
          editingComponent: (
            <TextInput
              name="pushNotificationTitle"
              value={values.pushNotificationDetails?.title}
              placeholder={t("pages.announcements.detailPage.rows.pushNotificationTitlePlaceholder")}
              required={true}
              onChange={e => {
                setFieldValue("pushNotificationDetails.title", e.target.value);
                onChangeValues(values);
                validatePushNotificationTitleAndDescription(values);
              }}
              maxLength={52}
              error={getIn(errors, "pushNotificationDetails.title")}
              disabled={!values.sendPushNotifications}
            />
          ),
        },
        {
          label: t("pages.announcements.detailPage.rows.pushNotificationDescription"),
          displayComponent: (
            <DescriptionTextContainer>
              <DescriptionText>
                {values.pushNotificationDetails?.description
                  ? values.pushNotificationDetails?.description
                  : t("pages.announcements.addPage.descriptionDefaultTitle")}
              </DescriptionText>
            </DescriptionTextContainer>
          ),
          required: values.sendPushNotifications,
          editingComponent: (
            <TextArea
              placeholder={t("pages.announcements.detailPage.rows.pushNotificationDescriptionPlaceholder")}
              value={values.pushNotificationDetails?.description}
              onChange={e => {
                setFieldValue("pushNotificationDetails.description", e.target.value);
                onChangeValues(values);
                validatePushNotificationTitleAndDescription(values);
              }}
              maxLength={491}
              error={getIn(errors, "pushNotificationDetails.description")}
              disabled={!values.sendPushNotifications}
            />
          ),
        },
      );
    }

    audience.push({
      label: (
        <ToogleDiv
          isEditing={isEditing && !(values.audience && AnnouncementsUtils.isConsumerAudience(values.audience))}
        >
          <Toggle
            key={`toogleDismiss ${isEditing}${values.audience}`}
            cb={(newValue: boolean) => {
              setFieldValue("dismissible", newValue);
            }}
            isDisabled={!isEditing || (values.audience && AnnouncementsUtils.isConsumerAudience(values.audience))}
            initialValue={values.dismissible}
          />
          <SpanForm>{t("pages.announcements.detailPage.rows.dismissible")}</SpanForm>
        </ToogleDiv>
      ),
      displayComponent: <></>,
      editingComponent: <></>,
    });
    return audience;
  };

  /**
   * function that will be call for onSubmit form
   * @param values values of the announcement
   */
  const onSubmitStepOne = (values: IAnnouncement) => {
    if (!isNotValidEndDate && !isNotValidStartDate) {
      setAnnouncement(values);
      onSubmitForm(values);
    }
  };

  /**
   * function that will be called when onChange of the form occurs
   * @param values values of announcement
   */
  const onChangeValues = (values: IAnnouncement) => {
    setAnnouncement(values);
    onReady(
      !!values.campaignName &&
        !!values.startDate &&
        !!values.endDate &&
        !!values.audience &&
        !!values.time &&
        !!values.endTime &&
        (profileTypesEnabled && AnnouncementsUtils.isConsumerAudience(values.audience)
          ? !!values.appPlacement
          : true) &&
        (profileTypesEnabled && AnnouncementsUtils.isConsumerAudience(values.audience) && values.profileTypes
          ? values.profileTypes.length > 0
          : true),
    );
  };

  /**
   * This validates the push notification title and description
   * @param values
   */
  const validatePushNotificationTitleAndDescription = (values: IAnnouncement) => {
    onReady(
      !!values.pushNotificationDetails?.title &&
        !!values.pushNotificationDetails.description &&
        values.pushNotificationDetails.title !== "" &&
        values.pushNotificationDetails.description !== "",
    );
  };

  useEffect(() => {
    onChangeValues(announcement);
    if (!isEditing) {
      (document.getElementById(AnnouncementFormSteps.STEP_ONE) as HTMLFormElement).reset();
    }
  }, []);

  return (
    <>
      {showTargetAudienceDrawer ? (
        <TargetAudienceDrawer
          isVisible={showTargetAudienceDrawer}
          setIsVisible={setShowTargetAudienceDrawer}
          validTargets={validTargets}
          invalidTargets={invalidTargets}
          allTotalTargets={allTargets}
          audience={audienceClicked}
          audienceSelected={audienceSelected}
          refBusiness={refBusinessAgent}
          refConsumer={refConsumer}
        />
      ) : null}
      <MainContent removeMargin={detailPage}>
        <Formik
          initialValues={announcement}
          onSubmit={onSubmitStepOne}
          validationSchema={validateStepOne}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, handleSubmit, resetForm, errors, setValues }) => (
            <form
              id={AnnouncementFormSteps.STEP_ONE}
              method="post"
              onSubmit={handleSubmit}
              onReset={() => {
                setIsNotValidStartDate(false);
                setUploadBusinessAgent(
                  announcement.targetBusinessAgent && announcement.targetBusinessAgent.length > 0 ? true : false,
                );
                setUploadBusinessMerchant(
                  announcement.targetBusinessMerchant && announcement.targetBusinessMerchant.length > 0 ? true : false,
                );
                setUploadConsumer(announcement.targetConsumer && announcement.targetConsumer.length > 0 ? true : false);
                setFieldValue("stories", announcement.stories);

                if (selectedAnnouncementSavedData) {
                  setAnnouncement(selectedAnnouncementSavedData);
                  setValues(selectedAnnouncementSavedData);
                  setEditModeOnAppVersions();
                  setEditModeOnHomeScreen();
                } else {
                  resetForm(announcement);
                }
              }}
            >
              <FormSection
                title={!detailPage ? t("pages.announcements.detailPage.rows.firstTitle") : ""}
                isEditing={isEditing}
                rows={getCampaignDetails(values, errors, setFieldValue)}
              />
              <FormSection
                title={
                  <AudienceSelection>{t("pages.announcements.detailPage.rows.selectionAudience")}</AudienceSelection>
                }
                isEditing={isEditing}
                rows={getAudienceSelection(values, errors, setFieldValue)}
              />
            </form>
          )}
        </Formik>
      </MainContent>
    </>
  );
};

export const MainContent = styled("div")<{
  removeMargin: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-top: ${props => (props.removeMargin ? "-4vh" : "4vh")};
  > form > div > div > div > span {
    :after {
      color: #ff2a58;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ToogleDiv = styled("div")<{ isEditing: boolean }>`
  ${props => (!props.isEditing ? "label{background-color: white;opacity: 0.5;}" : null)};
`;

const DivDateComponents = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
`;

const DivCalendar = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const DivTime = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const DivDisplayStartDate = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DivContent = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #333;
`;

const DivBadgeExclamation = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.1);
  width: 19px;
  height: 19px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  svg {
    width: 14px;
    height: 14px;
    stroke: ${props => props.theme.palette.errorRed};
  }
`;

const DivThumbnail = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  top: -73px;
  width: 120px;
  height: 60px;
  color: white;
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
`;

const Arrow = styled("div")`
  left: 9px;
  position: absolute;
  top: -30px;
  background-color: #333;
  transform: translate(-50%, 50%) rotate(45deg);
  height: 16px;
  width: 16px;
`;

const UploadFileContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
`;

const StatusChipContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  > div {
    cursor: pointer;
  }
`;

const InformativeTextContainer = styled("div")`
  margin-top: 8px;
`;
const WarningTextContainer = styled("div")`
  margin: 16px 0;
  margin-right: -100px;
  padding-left: 1.8rem;
`;

const ProfileTypesList = styled("div")`
  font-size: 16px;
  font-family: "Vodafone Rg";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default AnnouncementsStepCampaignDetails;
