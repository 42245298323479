import { IServiceManagerGroups } from "./../../shared/models/service-manager.model";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import {
  IServiceGroup,
  IServiceManagerService,
  ServiceManagerStatusEnum,
} from "../../shared/models/service-manager.model";
import { IAllowedScope } from "./allowed-scopes/allowed-scopes.model";
import { ServiceManagerFiltersEnum } from "./components/service-manager-filters.component";
import { object, string } from "yup";
import i18next from "i18next";

/**
 * Filters an array of Services using a ServiceStatusEnum[]
 * @param services ServiceManagerService[]
 * @param status ServiceManagerStatusEnum[]
 */
export const filterServiceManagerServicesByStatus = (
  services: IServiceManagerService[],
  status: ServiceManagerStatusEnum[],
): IServiceManagerService[] => {
  if (!!status && !!status.length) {
    const newStatus = [...status];
    if (status.includes(ServiceManagerStatusEnum.DISABLED)) {
      newStatus.push(ServiceManagerStatusEnum.UNDEPLOYED);
    }
    return services.filter(service => newStatus.includes(service.status));
  }
  return services;
};

/**
 * Filter an array of Services using a search string
 * @param services ServiceManagerService[]
 * @param searchString string
 */
export const filterServiceManagerServiceByString = (services: IServiceManagerService[], searchString: string) => {
  if (!!searchString) {
    return services.filter(service => service.name.toLowerCase().includes(searchString.toLowerCase()));
  }
  return services;
};

/**
 *  Filter an array of Services using ServiceManagerServiceTypeSearchEnum
 * @param services
 * @param servicesTypes
 * @returns array of IServiceManagerService
 */
export const filterServiceManagerServiceByServiceType = (
  services: IServiceManagerService[],
  servicesTypes: string[],
  serviceGroups: IServiceGroup[],
): IServiceManagerService[] => {
  if (!!servicesTypes && !!servicesTypes.length) {
    return services
      .filter(s => {
        return s.groups.find(g => servicesTypes.includes(getIDByServiceGroup(serviceGroups, g) || ""));
      })
      .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : -1));
  }
  return services;
};

/**
 * Get service group ID given a name, userType and serviceType attributes
 * @param servicesGroups
 * @param serviceGroup
 * @returns ID
 */
export const getIDByServiceGroup = (servicesGroups: IServiceGroup[], serviceGroup: IServiceManagerGroups) => {
  return servicesGroups.find(
    item =>
      item.value === serviceGroup.name &&
      item.userType === serviceGroup.userType &&
      item.serviceType === serviceGroup.serviceType,
  )?.id;
};

/**
 * Filter an array of Services using a search string, an array of ServiceStatusEnum
 * @param services ServiceManagerService[]
 * @param filters ServiceManagerFilters
 */
export const filterServiceManagerServices = (
  services: IServiceManagerService[],
  filters: Map<string, string[]>,
  serviceGroups: IServiceGroup[],
): IServiceManagerService[] => {
  let newServices = services;
  const status = filters.get(ServiceManagerFiltersEnum.STATUS);
  const search = filters.get(ServiceManagerFiltersEnum.SEARCH);
  const serviceType = filters.get(ServiceManagerFiltersEnum.SERVICES_TYPES);

  if (status) {
    newServices = filterServiceManagerServicesByStatus(newServices, status as ServiceManagerStatusEnum[]);
  }
  if (search) {
    newServices = filterServiceManagerServiceByString(newServices, search[0]);
  }

  if (serviceType && serviceType.length > 0) {
    newServices = filterServiceManagerServiceByServiceType(newServices, serviceType, serviceGroups);
  }
  return newServices;
};

/**
 * Return allowed scope options
 */
export const getAllowedScopesOptions = (
  allowedScopes: IAllowedScope[],
  groups: IServiceGroup[],
): SharedDropdownOption[] => {
  const options: SharedDropdownOption[] = [];
  const serviceType: string[] = [];

  groups.forEach(group => {
    if (!serviceType.includes(group.serviceType)) {
      serviceType.push(group.serviceType);
    }
  });

  allowedScopes.forEach(scope => {
    if (
      scope.serviceType &&
      scope.serviceType.length > 0 &&
      (scope.serviceType.length > 1 || serviceType.includes(scope.serviceType[0]))
    ) {
      options.push({
        label: scope.name,
        key: scope.id,
      });
    }
  });

  return options;
};

/**
 * Method to convert Categories list to  SharedDropdownOptions
 * @param categories
 * @returns SharedDropdownOption list
 */
export const getCategoriesOptions = (categories: SharedDropdownOption[]): SharedDropdownOption[] => {
  const options: SharedDropdownOption[] = [];
  categories.forEach(item => {
    options.push({
      label: item.label,
      key: item.key,
    });
  });

  return options;
};

/**
 * Return Selected Categories Options
 */
export const getSelectedCategoriesOptions = (
  selectedCategories: string[] | undefined,
  categories: SharedDropdownOption[],
): string => {
  if (!selectedCategories) {
    return "-";
  }
  const selectedOption = categories
    .map(item => {
      if (selectedCategories.includes(item.key)) {
        return `${item.label}`;
      }
    })
    .filter(item => item !== undefined);
  return selectedOption ? selectedOption.join(",") : "-";
};

/** validation schema */
export const getServiceDetailsValidationSchema = () => {
  return object().shape({
    name: string().required(i18next.t("pages.serviceManager.serviceDetails.validateSchema.name")),
    color: string().required(i18next.t("pages.serviceManager.serviceDetails.validateSchema.color")),
  });
};
