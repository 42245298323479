import {
  ModalTypeEnum,
  PrimaryButton,
  ProgressBar,
  SecondaryPageTitle,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ConfigContext } from "../../../app.component";
import { RoutesEnum } from "../../../routes/routes.constants";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import { PageContent } from "../../../shared/responsive-ui.styled";
import { StatusType } from "../../../shared/shared.enums";
import { PageContainer } from "../../../shared/shared.styled";
import AnnouncementsApi from "../announcements.api";
import {
  AudienceType,
  BusinessAudience,
  CallToActionType,
  IAnnouncement,
  IStoryInterface,
} from "../announcements.model";
import { AnnoucementsActions } from "../announcements.store";
import {
  ButtonInfo,
  ButtonSection,
  DetailSteps,
  DetailTitle,
  ProgressBarInfo,
  StepSection,
} from "../announcements.styled";
import { AnnouncementsUtils } from "../announcements.utils";
import AnnouncementsStepAnouncementDetails from "../components/announcements-step-announcement-details.component";
import AnnouncementsStepAppVersion from "../components/announcements-step-app-version.component";
import AnnouncementsStepCampaignDetails from "../components/announcements-step-campaign-details.component";
import AnnouncementsStepHomescreenCardBusiness from "../components/announcements-step-homescreen-card-business.component";
import AnnouncementsStepHomescreenCardConsumer from "../components/announcements-step-homescreen-card-consumer.component";
import ConfirmWithLoadingModal from "../components/confirm-with-loading-modal.component";

/**
 * Create Page to Announcement
 */
const AnnouncementsContentPage = () => {
  const [t] = useTranslation();
  const { config } = useContext(ConfigContext);
  const totalOfSteps = 4;
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [step, setStep] = React.useState(1);
  const [stepTitle, setStepTitle] = React.useState(t("pages.announcements.addPage.stepTitle.first"));
  const [finalStatus, setFinalStatus] = React.useState<StatusType>();
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
  const businessAgentEnabled = useCheckFeatureAvailable(FEATURES.BUSINESS_AUDIENCE_AGENT);

  const automaticSuggestedEnabled = useCheckFeatureAvailable(FEATURES.AUTOMATIC_SUGGESTED_ANNOUNCEMENTS);
  const [announcement, setAnnouncement] = React.useState<IAnnouncement>({
    id: "",
    campaignName: "",
    createDate: undefined,
    startDate: undefined,
    endDate: undefined,
    color: "",
    icon: "",
    sendPushNotifications: false,
    pushNotificationDetails: undefined,
    dismissible: false,
    audience: businessAgentEnabled ? BusinessAudience.ALL_BUSINESSAPPS : BusinessAudience.BUSINESSAPP_MERCHANT,
    targetBusinessAgent: undefined,
    targetBusinessMerchant: undefined,
    targetConsumer: undefined,
    stories: [{ callToAction: { action: {} } } as IStoryInterface],
    status: undefined,
    thumbnailImage: "",
    thumbnailImageCarousel: "",
    profileTypes: undefined,
  });

  const [showConfirmModal, hideConfirmModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <ConfirmWithLoadingModal
      isLoading={isRequesting}
      title={
        finalStatus === StatusType.DRAFT
          ? t("pages.announcements.detailPage.modals.confirmSaveDraft")
          : t("pages.announcements.detailPage.modals.confirmPublish")
      }
      description={
        finalStatus === StatusType.DRAFT
          ? t("pages.announcements.detailPage.modals.confirmDescriptionasDraft", {
              campaignName: announcement.campaignName,
            })
          : t("pages.announcements.detailPage.modals.confirmDescriptionPublish", {
              campaignName: announcement.campaignName,
            })
      }
      primaryBtnLabel={
        finalStatus === StatusType.DRAFT
          ? t("pages.announcements.detailPage.buttons.save")
          : t("pages.announcements.detailPage.buttons.publish")
      }
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.modalCancel")}
      primaryAction={() => {
        requestAddAnnouncement(announcement);
      }}
      secondaryAction={() => {
        hideConfirmModal();
      }}
    />,
  );

  const [isFormReady, setIsFormReady] = React.useState(false);

  useEffect(() => {
    if (automaticSuggestedEnabled) {
      announcement.suggested = false;
    }
  }, []);

  /**
   * function to made request add announcement
   */
  const requestAddAnnouncement = (values: IAnnouncement) => {
    validateLinkURL(values);
    const { time, endTime, fileInfoBusinessMerchant, fileInfoBusinessAgent, fileInfoConsumer, ...finalAnnoucement } = {
      ...values,
    };

    if (finalAnnoucement.targetConsumer) {
      finalAnnoucement.targetConsumer = AnnouncementsUtils.cleanMsisdn(finalAnnoucement.targetConsumer);
    }

    if (finalAnnoucement.targetBusinessAgent) {
      finalAnnoucement.targetBusinessAgent = Array.from(new Set(finalAnnoucement.targetBusinessAgent));
    }

    if (finalAnnoucement.targetBusinessMerchant) {
      finalAnnoucement.targetBusinessMerchant = Array.from(new Set(finalAnnoucement.targetBusinessMerchant));
    }

    finalAnnoucement.status = finalStatus;
    finalAnnoucement.endDate = announcement.endDate;
    // changed date format to unix ts
    finalAnnoucement.startDate = moment(finalAnnoucement.startDate, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf();
    finalAnnoucement.endDate = moment(finalAnnoucement.endDate, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf();

    finalAnnoucement.stories.forEach(story => {
      if (!story.title) {
        story.colorTitle = null;
      }
      if (!story.description) {
        story.colorDescription = null;
      }
    });
    setIsRequesting(true);

    //* If icon is optional and not set, exclude it from the announcement data
    let announcementData = finalAnnoucement;
    if (!finalAnnoucement.icon) {
      const { icon, ...rest } = finalAnnoucement;
      announcementData = rest;
    }

    AnnouncementsApi.methods.addAnnouncement(announcementData).then(
      res => {
        announcementData.id = res.data;
        announcementData.stories.forEach(story => (story.backgroundImage = ""));
        dispatch(AnnoucementsActions.creators.addAnnouncement(announcementData));
        setAlertProps({
          title: t("pages.announcements.announcementPublishedSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        history.push(RoutesEnum.ANNOUNCEMENTS);
      },
      err => {
        setIsRequesting(false);
        hideConfirmModal();
        setAlertProps({
          title: t("pages.announcements.errorAddAnnouncement"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * validate link url to verify if "http:// is added"
   * for iOS all the links must have http/https protocol
   */
  const validateLinkURL = (values: IAnnouncement) => {
    if (values.stories) {
      values.stories.forEach(story => {
        if (
          story.callAction &&
          story.callAction &&
          story.callToAction &&
          story.callToAction.action &&
          story.callToAction.action.resource &&
          story.callToAction.action.type === CallToActionType.LINK &&
          !story.callToAction.action.resource.startsWith("http")
        ) {
          story.callToAction.action.resource = `http://${story.callToAction.action.resource}`;
        }
      });
    }
  };

  /**
   * function to continue to the next step
   */
  const advanceToNextStep = () => {
    setStepTitle(
      step === 1
        ? t("pages.announcements.detailPage.rows.selectApp")
        : step === 2
        ? t("pages.announcements.addPage.stepTitle.second")
        : t("pages.announcements.addPage.stepTitle.third"),
    );
    setStep(currStep => currStep + 1);
  };

  /**
   * function go back
   */
  const goBack = () => {
    if (step === 1) {
      window.history.back();
    } else {
      goBackStep();
    }
  };

  /**
   * function go back step
   */
  const goBackStep = () => {
    setStepTitle(
      step === 1 ? t("pages.announcements.addPage.stepTitle.second") : t("pages.announcements.addPage.stepTitle.third"),
    );
    setStep(currStep => currStep - 1);
  };

  return (
    <PageContainer style={{ paddingBottom: "200px" }}>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.announcements.addPage.title")}
        goBackFn={goBack}
      />
      <PageContent className="pageContent display-column">
        <StepSection>
          <div>
            <DetailTitle>{stepTitle}</DetailTitle>
            <DetailSteps>{t("pages.announcements.detailPage.step") + step}</DetailSteps>
          </div>
          <ProgressBarInfo step={step}>
            <div>
              <ProgressBar
                step={step}
                totalOfSteps={totalOfSteps}
                canClickPreviousSteps={true}
                onClickStep={setStep}
              ></ProgressBar>
            </div>
          </ProgressBarInfo>
          <ButtonSection>
            {step < totalOfSteps ? (
              <ButtonInfo>
                <PrimaryButton
                  titleLabel={t("pages.announcements.detailPage.buttons.continue")}
                  redTheme={true}
                  type="submit"
                  form={AnnouncementsUtils.getFormIdsByStep(step)}
                  disabled={!isFormReady}
                />
              </ButtonInfo>
            ) : (
              <>
                <ButtonInfo style={{ marginRight: "16px" }}>
                  <PrimaryButton
                    titleLabel={t("pages.announcements.detailPage.buttons.saveCreate")}
                    redTheme={false}
                    type="submit"
                    form={AnnouncementsUtils.getFormIdsByStep(step)}
                    onClick={() => setFinalStatus(StatusType.DRAFT)}
                    disabled={!isFormReady}
                  />
                </ButtonInfo>
                <ButtonInfo>
                  <PrimaryButton
                    titleLabel={t("pages.announcements.detailPage.buttons.publish")}
                    redTheme={true}
                    type="submit"
                    form={AnnouncementsUtils.getFormIdsByStep(step)}
                    onClick={() => setFinalStatus(StatusType.SCHEDULED)}
                    disabled={!isFormReady}
                  />
                </ButtonInfo>
              </>
            )}
          </ButtonSection>
        </StepSection>
        {step === 1 ? (
          <AnnouncementsStepCampaignDetails
            announcement={announcement}
            setAnnouncement={setAnnouncement}
            isEditing={true}
            onSubmitForm={advanceToNextStep}
            onReady={setIsFormReady}
          />
        ) : step === 2 ? (
          <div style={{ marginTop: "32px" }}>
            <AnnouncementsStepAppVersion
              announcement={announcement}
              setAnnoucement={setAnnouncement}
              isEditing={true}
              onSubmitForm={advanceToNextStep}
              onReady={setIsFormReady}
            />
          </div>
        ) : step === 3 ? (
          AnnouncementsUtils.isConsumerAudience(announcement.audience as AudienceType) &&
          !AnnouncementsUtils.areDeprecatedVersionsSelected(
            announcement,
            config!.appVersionDependencies.announcementsThumbnail.min,
          ) ? (
            <AnnouncementsStepHomescreenCardConsumer
              announcement={announcement}
              setAnnoucement={setAnnouncement}
              isEditing={true}
              onSubmitForm={advanceToNextStep}
              onReady={setIsFormReady}
            />
          ) : (
            <AnnouncementsStepHomescreenCardBusiness
              announcement={announcement}
              setAnnoucement={setAnnouncement}
              isEditing={true}
              onSubmitForm={advanceToNextStep}
              onReady={setIsFormReady}
            />
          )
        ) : step === 4 ? (
          <AnnouncementsStepAnouncementDetails
            announcement={announcement}
            setAnnoucement={setAnnouncement}
            isEditing={true}
            onSubmitForm={showConfirmModal}
            onReady={setIsFormReady}
          />
        ) : (
          <></>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default AnnouncementsContentPage;
