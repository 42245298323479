import { MultipleOptionsDropdown, SecondaryPageTitle, TextArea, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import FormSection, {
  FormSectionRow,
} from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { TFunction } from "i18next";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { array, object, string } from "yup";
import { ConfigContext } from "../../../../app.component";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomPrimaryButton from "../../../../shared/components/safaricom-loading-button/safaricom-button.component";
import { BackOfficeMarketsEnums } from "../../../../shared/shared.enums";
import { getExternalServicePermissions } from "../../../../shared/shared.utils";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import ExternalServicesApi from "../external-services.api";
import {
  ICreateExternalServiceRequest,
  ISingleExternalServiceRequest,
  PermissionsRoleEnum,
} from "./external-service-add.interface";

/** function externalservice schema */
const addExternalServiceValidationSchema = (t: TFunction) => {
  return object().shape({
    externalService: array().of(
      object().shape({
        externalServiceId: string().required(t("commons.mandatoryField")),
        url: string().required(t("commons.mandatoryField")),
      }),
    ),
  });
};

/** page add external service page */
const ExternalServiceAddPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [permissionsSelected, setpermissionsSelected] = useState<string[]>([]);
  const { config } = useContext(ConfigContext);

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setpermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      setpermissionsSelected([...permissionsSelected, opt.key]);
    }
  }

  function isOptionSelected(opt: SharedDropdownOption) {
    return permissionsSelected.includes(opt.key);
  }

  function resetDropdown() {
    setpermissionsSelected([]);
  }

  function getDropdownLabel() {
    if (permissionsSelected.length === 2) {
      return (
        <div>
          {t("pages.externalServices.configurations.columns.permissionPIN")},{" "}
          {t("pages.externalServices.configurations.columns.permissionIDENTITY")}
        </div>
      );
    } else if (permissionsSelected.length === 1) {
      if (permissionsSelected.includes(PermissionsRoleEnum.PIN)) {
        return <div>{t("pages.externalServices.configurations.columns.permissionPIN")}</div>;
      } else {
        return <div>{t("pages.externalServices.configurations.columns.permissionIDENTITY")}</div>;
      }
    } else {
      return <div>{t("pages.externalServices.configurations.columns.choosePermission")}</div>;
    }
  }

  function getRows(
    externalService: ISingleExternalServiceRequest,
    errors: FormikErrors<ICreateExternalServiceRequest>,
    setFieldValue: any,
    idx: number,
  ): FormSectionRow[] {
    return [
      {
        label: (
          <TitleLabel mandatory={true}>{t("pages.externalServices.addPage.getRows.serviceIdentifier")}</TitleLabel>
        ),
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"externalServiceId"}
            onChange={e => setFieldValue(`externalService[${idx}].externalServiceId`, e.target.value)}
            error={
              errors.externalService && errors.externalService[idx]
                ? (errors.externalService[idx] as ISingleExternalServiceRequest).externalServiceId
                : undefined
            }
            placeholder={t("pages.externalServices.addPage.getRows.placeholderService")}
            value={externalService.externalServiceId}
            required={true}
          />
        ),
      },
      {
        label: <TitleLabel mandatory={true}>{t("pages.externalServices.addPage.getRows.url")}</TitleLabel>,
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"url"}
            onChange={e => setFieldValue(`externalService[${idx}].url`, e.target.value)}
            error={
              errors.externalService && errors.externalService[idx]
                ? (errors.externalService[idx] as ISingleExternalServiceRequest).url
                : undefined
            }
            placeholder={t("pages.externalServices.addPage.getRows.placeholderUrl")}
            value={externalService.url}
            required={true}
          />
        ),
      },
      {
        label: t("pages.externalServices.addPage.getRows.permissions"),
        displayComponent: null,
        editingComponent: (
          <>
            <div id={"permissions"}>
              <MultipleOptionsDropdown
                options={getExternalServicePermissions()}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                isOptionSelected={opt => isOptionSelected(opt)}
                label={getDropdownLabel()}
                hasValue={!!externalService.permissions}
              />
            </div>
          </>
        ),
      },
      {
        label: (
          <TitleLabel
            mandatory={
              permissionsSelected.includes(PermissionsRoleEnum.PIN) && config?.market === BackOfficeMarketsEnums.KE
            }
          >
            {t("pages.externalServices.addPage.getRows.RSApublicKey")}
          </TitleLabel>
        ),
        displayComponent: null,
        editingComponent: (
          <TextArea
            id={"rsaPublicKey"}
            onChange={e => setFieldValue(`externalService[${idx}].rsaPublicKey`, e.target.value)}
            placeholder={t("pages.externalServices.configurations.columns.publicKey")}
            value={externalService.rsaPublicKey}
            error={
              errors.externalService &&
              errors.externalService[idx] &&
              permissionsSelected.includes(PermissionsRoleEnum.PIN) &&
              config?.market === BackOfficeMarketsEnums.KE
                ? (errors.externalService[idx] as ISingleExternalServiceRequest).url
                : undefined
            }
            required={
              permissionsSelected.includes(PermissionsRoleEnum.PIN) && config?.market === BackOfficeMarketsEnums.KE
            }
          />
        ),
      },
    ];
  }

  /** function to add external service request */
  const addExternalServices = (
    values: ICreateExternalServiceRequest,
    actions: FormikHelpers<ICreateExternalServiceRequest>,
  ) => {
    values.externalService.some(element => {
      if (element.permissions === undefined && permissionsSelected.length > 0) {
        element.permissions = [];
        if (permissionsSelected.includes(PermissionsRoleEnum.PIN)) {
          element.permissions.push(PermissionsRoleEnum.PIN);
        }
        if (permissionsSelected.includes(PermissionsRoleEnum.IDENTITY)) {
          element.permissions.push(PermissionsRoleEnum.IDENTITY);
        }
      }
    });

    actions.setSubmitting(true);
    ExternalServicesApi.methods
      .createExternalService(values.externalService)
      .finally(() => actions.setSubmitting(false))
      .then(
        res => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.externalServices.addPage.addExternalService.title"),
          });
          showAlert();
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.externalServices.addPage.addExternalService.error"),
          });
          showAlert();
        },
      );
  };

  return (
    <>
      <SecondaryPageTitle
        title={t("pages.externalServices.addPage.title")}
        goBackFn={() => window.history.back()}
        displayInitialsCircle={false}
      />
      <Formik
        onSubmit={addExternalServices}
        initialValues={
          {
            externalService: [
              {
                externalServiceId: "",
                url: "",
              },
            ],
          } as ICreateExternalServiceRequest
        }
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={addExternalServiceValidationSchema(t)}
        render={({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
          <>
            <FormContainer onSubmit={handleSubmit}>
              {values.externalService.map((es, idx) => (
                <FormSectionCard key={idx}>
                  <FormSection
                    title={
                      <FormSectionCardTitle>
                        <div>{t("pages.externalServices.addPage.description")}</div>
                      </FormSectionCardTitle>
                    }
                    rows={getRows(es, errors, setFieldValue, idx)}
                    isEditing={true}
                  />
                </FormSectionCard>
              ))}
              <ButtonRow>
                <ButtonContainer>
                  <SafaricomPrimaryButton
                    redTheme={true}
                    type="submit"
                    disabled={isSubmitting}
                    titleLabel={t("pages.externalServices.addPage.saveChanges")}
                    loading={isSubmitting}
                  />
                </ButtonContainer>
              </ButtonRow>
            </FormContainer>
          </>
        )}
      />
    </>
  );
};

export default ExternalServiceAddPage;

const FormContainer = styled("form")`
  padding-top: 175px;
  margin-left: 245px;
  margin-right: 245px;
  @media (max-width: 1024px) {
    margin-left: 94px;
    margin-right: 24px;
  }
`;

const FormSectionCardTitle = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > span {
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${props => props.theme.palette.darkGrey};
  }
`;

const FormSectionCard = styled("div")`
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 18px 24px;

  > div > div > span > div > div > button {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    > div > div > span > div > div > button {
      display: flex;
    }
  }

  :not(:last-child) {
    margin-bottom: 24px;
  }

  > div > div {
    border-bottom: none !important;
  }

  > div:first-child > div:first-child > span:first-child {
    width: 100%;
  }
`;
const ButtonRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  svg {
    stroke: ${props => props.theme.palette.vodafoneRed};
  }
`;

const ButtonContainer = styled("div")`
  width: fit-content;
  margin-left: auto;
`;

const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

export const FileIconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

const FileNameContainer = styled("div")`
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;

const TitleLabel = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const FormError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
