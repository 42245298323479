import {
  AudienceType,
  ICallToActionInterface,
  IFileInfoInterface,
  IVersion,
} from "../announcements/announcements.model";

export interface INotification {
  id?: string;
  campaignName: string;
  createdOn?: string;
  startDate: string;
  startTime?: string;
  selectAudienceBy?: string;
  targetVersions?: IVersion[];
  backgroundImage: string;
  title: string;
  body?: string;
  csvFile?: string;
  csvFileContent?: File;
  hasCallToAction: boolean;
  callToAction: ICallToActionInterface;
  audience: AudienceType;
  businessAudience?: string[];
  consumerAudience?: string[];
  imageFileInfo?: IFileInfoInterface;
  sendImmediately: boolean;
  status: NOTIFICATION_STATUSES;
  isNotValidStartDate?: boolean;
}

export enum SelectAudienceByTypes {
  APP_AND_APP_VERSION = "APP_AND_APP_VERSION",
  TOPIC = "TOPIC",
  CSV_FILE_UPLOAD = "CSV_FILE_UPLOAD",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SetFieldValueType = (key: string, value: any, shouldValidate?: boolean) => void;

export enum NOTIFICATION_STATUSES {
  SENT = "SENT",
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
}
