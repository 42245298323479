import { Checkbox, PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { ConfigContext, IConfig } from "../../../app.component";
import { IStoreInterface } from "../../../configs/store.config";
import { getDefaultRoute } from "../../../routes/routes.component";
import { RoutesEnum } from "../../../routes/routes.constants";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import { FEATURES, THEMES } from "../../../shared/renderer.utils";
import { Row } from "../../../shared/shared.styled";
import {
  fetchPrivateConfig,
  getResToken,
  initPrivateTranslation,
  loginSuccessHandler,
} from "../../../shared/utils/login.utils";
import ReCAPTCHA from "react-google-recaptcha";
import AuthenticationApi from "../authentication.api";
import {
  ILoggedUser,
  ILoginRequest,
  ILoginResponse,
  ITwoFactorAuthenticationRequest,
} from "../authentication.interfaces";
import { AuthenticationActions } from "../authentication.store";
import { LoginErrorCodes, MAX_FAILED_LOGIN_ATTEMPTS, RECAPTCHA_KEY } from "../authentication.utils";
import "./login.styles.css";
import TwoFactorAuthenticationComponent from "./two-factor-authentication.component";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";

interface ILoginPageProps {
  config: IConfig;
}

/**
 * Login Page
 * @param param0
 */
const LoginPage = ({ config }: ILoginPageProps) => {
  const [t] = useTranslation(["public"]);
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  // State
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorCode, setLoginErrorCode] = useState(-1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [display2FA, setDisplay2FA] = useState(false);
  const [needsToSetup2FA, setNeedsToSetup2FA] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState<ILoginResponse>();
  const loginFromModal: boolean = false;
  const configContext = useContext(ConfigContext);
  const failedLoginAttempts = useSelector((state: IStoreInterface) => state.authenticationReducer.failedLoginAttempts);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const recaptchaTokenEnabled = useCheckFeatureAvailable(FEATURES.RECAPTCHA_TOKEN);

  /**
   * Login Schema
   */
  const getLoginSchema = () => {
    return object().shape({
      username: string()
        .email()
        .required(t("commons.mandatoryField")),
      password: string().required(t("commons.mandatoryField")),
    });
  };

  /**
   * A function that dispatch payload to our store
   * @param {any} payload
   */
  const dispatcher = (payload: any): void => dispatch(payload);

  /**
   * Login request
   * @param {ILoginRequest} values
   * @param {FormikHelpers<ILoginRequest>} actions
   */
  const login = (values: ILoginRequest, actions: FormikHelpers<ILoginRequest>) => {
    localStorage.setItem("twoFactorAuthenticated", "false");
    actions.setSubmitting(true);

    if (recaptchaTokenEnabled && recaptchaToken) {
      values.reCaptchaTokenResult = recaptchaToken;
    }

    AuthenticationApi.methods.login(values).then(
      res => {
        loginSuccessHandler(res, dispatcher, loginFromModal, rememberMe).then(configRes => {
          dispatch(AuthenticationActions.creators.resetFailedLoginAttempts());
          initPrivateTranslation(res, getResToken(res));
          configContext.setConfig(configRes ?? configContext.config);
          setLoggedInUser(res.data);
          if (res.data.is2FAEnabled) {
            setDisplay2FA(true);
            setNeedsToSetup2FA(res.data.needs2FASetup);
          } else {
            history.push(getDefaultRoute(res.data, configRes as IConfig));
            actions.setSubmitting(false);
          }
          setRecaptchaToken("");
        });
      },
      e => {
        dispatch(AuthenticationActions.creators.incrementFailedLoginAttempts());
        actions.setSubmitting(false);
        setLoginError(true);
        if (!e) {
          // timeout/cancelled request
          setLoginErrorCode(LoginErrorCodes.CANCELLED);
        } else {
          if (e.data && e.data.status && e.data.status.businessCode) {
            setLoginErrorCode(e.data.status.businessCode);
            if (e.data.status.businessCode === LoginErrorCodes.PASSWORD_EXPIRED) {
              history.push(RoutesEnum.EXPIRED_PASSWORD);
            }
          }
        }
      },
    );
  };

  /**
   * Error rendering
   * @returns {JSX.Element}
   */
  const renderLoginError = () => {
    let title, description;

    if (loginError) {
      switch (loginErrorCode) {
        case LoginErrorCodes.ACCOUNT_BLOCKED:
          title = "titleBlocked";
          description = "descBlocked";
          break;
        case LoginErrorCodes.CANCELLED:
          title = "title";
          description = "cancelled";
          break;
        default:
          title = "title";
          description = "desc";
      }
      return (
        <LoginErrorContainer>
          <LoginErrorTitle>{t(`pages.login.errors.${title}`)}</LoginErrorTitle>
          <LoginErrorDescription>{t(`pages.login.errors.${description}`)}</LoginErrorDescription>
        </LoginErrorContainer>
      );
    }
  };

  /**
   * On recaptcha change
   */
  const onValidRecaptcha = (token: string | null) => {
    if (token) {
      setRecaptchaToken(token);
    }
    dispatch(AuthenticationActions.creators.decrementFailedLoginAttempts());
  };

  /**
   * Renders the recaptcha form
   */
  const renderRecaptcha = () => {
    return (
      <>
        {failedLoginAttempts >= MAX_FAILED_LOGIN_ATTEMPTS ||
        (loginErrorCode === LoginErrorCodes.UNAUTHORIZED_NO_RECAPTCHA && recaptchaTokenEnabled) ? (
          <RecaptchaContainer>
            <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onValidRecaptcha} />
          </RecaptchaContainer>
        ) : null}
      </>
    );
  };

  /**
   * Validates the two step authentication code
   * @param {number} otpCode
   */
  const validate2FA = (
    values: ITwoFactorAuthenticationRequest,
    actions: FormikHelpers<ITwoFactorAuthenticationRequest>,
  ) => {
    actions.setSubmitting(true);
    AuthenticationApi.methods
      .validate2FAOTPCode(values.otpCode)
      .finally(() => actions.setSubmitting(false))
      .then(
        res => {
          localStorage.setItem("twoFactorAuthenticated", "true");
          fetchPrivateConfig(loggedInUser as ILoginResponse).then(
            configRes => {
              configContext.setConfig(configRes ?? configContext.config);
              initPrivateTranslation(res, getResToken(res));
              history.push(getDefaultRoute(loggedInUser as ILoggedUser, configRes as IConfig));
            },
            () => {},
          );
        },
        () => {
          setAlertProps({
            title: t("pages.twoFactorAuthentication.validate2FAError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  return (
    <>
      {display2FA ? (
        <TwoFactorAuthenticationComponent needsToSetup2FA={needsToSetup2FA} validate2FA={validate2FA} />
      ) : (
        <LoginContainer>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={login}
            validationSchema={getLoginSchema()}
            validateOnChange={false}
            render={({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
              <LoginFormContainer onSubmit={handleSubmit}>
                <LoginTitle dangerouslySetInnerHTML={{ __html: t("pages.login.title") }}></LoginTitle>
                <InputsContainer>
                  <TextInputContainer>
                    <TextInput
                      required={true}
                      value={values.username}
                      type="email"
                      error={errors.username}
                      name="username"
                      onChange={handleChange}
                      placeholder={t("pages.login.usernamePlaceholder")}
                      title={t("pages.login.username")}
                    />
                  </TextInputContainer>
                  <TextInputContainer>
                    <TextInput
                      required={true}
                      type="password"
                      name="password"
                      error={errors.password}
                      value={values.password}
                      onChange={handleChange}
                      placeholder={t("pages.login.passwordPlaceholder")}
                      title={t("pages.login.password")}
                    />
                  </TextInputContainer>
                  <Row id="remember-me-button" style={{ marginTop: 32 }}>
                    <Checkbox name="rememberMe" value={rememberMe} onValueChange={val => setRememberMe(val)} />
                    <CheckboxLabel>{t("pages.login.rememberMe")}</CheckboxLabel>
                    <ForgotPasswordLink onClick={() => history.push(RoutesEnum.FORGOT_PASSWORD)}>
                      {t("pages.login.forgotPassword")}
                    </ForgotPasswordLink>
                  </Row>
                  {renderLoginError()}
                  {renderRecaptcha()}

                  <ButtonContainer error={loginError}>
                    <PrimaryButton
                      id="login-button"
                      titleLabel={t("pages.login.loginBtn")}
                      redTheme={true}
                      safaricom={config.theme === THEMES.SAFARICOM}
                      type="submit"
                      loading={isSubmitting}
                      disabled={failedLoginAttempts >= MAX_FAILED_LOGIN_ATTEMPTS}
                    />
                  </ButtonContainer>
                </InputsContainer>
              </LoginFormContainer>
            )}
          />
          <AnimatedBackgroundImage />
        </LoginContainer>
      )}
    </>
  );
};

export default LoginPage;

export const LoginTitle = styled("div")`
  font-family: Vodafone Lt;
  font-size: 33px;
  color: #333;
  margin-bottom: 50px;

  > span {
    color: ${props => props.theme.palette.vodafoneRed};
  }
`;

export const LoginContainer = styled("div")`
  height: 100%;
`;

export const LoginFormContainer = styled("form")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const InputsContainer = styled("div")``;

const TextInputContainer = styled("div")`
  margin-bottom: 15px;
`;

export const ButtonContainer = styled("div")<{ error?: boolean }>`
  margin-top: ${props => (props.error ? 31 : 40)}px;
`;

const ForgotPasswordLink = styled("span")`
  margin-left: auto;
  font-size: 14px;
  font-family: Vodafone Rg;
  color: rgb(153, 153, 153);
  cursor: pointer;
  text-decoration: none;
`;

const CheckboxLabel = styled("label")`
  font-family: Vodafone Rg;
  color: rgb(153, 153, 153);
  font-size: 14px;
  margin-left: 8px;
`;

const LoginErrorContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const RecaptchaContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

const LoginErrorTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.vodafoneRed};
`;

const LoginErrorDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.vodafoneRed};
`;
