import React from "react";
import { useTranslation } from "react-i18next";
import { FormSection, TextInput, GalleryIcon } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import {
  IGalleryImage,
  IReferralCampaign,
  IReward,
  IScreen,
  ITranslatableObject,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
} from "../referral-campaigns.model";
import ReferralCampaignImageGallery from "./referral-campaign-image-gallery.component";
import ImageGalleryComponent from "../../../../shared/components/image-gallery.component";

interface ISenderScreenFormProps {
  firstTitle: React.ReactNode;
  secondTitle: React.ReactNode;
  isEditing: boolean;
  language: string;
  values: IReferralCampaign;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isLanguageRequired?: boolean;
}

/**
 * CampaignDetailsForm component
 * Can be used to create or edit the campaign details
 */
const SenderScreenForm = ({
  firstTitle,
  secondTitle,
  isEditing,
  language: lang,
  values,
  setFieldValue,
  errors,
  setTab,
  isLanguageRequired = false,
}: ISenderScreenFormProps) => {
  const [t] = useTranslation();
  const [initialImgName, setInitialImgName] = React.useState<string | undefined>();
  const [initialImgUrl, setInitialImgUrl] = React.useState<string>();
  const [finalImgName, setFinalImgName] = React.useState<string | undefined>();
  const [finalImgUrl, setFinalImgUrl] = React.useState<string>();
  const [showGalleryType, setShowGalleryType] = React.useState<TypeScreenEnum | undefined>();

  React.useEffect(() => {
    if (findScreen(TypeScreenEnum.SENDER_INITIAL)) {
      const initialImage = findScreen(TypeScreenEnum.SENDER_INITIAL).urlImage;
      if (initialImage) {
        setInitialImgUrl(initialImage);
        setInitialImgName(initialImage.split("/").pop());
      }
    }
    if (findScreen(TypeScreenEnum.SENDER_FINAL)) {
      const finalImage = findScreen(TypeScreenEnum.SENDER_FINAL).urlImage;
      if (finalImage) {
        setFinalImgUrl(finalImage);
        setFinalImgName(finalImage.split("/").pop());
      }
    }
  }, []);

  /**
   * method to find a screen
   */
  const findScreen = (type: TypeScreenEnum): IScreen => {
    return values.screens.find(screen => screen.type === type) as IScreen;
  };

  /**
   * method to set a field at scren array
   */
  const setScreenField = (
    values: IReferralCampaign,
    type: TypeScreenEnum,
    field: string,
    translation: boolean,
    lang: string,
    value: any,
  ) => {
    setTab(type === TypeScreenEnum.SENDER_INITIAL ? 0 : 1);
    if (values.screens) {
      const screens = [...values.screens];
      const screen = screens.find(screen => screen.type === type);
      if (!translation) {
        if (screen) {
          screens[screens.indexOf(screen)] = { ...screen, [field]: value };
        } else {
          screens.push({ type, [field]: value } as IScreen);
        }
      } else {
        //@ts-ignore
        if (screen && screen[field] && screen[field].translations) {
          screens[screens.indexOf(screen)] = {
            ...screen,
            //@ts-ignore
            [field]: { translations: { ...screen[field].translations, [lang]: value } },
          };
        } else {
          screens.push({
            type,
            [field]: { translations: { [lang]: value } },
          } as IScreen);
        }
      }
      return screens;
    } else {
      return !translation
        ? [{ type, [field]: value } as IScreen]
        : [{ type, [field]: { translations: { [lang]: value } } } as IScreen];
    }
  };

  /**
   * method to select a gallery image
   */
  const selectGalleryImage = (image: IGalleryImage) => {
    if (showGalleryType) {
      if (showGalleryType === TypeScreenEnum.SENDER_INITIAL) {
        setInitialImgUrl(image.url);
        setInitialImgName(image.filename);
      } else if (showGalleryType === TypeScreenEnum.SENDER_FINAL) {
        setFinalImgUrl(image.url);
        setFinalImgName(image.filename);
      }
      setFieldValue("screens", setScreenField(values, showGalleryType, "urlImage", false, lang, image.url));
    }
  };

  /**
   * method to remove a gallery image
   */
  const removeImage = (type: TypeScreenEnum) => {
    if (type === TypeScreenEnum.SENDER_INITIAL) {
      setInitialImgUrl(undefined);
      setInitialImgName(undefined);
    } else if (type === TypeScreenEnum.SENDER_FINAL) {
      setFinalImgUrl(undefined);
      setFinalImgName(undefined);
    }
    setFieldValue("screens", setScreenField(values, type, "urlImage", false, lang, undefined));
  };

  /**
   * method to open gallery
   */
  const displayGallery = (type: TypeScreenEnum) => {
    setShowGalleryType(type);
  };

  /**
   * method to hide gallery
   */
  const hideGallery = () => {
    setShowGalleryType(undefined);
  };

  return (
    <>
      <FormSection
        isEditing={isEditing}
        title={firstTitle}
        rows={[
          {
            label: (
              <FormLabelContainer>
                <FormLabel required={isEditing && isLanguageRequired}>
                  {t("pages.referralCampaigns.createCampaign.senderScreen.titleScreen")}
                </FormLabel>
                <FormLabelLimit>
                  {(findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang] &&
                    (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang].length +
                      t("pages.referralCampaigns.sixtyLimit")}
                </FormLabelLimit>
              </FormLabelContainer>
            ),
            displayComponent:
              findScreen(TypeScreenEnum.SENDER_INITIAL) &&
              findScreen(TypeScreenEnum.SENDER_INITIAL).title &&
              (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                ? (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                : null,
            editingComponent: (
              <TextInput
                name="initial-title"
                value={
                  values.screens &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).title
                    ? (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang] || ""
                    : null
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.senderScreen.titleScreen",
                )} (${lang.toUpperCase()})`}
                required={isLanguageRequired}
                onChange={e =>
                  setFieldValue(
                    `screens`,
                    setScreenField(values, TypeScreenEnum.SENDER_INITIAL, "title", true, lang, e.target.value),
                  )
                }
                error={
                  errors.screens &&
                  errors.screens.find((error: IScreen) => error && error.title) &&
                  errors.screens.find((error: IScreen) => error && error.title).title &&
                  errors.screens.find((error: IScreen) => error && error.title).title.translations &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).title &&
                  (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations &&
                  !(findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                    ? errors.screens.find((error: IScreen) => error && error.title).title.translations[lang]
                    : undefined
                }
                maxLength={60}
              />
            ),
            required: isLanguageRequired,
          },
          {
            label: (
              <FormLabelContainer>
                <FormLabel required={isEditing && isLanguageRequired}>
                  {t("pages.referralCampaigns.createCampaign.senderScreen.descriptionScreen")}
                </FormLabel>
                <FormLabelLimit>
                  {(findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang] &&
                    (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                      .length + t("pages.referralCampaigns.oneFiftyLimit")}
                </FormLabelLimit>
              </FormLabelContainer>
            ),
            displayComponent:
              findScreen(TypeScreenEnum.SENDER_INITIAL) &&
              findScreen(TypeScreenEnum.SENDER_INITIAL).description &&
              (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                ? (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                : null,
            editingComponent: (
              <TextInput
                name="initial-description"
                value={
                  values.screens &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).description
                    ? (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[
                        lang
                      ] || ""
                    : null
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.senderScreen.descriptionScreen",
                )} (${lang.toUpperCase()})`}
                required={isLanguageRequired}
                onChange={e =>
                  setFieldValue(
                    `screens`,
                    setScreenField(values, TypeScreenEnum.SENDER_INITIAL, "description", true, lang, e.target.value),
                  )
                }
                error={
                  errors.screens &&
                  errors.screens.find((error: IScreen) => error && error.description) &&
                  errors.screens.find((error: IScreen) => error && error.description).description &&
                  errors.screens.find((error: IScreen) => error && error.description).description.translations &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).description &&
                  (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations &&
                  !(findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                    ? errors.screens.find((error: IScreen) => error && error.description).description.translations[lang]
                    : undefined
                }
                maxLength={150}
              />
            ),
            required: isLanguageRequired,
          },
          {
            label: t("pages.referralCampaigns.createCampaign.senderScreen.imageScreen"),
            displayComponent: <ImageGalleryComponent imageUrl={initialImgUrl} />,
            editingComponent: (
              <ImageGalleryComponent
                imageUrl={initialImgUrl}
                imageName={initialImgName}
                title={t("pages.referralCampaigns.createCampaign.senderScreen.openGallery")}
                mainIcon={<GalleryIcon />}
                actionFunctionOpenGallery={() => displayGallery(TypeScreenEnum.SENDER_INITIAL)}
                actionFunctionRemoveSelectedImage={() => removeImage(TypeScreenEnum.SENDER_INITIAL)}
                imageGalleryContainerId={"open-sender-initial-gallery"}
                errors={errors}
              />
            ),
            required: true,
          },
        ]}
      />
      {values.rewards &&
      values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
      (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
        SenderTypeRewardEnum.NONE &&
      ((values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
        .maxInvites as number) > 0 ? (
        <div style={{ marginTop: "40px" }}>
          <FormSection
            isEditing={isEditing}
            title={secondTitle}
            rows={[
              {
                label: (
                  <FormLabelContainer>
                    <FormLabel required={isEditing && isLanguageRequired}>
                      {t("pages.referralCampaigns.createCampaign.senderScreen.titleScreen")}
                    </FormLabel>
                    <FormLabelLimit>
                      {(findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang] &&
                        (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                          .length + t("pages.referralCampaigns.sixtyLimit")}
                    </FormLabelLimit>
                  </FormLabelContainer>
                ),
                displayComponent:
                  findScreen(TypeScreenEnum.SENDER_FINAL) &&
                  findScreen(TypeScreenEnum.SENDER_FINAL).title &&
                  (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                    : null,
                editingComponent: (
                  <TextInput
                    name="final-title"
                    value={
                      values.screens &&
                      findScreen(TypeScreenEnum.SENDER_FINAL) &&
                      findScreen(TypeScreenEnum.SENDER_FINAL).title
                        ? (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang] ||
                          ""
                        : null
                    }
                    placeholder={`${t(
                      "pages.referralCampaigns.createCampaign.senderScreen.titleScreen",
                    )} (${lang.toUpperCase()})`}
                    required={isLanguageRequired}
                    onChange={e =>
                      setFieldValue(
                        `screens`,
                        setScreenField(values, TypeScreenEnum.SENDER_FINAL, "title", true, lang, e.target.value),
                      )
                    }
                    error={
                      errors.screens &&
                      errors.screens.find((error: IScreen) => error && error.title) &&
                      errors.screens.find((error: IScreen) => error && error.title).title &&
                      errors.screens.find((error: IScreen) => error && error.title).title.translations &&
                      findScreen(TypeScreenEnum.SENDER_FINAL).title &&
                      (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations &&
                      !(findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                        ? errors.screens.find((error: IScreen) => error && error.title).title.translations[lang]
                        : undefined
                    }
                    maxLength={60}
                  />
                ),
                required: isLanguageRequired,
              },
              {
                label: (
                  <FormLabelContainer>
                    <FormLabel required={isEditing && isLanguageRequired}>
                      {t("pages.referralCampaigns.createCampaign.senderScreen.descriptionScreen")}
                    </FormLabel>
                    <FormLabelLimit>
                      {(findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[
                        lang
                      ] &&
                        (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                          .length + t("pages.referralCampaigns.oneFiftyLimit")}
                    </FormLabelLimit>
                  </FormLabelContainer>
                ),
                displayComponent:
                  findScreen(TypeScreenEnum.SENDER_FINAL) &&
                  findScreen(TypeScreenEnum.SENDER_FINAL).description &&
                  (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                    : null,
                editingComponent: (
                  <TextInput
                    name="final-description"
                    value={
                      values.screens &&
                      findScreen(TypeScreenEnum.SENDER_FINAL) &&
                      findScreen(TypeScreenEnum.SENDER_FINAL).description
                        ? (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[
                            lang
                          ] || ""
                        : null
                    }
                    placeholder={`${t(
                      "pages.referralCampaigns.createCampaign.senderScreen.descriptionScreen",
                    )} (${lang.toUpperCase()})`}
                    required={isLanguageRequired}
                    onChange={e =>
                      setFieldValue(
                        `screens`,
                        setScreenField(values, TypeScreenEnum.SENDER_FINAL, "description", true, lang, e.target.value),
                      )
                    }
                    error={
                      errors.screens &&
                      errors.screens.find((error: IScreen) => error && error.description) &&
                      errors.screens.find((error: IScreen) => error && error.description).description &&
                      errors.screens.find((error: IScreen) => error && error.description).description.translations &&
                      findScreen(TypeScreenEnum.SENDER_FINAL).description &&
                      (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations &&
                      !(findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                        ? errors.screens.find((error: IScreen) => error && error.description).description.translations[
                            lang
                          ]
                        : undefined
                    }
                    maxLength={150}
                  />
                ),
                required: isLanguageRequired,
              },
              {
                label: t("pages.referralCampaigns.createCampaign.senderScreen.imageScreen"),
                displayComponent: <ImageGalleryComponent imageUrl={finalImgUrl} />,
                editingComponent: (
                  <ImageGalleryComponent
                    imageUrl={finalImgUrl}
                    imageName={finalImgName}
                    title={t("pages.referralCampaigns.createCampaign.senderScreen.openGallery")}
                    mainIcon={<GalleryIcon />}
                    actionFunctionOpenGallery={() => displayGallery(TypeScreenEnum.SENDER_FINAL)}
                    actionFunctionRemoveSelectedImage={() => removeImage(TypeScreenEnum.SENDER_FINAL)}
                    imageGalleryContainerId={"open-sender-final-gallery"}
                    errors={errors}
                  />
                ),
                required: true,
              },
            ]}
          />
        </div>
      ) : null}
      {showGalleryType ? (
        <ReferralCampaignImageGallery selectImage={selectGalleryImage} hideDrawer={hideGallery} />
      ) : null}
    </>
  );
};

export default SenderScreenForm;

const FormLabelContainer = styled("span")`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.red.normal};
  }`
      : ""}
`;

const FormLabelLimit = styled("span")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: ${props => props.theme.palette.greyDarker};
`;
