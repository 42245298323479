import { AutoClosingDropdown, PlusIcon, SearchBar, SmallButton, useAlert } from "@wit/mpesa-ui-components";
import { TableCell } from "@wit/mpesa-ui-components/lib/components/table/table-row/table-row.styled";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { ICategory } from "../../../../shared/models/category.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { IconContainer, Row } from "../../../../shared/shared.styled";
import { getNewData } from "../business-utils";
import { BusinessesAPI } from "../business.api";
import CategoryDrawer from "../components/categoryDrawer";
import CategoryListItem from "../components/categoryItem";
import { ILanguage } from "../../../../shared/models/language.model";

/**
 * Business Category List Component
 * @returns  {JSX.Element}
 */
const BusinessCategoryList = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { categories } = useSelector((state: IStoreInterface) => state.businessesReducer);
  const [selectedCategory, setSelectedCategory] = React.useState<ICategory | undefined>(undefined);

  const [searchTerm, setSearchTerm] = React.useState("");

  const [showDrawer, setShowDrawer] = React.useState<Boolean>(false);

  const [groups, setGroups] = React.useState<any>();
  const themeContext = useContext(ThemeContext);

  const [showAlert, , setAlertProps] = useAlert();
  const [languages, setLanguages] = React.useState<ILanguage[]>([
    { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
  ]);

  const [language, setLanguage] = React.useState<{
    label: string;
    key: string;
  }>({ label: languages[0].name, key: languages[0].code });

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(res => {
      setLanguages(res.data.availableLanguages);
    });
  }, []);

  const editCategory = (category: ICategory) => {
    setSelectedCategory(category);
    setShowDrawer(true);
  };

  const deleteCategory = (category: ICategory) => {
    BusinessesAPI.methods
      .deleteCategories(category)
      .then(
        () => {
          setAlertProps({
            title: t("pages.categories.edit.success.delete"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.categories.edit.error.delete"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getNewData(dispatch, showAlert, setAlertProps, t));
  };

  const revertDelete = (category: ICategory) => {
    BusinessesAPI.methods
      .restoreCategories(category)
      .then(
        () => {
          setAlertProps({
            title: t("pages.categories.edit.success.restore"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.categories.edit.error.restore"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getNewData(dispatch, showAlert, setAlertProps, t));
  };

  const createNewBusiness = () => {
    setShowDrawer(true);
  };

  const groupBy = (array: any[], key: string) => {
    const grouped = array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
    if (grouped["null"]) {
      return grouped["null"].map((noParentCategory: ICategory) => {
        return {
          groupCategory: { ...{ isGroup: true }, ...noParentCategory },
          children:
            array.filter(
              (cat: ICategory) =>
                noParentCategory.id && cat.parentCategory && cat.parentCategory === noParentCategory.id,
            ) || [],
        };
      });
    }
    return grouped;
  };

  React.useEffect(() => {
    const search = categories.data.filter(cat => {
      const languageKey = cat.name && cat.name.en === "Others" ? "en" : language.key;
      if (cat && cat.name && cat.name[languageKey]) {
        return cat.name[languageKey].toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return false;
      }
    });
    if (searchTerm && searchTerm !== "") {
      const searchGroups = search.map((cat: ICategory) => {
        return { groupCategory: cat, children: [] };
      });
      setGroups(searchGroups);
    } else {
      const groups = groupBy(categories.data, "parentCategory");
      setGroups(groups);
    }
  }, [categories.data, dispatch, language.key, searchTerm]);

  return (
    <BusinessListContainer>
      <CategoryDrawer
        isVisible={showDrawer}
        setIsVisible={setShowDrawer}
        selectedCategory={selectedCategory as ICategory}
        setSelectedCategory={setSelectedCategory}
        languages={languages}
        groups={groups}
      />

      <Row style={{ justifyContent: "space-between", marginBottom: 24 }}>
        <div style={{ width: 432 }}>
          <SearchBar
            placeholderLabel={t("pages.categories.search")}
            value={searchTerm || ""}
            onChange={e => setSearchTerm(e.target.value)}
            clearValue={() => setSearchTerm("")}
          />
        </div>
        <div id={"add-category-button"}>
          <SmallButton
            iconComponent={
              <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
                <PlusIcon />
              </IconContainer>
            }
            onClick={() => createNewBusiness()}
            titleLabel={t("pages.categories.edit.add")}
          />
        </div>
      </Row>
      <TableHeader>
        <HeaderTitle className="name">
          {t("pages.categories.parentCategory")}
          <DropdownWrapper>
            <AutoClosingDropdown
              hasValue={false}
              label={language.label}
              options={languages.map(({ code, name }) => ({ label: name, key: code }))}
              selectOption={opt => setLanguage(opt)}
            />
          </DropdownWrapper>
        </HeaderTitle>
        <HeaderTitle className="size"> {t("pages.categories.categories")}</HeaderTitle>
        <HeaderTitle className="status">{t("pages.categories.status")}</HeaderTitle>
      </TableHeader>
      {groups &&
        groups.map((group: any) => (
          <CategoryListItem
            language={language}
            key={group.groupCategory.id}
            groupItem={group}
            onDelete={deleteCategory}
            onRestore={revertDelete}
            onEdit={editCategory}
          ></CategoryListItem>
        ))}

      {groups && groups.length === 0 && (
        <ErrorContainer>
          <img src="./images/categoryIcons/document.svg" alt="empty data message" />
          <ErrorMessage>{t("pages.categories.noSearchResults")}</ErrorMessage>
        </ErrorContainer>
      )}
    </BusinessListContainer>
  );
};

export default BusinessCategoryList;

const BusinessListContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 50px;
  ${TableCell}:last-of-type {
    margin-right: 0 !important;
  }
`;

const TableHeader = styled(Row)`
  padding-right: 75px;
  padding-left: 45px;
`;

const HeaderTitle = styled.div`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  align-items: center;

  &&.name {
    flex: calc(7 / 12);
  }

  &&.size {
    flex: calc(2 / 12);
  }

  &&.status {
    flex: calc(3 / 12);
    justify-content: flex-end;
  }
`;

export const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;

export const ErrorContainer = styled.div`
  margin-top: 120px;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const DropdownWrapper = styled.div`
  > button {
    border: none;
    color: #00b0ca;
    :hover {
      border: none;
    }
    > span {
      padding: 0;
    }
  }
`;
